const Comentarios = {
	titulo: "Comentarios para ",
	alertEnviado: "Comentario enviado",
	noComentarios: "No hay comentarios aún",
	lblDejaComentario: "Deja tu comentario",
	btnAgregar: "Agregar",
	btnResponder: "Responder",
	phComentario: "Ingrese el comentario",
	lblVer: "Ver",
	lblRespuestas: "respuesta (s)",
	btnCancelar: "Cancelar",
	btnCerrar: "Cerrar",
};

export default Comentarios;
