const DashboardProveedor = {
	titulo: "Indicadores Clave",
	lblCargando: "Cargando...",
	lblServiciosPublicados: "Total de Servicios Publicados",
	lblClientesTotales: "Clientes Totales",
	lblProveedoresTotales: "Proveedores Totales",
	lblTiempoMasLento: "Tiempo más lento presentado (ms) *",
	lblErrores: "Errores *",
	lblTotalSolicitudes: "Total Solicitudes*",
	lbl30dias: "* Datos de los últimos 30 días",
	lblServiciosConsumidos: "Total de Servicios Consumidos",

	lblFechaDesde: "Fecha Desde",
	lblFechaHasta: "Fecha Hasta",
	bntCliente: "Cliente",
	ttLimpiar: "Limpiar",
	lblErroresPresentados: "Errores presentados",
	lblPeticionesConError: "Peticiones con Error",
	lblNoSeEncontroInformacion: "No se encontró información",
	lblTop5MayorTiempo: "Top 5 servicios con mayor tiempo de ejecución (ms)",
	lblTiempoDeEjecucion: "Tiempo de ejecución (ms)",

	lblTop5MenosUtilizados: "Top 5 servicios menos utilizados",
	lblPeticiones: "Peticiones",

	lblTop5MasUtilizados: "Top 5 servicios más utilizados",

	lblDesempenoEquipo: "Desempeño del equipo",
	lblTiempoPromedio: "Tiempo promedio (ms)",
	lblPeticionesPorUsuario: "Peticiones por usuario",
	lblErroresTitulo: "Errores",
	lblNoHayDatos: "No hay datos",
};

export default DashboardProveedor;
