import React from "react";
import ReactTable from "react-table";
import { callApi, showAlert, showConfirm } from "utils/Utilerias";
import { Config } from "utils/Config";
import ResponsableMtto from "./ResponsableMtto";
import NotificationAlert from "react-notification-alert";

import { Button, Card, CardHeader, UncontrolledTooltip, CardBody, CardTitle, Row, Col, Modal } from "reactstrap";

let srcData = [];
let tableWidth;
export default class Responsables extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			alert: null,
		};

		this.showConfirm = this.showConfirm.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.hideModal = this.hideModal.bind(this);
		this.llenarTabla = this.llenarTabla.bind(this);
		this.desactivarResponsable = this.desactivarResponsable.bind(this);
		this.desactivarResponsable = this.desactivarResponsable.bind(this);
	}

	componentDidMount() {
		this.llenarTabla();
	}

	/**
	 * Función 	que oculta el modal
	 * correcta y refresca la pantalla
	 */
	hideModal() {
		this.llenarTabla();
		this.setState({
			showModal: false,
		});
	}

	/**
	 * Funcion para ocultar la alerta/mensaje que fue activado
	 */
	hideAlert() {
		this.setState({
			showAlert: null,
			showConfirm: null,
		});
	}

	/**
	 * Función que se muestra un mensaje de éxito en la operación
	 * @param {String} title titulo que tendrá el mensaje mostrado
	 * @param {String} text contenido del mensaje
	 * @param {String} icon icono que mostrará el mensaje
	 */
	successAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Función que desactiva a un responsable
	 * @param {Object} objResponsable Responsable que se va a desactivar
	 */
	desactivarResponsable(objResponsable) {
		try {
			let urlWebService = Config.urlApiService + "/Responsables/" + objResponsable.ClaResponsable;

			let data = {
				claResponsable: objResponsable.ClaResponsable,
				nomResponsable: objResponsable.NomResponsable,
				bajaLogica: 1,
			};

			callApi(urlWebService, "PUT", data, (status, response) => {
				this.setState({
					isLoaded: true,
					objResponsable: response,
				});

				this.hideAlert();
				this.successAlert("El responsable se eliminó correctamente", undefined, "success");
			});
			// .then((res) => {

			// })
			// .catch((oErr) => {
			//     this.hideAlert();
			//     this.successAlert("Error", oErr, "error");
			// });

			this.llenarTabla();
		} catch (err) {
			alert(err);
		}

		this.llenarTabla();
	}

	/**
	 * Función 	para llenar la tabla incial
	 */
	llenarTabla() {
		let urlWebService = Config.urlApiService + "/Responsables";
		let data = {};

		callApi(urlWebService, "GET", data, (status, response) => {
			if (response.length <= 0) {
				srcData = [];
			} else {
				srcData = response.map((obj, key) => {
					return {
						claResponsable: obj.ClaResponsable,
						nomResponsable: obj.NomResponsable,
						Actions: (
							<div className="actions-right">
								<UncontrolledTooltip target={`btnEditar_${key}`}> Editar</UncontrolledTooltip>
								<Button
									onClick={() => {
										this.setState({
											showModal: true,
											claResponsable: obj.ClaResponsable,
										});
									}}
									color="info"
									size="sm"
									className="btn-icon btn-link"
									id={`btnEditar_${key}`}>
									<i className="fa fa-edit" />
								</Button>
								<UncontrolledTooltip target={`btnEliminar_${key}`}> Eliminar</UncontrolledTooltip>
								<Button
									onClick={() => {
										this.showConfirm(
											() => this.desactivarResponsable(obj),
											() => this.hideAlert(),
											`¿Desea desactivar el Responsable?`,
											obj.nomResponsable,
										);
										this.setState({
											claResponsable: obj.ClaResponsable,
										});
									}}
									color="danger"
									size="sm"
									className="btn-icon btn-link"
									id={`btnEliminar_${key}`}>
									<i className="fa fa-eraser" />
								</Button>
							</div>
						),
					};
				});

				this.setState({
					isLoaded: true,
					data: srcData,
				});
			}
		});
	}

	/**
	 * Función 	que muestra un mensaje en el centro de la pantalla con poder de
	 * elegir una opción
	 * @param {Function} onConfirm Función que se ejecuta al presionar el boton de confirmar
	 * @param {Function} OnCancel Función que se ejecuta al presionar el botón de cancelar
	 * @param {String} title titulo del mensaje
	 * @param {String} text cuerpo del mensaje
	 */
	showConfirm(onConfirm, onCancel, title, text) {
		const warningAlert = showConfirm(onConfirm, onCancel, title, text);

		this.setState({
			showConfirm: warningAlert,
		});
	}

	render() {
		return (
			<>
				<NotificationAlert ref={(ref) => (this.notify = ref)} />
				{this.state.showAlert}
				{this.state.showConfirm}
				<div className="content">
					<Card>
						<CardHeader>
							<Row>
								<Col>
									<CardTitle tag="h4">Catálogo Responsables</CardTitle>
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							<Col className="text-right">
								<UncontrolledTooltip target={`btnNuevo`}>Agregar Responsable</UncontrolledTooltip>
								<Button
									id={`btnNuevo`}
									onClick={() => {
										this.setState({
											showModal: true,
											claResponsable: 0,
										});
									}}
									color="success"
									size="sm"
									className="btn-simple">
									<i className="fa fa-plus" />
									{" Nuevo"}
								</Button>
							</Col>
							<div
								ref={(el) => {
									if (!el) return;
									tableWidth = el.getBoundingClientRect().width;
								}}>
								<ReactTable
									data={srcData}
									filterable={false}
									previousText="Anterior"
									nextText="Siguiente"
									loadingText="Cargando..."
									noDataText="No se encontró información"
									pageText="Página"
									ofText="de"
									rowsText="filas"
									columns={[
										{
											Header: "ID",
											accessor: "claResponsable",
											width: Math.round(tableWidth * 0.05),
											className: "text-center",
										},
										{
											Header: "Responsable",
											accessor: "nomResponsable",
											width: Math.round(tableWidth * 0.85),
										},
										{
											Header: " ",
											accessor: "Actions",
											sortable: false,
											filterable: false,
											width: Math.round(tableWidth * 0.05),
										},
									]}
									defaultPageSize={10}
									showPaginationTop={false}
									showPaginationBottom={true}
									className="-highlight secondary-pagination"
								/>
							</div>
						</CardBody>
					</Card>
				</div>

				<Modal centered={true} isOpen={this.state.showModal} size="md">
					<ResponsableMtto
						hideModal={() => {
							this.hideModal();
						}}
						claResponsable={this.state.claResponsable}
					/>
				</Modal>
			</>
		);
	}
}
