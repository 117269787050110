const TablaReplicaMtto = {
	titulo: "New Request for a table",
	lblTitulo: "Title",
	lblProducto: "Product",
	lblTabla: "Table",
	lblOrigenes: "Sources",
	lblBaseDeDatos: "Data base",
	lblServidor: "Server",
	lblDestinos: "Destinations",
	lblMotivo: "Reason for request",

	phTitulo: "Request title",
	ttTitulo: "Request title",
	phTabla: "Table name",
	ttTabla: "Table to be replicated",
	phBaseDeDatosOrigen: "Name of the source database",
	ttBaseDeDatosOrigen: "Source database",
	phServidorOrigen: "Name of the source server",
	ttServidorOrigen: "Source server",
	phBaseDeDatosDestino: "Name of the destination database",
	ttBaseDeDatosDestino: "Destination database",
	phServidorDestino: "Destination server name",
	ttServidorDestino: "Destination server",

	btnGuardar: "Save",
	btnCerrar: "Close",

	noDescripcion: "No description found",
	ttProducto: "Product to which the replica is requested",
	phMotivo: "Detailed reason why the request is made",
};

export default TablaReplicaMtto;
