const FiltroMtto = {
	titulo: "Filtrar",
	colNombre: "Nombre",
	colServiciosConsumidos: "Servicios Consumidos",
	colTotalErrores: "Total Errores",
	colTiempoMasLento: "Tiempo más lento (ms)",
	colActividad: "Actividad(%)",
	btnGuardar: "Guardar",
	btnCancelar: "Cancelar",
	alertSeleccionarSoloUno: "Favor de seleccionar solo un ",
	alertSeleccionarUn: "Favor de seleccionar un ",
	cargando: "Cargando...",
	cliente: "cliente",
	proveedor: "proveedor",
};

export default FiltroMtto;
