const MisServiciosSuscritos = {
	titulo: "My Suscriptions",
	lblServicio: "Service",
	lblGlosario: "Glossary",
	btnAgregar: " Add",
	lblTotalDeRegistros: "Total records",
	lblProducto: "Product",
	colNombre: "Name",
	colProducto: "Product",
	colServicio: "Service",
	phNombre: " Name, description or id of the service",
	phTerminosRelacionados: "Related terms",
	ttLimpiar: "Clear",
	ttBuscar: "Search",
	ttVerificado: "Verified service",
	ttNoVerificado: "The service is not verified",
	ttEditar: "Edit",
	ttComentarios: "Comments",
	ttEliminar: "Delete suscription",
	ttInformacion: "Information",
	alertEliminarSuscripcion: "Unsubscribe was successful",
};

export default MisServiciosSuscritos;
