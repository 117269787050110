/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink, Route } from "react-router-dom";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, Collapse } from "reactstrap";

import { setSessionData } from "utils/Utilerias";

var ps;

class Sidebar extends React.Component {
	constructor(props) {
		super(props);

		this.state = this.getCollapseStates(props.routes);
		this.createLinks = this.createLinks.bind(this);
		this.activeRoute = this.activeRoute.bind(this);
	}
	// this creates the intial state of this component based on the collapse routes
	// that it gets through this.props.routes
	getCollapseStates(routes) {
		let initialState = {};
		routes.map((prop, key) => {
			if (prop.collapse) {
				initialState = {
					[prop.state]: this.getCollapseInitialState(prop.views),
					...this.getCollapseStates(prop.views),
					...initialState,
				};
			}
			return null;
		});
		return initialState;
	}
	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	// while on the src/views/forms/RegularForms.js - route /admin/regular-forms
	getCollapseInitialState(routes) {
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
				return true;
			} else if (window.location.href.indexOf(routes[i].path) !== -1) {
				return true;
			}
		}
		return false;
	}
	// this function creates the links and collapses that appear in the sidebar (left menu)
	createLinks(routes) {
		const { rtlActive } = this.props;
		return routes.map((prop, key) => {
			if (prop.esVisible === 0) {
				return null;
			}

			if (prop.esAyuda && prop.esAyuda === 1) {
				return (
					<li className={this.activeRoute(prop.layout + prop.path)} key={key}>
						<a className="sidebar-normal" target="_blank" href="https://dlabs.gitbook.io/kraken/">
							<i className={prop.icon} />
							<p>{rtlActive ? prop.rtlName : prop.name}</p>
						</a>
					</li>
				);
			}

			if (prop.redirect) {
				return null;
			}

			if (prop.collapse) {
				var st = {};
				st[prop["state"]] = !this.state[prop.state];

				return (
					<li
						id={`mnu_${prop.claMenu}`}
						className={this.getCollapseInitialState(prop.views) ? "active" : ""}
						key={key}>
						<a
							href="#pablo"
							data-toggle="collapse"
							aria-expanded={this.state[prop.state]}
							onClick={(e) => {
								e.preventDefault();
								this.setState(st);
							}}>
							{prop.icon !== undefined ? (
								<>
									<i className={prop.icon} />
									<p>
										{rtlActive ? prop.rtlName : prop.name}
										<b className="caret" />
									</p>
								</>
							) : (
								<>
									<span className="sidebar-mini-icon">{rtlActive ? prop.rtlMini : prop.mini}</span>
									<span className="sidebar-normal">
										{rtlActive ? prop.rtlName : prop.name}
										<b className="caret" />
									</span>
								</>
							)}
						</a>
						<Collapse isOpen={this.state[prop.state]}>
							<ul className="nav">{this.createLinks(prop.views)}</ul>
						</Collapse>
					</li>
				);
			}
			return (
				<li className={this.activeRoute(prop.layout + prop.path)} key={key}>
					<NavLink
						to={prop.layout + prop.path}
						activeClassName=""
						id={`mnu_${prop.claMenu}`}
						onClick={() => {
							this.props.closeSidebar;
							setSessionData({
								url: prop.layout + prop.path,
							});
						}}>
						{prop.icon !== undefined ? (
							<>
								<i className={prop.icon} />
								<p>{rtlActive ? prop.rtlName : prop.name}</p>
							</>
						) : (
							<>
								<span className="sidebar-mini-icon">{rtlActive ? prop.rtlMini : prop.mini}</span>
								<span className="sidebar-normal">{rtlActive ? prop.rtlName : prop.name}</span>
							</>
						)}
					</NavLink>
				</li>
			);
		});
	}
	// verifies if routeName is the one active (in browser input)
	activeRoute(routeName) {
		return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
	}
	componentDidMount() {
		//if you are using a Windows Machine, the scrollbars will have a Mac look
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(this.refs.sidebar, {
				suppressScrollX: true,
				suppressScrollY: false,
			});
		}
	}
	componentWillUnmount() {
		// we need to destroy the false scrollbar when we navigate
		// to a page that doesn't have this component rendered
		// if (navigator.platform.indexOf("Win") > -1) {
		//   ps.destroy();
		// }
	}
	render() {
		const { activeColor, logo } = this.props;
		let logoImg = null;
		let logoText = null;
		if (logo !== undefined) {
			if (logo.outterLink !== undefined) {
				logoImg = (
					<a
						href={logo.outterLink}
						className="simple-text logo-mini"
						target="_blank"
						onClick={this.props.closeSidebar}>
						<div className="logo-img">
							<img src={logo.imgSrc} alt="react-logo" />
						</div>
					</a>
				);
				logoText = (
					<a
						href={logo.outterLink}
						className="simple-text logo-normal"
						target="_blank"
						onClick={this.props.closeSidebar}>
						{logo.text}
					</a>
				);
			} else {
				logoImg = (
					<NavLink to={logo.innerLink} className="simple-text logo-mini" onClick={this.props.closeSidebar}>
						<div className="logo-img">
							<img src={logo.imgSrc} alt="react-logo" />
						</div>
					</NavLink>
				);
				logoText = (
					<NavLink to={logo.innerLink} className="simple-text logo-normal" onClick={this.props.closeSidebar}>
						{logo.text}
					</NavLink>
				);
			}
		}
		return (
			<div className="sidebar" data={activeColor} id="ctnMenu">
				<div className="sidebar-wrapper" ref="sidebar">
					{logoImg !== null || logoText !== null ? (
						<div className="logo" id="ctnMnuLogo">
							{logoImg}
							{logoText}
						</div>
					) : null}
					<Nav>{this.createLinks(this.props.routes)}</Nav>
				</div>
			</div>
		);
	}
}

Sidebar.propTypes = {
	activeColor: PropTypes.oneOf(["primary", "blue", "green", "orange", "red"]),
	rtlActive: PropTypes.bool,
	routes: PropTypes.array.isRequired,
	logo: PropTypes.oneOfType([
		PropTypes.shape({
			innerLink: PropTypes.string.isRequired,
			imgSrc: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
		PropTypes.shape({
			outterLink: PropTypes.string.isRequired,
			imgSrc: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	]),
	// this is used on responsive to close the sidebar on route navigation
	closeSidebar: PropTypes.func,
};

export default Sidebar;
