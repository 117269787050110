import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import ErrorHandler from "utils/ErrorHandler";
import { FlagsProvider } from "react-feature-flags";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";

import Login from "./views/Login/Login";
import Error from "./views/Error/Error";
import SeleccionProducto from "./views/Login/SeleccionProducto";
import Profile from "./views/Login/isAuthenticated";
import AutorizarSuscripcion from "views/Suscripcion/AutorizarSuscripcion";
import AutorizarPeticionTabla from "views/Replicas/AutorizarPeticionTabla";
import AdminLayout from "layouts/Admin/Admin";

import "assets/scss/black-dashboard-pro-react.scss?v=1.1.0";
import "assets/css/kraken.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "devextreme/dist/css/dx.common.css";
import "assets/css/dx.generic.kraken-compact.css";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";

const hist = createBrowserHistory();
const flags = [
	{ name: "Pruebas", isActive: true },
	{ name: "Usuario Normal", isActive: true },
];

ReactDOM.render(
	<FlagsProvider value={flags}>
		<React.StrictMode>
			<ErrorHandler>
				<Router history={hist}>
					<Auth0ProviderWithHistory>
						<Switch>
							<Redirect exact from="/" to="/Login" />
							{/* <Redirect exact from="/SolicitudSuscripcion" to="/Login" /> */}
							<Route path="/Login" component={Login} />
							<Route path="/Error" component={Error} />
							<Route path="/auth" component={Profile} />
							<Route path="/SeleccionProducto" component={SeleccionProducto} />
							<Route path="/AutorizarSuscripcion" render={(props) => <AutorizarSuscripcion {...props} />} />
							<Route path="/AutorizarPeticionTabla" render={(props) => <AutorizarPeticionTabla {...props} />} />
							<Route path="/admin/Producto" render={(props) => <AdminLayout {...props} />} />
							<Route path="/admin/Home" render={(props) => <AdminLayout {...props} />} />
							<Route path="/admin/ServiciosDisponibles" render={(props) => <AdminLayout {...props} />} />
							<Route path="/admin/SolicitudServicio" render={(props) => <AdminLayout {...props} />} />
							<Route path="/admin/Entidades" render={(props) => <AdminLayout {...props} />} />
							<Route path="/admin/BitacoraUso" render={(props) => <AdminLayout {...props} />} />
							<Route path="/admin/MisEntidades" render={(props) => <AdminLayout {...props} />} />
							<Route path="/admin/DashboardProveedor" render={(props) => <AdminLayout {...props} />} />
							<Route path="/admin/DashboardCliente" render={(props) => <AdminLayout {...props} />} />
							<Route path="/admin/Dashboard" render={(props) => <AdminLayout {...props} />} />
							<Route path="/admin/Seguridad" render={(props) => <AdminLayout {...props} />} />
							<Route path="/admin/Replicas" render={(props) => <AdminLayout {...props} />} />
							<Route path="/admin/Glosario" render={(props) => <AdminLayout {...props} />} />
						</Switch>
					</Auth0ProviderWithHistory>
				</Router>
			</ErrorHandler>
		</React.StrictMode>
	</FlagsProvider>,
	document.getElementById("root"),
);
