import React from "react";
import { Row, Col, UncontrolledTooltip } from "reactstrap";
import PropTypes from "prop-types";
import { callApi } from "utils/Utilerias";
import { Config } from "../../utils/Config";
import translate from "utils/translate";

let srcData = {};
export default class IndicadoresGenerales extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		};
	}

	componentDidMount() {
		this.fillData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.claCliente !== prevProps.claCliente || this.props.claProducto !== prevProps.claProducto) {
			this.setState({
				isLoaded: false,
			});
			this.fillData();
		}
	}

	fillData() {
		//Obtener información general
		let urlService = `${Config.urlApiService}/Dashboard2`;
		let data = {
			ClaProducto: this.props.claProducto,
			ClaCliente: this.props.claCliente,
			ClaConsulta: 1,
		};

		callApi(urlService, "POST", data, (status, response) => {
			if (status === 200 && response.length > 0) {
				srcData = response[0];
			} else {
				srcData = {
					ServiciosConsumidos: 0,
					TiempoMasLento: 0,
					TotalClientes: 0,
					TotalErrores: 0,
					TotalPeticiones: 0,
					TotalProveedores: 0,
					TotalServicios: 0,
				};
			}

			this.setState({
				isLoaded: true,
			});
		});
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<div className="row justify-content-center p-5">
					<div className="spinner-border text-info" style={{ backgroundColor: "transparent" }} role="status">
						<span className="sr-only">{translate("DashboardProveedor.lblCargando")}</span>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<Row className="text-default text-center justify-content-center">
						{this.props.claProducto !== undefined && (
							<Col md={2}>
								<span className="display-3 text-primary">{srcData.TotalServicios}</span>
								<br /> {translate("DashboardProveedor.lblServiciosPublicados")}
							</Col>
						)}
						{this.props.claCliente !== undefined && (
							<Col md={2}>
								<span className="display-3 text-primary">{srcData.ServiciosConsumidos}</span>
								<br />
								{translate("DashboardProveedor.lblServiciosConsumidos")}
							</Col>
						)}

						<Col
							md={2} //className="border-right"
						>
							{this.props.claProducto ? (
								<>
									<span className="display-3 text-primary">{srcData.TotalClientes} </span>
									<br /> {translate("DashboardProveedor.lblClientesTotales")}
								</>
							) : (
								<>
									<span className="display-3 text-primary">{srcData.TotalProveedores} </span>
									<br /> {translate("DashboardProveedor.lblProveedoresTotales")}
								</>
							)}
						</Col>
						<Col md={2}>
							<UncontrolledTooltip placement="top" target={`txtTiempoMasLento`}>
								{srcData.TiempoMasLento}
							</UncontrolledTooltip>
							<span id="txtTiempoMasLento" className="display-3 text-primary">
								{srcData.TiempoMasLento.toFormatNumber()}
							</span>
							<br /> {translate("DashboardProveedor.lblTiempoMasLento")}
						</Col>
						<Col md={2}>
							<UncontrolledTooltip placement="top" target={`txtTotalErrores`}>
								{srcData.TotalErrores}
							</UncontrolledTooltip>
							<span id="txtTotalErrores" className="display-3 text-primary">
								{srcData.TotalErrores.toFormatNumber()}
							</span>
							<br /> {translate("DashboardProveedor.lblErrores")}
						</Col>
						<Col md={2}>
							<UncontrolledTooltip placement="top" target={`txtTotalPeticiones`}>
								{srcData.TotalPeticiones}
							</UncontrolledTooltip>
							<span id="txtTotalPeticiones" className="display-3 text-primary">
								{srcData.TotalPeticiones.toFormatNumber()}
							</span>
							<br />
							{translate("DashboardProveedor.lblTotalSolicitudes")}
						</Col>
					</Row>
					<Row className="text-right">
						<Col>
							<label>{translate("DashboardProveedor.lbl30dias")}</label>
						</Col>
					</Row>
				</div>
			);
		}
	}
}

IndicadoresGenerales.propTypes = {
	claProducto: PropTypes.number,
	claCliente: PropTypes.number,
};
