const UsuariosMtto = {
	titulo: "Nuevo Usuario",
	lblClaveEmpleado: "Clave Empleado",
	phClaveEmpleado: "Ej. 909090",
	lblNombreEmpleado: "Nombre Empleado",
	phNombreEmpleado: "Se obtiene al buscar una Clave Empleado válida",
	lblPerfil: "Perfil",
	lblNotificarCorreo: "Notificar por correo",
	btnGuardar: "Guardar",
	btnCancelar: "Cancelar",
	btnCerrar: "Cerrar",
	lblNombreExterno: "Nombre",
	lblApellidoExterno: "Apellido",
	lblCorreo: "Correo",
	phNombreExterno: "Ej. Juan",
	phApellidoExterno: "Ej. Garza",
	phCorreo: "Ej. abcd@ejemplo.com",

	ttBuscar: "Buscar",
	ttDesarrollador: "Desarrollador",
	ttDescripcionDesarrollador: "Usuario estándar",
	ttPM: "Product Manager",
	ttPMDescripcion: "Administrador del sistema, tiene acceso a todas las páginas",
	ttCorreo: "Notificar por correo en caso de errores en servicios del producto",
	ttExterno: "Poner información correspondiente para miembros que no son parte de Deacero",
	ttAccesoExt: "Acceso a Usuarios Externos",

	alertClaveValido: "Favor de ingresar una clave de empleado válida",
	alertUsuarioInvalido: "El usuario ingresado no éxiste",
	alertRegistroGuardado: "Registro guardado con éxito",
	alertCambiosRealizados: "Cambios realizados con éxito",
	noDescripcion: "No se encontró descripción",
	cargando: "Cargando...",
};

export default UsuariosMtto;
