import React from "react";
import {
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	Form,
	FormGroup,
	Row,
	Col,
	Button,
	Label,
	CardTitle,
} from "reactstrap";
import { Formik, Field } from "formik";
import PropTypes from "prop-types";

import { Config } from "utils/Config";
import { callApi } from "utils/Utilerias";

export default class ResponsableMtto extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
		};

		if (this.props.claResponsable === 0) {
			this.setState({
				isLoaded: true,
				objResponsable: { ClaResponsable: 0, NomResponsable: "" },
			});
		}

		this.btnCancelar_Click = this.btnCancelar_Click.bind(this);
		this.handleSubmitAceptar = this.handleSubmitAceptar.bind(this);
	}

	componentDidMount() {
		//Si es un nuevo registro, claResponsable será igual a 0

		let data = {};
		let urlWebService = Config.urlApiService + "/Responsables/" + this.props.claResponsable;
		callApi(urlWebService, "GET", data)
			.then((res) => {
				this.setState({
					isLoaded: true,
					objResponsable: res[0],
				});
			})
			.catch((oErr) => {
				alert(oErr);
			});
	}

	/**
	 * Función del botón para cancelar la operación
	 * y ocultar el modal sin guardar ningún cambio
	 */
	btnCancelar_Click() {
		this.props.hideModal();
	}

	/**
	 * Función del botón para guardar cambios en el objeto responsable
	 * @param {JSON} value los valores del formulario
	 */
	handleSubmitAceptar(value) {
		try {
			let method = "PUT"; //Editar

			if (this.props.claResponsable === 0) {
				method = "POST"; //Agregar
			}

			let urlWebService = Config.urlApiService + "/Responsables/" + this.props.claResponsable;
			callApi(urlWebService, method, value)
				.then((res) => {
					this.setState({
						isLoaded: true,
						objResponsable: res,
					});
					this.btnCancelar_Click();
				})
				.catch((oErr) => {
					alert(oErr);
				});
		} catch (err) {
			alert(err);
		}
	}

	render() {
		if (!this.state.isLoaded) {
			return <div>Cargando...</div>;
		}
		return (
			<div className="content">
				<Formik
					initialValues={{
						claResponsable: this.state.objResponsable.ClaResponsable,
						nomResponsable: this.state.objResponsable.NomResponsable,
					}}
					onSubmit={this.handleSubmitAceptar}>
					{(props) => (
						<Form onSubmit={props.handleSubmit}>
							<Card>
								<CardHeader>
									<Row>
										<Col>
											<CardTitle>Mantenimiento de Responsables</CardTitle>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<div className="flex-row">
										<div className="col-12">
											<FormGroup row>
												<Col md={12}>
													<Label for="nomResponsable">Nombre</Label>
													<Field
														name="nomResponsable"
														type="input"
														className="form-control"
														placeholder="Nombre del responsable"
														required
													/>
												</Col>
											</FormGroup>
										</div>
									</div>
								</CardBody>
								<CardFooter>
									<Col md={12} className="text-right">
										<Button
											id="btnGuardar"
											className="animation-on-hover"
											color="success"
											size="sm"
											type="submit">
											Guardar
										</Button>

										<Button
											id="btnCancelar"
											className="animation-on-hover"
											color="default"
											size="sm"
											onClick={() => this.btnCancelar_Click()}>
											Cancelar
										</Button>
									</Col>
								</CardFooter>
							</Card>
						</Form>
					)}
				</Formik>
			</div>
		);
	}
}

ResponsableMtto.propTypes = {
	claResponsable: PropTypes.number,
	hideModal: PropTypes.func,
};
