const Producto = {
	navGeneral: "General",
	navConsulta: "Consulta",
	navTransaccional: "Transaccional",
	lblNomProducto: "Nombre del Producto",
	lblApiKey: "API Key",
	lblClientId: "Client Id",
	lblClientSecret: "Client Secret",
	lblDescripcion: "Descripción",
	phDescripcion: "Descripción detallada del producto",
	lblAgregaServidorConsulta: "Agrega un servidor de consulta",
	lblServidor: "Servidor:",
	phNombreServidor: "Nombre del servidor",
	lblUsuario: "Usuario:",
	phUsuario: "Usuario",
	lblBaseDatos: "Base Datos:",
	phBaseDatos: "Nombre de la base de datos",
	lblPassword: "Password:",
	phPassword: "Contraseña",
	lblEsProductivo: "Es productivo",
	btnAgregar: "Agregar",
	lblServidoresRegistrados: "Servidores registrados",
	colNombreServidor: "Nombre Servidor",
	colBaseDatos: "Base Datos",
	colUsuario: "Usuario",
	colDescripcion: "Descripción",
	colProductivo: "Productivo",
	lblAgregaServidorTransaccional: "Agrega un servidor transaccional",
	alertCambioExitoso: "Aviso",
	alertCambioExitosoDescripcion: "Cambios realizados con éxito",
	phServidor: "Servidor",
	pbBaseDatos: "Base de datos",
	btnActualizar: "Actualizar",
	btnGuardar: "Guardar",
	cargando: "Cargando...",
};

export default Producto;
