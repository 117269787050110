import React from "react";
import NotificationAlert from "react-notification-alert";
import { Card, CardHeader, CardBody, Button, UncontrolledTooltip, Modal, Row, Col } from "reactstrap";
import { getProducto, callApi, writeLog } from "utils/Utilerias";
import { Config } from "utils/Config";
import { Formularios } from "utils/funcionalidad";
import SolicitudServicioMtto from "../SolicitudServicios//SolicitudServicioMtto";
import translate from "utils/translate";

let srcData = [];
let formularioDet = [];

export default class EstatusMisSolicitudes extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			showsSolicitudModal: false,
			peticiones: [],
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		};

		this.load = this.load.bind(this);
		this.hideSolicitudModal = this.hideSolicitudModal.bind(this);
	}

	selectColorBadge(nomEstatus) {
		switch (nomEstatus.toUpperCase()) {
			case "AUTORIZADO":
				return "info";

			case "CAPTURADO":
				return "secondary";

			case "ACEPTADO":
				return "success";

			case "RECHAZADO":
				return "warning";

			default:
				return;
		}
	}

	componentDidMount() {
		this.load();
	}

	load() {
		//Help tooltips
		let urlService = `${Config.urlApiService}/CatFormularioDet/8`;
		callApi(urlService, "GET", null, (status, response) => {
			formularioDet = response;
		});

		//Obtener información
		urlService = `${Config.urlApiService}/Home/EstatusSolicitudes/${getProducto()}`;

		callApi(urlService, "GET", null, (status, response) => {
			// console.log("estatus solicitudes", response);
			if (status === 200) {
				srcData = response.map((obj, key) => {
					return {
						IdPeticion: obj.IdPeticion,
						Titulo: obj.Titulo,
						NomProducto: obj.NomProducto,
						FechaPromesa: obj.FechaPromesa !== null ? obj.FechaPromesa.toDateNoTime() : "N/A",
						NomEstatus: obj.NomEstatus,
						VerDetalle: (
							<>
								<UncontrolledTooltip placement="right" target={`btnVerDetalle_${key}`}>
									<span>{translate("HomeDev.ttInformacion")}</span>
								</UncontrolledTooltip>
								<Button
									id={`btnVerDetalle_${key}`}
									className="btn btn-icon btn-link"
									color="info"
									size="sm"
									onClick={() => {
										writeLog(
											Formularios.EstatusMisSolicitudes.ClaFuncionalidad,
											Formularios.EstatusMisSolicitudes.Funcionalidades.btnVerDetalle,
											undefined,
											obj,
										);
										this.setState({
											showSolicitudModal: true,
											idPeticion: obj.IdPeticion,
										});
									}}>
									<i className="fas fa-info-circle" />
								</Button>
							</>
						),
					};
				});
				this.setState({
					isLoaded: true,
					peticiones: srcData,
					claIdioma: sessionStorage.getItem("ClaIdioma"),
				});
			}
		});
	}
	/**
	 * Función 	que oculta el modal
	 * correcta y refresca la pantalla
	 */
	hideSolicitudModal(isOk) {
		this.setState({
			showSolicitudModal: false,
		});

		if (isOk) {
			var options = {
				place: "br",
				message: "Operación realizada correctamente.",
				type: "success",
				autoDismiss: 3,
			};

			this.notify.notificationAlert(options);
		}
	}

	renderDetTexto(nomControl) {
		let detalle = formularioDet.filter((detalle) => detalle.NomControl === nomControl);
		if (detalle[0]) return detalle[0].Texto;
		else return "No se encontró descripción";
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<div className="spinner-border text-info" role="status">
					<span className="sr-only">{translate("HomeDev.lblCargando")}</span>
				</div>
			);
		} else {
			return (
				<>
					{sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.load()}
					<NotificationAlert ref={(ref) => (this.notify = ref)} />
					<Card id="ctnEstatusMisSolicitudes">
						<CardHeader>
							<UncontrolledTooltip placement="right" target={`helpEstatusSolicitudes`}>
								<span>{this.renderDetTexto("hdrEstatusMisSolicitudes")}</span>
							</UncontrolledTooltip>
							<Row>
								<Col md={8}>
									<h2>
										{translate("HomeDev.ttEstatusMisSolicitudes")} &nbsp;
										<span id="helpEstatusSolicitudes">
											<i className="far fa-question-circle"></i>
										</span>
									</h2>
								</Col>
								<Col md={4} className="text-right">
									<Button
										id="btnNuevo"
										onClick={() => {
											writeLog(
												Formularios.EstatusMisSolicitudes.ClaFuncionalidad,
												Formularios.EstatusMisSolicitudes.Funcionalidades.btnNuevo,
											);

											this.setState({
												showSolicitudModal: true,
												idPeticion: 0,
											});
										}}
										size="sm"
										className="btn-link btn-home">
										{translate("HomeDev.lblCrearSolicitud")}
									</Button>
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							<div className="misSolicitudes">
								<table className="table table-sm table-noBorder">
									<thead>
										<tr>
											<th className="w-40">{translate("HomeDev.colSolicitud")}</th>
											<th
												className="w-20"
												style={{
													textAlign: "center",
												}}>
												{translate("HomeDev.colEstatus")}
											</th>
											<th className="w-20">{translate("HomeDev.colProveedor")}</th>
											<th
												className="w-15"
												style={{
													textAlign: "center",
												}}>
												{translate("HomeDev.colFechaPromesa")}
											</th>
											<th className="w-5"></th>
										</tr>
									</thead>
									<tbody>
										{(this.state.peticiones.length > 0 &&
											this.state.peticiones.map((peticion, index) => {
												return (
													<tr key={`peticion_${index}`}>
														<td>{peticion.Titulo}</td>
														<td
															style={{
																textAlign: "center",
															}}>
															{/* <Badge color={this.selectColorBadge(peticion.NomEstatus)}> */}
															{peticion.NomEstatus}
															{/* </Badge> */}
														</td>
														<td>{peticion.NomProducto}</td>
														<td style={{ textAlign: "center" }}>{peticion.FechaPromesa}</td>
														<td className="w-10 text-right"> {peticion.VerDetalle} </td>
													</tr>
												);
											})) || (
											<tr>
												<td colSpan={5} style={{ textAlign: "center", height: "105px" }}>
													<p>
														{translate("HomeDev.lblSolicitudesPendientes")}
														<span className="btn-icon btn-link text-success">
															<i className="fas fa-check"></i>
														</span>
													</p>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</CardBody>
					</Card>
					<Modal centered={true} isOpen={this.state.showSolicitudModal} size="lg">
						<SolicitudServicioMtto
							hideModal={this.hideSolicitudModal}
							pantallaEntidad={false}
							idPeticion={this.state.idPeticion}
							claProducto={getProducto()}
						/>
					</Modal>
				</>
			);
		}
	}
}
