const DashboardCliente = {
	titulo: "Key Indicators",
	lblFechaDesde: "From:",
	lblFechaHasta: "To:",
	ttLimpiar: "Clear",
	lblErroresTitulo: "Errors",
	lblPeticionesPorProveedor: "Requests per provider",
	lblTop5MayorTiempo: "Top 5 services with the longest execution time (ms)",
	lblTop5MasUtilizados: "Top 5 most used services",

	lblPeticiones: "Requests",
	lblSolicitudesPorHora: "Requests per hour",
	lblErroresPorHora: "Errors per hour",
	lblPorcentajeError: " % Error",
	lblClientesAfectados: "Afected Clients",
	lblCerrar: "Close",
};

export default DashboardCliente;
