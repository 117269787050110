import React from "react";
import PropTypes from "prop-types";
import {
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	CardTitle,
	Form,
	FormGroup,
	Row,
	Col,
	Button,
	Label,
	UncontrolledTooltip,
	Badge,
} from "reactstrap";
import { Formik, Field, FieldArray } from "formik";
import translate from "utils/translate";

import {
	callApi,
	getLoginInfo,
	getNomProducto,
	getProducto,
	showAlert,
	showConfirm,
	writeLog,
	showSweetAlert,
} from "utils/Utilerias";

import { Formularios } from "utils/funcionalidad";
import { Config } from "utils/Config";
let productos;
let tipoDato;
let formularioDet = [];

export default class SolicitudServicioMtto extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: "general",
			esGuardado: false,
			idPeticion: this.props.idPeticion,
		};

		this.handleSubmitButton = this.handleSubmitButton.bind(this);
		this.btnCancelarClick = this.btnCancelarClick.bind(this);
	}

	async componentDidMount() {
		await this.load();
	}

	/**
	 * Función que oculta el modal
	 */
	btnCancelarClick(isOk) {
		this.props.hideModal(isOk);
	}

	/**
	 * Función que envia los datos a la base de datos
	 * @param {JSON} value Valores ingresados en el formulario
	 */
	handleSubmitButton(value) {
		let urlService = `${Config.urlApiService}/SolicitudServicio/${this.props.claProducto}/${this.state.idPeticion}`;
		const method = this.state.idPeticion > 0 || this.state.peticion.IdPeticion ? "PUT" : "POST";
		if (this.state.idPeticion > 0) value.IdPeticion = this.state.idPeticion;

		value.BajaLogica = 0;
		value.ClaEstatus = 1;
		value.FechaPromesa = this.state.peticion.FechaPromesa;
		value.ClaUsuarioAsignacion = this.state.peticion.ClaUsuarioAsignacion;
		value.ClaUsuarioSolicita = this.state.peticion.ClaUsuarioSolicita;
		value.IdEntidad = this.state.peticion.IdEntidad;
		value.ClaProductoSolicita = getProducto();
		//Se comenta la validacion debido a que ahora es realizada por la API.
		//Se elimina la funcion.	11112020
		//this.fnValidar(value);

		let sinTipoDato = false;

		if (value.FechaPromesa !== null && value.FechaPromesa !== undefined) {
			value.FechaPromesa = new Date(value.FechaPromesa);
			value.FechaPromesa = value.FechaPromesa.toSqlDateTime();
		}

		if (value.FechaCaptura !== null && value.FechaCaptura !== undefined) {
			value.FechaCaptura = new Date(value.FechaCaptura);
			value.FechaCaptura = value.FechaCaptura.toSqlDateTime();
		}

		if (value.Detalles.length > 0) {
			value.Detalles.forEach((element) => {
				if (element.ClaTipoDato === 0) {
					this.showAlert("", translate("SolicitudMtto.alertEntradasSalidas"), "warning");
					sinTipoDato = true;
					return;
				}
			});
		}

		if (value.ClaProducto === 0) {
			this.showAlert("", translate("SolicitudMtto.alertProductoSolicitud"), "warning");
			return;
		}

		if (!sinTipoDato) {
			callApi(
				urlService,
				method,
				value,
				(status, response) => {
					if (status === 200) {
						writeLog(
							Formularios.SolicitudServicioMtto.ClaFuncionalidad,
							Formularios.SolicitudServicioMtto.Funcionalidades.btnGuardar,
							urlService,
							value,
							response,
							"Operación realizada con éxito.",
							0,
						);

						let mensaje =
							this.state.peticion.IdPeticion === 0
								? translate("SolicitudMtto.alertRegistroGuardado")
								: translate("SolicitudMtto.alertCambioGuardado");

						showSweetAlert(mensaje, "", "success");
						this.setState({
							esGuardado: true,
							idPeticion: response.IdPeticion,
						});

						this.load();
					}
				},
				Formularios.SolicitudServicioMtto.ClaFuncionalidad,
				Formularios.SolicitudServicioMtto.Funcionalidades.btnGuardar,
			);
		}
	}

	/**
	 *
	 * @param {json} tab
	 * Muestra el tab activo
	 */
	renderNavItem(tab) {
		return (
			<NavItem>
				<NavLink
					id={tab.Id}
					className={this.state.activeTab === tab.Name ? "active" : ""}
					onClick={() => this.setState({ activeTab: tab.Name })}>
					{tab.Title}
				</NavLink>
			</NavItem>
		);
	}

	renderNoInfo() {
		return (
			<tr>
				<td colSpan={3} className="text-center">
					{translate("SolicitudMtto.noInfo")}
				</td>
			</tr>
		);
	}

	/**
	 *
	 * @param {JSON} obj objeto al que se le modificara el estatus o asociara una entidad
	 * quiere decir que ya se documentó en la pantalla de entidades
	 */
	editarEstatus(obj) {
		let urlWebService = Config.urlApiService + "/SolicitudServicio/" + obj.ClaProducto + "/" + obj.IdPeticion;
		let data = {
			...obj,
		};

		if (obj.FechaPromesa !== null) {
			obj.FechaPromesa = new Date(obj.FechaPromesa);
			data.FechaPromesa = obj.FechaPromesa.toSqlDateTime();
			obj.FechaCaptura = new Date(obj.FechaCaptura);
			data.FechaCaptura = obj.FechaCaptura.toSqlDateTime();
		} else {
			data.FechaPromesa = null;
		}

		data.BajaLogica = 0;

		data.ClaEstatus = obj.ClaEstatus + 1;

		callApi(
			urlWebService,
			"PUT",
			data,
			(status, response) => {
				if (status === 200) {
					writeLog(
						Formularios.SolicitudServicioMtto.ClaFuncionalidad,
						Formularios.SolicitudServicioMtto.Funcionalidades.btnEnviar,
						urlWebService,
						data,
						response,
						"Estatus cambiado con éxito",
						0,
					);
					this.showAlert("", translate("SolicitudMtto.alertEstatusCambiado"), "success");
					this.btnCancelarClick(true);
				}
			},
			Formularios.SolicitudServicioMtto.ClaFuncionalidad,
			Formularios.SolicitudServicioMtto.Funcionalidades.btnEnviar,
		);
	}

	/**
	 *
	 * @param {JSON} obj objeto al que se le modificara el estatus
	 */
	rechazarSolicitud(obj) {
		let urlWebService = Config.urlApiService + "/SolicitudServicio/" + obj.ClaProducto + "/" + obj.IdPeticion;
		let data = {
			...obj,
		};

		if (obj.FechaPromesa !== null) {
			obj.FechaPromesa = new Date(obj.FechaPromesa);
			data.FechaPromesa = obj.FechaPromesa.toSqlDateTime();
			obj.FechaCaptura = new Date(obj.FechaCaptura);
			data.FechaCaptura = obj.FechaCaptura.toSqlDateTime();
		} else {
			data.FechaPromesa = null;
		}
		data.BajaLogica = 0;

		data.ClaEstatus = 9;

		callApi(
			urlWebService,
			"PUT",
			data,
			(status, response) => {
				writeLog(
					Formularios.SolicitudServicioMtto.ClaFuncionalidad,
					Formularios.SolicitudServicioMtto.Funcionalidades.btnRechazar,
					urlWebService,
					data,
					response,
					"Estatus cambiado con éxito",
					0,
				);
				if (status === 200) {
					this.showAlert("", translate("SolicitudMtto.alertEstatusCambiado"), "success");
					this.btnCancelarClick(true);
				}
			},
			Formularios.SolicitudServicioMtto.ClaFuncionalidad,
			Formularios.SolicitudServicioMtto.Funcionalidades.btnRechazar,
		);
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		//error.stack.split("\n")[1].trim().split(" ")[1].split(".");
		//[0] - Funcionalidad/Pantalla
		//[1] - Detalle

		writeLog(0, 0, undefined, undefined, errorInfo.componentStack.toString(), error.message.toString(), 1);

		this.setState({
			error,
			errorInfo,
		});
		// You can also log error messages to an error reporting service here
	}

	/**
	 * Carga inicial de los catalogos en pantalla
	 *
	 * @returns
	 * @memberof EntidadMtto
	 */
	load() {
		let urlService = ``;

		urlService = `${Config.urlApiService}/Catalogos/3`;
		callApi(urlService, "GET", null, (status, response) => {
			tipoDato = response;
		});

		urlService = `${Config.urlApiService}/Catalogos/11`;
		callApi(urlService, "GET", null, (status, response) => {
			productos = response;
			if (this.state.idPeticion === 0) {
				let claveProducto = parseInt(getProducto());
				productos = productos.filter((producto) => producto.ClaProducto !== claveProducto);
			}
		});

		urlService = `${Config.urlApiService}/CatFormularioDet/1`;
		callApi(urlService, "GET", null, (status, response) => {
			if (status === 200) {
				formularioDet = response;
			} else {
				throw Error(response.message);
			}
		});

		if (this.state.idPeticion && this.state.idPeticion > 0) {
			// Si la entidad existe se trae el ejemplo de consumo, por eso se agrega el /1 al final
			let urlService = `${Config.urlApiService}/SolicitudServicio/${this.props.claProducto}/${this.state.idPeticion}`;

			callApi(urlService, "GET", null, (status, response) => {
				let isOwner = parseInt(response.recordset[0].ClaProductoSolicita) === parseInt(getProducto());

				this.setState({
					isLoaded: true,
					peticion: response.recordset[0],
					isOwner: isOwner,
					idPeticion: response.recordset[0].IdPeticion,
				});
			});
		} else {
			this.setState({
				isLoaded: true,
				peticion: {
					IdPeticion: 0,
					Titulo: "",
					Descripcion: "",
					EntradasEsperadas: "",
					ResultadoEsperado: "",
					ClaProducto: 0,
					NomProducto: getNomProducto(),
					ClaProductoSolicita: getProducto(),
					ClaUsuarioSolicita: 0,
					ClaEstatus: 1,
					FechaPromesa: null,
					IdEntidad: null,
					ClaUsuarioAsignacion: null,
					BajaLogica: 0,
					Detalles: [],
				},
			});
		}
	}

	/**
	 *
	 * @param {*} arrayHelpers
	 * @param {*} formikProps
	 * @param {*} claTipoDato
	 * @param {*} tooltip
	 * @param {*} btnTarget
	 */
	renderDetallesHeader(arrayHelpers, formikProps, esEntrada, tooltip, btnTarget) {
		return (
			<tr>
				<UncontrolledTooltip placement="right" target={esEntrada === 0 ? `helpNombreS` : `helpNombreE`}>
					<span>
						{esEntrada === 0
							? translate("SolicitudMtto.ttSalidaNombre")
							: translate("SolicitudMtto.ttEntradaNombre")}
					</span>
				</UncontrolledTooltip>
				<th className="w-25">
					{translate("SolicitudMtto.colNombre")} &nbsp;
					<span id={esEntrada === 0 ? "helpNombreS" : "helpNombreE"}>
						<i className="far fa-question-circle fa-lg"></i>
					</span>
				</th>
				<UncontrolledTooltip placement="right" target={esEntrada === 0 ? `helpDescripcionS` : `helpDescripcionE`}>
					<span>
						{esEntrada === 0
							? translate("SolicitudMtto.ttSalidaDescripcion")
							: translate("SolicitudMtto.ttEntradaDescripcion")}
					</span>
				</UncontrolledTooltip>
				<th className="w-60">
					{translate("SolicitudMtto.colDescripcion")} &nbsp;
					<span id={esEntrada === 0 ? "helpDescripcionS" : "helpDescripcionE"}>
						<i className="far fa-question-circle fa-lg"></i>
					</span>
				</th>
				<UncontrolledTooltip placement="right" target={esEntrada === 0 ? `helpTipoDatoS` : `helpTipoDatoE`}>
					<span>
						{esEntrada === 1 ? translate("SolicitudMtto.ttEntradaTipo") : translate("SolicitudMtto.ttSalidaTipo")}
					</span>
				</UncontrolledTooltip>
				<th className="w-15">
					{translate("SolicitudMtto.colTipo")}&nbsp;
					<span id={esEntrada === 0 ? "helpTipoDatoS" : "helpTipoDatoE"}>
						<i className="far fa-question-circle fa-lg"></i>
					</span>
				</th>
				<th>
					<UncontrolledTooltip placement="left" target={btnTarget}>
						{tooltip}
					</UncontrolledTooltip>
					<Button
						id={btnTarget}
						onClick={() => {
							arrayHelpers.push({
								IdPeticion: formikProps.values.IdPeticion,
								IdPeticionDet: formikProps.values.Detalles.length + 1,
								NomPeticionDet: "",
								Descripcion: "",
								EsEntrada: esEntrada,
								ClaTipoDato: 0,
								ClaProducto: getProducto(),

								BajaLogica: 0,
							});
						}}
						className="btn-icon btn-link"
						color="info"
						size="sm">
						<i className="fa fa-plus" />
					</Button>
				</th>
			</tr>
		);
	}

	renderDetalles(formikProps, arrayHelpers, esEntrada, verBajaLogica) {
		let values = [];

		if (formikProps.values.Detalles) {
			formikProps.values.Detalles.forEach((item, index) => {
				if (item.EsEntrada === esEntrada && (item.BajaLogica === 0 || verBajaLogica)) {
					if (this.props.readOnly) {
						values.push(this.renderDetallesInfo(item, index));
					} else {
						values.push(this.renderDetallesRow(formikProps, arrayHelpers, item, index));
					}
				}
			});
		} else {
			return this.renderNoInfo();
		}

		return values;
	}

	renderDetallesInfo(item, index) {
		return (
			<tr key={index}>
				<td
					className="pl-3 pr-4 pt-3 pb-0"
					style={{
						borderTop: "1px solid #2b3553",
						borderBottom: "1px solid #2b3553",
					}}>
					<div className="row">
						<h5>
							{item.NomPeticionDet + "   "}
							{item.NomTipoDato ? <Badge color="info">{`${item.NomTipoDato}`}</Badge> : null}
						</h5>
					</div>
					<div className="row pl-3" style={{ wordWrap: "break-word" }}>
						{item.Descripcion}
					</div>
				</td>
			</tr>
		);
	}

	renderDetallesRow(formikProps, arrayHelpers, item, index) {
		return (
			<tr key={index}>
				<td className="w-25">
					<Field name={`Detalles.${index}.NomPeticionDet`} className="form-control" />
				</td>
				<td className="w-60">
					<Field name={`Detalles.${index}.Descripcion`} className="form-control" />
				</td>
				<td className="w-15">
					<Field name={`Detalles.${index}.ClaTipoDato`} as="select" className="form-control ddlOptions-noBorder">
						{sessionStorage.getItem("ClaIdioma") === "1" ? (
							<option className="ddlOptions" key={index + 1} value={0}>
								SELECCIONE
							</option>
						) : (
							<option className="ddlOptions" key={index + 1} value={0}>
								SELECT
							</option>
						)}
						{tipoDato.map((obj, index) => (
							<option className="ddlOptions" key={index + 1} value={obj.ClaTipoDato}>
								{obj.NomTipoDato}
							</option>
						))}
					</Field>
				</td>
				<td>
					<Button
						onClick={() => {
							let oX = formikProps.values.Detalles[index];

							oX.BajaLogica = oX.BajaLogica === 1 ? 0 : 1;
							arrayHelpers.replace(index, oX);
							arrayHelpers.remove(index);
						}}
						className="btn-icon btn-link"
						color={item.BajaLogica === 0 ? "danger" : "info"}
						size="sm">
						<i className={item.BajaLogica === 1 ? "fa fa-plus" : "fa fa-times"} />
					</Button>
				</td>
			</tr>
		);
	}

	renderDetTexto(nomControl) {
		let detalle = formularioDet.filter((detalle) => detalle.NomControl === nomControl);
		if (detalle[0]) return detalle[0].Texto;
		else return translate("SolicitudMtto.noInfo");
	}

	/**
	 * Muestra los prompts de alertas
	 */
	showAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Oculta los promts de alertas con pregunta de confirmacion
	 */
	showConfirm(onConfirm, onCancel, title, text) {
		const warningAlert = showConfirm(onConfirm, onCancel, title, text);

		this.setState({
			showConfirm: warningAlert,
		});
	}

	/**
	 * Oculta los promts de alertas
	 */
	hideAlert() {
		this.setState({
			showAlert: null,
			showConfirm: null,
		});
	}

	render() {
		const NAVITEMS = [
			{
				Name: "general",
				Title: translate("SolicitudMtto.navGeneral"),
				Id: 0,
			},
			{
				Name: "entradas",
				Title: translate("SolicitudMtto.navEntradas"),
				Id: 1,
			},
			{
				Name: "salidas",
				Title: translate("SolicitudMtto.navSalidas"),
				Id: 2,
			},
		];

		if (!this.state.isLoaded) {
			return (
				<div className="spinner-border text-info" style={{ backgroundColor: "transparent" }} role="status">
					<span className="sr-only">{translate("SolicitudMtto.cargando")}</span>
				</div>
			);
		}

		return (
			<>
				{this.state.showAlert}
				{this.state.showConfirm}
				<Formik
					initialValues={{
						...this.state.peticion,
					}}
					onSubmit={this.handleSubmitButton}>
					{(formikProps) => (
						<Form onSubmit={formikProps.handleSubmit} key="frmSolicitudes">
							<Card>
								<CardHeader>
									<Row>
										<Col md={6}>
											<CardTitle>{translate("SolicitudMtto.titulo")}</CardTitle>
										</Col>
										{this.state.peticion.ClaEstatus === 1 &&
											getLoginInfo().ClaRol === 5 &&
											this.state.isOwner && (
												<Col md={6} className="text-right">
													<UncontrolledTooltip target="btnEnviar">
														{translate("SolicitudMtto.ttEnviarSolicitud")}
													</UncontrolledTooltip>
													<Button
														id="btnEnviar"
														onClick={() =>
															this.showConfirm(
																() => this.editarEstatus(this.state.peticion),
																() => this.hideAlert(),
																translate("SolicitudMtto.alertEnviarSolicitud"),
																"",
															)
														}
														className="btn-simple"
														color="info"
														size="sm">
														<i className="fa fa-check" />
														{translate("SolicitudMtto.btnEnviar")}
													</Button>
													<UncontrolledTooltip target="btnRechazar">
														{translate("SolicitudMtto.ttRechazarSolicitud")}
													</UncontrolledTooltip>
													<Button
														id="btnRechazar"
														onClick={() =>
															this.showConfirm(
																() => this.rechazarSolicitud(this.state.peticion),
																() => this.hideAlert(),
																translate("SolicitudMtto.alertRechazarSolicitud"),
																"",
															)
														}
														className="btn-simple"
														color="warning"
														size="sm">
														<i className="fa fa-times" />
														{translate("SolicitudMtto.btnRechazar")}
													</Button>
												</Col>
											)}
									</Row>
								</CardHeader>
								<CardBody>
									<Row style={{ alignItems: "start" }}>
										<Col md={2} xs={2}>
											<Nav pills className="nav-pills-info flex-column highlight">
												{NAVITEMS.map((item) => {
													return this.renderNavItem(item);
												})}
											</Nav>
										</Col>
										<Col md={10} xs={10}>
											<TabContent activeTab={this.state.activeTab} className="tab-space">
												<TabPane tabId="general" style={{ height: "450px" }}>
													<Row>
														<Col md={6} sm={12}>
															<UncontrolledTooltip placement="right" target={`txtTituloPeticion`}>
																<span>{translate("SolicitudMtto.ttTitulo")}</span>
															</UncontrolledTooltip>
															<Label for="Titulo">{translate("SolicitudMtto.lblTitulo")}</Label>
															<Field
																id="txtTituloPeticion"
																as="input"
																placeholder={translate("SolicitudMtto.phTitulo")}
																className="form-control"
																name="Titulo"
																disabled={this.props.readOnly}></Field>
														</Col>
														<Col md={6} sm={12}>
															<UncontrolledTooltip placement="right" target={`txtNomProductoPeticion`}>
																<span>{this.renderDetTexto("txtNomProductoPeticion")}</span>
															</UncontrolledTooltip>
															<Label for="ClaProducto">{translate("SolicitudMtto.lblProducto")}</Label>
															<Field
																id="txtNomProductoPeticion"
																name="ClaProducto"
																as="select"
																type="input"
																className="form-control"
																placeholder={translate("SolicitudMtto.phProducto")}
																disabled={this.props.readOnly}>
																{productos &&
																	productos.length > 0 &&
																	productos.map((obj, index) => (
																		<option
																			className="ddlOptions"
																			key={index}
																			value={obj.ClaProducto}>
																			{sessionStorage.getItem("ClaIdioma") === "2" &&
																			obj.NomProducto === "SELECCIONE"
																				? "SELECT"
																				: obj.NomProducto}
																		</option>
																	))}
															</Field>
														</Col>
													</Row>

													<FormGroup row>
														<Col md={12}>
															<UncontrolledTooltip placement="right" target={`txtDescripcion`}>
																<span>{translate("SolicitudMtto.ttDescripcion")}</span>
															</UncontrolledTooltip>
															<Label for="Descripcion">
																{translate("SolicitudMtto.lblDescripcion")}
															</Label>
															<Field
																as="textarea"
																id="txtDescripcion"
																name="Descripcion"
																placeholder={translate("SolicitudMtto.phDescripcion")}
																className="form-control"
																disabled={this.props.readOnly}
																rows={6}
																style={{ minHeight: "115px" }}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Col md={12}>
															<UncontrolledTooltip placement="right" target={`txtEntradasEsperadas`}>
																<span>{translate("SolicitudMtto.ttEntradasEsperadas")}</span>
															</UncontrolledTooltip>
															<Label for="EntradasEsperadas">
																{translate("SolicitudMtto.lblEntradasEsperadas")}
															</Label>
															<Field
																as="textarea"
																id="txtEntradasEsperadas"
																name="EntradasEsperadas"
																placeholder={translate("SolicitudMtto.phEntradasEsperadas")}
																className="form-control"
																disabled={this.props.readOnly}
																rows={6}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Col md={12}>
															<UncontrolledTooltip placement="right" target={`txtResultadoEsperado`}>
																<span>{translate("SolicitudMtto.ttResultadoEsperado")}</span>
															</UncontrolledTooltip>
															<Label for="ResultadoEsperado">
																{translate("SolicitudMtto.lblResultadoEsperado")}
															</Label>
															<Field
																as="textarea"
																id="txtResultadoEsperado"
																name="ResultadoEsperado"
																placeholder={translate("SolicitudMtto.phResultadoEsperado")}
																className="form-control"
																disabled={this.props.readOnly}
																rows={6}
															/>
														</Col>
													</FormGroup>
												</TabPane>
												<TabPane tabId="entradas" style={{ height: "450px" }}>
													<FieldArray
														name="Detalles"
														render={(arrayHelpers) => (
															<table className="table table-hover table-sm scroll table-striped w-100">
																<thead hidden={this.props.readOnly}>
																	{this.renderDetallesHeader(
																		arrayHelpers,
																		formikProps,
																		1,
																		translate("SolicitudMtto.lblAgregarParametro"),
																		"btnAgregarEntradas",
																	)}
																</thead>
																<tbody>
																	{this.renderDetalles(
																		formikProps,
																		arrayHelpers,
																		1,
																		this.state.verBajaEntradas,
																	)}
																</tbody>
															</table>
														)}
													/>
												</TabPane>

												<TabPane tabId="salidas" style={{ height: "450px" }}>
													<FieldArray
														name="Detalles"
														render={(arrayHelpers) => (
															<table className="table table-hover table-sm scroll table-striped w-100">
																<thead hidden={this.props.readOnly}>
																	{this.renderDetallesHeader(
																		arrayHelpers,
																		formikProps,
																		0,
																		translate("SolicitudMtto.lblAgregarParametro"),
																		"btnAgregarSalidas",
																	)}
																</thead>
																<tbody>
																	{this.renderDetalles(
																		formikProps,
																		arrayHelpers,
																		0,
																		this.verBajaSalidas,
																	)}
																</tbody>
															</table>
														)}
													/>
												</TabPane>
											</TabContent>
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<Row>
										<Col md={12} className="text-right">
											<Button
												id="btnGuardar"
												className="animation-on-hover"
												color="success"
												size="sm"
												hidden={this.props.readOnly}
												type="submit">
												{translate("SolicitudMtto.btnGuardar")}
											</Button>

											{this.state.esGuardado || this.props.readOnly ? (
												<Button
													id="btnCancelar"
													className="animation-on-hover"
													color="default"
													size="sm"
													onClick={() => {
														writeLog(
															Formularios.SolicitudServicioMtto.ClaFuncionalidad,
															Formularios.SolicitudServicioMtto.Funcionalidades.btnCancelar,
														);
														this.btnCancelarClick(false);
													}}>
													{translate("SolicitudMtto.btnCerrar")}
												</Button>
											) : (
												<Button
													id="btnCancelar"
													className="animation-on-hover"
													color="danger"
													size="sm"
													onClick={() => {
														writeLog(
															Formularios.SolicitudServicioMtto.ClaFuncionalidad,
															Formularios.SolicitudServicioMtto.Funcionalidades.btnCancelar,
														);
														this.btnCancelarClick(false);
													}}>
													{translate("SolicitudMtto.btnCancelar")}
												</Button>
											)}
										</Col>
									</Row>
								</CardFooter>
							</Card>
						</Form>
					)}
				</Formik>
			</>
		);
	}
}

SolicitudServicioMtto.propTypes = {
	hideModal: PropTypes.func,
	claProducto: PropTypes.any,
	idPeticion: PropTypes.any,
	readOnly: PropTypes.bool,
};
