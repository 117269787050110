import React from "react";

import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

/**
 * Crea los items de el arbol
 * {props} Son las propiedades que recibe Dependencias js
 * {classes} estilos generados
 */
var buildTreeItems = (props, classes) => {
	let array = props.entidad.Dependencias;
	if (array === undefined || array.length === 0) {
		return (
			<>
				<h2>Este servicio no tiene dependencias</h2>
			</>
		);
	} else {
		let schemas = [];
		let schemaType = [];
		let flags = [];
		let longitud = array.length,
			i;

		//obtiene los distintos esquemas
		for (i = 0; i < longitud; i++) {
			if (flags[array[i].RefSchema.toUpperCase()]) continue;
			flags[array[i].RefSchema.toUpperCase()] = true;
			schemas.push(array[i].RefSchema);
		}

		//Separar por esquemas y tipo
		for (let index = 0; index < schemas.length; index++) {
			let schema = schemas[index];
			let obj = {};
			obj.Schema = schema;
			obj.View = array.filter((oX) => oX.RefSchema === schema && oX.Type.trim() === "V");
			obj.Procedure = array.filter((oX) => oX.RefSchema === schema && oX.Type.trim() === "P");
			obj.Function = array.filter(
				(oX) => oX.RefSchema === schema && (oX.Type.trim() === "FN" || oX.Type.trim() === "TF"),
			);
			obj.Table = array.filter((oX) => oX.RefSchema === schema && oX.Type.trim() === "U");

			schemaType.push(obj);
		}

		// Crea los objetos hijos y nietos
		let TreeItems = schemaType.map((schema, key) => {
			let vistasTags =
				schema.View.length === 0 ? (
					<></>
				) : (
					<>
						<StyledTreeItem nodeId={schema.Schema + "_V_" + key} label={"Vistas"} labelIcon="fas fa-table fa-xs">
							{schema.View.map((vista) => {
								return (
									<>
										<StyledTreeItem nodeId={vista.RefName} label={vista.RefName}></StyledTreeItem>
									</>
								);
							})}
						</StyledTreeItem>
					</>
				);

			let proceduresTags =
				schema.Procedure.length === 0 ? (
					<></>
				) : (
					<>
						<StyledTreeItem
							nodeId={schema.Schema + "_P_" + key}
							label={"Procedimientos Almacenados"}
							labelIcon="fas fa-code fa-xs">
							{schema.Procedure.map((procedure) => {
								return (
									<>
										<StyledTreeItem nodeId={procedure.RefName} label={procedure.RefName}></StyledTreeItem>
									</>
								);
							})}
						</StyledTreeItem>
					</>
				);

			let funcionesTags =
				schema.Function.length === 0 ? (
					<></>
				) : (
					<>
						<StyledTreeItem
							nodeId={schema.Schema + "_FN_" + key}
							label={"Funciones"}
							labelIcon="fas fa-network-wired fa-xs">
							{schema.Function.map((funcion) => {
								return (
									<>
										<StyledTreeItem nodeId={funcion.RefName} label={funcion.RefName}></StyledTreeItem>
									</>
								);
							})}
						</StyledTreeItem>
					</>
				);

			let tablasTags =
				schema.Table.length === 0 ? (
					<></>
				) : (
					<>
						<StyledTreeItem nodeId={schema.Schema + "_U_" + key} label={"Tablas"} labelIcon="fas fa-table fa-xs">
							{schema.Table.map((tabla) => {
								return (
									<>
										<StyledTreeItem nodeId={tabla.RefName} label={tabla.RefName}></StyledTreeItem>
									</>
								);
							})}
						</StyledTreeItem>
					</>
				);

			return (
				<>
					<StyledTreeItem nodeId={schema.Schema} label={schema.Schema} labelIcon="fas fa-server fa-xs">
						{vistasTags}
						{proceduresTags}
						{funcionesTags}
						{tablasTags}
					</StyledTreeItem>
				</>
			);
		});

		return (
			//El objeto padre de todos
			<TreeView
				className={classes}
				defaultExpanded={["1"]}
				defaultCollapseIcon={<ArrowDropDownIcon />}
				defaultExpandIcon={<ArrowRightIcon />}>
				<StyledTreeItem nodeId="1" label={props.entidad.NomEntidad} labelIcon="fas fa-cloud fa-xs">
					{TreeItems}
				</StyledTreeItem>
			</TreeView>
		);
	}
};

/**
 * Establece y aplica los estilos a los objetos tipo  Tree Item
 */
const StyledTreeItem = withStyles((theme) => ({
	fontFamily: "Poppins, sans-serif !important",
	iconContainer: {
		"& .close": {
			opacity: 0.3,
		},
	},
	labelIcon: {
		marginRight: theme.spacing(1),
	},
	group: {
		marginLeft: 7,
		paddingLeft: 18,
		borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
	},
	selected: {
		borderRadius: "3px",
	},
}))((props) => {
	return (
		<TreeItem
			{...props}
			label={
				<div>
					<i className={props.labelIcon} style={{ marginRight: "3px" }}></i> {props.label}
				</div>
			}
		/>
	);
});

/**
 * Crea los estilos
 */
const useStyles = makeStyles({
	root: {
		height: 264,
		flexGrow: 1,
		maxWidth: 400,
	},

	selected: {
		borderRadius: "3px",
	},
});

/**
 * Llama a el objeto principal.
 * @param {*} props
 */
var CustomizedTreeView = (props) => {
	const classes = useStyles();

	return (
		<>
			<div className="content">
				<div className="scrollModal">{buildTreeItems(props, classes)}</div>
			</div>
		</>
	);
};

export default CustomizedTreeView;
