const SeleccionProducto = {
    lblProductos: "Products",
    phProducto: "Name",
    ttBuscar: "Search",
    ttLimpiar: "Clear",
    lblEmpresa: "Company",
    colNombre: "Name",
    colDescripcion: "Description",
    colResponsable: "Manager",
    btnSalir: "Log Out",
    msjNoProducto1: "You do not have access to any product.",
    msjNoProducto2: "Contact the product administrator for access.",
};

export default SeleccionProducto;