const SeleccionProducto = {
	lblProductos: "Productos",
	phProducto: "Nombre",
	ttBuscar: "Buscar",
	ttLimpiar: "Limpiar",
	lblEmpresa: "Empresa",
	colNombre: "Nombre",
	colDescripcion: "Descripción",
	colResponsable: "Responsable",
	btnSalir: "Salir",
	msjNoProducto1: "No cuentas con acceso a ningún producto.",
	msjNoProducto2: "Ponte en contacto con el administrador del producto para que te brinde acceso.",
};

export default SeleccionProducto;
