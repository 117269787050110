import React from "react";
import { Card, CardBody, CardHeader, Row, Col, Button, Badge, Collapse, Modal, CardTitle } from "reactstrap";

import { Config } from "utils/Config";
import { callApi } from "utils/Utilerias";
import IncidenteMtto from "./IncidenteMtto";
import NotificationAlert from "react-notification-alert";
import IncidentesGrafica from "./IncidentesGrafica";
import { getProducto } from "utils/Utilerias";
import PropTypes from "prop-types";

let srcData = [];
let datosTitulos = [];
let seleccionFila = 0;
export default class Incidentes extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			IdEntidad: this.props.location.idEntidad,
			IsLoaded: false,
			TituloSt: "",
			DescSt: "",
			DescExtSt: "",
			EstatusSt: "",
			IdIncidenteSt: "",
			mostrarTarjeta: false,
			openedCollapses: [""],
			filaSeleccion: 0,
			showModal: false,
		};

		this.btnCancelar_Click = this.btnCancelar_Click.bind(this);
		this.btnDescripcion_Click = this.btnDescripcion_Click.bind(this);
	}

	componentDidMount() {
		if (this.props.location.idEntidad && this.props.location.idEntidad > 0) {
			let data = {
				idEntidad: this.props.location.idEntidad,
			};

			let urlService = `${Config.urlApiService}/Incidentes/${getProducto()}/${this.props.location.idEntidad}`;

			callApi(urlService, "GET", data, (status, res) => {
				console.log(res);
				console.log("status: " + status);
				if (res.StatusCode !== undefined) {
					alert(res.StatusCode + " - " + res.StatusDescription);
				} else {
					srcData = res.map((oIncidente, key) => {
						return {
							Key: key,
							...oIncidente,
						};
					});
					this.listaDeTitulos(srcData);
					this.btnDescripcion_Click(srcData[0]);
					this.setState({
						IsLoaded: true,
						data: srcData,
					});
				}
			});
		} else {
			this.props.history.push({
				pathname: "/admin/Entidades",
			});
		}
	}

	/**
	 * Función para abrir y cerrar el apartado de Descripción Extendida
	 * @param {String} collapse id del apartado que muestra o oculta su contenido
	 */
	collapsesToggle(collapse) {
		let openedCollapses = this.state.openedCollapses;
		if (openedCollapses.includes(collapse)) {
			this.setState({
				openedCollapses: [],
			});
		} else {
			this.setState({
				openedCollapses: [collapse],
			});
		}
	}

	/**
	 * Función del botón para cancelar la operación
	 * y regresar a la página de entidades
	 */
	btnCancelar_Click() {
		this.props.history.goBack();
	}

	/**
	 * Muestra la descripción más detallada acerca de un incidente
	 * @param {Object} oIncidente Objeto del que se consultara la información
	 * más detallada
	 */
	btnDescripcion_Click(oIncidente) {
		seleccionFila = oIncidente.Key;
		let urlService = `${Config.urlApiService}/Incidentes/${getProducto()}/${this.props.location.idEntidad}/${
			oIncidente.ClaIncidente
		}`;

		let data = {};

		callApi(urlService, "GET", data)
			.then((res) => {
				if (res.StatusCode !== undefined) {
					alert(res.StatusCode + " - " + res.StatusDescription);
				} else {
					let objIncidente = res;
					this.setState({
						IsLoaded: true,
						Incidente: objIncidente,
					});
				}
			})
			.catch((oErr) => {
				alert(oErr);
			});

		this.listaDeTitulos(srcData);
	}

	/**
	* Función que consulta el API y carga la tabla con los titulos de los incidentes
	* @param {Array} srcData Arreglo con objetos de tipo incidente
	relacionados con la entidad
	*/
	listaDeTitulos(srcData) {
		let highlight = "";

		datosTitulos = srcData.map((oIncidente, key) => {
			if (seleccionFila === oIncidente.Key) {
				highlight = "selectedTableRow";
			} else {
				highlight = "";
			}

			return (
				<tr key={key} className={highlight}>
					<td className="w-25">{oIncidente.Titulo}</td>
					<td className="w-25">{`${oIncidente.NomIncidenteEstatus}`}</td>
					<td className="w-25">{`${oIncidente.FechaUltimaMod.toFormatString()}`}</td>
					<td className="td-actions text-right w-25">
						<Button
							color="link"
							title=""
							type="button"
							onClick={() => {
								this.btnDescripcion_Click(oIncidente);
							}}>
							<i className="fa fa-search" />
						</Button>
					</td>
				</tr>
			);
		});
	}

	/**
	 * Función que oculta el modal mostrado
	 * @param {Boolean} isOk Establece si la operación se realizó correctamente
	 * @param {Object} incidente Objeto de tipo incidente
	 */
	hideModal(isOk, incidente) {
		let urlService = `${Config.urlApiService}/Incidentes/${getProducto()}/${incidente.IdEntidad}/${
			incidente.ClaIncidente
		}`;
		let data = {};

		callApi(urlService, "GET", data)
			.then((res) => {
				if (res.StatusCode !== undefined) {
					alert(res.StatusCode + " - " + res.StatusDescription);
				} else {
					let objIncidente = res;
					this.setState({
						IsLoaded: true,
						Incidente: objIncidente,
					});
				}
			})
			.catch((oErr) => {
				alert(oErr);
			});

		this.setState({
			showModal: false,
		});

		if (isOk) {
			var options = {
				place: "tc",
				message: "Operación realizada correctamente.",
				type: "success",
				autoDismiss: 3,
			};

			this.notify.notificationAlert(options);
		}
	}

	render() {
		if (!this.state.IsLoaded) {
			return <div>Cargando...</div>;
		}
		return (
			<>
				<NotificationAlert ref={(ref) => (this.notify = ref)} />

				<div className="content">
					<div className="float-left w-50">
						<Card className="card-tasks text-left" id="cardTitulos">
							<CardHeader>
								<CardTitle>{this.props.location.nombreEntidad}</CardTitle>
							</CardHeader>
							<CardBody>
								<table className="table table-borderless w-100 table-incidentes">
									<thead>
										<tr>
											<th>Incidente</th>
											<th>Estatus</th>
											<th>Última modificación</th>
											<th></th>
										</tr>
									</thead>
									<tbody>{datosTitulos}</tbody>
								</table>
							</CardBody>
						</Card>
						<div className="fixed-left">
							<Card style={{ marginTop: "20px" }}>
								<CardHeader>
									<CardTitle>Historial de Incidentes</CardTitle>
								</CardHeader>
								<CardBody>
									<IncidentesGrafica entidad={this.props.location.idEntidad} ref={this.chartRef} />
								</CardBody>
							</Card>
						</div>
					</div>

					<div className="float-right w-50" style={{ paddingLeft: "10px" }}>
						<Card>
							<CardHeader>
								<CardTitle>
									{this.state.Incidente &&
										`# ${this.state.Incidente.ClaIncidente} - ${this.state.Incidente.Titulo}`}
								</CardTitle>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md={2}>Cliente</Col>
									<Col>
										<b>{this.state.Incidente && this.state.Incidente.NomCliente}</b>
									</Col>
									<Col sm="6" style={{ textAlign: "right" }}>
										<Button
											onClick={() => {
												this.setState({
													showModal: true,
												});
											}}
											color="info"
											size="sm"
											className="animation-on-hover">
											Editar
										</Button>
									</Col>
								</Row>
								<br />
								<div aria-multiselectable={true} className="card-collapse" id="accordion" role="tablist">
									<Card className="card-plain">
										<CardHeader role="tab">
											<a
												aria-expanded={this.state.openedCollapses.includes("collapseOne")}
												href="/"
												data-parent="#accordion"
												data-toggle="collapse"
												onClick={(e) => {
													e.preventDefault();
													this.collapsesToggle("collapseOne");
												}}>
												{this.state.Incidente && this.state.Incidente.Descripcion}
												<i className="tim-icons icon-minimal-down" />
											</a>
										</CardHeader>
										<Collapse role="tabpanel" isOpen={this.state.openedCollapses.includes("collapseOne")}>
											<CardBody>
												{this.state.Incidente && this.state.Incidente.DescripcionExtendida}
											</CardBody>
										</Collapse>
									</Card>
								</div>
							</CardBody>
						</Card>

						<Card className="card-timeline card-plain" style={{ border: "none !important" }}>
							<CardBody style={{ padding: "10px" }}>
								<ul className="timeline timeline-simple">
									{this.state.Incidente &&
										this.state.Incidente.Detalles.map((item, key) => {
											let classColor = "warning";
											let classIcon = "fas fa-pencil-alt";

											switch (item.ClaEstatusIncidente) {
												case 1:
													classColor = "info";
													classIcon = "fas fa-pencil-alt";
													break;

												case 2:
													classColor = "warning";
													classIcon = "fas fa-cog";
													break;

												case 3:
													classColor = "success";
													classIcon = "fas fa-check";
													break;

												case 4:
													classColor = "danger";
													classIcon = "fas fa-times";
													break;

												case 5:
													classColor = "danger";
													classIcon = "fas fa-user";
													break;

												default:
													return null;
											}

											return (
												<li key={key} className="timeline-inverted">
													<div className={`timeline-badge ${classColor}`}>
														<i className={classIcon} />
													</div>
													<div className="timeline-panel">
														<div className="timeline-heading">
															<Badge color={classColor}>{item.NomEstatusIncidente}</Badge>
														</div>
														<div className="timeline-body">
															<p>{item.NomUsuarioAsignacion}</p>
														</div>
														<h6>
															<i className="ti-time" />
															{item.FechaUltimaMod.toFormatString()}
														</h6>
													</div>
												</li>
											);
										})}
								</ul>
							</CardBody>
						</Card>
					</div>
				</div>

				<Modal centered={true} isOpen={this.state.showModal} toggle={this.toggleModalDemo} size="lg">
					<IncidenteMtto hideModal={this.hideModal} Incidente={this.state.Incidente} />
				</Modal>
			</>
		);
	}
}

Incidentes.propTypes = {
	location: PropTypes.any,
	history: PropTypes.any,
};
