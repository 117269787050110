import React from "react";

import { Form, FormGroup, Col, Button, Label, UncontrolledTooltip } from "reactstrap";

import { Formik, Field } from "formik";
import ReactTable from "react-table";
import { callApi, getProducto } from "utils/Utilerias";
import { Config } from "utils/Config";

require("utils/Prototipos");

let tipo;
let categoria;
let producto;
let srcData = [];

export default class Buscar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			srcData: srcData,
			isLoaded: false,
			isSuscrito: false,
		};

		this.btnBuscarClick = this.btnBuscarClick.bind(this);
		this.btnSuscribirseClick = this.btnSuscribirseClick.bind(this);
	}

	componentDidMount() {
		this.load();
	}

	load() {
		let huboErrores = false;

		try {
			let urlApi = ``;

			urlApi = `${Config.urlApiService}/Catalogos/11`;
			callApi(urlApi, "GET", null)
				.then((result) => {
					producto = result;
				})
				.catch((err) => {
					throw Error(err);
				});

			urlApi = `${Config.urlApiService}/Catalogos/2`;
			callApi(urlApi, "GET", null)
				.then((result) => {
					tipo = result;
				})
				.catch((err) => {
					throw Error(err);
				});

			urlApi = `${Config.urlApiService}/Catalogos/9`;
			callApi(urlApi, "GET", null)
				.then((result) => {
					categoria = result;
				})
				.catch((err) => {
					throw Error(err);
				});

			this.setState({
				isLoaded: true,
			});
		} catch (err) {
			huboErrores = true;
			alert(err.message);
		}

		return !huboErrores;
	}

	btnSuscribirseClick(idEntidad, claProducto) {
		const data = {
			claCliente: getProducto(),
			idEntidad: idEntidad,
			claProducto: claProducto,
			bajaLogica: this.state.esSuscrito ? 1 : 0,
		};

		const urlService = `${Config.urlApiService}/Entidades/Suscripcion`;

		callApi(urlService, "PUT", data).then((res) => {
			console.log(res);

			alert("La suscripción se realizó con exito.");

			this.setState({
				esSuscrito: res.BajaLogica !== 1,
			});
		});
	}

	btnBuscarClick(value) {
		try {
			//const urlService = `${Config.urlApiService}/Entidades`;
			const urlService = `${Config.urlApiService}/Entidades/Buscar/${value.ClaProducto}/${value.ClaTipoEntidad}/${value.ClaSistema}/${value.ClaCategoria}/${value.Nombre}`;

			callApi(urlService, "GET", null).then((res) => {
				srcData = res.map((item, key) => {
					return {
						...item,
						key: key,
						Descripcion: (
							<div>
								<UncontrolledTooltip target={`txtDescripcion_${key}`}>{item.Descripcion}</UncontrolledTooltip>

								<div id={`txtDescripcion_${key}`}>
									{item.Descripcion.length > 55 ? `${item.Descripcion.substring(0, 55)}...` : item.Descripcion}
								</div>
							</div>
						),
						Actions: (
							<div>
								<UncontrolledTooltip target={`btnSuscribirse_${key}`}>Suscribirse</UncontrolledTooltip>
								<Button
									id={`btnSuscribirse_${key}`}
									onClick={() => {
										this.btnSuscribirseClick(item.IdEntidad, item.ClaProducto);
									}}
									color="success"
									size="sm"
									className="btn-link">
									<i className="far fa-hand-point-up" />
								</Button>
							</div>
						),
					};
				});

				this.setState({
					srcData: srcData,
				});
			});
		} catch (err) {
			alert(err.message);
		}
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<>
					<div className="spinner-border text-info" style={{ backgroundColor: "transparent" }} role="status">
						<span className="sr-only">Cargando...</span>
					</div>
				</>
			);
		} else {
			return (
				<>
					<div>
						<Formik
							initialValues={{
								ClaTipoEntidad: 0,
								ClaProducto: 0,
								ClaCategoria: 0,
								ClaSistema: 0,
								Nombre: "",
							}}
							onSubmit={this.btnBuscarClick}>
							{(props) => (
								<Form onSubmit={props.handleSubmit} key="frmEntidadBuscar">
									<FormGroup row>
										<Col md={1}>
											<Label>Tipo</Label>
										</Col>
										<Col md={3}>
											<Field name="ClaTipoEntidad" as="select" type="input" className="form-control">
												{tipo &&
													tipo.length > 0 &&
													tipo.map((obj, index) => (
														<option className="ddlOptions" key={index} value={obj.ClaTipoEntidad}>
															{obj.NomTipoEntidad}
														</option>
													))}
											</Field>
										</Col>
										<Col md={1}>
											<Label>Producto</Label>
										</Col>
										<Col md={3}>
											<Field name="ClaProducto" as="select" type="input" className="form-control">
												{producto &&
													producto.length > 0 &&
													producto.map((obj, index) => (
														<option className="ddlOptions" key={index} value={obj.ClaProducto}>
															{obj.NomProducto}
														</option>
													))}
											</Field>
										</Col>
										<Col md={1}>
											{" "}
											<Label for="ClaCategoria">Categoría</Label>
										</Col>
										<Col md={3}>
											<Field
												name="ClaCategoria"
												as="select"
												type="input"
												className="form-control"
												placeholder="Tipo de entidad">
												{categoria &&
													categoria.length > 0 &&
													categoria.map((obj, index) => (
														<option className="ddlOptions" key={index} value={obj.ClaCategoria}>
															{obj.NomCategoria}
														</option>
													))}
											</Field>
										</Col>
									</FormGroup>

									<FormGroup row>
										<Col md={1}>
											<Label>Nombre</Label>
										</Col>
										<Col md={9}>
											<Field
												name="Nombre"
												type="input"
												className="form-control"
												placeholder="Nombre del servicio"></Field>
										</Col>
										<Col md={2}>
											<Button
												// onClick={() => {
												//     this.btnBuscarClick();
												// }}
												color="info"
												size="sm"
												className="btn-simple"
												type="submit">
												<i className="fas fa-search" />
												{" Buscar"}
											</Button>
										</Col>
									</FormGroup>
								</Form>
							)}
						</Formik>
						<hr />
						<ReactTable
							data={this.state.srcData}
							filterable={false}
							previousText="Anterior"
							nextText="Siguiente"
							loadingText="Cargando..."
							noDataText="No se encontró información"
							pageText="Página"
							ofText="de"
							rowsText="filas"
							defaultPageSize={6}
							showPaginationTop={false}
							showPaginationBottom={true}
							showPageSizeOptions={false}
							className="secondary-pagination table-hover tblBuscar"
							columns={[
								{
									Header: "Clave",
									accessor: "IdEntidad",
									width: Math.round(window.innerWidth * 0.05),
									className: "text-center",
								},
								{
									Header: "Producto",
									accessor: "NomProducto",
									width: Math.round(window.innerWidth * 0.08),
									className: "text-center",
								},
								{
									Header: "Servicio",
									accessor: "NomEntidad",
									width: Math.round(window.innerWidth * 0.12),
								},
								{
									Header: "Descripción",
									accessor: "Descripcion",
									width: Math.round(window.innerWidth * 0.15),
								},
								{
									Header: "Categoría",
									accessor: "NomCategoria",
									width: Math.round(window.innerWidth * 0.08),
									className: "text-center",
								},
								{
									Header: " ",
									accessor: "Actions",
									sortable: false,
									width: Math.round(window.innerWidth * 0.05),
								},
							]}></ReactTable>
					</div>
				</>
			);
		}
	}
}
