import React from "react";
import { Table } from "@devexpress/dx-react-grid-bootstrap4";

function tableComponent({ ...restProps }) {
	return <Table.Table {...restProps} className="table-hover table-gv table-sm" />;
}

function headerComponent({ ...restProps }) {
	return <Table.TableHead {...restProps} className="table-head" />;
}
const gvConfig = {
	currentPage: 0,
	rows: [],
	pageSize: 20,
	pageSizes: [5, 10, 20, 50],
	totalCount: 0,
	noData: "No se encontró información",
};

export { tableComponent, headerComponent, gvConfig };
