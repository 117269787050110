import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Card, CardHeader, CardTitle, CardBody, UncontrolledTooltip } from "reactstrap";
import { callApi, getProducto } from "utils/Utilerias";
import translate from "utils/translate";

import { Config } from "../../utils/Config";

let formularioDet = [];

export default class MayorYMenorTiempo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		};
	}

	getChartOpstions() {
		let color = "#1d8cf8";
		if (this.props.esTop === 1) {
			color = "#2dce89";
		}
		return {
			chart: {
				type: "line",
				backgroundColor: "#27293d",
			},
			colors: [color],
			title: {
				text: "",
			},
			legend: {
				itemStyle: {
					fontSize: "9pt",
					font: "9pt Trebuchet MS, Verdana, sans-serif",
					color: "#A0A0A0",
				},
			},
			plotOptions: {
				line: {
					dataLabels: {
						enabled: true,
					},
					enableMouseTracking: false,
				},
			},
			tooltip: {
				shared: true,
				backgroundColor: "rgb(49, 51, 77, .80)",
				style: {
					color: "#FFFFFF",
				},
			},
			yAxis: {
				title: {
					text: translate("HomeDev.lblTiempoMilisegundos"),
				},
				gridLineColor: "rgba(255, 255, 255, 0.1)",
				tickLength: 10,
			},
			xAxis: {},
		};
	}

	componentDidMount() {
		this.fillData();
	}

	fillData() {
		//Help tooltips
		let urlService = `${Config.urlApiService}/CatFormularioDet/9`;
		callApi(urlService, "GET", null, (status, response) => {
			formularioDet = response;
		});

		//Obtener información
		urlService = `${Config.urlApiService}/Home/TiempoEjecucion/${getProducto()}/${this.props.esTop}`;

		callApi(urlService, "GET", {}, (status, response) => {
			console.log(response);
			let dataArray = response.data[0].Result0;
			let tempOptions = {};
			tempOptions = this.getChartOpstions();

			if (dataArray && dataArray[0][0] !== "") {
				let dataNames = [];
				let dataNumbers = [];
				dataArray.map((item, index) => {
					let arrayName = dataArray[index].splice(0, 1)[0];
					let arrayItem = dataArray[index];

					dataNumbers.push(arrayItem);
					dataNames.push(arrayName);

					return item;
				});

				tempOptions.series = [
					{
						name: "",
						data: dataNumbers,
					},
				];
				tempOptions.xAxis.categories = dataNames;
			} else {
				tempOptions.series = [{ name: translate("HomeDev.lblNoInformacion"), data: 0 }];
				tempOptions.xAxis.categories = [translate("HomeDev.lblNoInformacion")];
			}

			this.setState({
				isLoaded: true,
				options: tempOptions,
				claIdioma: sessionStorage.getItem("ClaIdioma"),
			});
		}).catch((err) => {
			console.log("ServiciosPorTiempoDeEjecución", err);
			//throw err;
		});
	}

	renderDetTexto(nomControl) {
		let detalle = formularioDet.filter((detalle) => detalle.NomControl === nomControl);
		if (detalle[0]) return detalle[0].Texto;
		else return "No se encontró descripción";
	}

	render() {
		if (this.state.isLoaded) {
			return (
				<div style={{ width: "inherit" }}>
					{sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.fillData()}
					<Card
						id={`ctnEntidadesTiempo${this.props.esTop === 1 ? "Top" : "Bottom"}_${
							this.props.esAdmin ? "PM" : "Dev"
						}`}>
						<CardHeader>
							<CardTitle>
								<UncontrolledTooltip
									placement="right"
									target={`help${this.props.esTop === 1 ? "Menor" : "Mayor"}Tiempo`}>
									<span>
										{this.props.esTop === 1
											? translate("HomeDev.ttMenorTiempo")
											: translate("HomeDev.ttMayorTiempo")}
									</span>
								</UncontrolledTooltip>
								<h2>
									{this.props.esTop === 1
										? translate("HomeDev.lblMenorTiempo")
										: translate("HomeDev.lblMayorTiempo")}

									<span id={`help${this.props.esTop === 1 ? "Menor" : "Mayor"}Tiempo`}>
										<i className="far fa-question-circle"></i>
									</span>
								</h2>
							</CardTitle>
						</CardHeader>
						<CardBody>
							<HighchartsReact
								containerProps={{ style: { height: "100%" } }}
								highcharts={Highcharts}
								options={this.state.options}
							/>
						</CardBody>
					</Card>
				</div>
			);
		} else {
			return (
				<div className="text-center">
					<h3>Cargando...</h3>
				</div>
			);
		}
	}
}

MayorYMenorTiempo.propTypes = {
	esTop: PropTypes.number,
	esAdmin: PropTypes.number,
};
