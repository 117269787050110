const TablaReplicaMtto = {
	navGeneral: "General",
	navOrigenes: "Sources",
	navDestinos: "Destinations",

	lblEsquema: "Scheme",
	lblNombre: "Name",
	lblProducto: "Product",
	lblDescripción: "Description",
	lblColumnas: "Columns",

	colNombre: "Name",
	colTipoDato: "Data Type",
	colDescripcion: "Description",

	ttProducto: "Product to which the table belongs",
	ttDescripcion: "Table description",

	colServidor: "Server",
	colBaseDeDatos: "Database",
	colDescripcionServidor: "Server Description",
	colPublicacion: "Publication",

	colCliente: "Client",

	btnGuardar: "Save",
	btnCerrar: "Close",

	noInformacion: "No information found",
};

export default TablaReplicaMtto;
