/* eslint-disable no-unused-vars */
/* eslint no-extend-native: ["off", { "exceptions": ["Object"] }] */
const { func } = require("prop-types");

String.prototype.isNullOrEmpty =
	String.prototype.isEmpty ||
	function () {
		return !this.trim().length;
	};

String.prototype.toSqlString =
	String.prototype.toSqlString ||
	function () {
		if (this.isEmpty()) {
			return " null ";
		}

		return `'${this.replace("'", "''")}'`;
	};

String.prototype.toJsonString =
	String.prototype.toJsonString ||
	function () {
		return JSON.stringify(this);
	};

String.prototype.fromJSonString =
	Object.prototype.fromJSonString ||
	function () {
		return JSON.parse(this);
	};

String.prototype.isNumber =
	String.prototype.isNumber ||
	function () {
		let xValue = this.replace(",", "");
		xValue = this.replace("$", "");

		return !isNaN(xValue);
	};

String.prototype.toSqlNumber =
	String.prototype.toSqlNumber ||
	function () {
		let xValue = this;

		if (xValue.isEmpty()) {
			return " null ";
		}

		if (xValue.isNumber()) {
			xValue = xValue.replace(",", "");
			xValue = xValue.replace("$", "");

			return xValue;
		}
	};

String.prototype.toFormatString =
	String.prototype.toFormatString ||
	function () {
		const d = new Date(this); // Date.parse(this);

		return `${[d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/")} ${[
			(d.getHours() + 5).padLeft(),
			d.getMinutes().padLeft(),
			d.getSeconds().padLeft(),
		].join(":")}`;
	};

String.prototype.toDateNoTime =
	String.prototype.toDateNoTime ||
	function () {
		const d = new Date(this); // Date.parse(this);

		return `${[d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/")}`;
	};

Date.prototype.toSqlDateTime =
	Date.prototype.toSqlDateTime ||
	function () {
		const d = this;
		return `'${[d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("")} ${[
			d.getHours(),
			d.getMinutes(),
			d.getSeconds(),
		].join(":")}.${d.getMilliseconds()}'`;
	};

Number.prototype.padLeft = function (base, chr) {
	const len = String(base || 10).length - String(this).length + 1;
	return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

Number.prototype.toFormatNumber =
	Number.prototype.toFormatNumber ||
	function () {
		let num = this,
			digits = 1;
		var si = [
			{ value: 1, symbol: "" },
			{ value: 1e3, symbol: "k" },
			{ value: 1e6, symbol: "M" },
			{ value: 1e9, symbol: "G" },
			{ value: 1e12, symbol: "T" },
			{ value: 1e15, symbol: "P" },
			{ value: 1e18, symbol: "E" },
		];
		var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
		var i;
		for (i = si.length - 1; i > 0; i--) {
			if (num >= si[i].value) {
				break;
			}
		}
		return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
	};
