import * as languages from "../languages";

const locales = {
	1: "es_mx",
	2: "en_us",
};
const paramRegex = /\$[\d]+/g;

function translate(path = "", parameters = []) {
	if (path === "") {
		return "#NoPath";
	}

	const ClaIdioma = sessionStorage.getItem("ClaIdioma", 1);
	const locale = locales[ClaIdioma];
	const paths = path.split(".");

	if (paths.length < 1) {
		return "#NoTraduccion_" + path;
	}

	let translation = languages[locale];
	if (typeof translation === "undefined") {
		return `#NoLocale_${ClaIdioma}_${locale}`;
	}

	for (let i = 0; i < paths.length; i++) {
		translation = translation[paths[i]];
		if (typeof translation === "undefined") {
			return "#NoTraduccion_" + path;
		}
	}

	if (typeof translation !== "string") {
		return "#PathIncompleto_" + path;
	}

	if (parameters.length === 0) {
		return translation;
	}

	const stringMatches = translation.match(paramRegex);
	if (stringMatches === null) {
		return translation;
	}

	for (let i = 0; i < stringMatches.length; i++) {
		const match = stringMatches[i];
		const paramsIndex = match.replace("$", "");
		const replacement = parameters[paramsIndex];

		if (typeof replacement === "undefined") {
			translation = translation.replace(match, "#NoIndex_" + match);
		} else {
			translation = translation.replace(match, replacement);
		}
	}

	return translation;
}

export default translate;
