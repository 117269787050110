const FiltroMtto = {
	titulo: "Filter",
	colNombre: "Name",
	colServiciosConsumidos: "Consumed Services",
	colTotalErrores: "Total Errors",
	colTiempoMasLento: "Slowest time (ms)",
	colActividad: "Activity(%)",
	btnGuardar: "Save",
	btnCancelar: "Cancel",
	alertSeleccionarSoloUno: "Please select only one ",
	alertSeleccionarUn: "Please select a ",
	cargando: "Loading...",
	cliente: "client",
	proveedor: "provider",
};

export default FiltroMtto;
