const SuscripcionMtto = {
	titulo: "Request to Service",
	lblMsj: "Permission is required to consume the service ",
	lblMotivo: "Reason for subscription",
	phMotivo: "Enter the purpose",
	btnEnviar: "Send",
	btnCancelar: "Cancel",
	alertNoSeConsulta: "Could not query service data",
	alertRegistroExitoso: "Successfully registered request",
	alertRegistroExitosoDescripcion: "You will receive an email when the owner of the service responds to the request.",
};

export default SuscripcionMtto;
