import React from "react";
import { Card, CardHeader, CardBody, Button, UncontrolledTooltip, Row, Col } from "reactstrap";
import { getProducto, callApi, writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";
import { Config } from "utils/Config";
import translate from "utils/translate";

let srcData = [];
let formularioDet = [];

export default class ErroresIdentificados extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			errores: [],
		};

		this.load = this.load.bind(this);
	}

	componentDidMount() {
		this.load();
	}

	load() {
		//Help tooltips
		let urlService = `${Config.urlApiService}/CatFormularioDet/3`;
		callApi(urlService, "GET", null, (status, response) => {
			formularioDet = response;
		});

		//Obtener información
		urlService = `${Config.urlApiService}/Home/ErroresIdentificados/${getProducto()}`;

		callApi(urlService, "GET", null, (status, response) => {
			console.log(response);
			if (status === 200) {
				srcData = response.map((obj) => {
					return {
						NomEntidad: obj.NomEntidad,
						NumErrores: obj.NumErrores,
					};
				});
				this.setState({
					isLoaded: true,
					errores: srcData,
				});
			}
		});
	}

	consultaPeticion(obj) {
		let urlWebService = Config.urlApiService + "/SolicitudServicio/" + obj.ClaProducto + "/" + obj.IdPeticion;
		let data = {
			...obj,
		};

		callApi(urlWebService, "GET", data, (status, response) => {
			if (status === 200) {
				this.setState({
					showModal: true,
					idEntidad: obj.IdEntidad,
					claProducto: obj.ClaProducto,
					objPeticion: response.recordset[0],
				});
			} else {
				this.hideAlert();
				this.showAlert("", "Algo salió mal", "warning");
			}
		});
	}

	renderDetTexto(nomControl) {
		let detalle = formularioDet.filter((detalle) => detalle.NomControl === nomControl);
		if (detalle[0]) return detalle[0].Texto;
		else return "No se encontró descripción";
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<div className="spinner-border text-info" role="status">
					<span className="sr-only">Cargando...</span>
				</div>
			);
		} else {
			return (
				<>
					{this.state.showAlert}
					<Card className="card-home" id="ctnErroresIdentificados">
						<CardHeader>
							<UncontrolledTooltip placement="right" target={`helpErroresIdentificados`}>
								<span>{translate("HomeDev.phErrores")}</span>
							</UncontrolledTooltip>

							<Row>
								<Col md={8}>
									<h2>
										{translate("HomeDev.lblErroresIdentificados")}
										<span id="helpErroresIdentificados">
											<i className="far fa-question-circle"></i>
										</span>
									</h2>
								</Col>
								<Col md={4} className="text-right">
									<Button
										id="btnRefrescar"
										className="btn-link btn-home"
										color="info"
										size="sm"
										onClick={() => {
											writeLog(
												Formularios.ErroresIdentificados.ClaFuncionalidad,
												Formularios.ErroresIdentificados.Funcionalidades.btnRefrescar,
											);
											this.load();
										}}>
										{/* <i className="fas fa-redo" /> */}
										{translate("HomeDev.lblActualizar")}
									</Button>
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							<div className="erroresIdentificados">
								<table className="table table-sm table-noBorder">
									<thead>
										<tr>
											<th className="w-90">{translate("HomeDev.colServicio")}</th>
											<th className="w-10">{translate("HomeDev.colErrores")}</th>
										</tr>
									</thead>
									<tbody>
										{(this.state.errores.length > 0 &&
											this.state.errores.map((obj, index) => {
												return (
													<tr key={`err_${index}`}>
														<td>{obj.NomEntidad}</td>
														<td
															style={{
																textAlign: "center",
															}}>
															{obj.NumErrores}
														</td>
													</tr>
												);
											})) || (
											<tr>
												<td colSpan={2} style={{ textAlign: "center", height: "105px" }}>
													<p>
														{translate("HomeDev.noErrores")}
														<span className="btn-icon btn-link text-success">
															<i className="fas fa-check"></i>
														</span>
													</p>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</CardBody>
					</Card>
				</>
			);
		}
	}
}
