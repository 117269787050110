const Replicas = {
	titulo: "Servers",
	lblServidorOrigen: "Origin Server",
	phServidorOrigen: "Origin Server name",
	lblBaseDeDatos: "Origin Database",
	phBaseDeDatos: "Database name",
	lblEsquema: "Scheme",
	phEsquema: "Scheme",
	lblNombre: "Name",
	phNombre: "Element name",
	ttBuscar: "Search",
	ttLimpiar: "Clear",
	btnSolicitar: "Request",
	lblTotalDeRegistros: "Total records: ",
	colEsquema: "Scheme",
	colNombreTabla: "Table Name",
	colNombreServidor: "Server Name",
	colDetalle: "Detail",
	colTipo: "Type",
	ttInformacion: "Information",
	notificacionExito: "Successful operation.",
};

export default Replicas;
