const Login = {
	lblBienvenido: "Bienvenido",
	lblUsuario: "Usuario",
	lblContraseña: "Contraseña",
	lblOlvidasteTuContraseña: "¿Olvidaste tu contraseña?",
	btnEntrar: "Entrar",
	AcercaDe: "Acerca de",
};

export default Login;
