import React from "react";

import { Config } from "utils/Config";
import { callApi, writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";

import Buscar from "./Buscar";
import EntidadMtto from "./EntidadMtto";
import PropTypes from "prop-types";
import ComentariosMtto from "../Comentarios/ComentariosMtto";
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	CardFooter,
	Row,
	Col,
	Modal,
	Label,
	UncontrolledTooltip,
	Form,
	FormGroup,
} from "reactstrap";
import translate from "utils/translate";
import { showAlert, showConfirm, getProducto, getLoginInfo } from "utils/Utilerias";
import { Flags } from "react-feature-flags";
import NotificationAlert from "react-notification-alert";
import { Formik, Field } from "formik";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ReactStars from "react-rating-stars-component";
import { Grid, PagingPanel, Table, TableHeaderRow, TableRowDetail } from "@devexpress/dx-react-grid-bootstrap4";
import {
	IntegratedPaging,
	IntegratedSorting,
	PagingState,
	SortingState,
	RowDetailState,
} from "@devexpress/dx-react-grid";
import { tableComponent, headerComponent, gvConfig } from "utils/gridConfig";
import TourVirtual from "components/Tour/TourVirtual";
import { Tours } from "../../utils/Config";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";

require("utils/Prototipos");

// eslint-disable-next-line no-unused-vars
let tipo;
// let categoria;
let producto;
//let tipoDato;
let srcData = [];
let terminosGlosario = [];

export default class MisEntidades extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			showModalBuscar: false,
			idEntidad: -1,
			isLoaded: false,
			categoria: -1,
			alert: null,
			entidad: null,
			nuevosTerminos: [],
			clearAutocomplete: false,
			showModalComentarios: false,
			srcData: [],
			currentPage: 0,
			pageSize: 20,
			sorting: [{ columnName: "NomProducto", direction: "asc" }],
			buscarData: {},

			gvEntidades: {
				columns: [
					{ name: "IdEntidad", title: "ID" },
					{ name: "NomProducto", title: "Producto" },
					{ name: "NomServicio", title: "Servicio" },
					//{ name: "Descripcion", title: "Descripción" },
					{ name: "Actions", title: " " },
				],
				tableColumnExtensions: [
					{ columnName: "IdEntidad", width: "80px", align: "center" },
					{ columnName: "NomProducto", width: "150px", align: "left" },
					{ columnName: "NomServicio", width: "auto", wordWrapEnabled: true },
					//{ columnName: "Descripcion", width: "auto", wordWrapEnabled: true },
					{ columnName: "Actions", width: "200px", align: "right" },
				],
			},
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		};

		this.displayName = "FullComponentName";

		this.hideModal = this.hideModal.bind(this);
		this.hideModalInfo = this.hideModalInfo.bind(this);
		this.successAlert = this.successAlert.bind(this);
		this.showConfirm = this.showConfirm.bind(this);
		this.verEjemplo = this.verEjemplo.bind(this);

		this.frmLoad = this.frmLoad.bind(this);
		this.btnBuscarClick = this.btnBuscarClick.bind(this);
		this.dgvFillTable = this.dgvFillTable.bind(this);
		this.clear = this.clear.bind(this);
		this.hideModalComentarios = this.hideModalComentarios.bind(this);
	}

	componentDidMount() {
		this.frmLoad();
		//this.btnBuscarClick({ ClaTipoEntidad: 0, ClaProducto: 0, ClaCategoria: 0, ClaSistema: 0, Nombre: "", TerminosGlosario: []});
	}

	RowDetail = ({ row }) => (
		<div
			style={{
				padding: "1%",
				marginLeft: "20px",
			}}>
			<Row>
				<Col md="5">{row.Descripcion}</Col>
				<Col md="3">
					{/* <div className="float-right text-right"> */}
					<ReactStars
						count={5}
						size={20}
						edit={false}
						activeColor="#ffd700"
						isHalf={true}
						value={row.Calificacion}
					/>
					{/* </div> */}
				</Col>
				<Col md="2" style={{ textAlign: "right", borderLeft: "2px solid #999" }}>
					{translate("MisServiciosPublicados.ttOtrasFn")}
				</Col>
				<Col md="2" style={{ textAlign: "right" }}>
					<Flags authorizedFlags={[getLoginInfo().NomGrupo]}>
						<span id="beta">
							<UncontrolledTooltip
								placement="left"
								target={`btnComentarios_${row.IdEntidad.toString() + "-" + row.ClaProducto.toString()}`}>
								{translate("MisServiciosPublicados.ttComentarios")}
							</UncontrolledTooltip>
							<Button
								id={`btnComentarios_${row.IdEntidad.toString() + "-" + row.ClaProducto.toString()}`}
								onClick={() => {
									writeLog(
										Formularios.ServiciosPublicados.ClaFuncionalidad,
										Formularios.ServiciosPublicados.Funcionalidades.btnComentarios,
										undefined,
										row,
									);

									this.setState({
										showModalComentarios: true,
										idEntidad: row.IdEntidad,
										claProducto: row.ClaProducto,
										nomEntidad: row.NomEntidad,
										calificacion: row.Calificacion,
										isClone: false,
										isSubscribed: false,
									});
								}}
								color="info"
								size="sm"
								className="btn-icon btn-link ml-0">
								<i className="fas fa-comments" />
							</Button>
						</span>
					</Flags>
				</Col>
			</Row>
		</div>
	);

	/**
	 * Función que oculta el modal de los comentarios.
	 */
	hideModalComentarios() {
		this.setState({
			showModalComentarios: false,
		});
	}

	clear() {
		this.frmLoad();
		this.setState({
			nuevosTerminos: [],
			clearAutocomplete: !this.state.clearAutocomplete,
		});
	}

	frmLoad() {
		let urlApi = `${Config.urlApiService}/Glosario`;
		callApi(urlApi, "GET", null, (status, response) => {
			terminosGlosario = response;
		});

		urlApi = `${Config.urlApiService}/Catalogos/11`;
		callApi(urlApi, "GET", null, (status, response) => {
			producto = response;
		});

		urlApi = `${Config.urlApiService}/Catalogos/2`;
		callApi(urlApi, "GET", null, (status, response) => {
			tipo = response;
		});

		urlApi = `${Config.urlApiService}/Entidades/BuscarSuscripciones`;

		let body = {
			nombreEntidad: "",
			claTipo: 0,
			claProducto: 0,
			claCliente: getProducto(),
			terminosGlosario: [],
		};

		callApi(urlApi, "POST", body, (status, response) => {
			srcData = this.dgvFillTable(response);

			this.setState({
				srcData: srcData,
				totalCount: srcData.length,
			});
		});

		this.setState({
			isLoaded: true,
			gvEntidades: {
				columns: [
					{ name: "IdEntidad", title: "ID" },
					{ name: "NomProducto", title: translate("MisServiciosSuscritos.colProducto") },
					{ name: "NomServicio", title: translate("MisServiciosSuscritos.colServicio") },
					//{ name: "Descripcion", title: "Descripción" },
					{ name: "Actions", title: " " },
				],
				// tableColumnExtensions: [
				// 	{ columnName: "IdEntidad", width: "30px", align: "center" },
				// 	{ columnName: "NomProducto", width: "100px", align: "left" },
				// 	{ columnName: "NomServicio", width: "auto", wordWrapEnabled: true },
				// 	//{ columnName: "Descripcion", width: "auto", wordWrapEnabled: true },
				// 	{ columnName: "Actions", width: "10%", align: "right" },
				// ],
				tableColumnExtensions: [
					{ columnName: "IdEntidad", width: "80px", align: "center" },
					{ columnName: "NomProducto", width: "150px", align: "left" },
					{ columnName: "NomServicio", width: "auto", wordWrapEnabled: true },
					//{ columnName: "Descripcion", width: "auto", wordWrapEnabled: true },
					{ columnName: "Actions", width: "200px", align: "right" },
				],
			},
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		});
	}

	btnBuscarClick(value) {
		const urlService = `${Config.urlApiService}/Entidades/BuscarSuscripciones`;

		var body = {
			nombreEntidad: value.Nombre,
			claTipo: value.ClaTipoEntidad,
			//claCategoria: value.IdCategoria,
			claProducto: value.ClaProducto,
			claCliente: getProducto(),
			terminosGlosario: this.state.nuevosTerminos,
		};

		callApi(
			urlService,
			"POST",
			body,
			(status, response) => {
				srcData = this.dgvFillTable(response);

				writeLog(
					Formularios.ServiciosSuscritos.ClaFuncionalidad,
					Formularios.ServiciosSuscritos.Funcionalidades.btnBuscar,
					urlService,
					body,
					response,
					undefined,
					0,
				);

				this.setState({
					srcData: srcData,
					buscarData: value,
				});
			},
			Formularios.ServiciosSuscritos.ClaFuncionalidad,
			Formularios.ServiciosSuscritos.Funcionalidades.btnBuscar,
		);
	}

	/**
	 * Función para desactivar una entidad en específico
	 * @param {Number} idEntidad id de la entidad a desactivar
	 */
	btnDesactivarClick(idEntidad, claProducto) {
		const urlService = `${Config.urlApiService}/Entidades/Suscripcion`;

		let body = {
			claProducto: claProducto,
			claCliente: getProducto(),
			idEntidad: idEntidad,
			bajaLogica: 1,
		};

		callApi(
			urlService,
			`PUT`,
			body,
			(status, response) => {
				writeLog(
					Formularios.ServiciosSuscritos.ClaFuncionalidad,
					Formularios.ServiciosSuscritos.Funcionalidades.btnEliminar,
					urlService,
					body,
					response,
					translate("MisServiciosSuscritos.alertEliminarSuscripcion"),
					0,
				);

				console.log(response);

				this.hideAlert();
				this.successAlert(translate("MisServiciosSuscritos.alertEliminarSuscripcion"), undefined, "success");
			},
			Formularios.ServiciosSuscritos.ClaFuncionalidad,
			Formularios.ServiciosSuscritos.Funcionalidades.btnEliminar,
		);
	}

	/**
	 * Funcion para ocultar la alerta/mensaje que fue activado
	 */
	hideAlert(refreshData = false) {
		if (refreshData) {
			this.btnBuscarClick(this.state.buscarData);
		}
		this.setState({
			showAlert: null,
			showConfirm: null,
		});
	}

	/**
	 * Función 	que muestra un mensaje en el centro de la pantalla
	 * @param {String} title titulo del mensaje
	 * @param {String} text cuerpo del mensaje
	 * @param {String} icon icono que se mostrará en el mensaje
	 */
	successAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(true), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Función 	que muestra un mensaje en el centro de la pantalla con poder de
	 * elegir una opción
	 * @param {Function} onConfirm Función que se ejecuta al presionar el boton de confirmar
	 * @param {Function} OnCancel Función que se ejecuta al presionar el botón de cancelar
	 * @param {String} title titulo del mensaje
	 * @param {String} text cuerpo del mensaje
	 */
	showConfirm(onConfirm, onCancel, title, text) {
		const warningAlert = showConfirm(onConfirm, onCancel, title, text);

		this.setState({
			showConfirm: warningAlert,
		});
	}

	dgvFillTable(source) {
		let dgvSource;

		dgvSource = source.map((obj, key) => {
			return {
				key: key,
				IdEntidad: obj.IdEntidad,
				ClaServicio: obj.IdEntidad,
				NomTipoServicio: obj.NomTipoEntidad,
				NomCliente: obj.NomCliente,
				NomCategoria: obj.NomCategoria,
				Servicio: obj.Servicio,
				TotalTickets: obj.TotalIncidentes,
				ClaEstatus: obj.ClaEstatus,
				ClaProducto: obj.ClaProducto,
				NomProducto: obj.NomProducto,
				NomServicio: obj.Titulo,
				// (
				// 	<div>
				// 		<UncontrolledTooltip placement="left" target={`txtNomEntidad_${key}`}>{obj.Titulo}</UncontrolledTooltip>

				// 		<div id={`txtNomEntidad_${key}`}>{obj.Titulo}</div>
				// 	</div>
				// ),
				Descripcion: obj.Descripcion,
				// (
				// 	<div>
				// 		<UncontrolledTooltip placement="left" target={`txtDescripcion_${key}`}>{obj.Descripcion}</UncontrolledTooltip>

				// 		<div id={`txtDescripcion_${key}`}>{obj.Descripcion}</div>
				// 	</div>
				// ),
				Actions: (
					<div className="actions-right">
						<UncontrolledTooltip placement="left" target={`iconEsVerificado${key}`}>
							{obj.EsVerificado === 0 ? (
								<div>{translate("MisServiciosSuscritos.ttNoVerificado")}</div>
							) : (
								<div>{translate("MisServiciosSuscritos.ttVerificado")}</div>
							)}
						</UncontrolledTooltip>
						{obj.EsVerificado === 0 ? (
							<span id={`iconEsVerificado${key}`} className="btn-icon btn-link" style={{ color: "yellow" }}>
								<i className="fa fa-exclamation-triangle" />
							</span>
						) : (
							<span id={`iconEsVerificado${key}`} className="btn-icon btn-link text-success">
								<i className="fas fa-check"></i>
							</span>
						)}
						<UncontrolledTooltip placement="left" target={`btnInformacion_${key}`}>
							{translate("MisServiciosSuscritos.ttInformacion")}
						</UncontrolledTooltip>
						<Button
							id={`btnInformacion_${key}`}
							onClick={() => {
								writeLog(
									Formularios.ServiciosSuscritos.ClaFuncionalidad,
									Formularios.ServiciosSuscritos.Funcionalidades.btnInformacion,
									undefined,
									obj,
								);
								this.setState({
									showModal: true,
									idEntidad: obj.IdEntidad,
									claProducto: obj.ClaProducto,
								});
							}}
							color="info"
							size="sm"
							className="btn-icon btn-link">
							<i className="fas fa-info-circle" />
						</Button>

						<UncontrolledTooltip placement="left" target={`btnEliminar_${key}`}>
							{translate("MisServiciosSuscritos.ttEliminar")}
						</UncontrolledTooltip>
						<Button
							id={`btnEliminar_${key}`}
							onClick={() => {
								this.showConfirm(
									() => this.btnDesactivarClick(obj.IdEntidad, obj.ClaProducto),
									() => this.hideAlert(),
									sessionStorage.getItem("ClaIdioma") === "1"
										? `¿Desea eliminar la suscripción al servicio?`
										: "Do you want to unsubscribe from the service?",
									obj.NomEntidad,
								);
							}}
							color="danger"
							size="sm"
							className="btn-icon btn-link">
							<i className="fas fa-trash-alt" />
						</Button>
					</div>
				),
			};
		});

		return dgvSource;
	}

	// /**
	//  * Función 	para llenar la tabla incial
	//  */
	// llenarTabla(data) {}

	/**
	 * Función 	que oculta el modal
	 * correcta y refresca la pantalla
	 */
	hideModal(nombreModal) {
		switch (nombreModal) {
			case "mdlBuscar":
				this.setState({
					showModalBuscar: false,
				});
				break;

			case "mdlEjemplo":
				this.setState({
					showModal: false,
				});
				break;

			default:
				return;
		}
	}

	hideModalInfo() {
		this.setState({
			showModal: false,
		});
	}

	/**
	 * Función del botón	que redirige a la página de incidentes
	 * @param {String} idEntidad id de la entidad seleccionada para consultar
	 * sus incidentes
	 * @param {String} nomEntidad nombre de la entidad seleccionada para consultar
	 * sus incidentes
	 */
	btnIncidenteClick(idEntidad, nomEntidad) {
		this.props.history.push({
			pathname: "/admin/Incidentes",
			idEntidad: idEntidad,
			nombreEntidad: nomEntidad,
		});
	}

	/**
	 * Consulta el ejemplo de consumo de una entidad específica y abre el modal
	 * @param {Number} idEntidad id de la entidad a la que se consultara
	 * el ejemplo de consumto
	 */
	verEjemplo(idEntidad, ClaProducto) {
		let urlService = `${Config.urlApiService}/Entidades/${ClaProducto}/${idEntidad}/1`;

		callApi(urlService, "GET", null)
			.then((res) => {
				let entidad = res;
				this.setState({
					isLoaded: true,
					entidad: entidad,
					showModal: true,
				});
			})
			.catch((err) => {
				alert(err.message);
			});
	}

	render() {
		return (
			<>
				{getLoginInfo().NomGrupo === "Pruebas" ? (
					<TourVirtual PaginaID="11" ClaTour={Tours.NuevosFeatureSuscritos} />
				) : (
					<TourVirtual PaginaID="11" ClaTour={Tours.Suscripciones} />
				)}
				<NotificationAlert ref={(ref) => (this.notify = ref)} />
				{this.state.showAlert}
				{this.state.showConfirm}
				{sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.frmLoad()}
				<div className="content">
					<Formik
						initialValues={{
							ClaProducto: 0,
							ClaTipoEntidad: 0,
							ClaSistema: 0,
							Nombre: "",
							TerminosGlosario: "",
						}}
						onSubmit={this.btnBuscarClick}
						onReset={this.clear}>
						{(formikProps) => (
							<Form onSubmit={formikProps.handleSubmit} key="frmEntidadBuscar">
								<Card>
									<CardHeader>
										<FormGroup row>
											<Col md={6}>
												<h1>{translate("MisServiciosSuscritos.titulo")}</h1>
											</Col>
											<Col md={6} className="text-right">
												<UncontrolledTooltip target={`btnBuscar`}>
													{translate("MisServiciosSuscritos.ttBuscar")}
												</UncontrolledTooltip>
												<Button
													id="btnBuscar"
													color="info"
													size="sm"
													className="btn-simple btn-icon"
													type="submit">
													<i className="fas fa-search" />
												</Button>

												<UncontrolledTooltip target={`btnLimpiar`}>
													{translate("MisServiciosSuscritos.ttLimpiar")}
												</UncontrolledTooltip>
												<Button
													onClick={() => {
														writeLog(
															Formularios.ServiciosSuscritos.ClaFuncionalidad,
															Formularios.ServiciosSuscritos.Funcionalidades.btnLimpiar,
														);
														formikProps.handleReset();
													}}
													id="btnLimpiar"
													color="info"
													size="sm"
													className="btn-simple btn-icon"
													type="submit">
													<i className="fas fa-eraser" />
												</Button>
											</Col>
										</FormGroup>
									</CardHeader>
									<CardBody>
										<FormGroup row>
											<Col md={2}>
												<Label>{translate("MisServiciosSuscritos.lblProducto")}</Label>
												<Field
													name="ClaProducto"
													as="select"
													type="input"
													className="form-control"
													id="selProducto">
													{producto &&
														producto.length > 0 &&
														producto.map((obj, index) => (
															<option className="ddlOptions" key={index} value={obj.ClaProducto}>
																{sessionStorage.getItem("ClaIdioma") === "2" &&
																obj.NomProducto === "SELECCIONE"
																	? "SELECT"
																	: obj.NomProducto}
															</option>
														))}
												</Field>
											</Col>
											<Col md={3}>
												<Label>{translate("MisServiciosSuscritos.lblServicio")}</Label>
												<Field
													name="Nombre"
													type="input"
													className="form-control"
													placeholder={translate("MisServiciosSuscritos.phNombre")}
													id="txtNombre"></Field>
											</Col>
											<Col md={3}>
												<Label for="TerminosGlosario">
													{translate("MisServiciosSuscritos.lblGlosario")}
												</Label>
												<Autocomplete
													multiple
													disableCloseOnSelect
													key={this.state.clearAutocomplete}
													id="TerminosGlosario"
													size="small"
													onChange={(_, value) =>
														this.setState({
															nuevosTerminos: value,
														})
													}
													options={terminosGlosario}
													getOptionLabel={(option) => option.NomTermino}
													getOptionSelected={(option, value) => option.ClaTermino === value.ClaTermino}
													//defaultValue={this.state.nuevosTerminos ? this.state.nuevosTerminos : ''}
													renderInput={(params) => (
														<TextField
															{...params}
															multiline
															name="TerminosGlosario"
															placeholder={translate("MisServiciosSuscritos.phTerminosRelacionados")}
															variant="outlined"
															rowsMax="6"
														/>
													)}
												/>
											</Col>
										</FormGroup>
										<Row>
											<Col md={12} className="text-right">
												<label>
													{translate("MisServiciosSuscritos.lblTotalDeRegistros")} {this.state.totalCount}
												</label>
											</Col>
										</Row>
										<Grid rows={this.state.srcData} columns={this.state.gvEntidades.columns}>
											<SortingState
												sorting={this.state.sorting}
												onSortingChange={(value) => {
													this.setState({ sorting: value });
												}}
											/>

											<RowDetailState />

											<PagingState
												currentPage={this.state.currentPage}
												onCurrentPageChange={(value) => {
													this.setState({ currentPage: value });
												}}
												pageSize={this.state.pageSize}
												onPageSizeChange={(value) => {
													this.setState({ pageSize: value });
												}}
											/>

											<IntegratedSorting />
											<IntegratedPaging />

											<Table
												tableComponent={tableComponent}
												columnExtensions={this.state.gvEntidades.tableColumnExtensions}
												headComponent={headerComponent}
												messages={{ noData: gvConfig.noData }}
											/>

											<TableHeaderRow showSortingControls={true} />
											<TableRowDetail contentComponent={this.RowDetail} />

											<PagingPanel pageSizes={gvConfig.pageSizes} />
										</Grid>
									</CardBody>
								</Card>
							</Form>
						)}
					</Formik>
				</div>

				<Modal centered={true} isOpen={this.state.showModalBuscar} size="xl">
					<Card style={{ minHeight: "600px" }}>
						<CardHeader>
							<CardTitle>{translate("MisServiciosSuscritos.lblBusqueda")}</CardTitle>
						</CardHeader>
						<CardBody>
							<Buscar />
						</CardBody>
						<CardFooter>
							<Row>
								<Col md={12} className="text-right">
									<Button
										id="btnBuscar"
										className="animation-on-hover"
										color="default"
										size="sm"
										onClick={() => this.hideModal("mdlBuscar")}>
										{translate("MisServiciosSuscritos.btnCerrar")}
									</Button>
								</Col>
							</Row>
						</CardFooter>
					</Card>
				</Modal>
				<Modal centered isOpen={this.state.showModal} size="lg">
					<EntidadMtto
						hideModal={this.hideModalInfo}
						idEntidad={this.state.idEntidad}
						claProducto={this.state.claProducto}
						readOnly={true}
						className="mdlEntidad"
					/>
				</Modal>
				<Flags authorizedFlags={[getLoginInfo().NomGrupo]}>
					<Modal centered={true} isOpen={this.state.showModalComentarios} size="md">
						<ComentariosMtto
							hideModalComentarios={this.hideModalComentarios}
							idEntidad={this.state.idEntidad}
							claProducto={this.state.claProducto}
							calificacion={this.state.calificacion}
							nomEntidad={this.state.nomEntidad}
							className="mdlEntidad"
						/>
					</Modal>
				</Flags>
			</>
		);
	}
}

MisEntidades.propTypes = {
	history: PropTypes.any,
};

// RowDetail.propTypes = {
// 	row: {
// 		Descripcion: PropTypes.string,
// 		NomTipoServicio: PropTypes.string,
// 	},
// };
