import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import { getApiStoredProcedureData, getCliente } from 'utils/Utilerias';
let colores = [];
export default class IncidentesGrafica extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			options: {
				chart: {
					type: "column",
					backgroundColor: "#27293d",
				},
				colors: { colores },
				title: {
					text: "",
				},
				legend: {
					itemStyle: {
						fontSize: "10pt",
						font: "10pt Trebuchet MS, Verdana, sans-serif",
						color: "#A0A0A0",
					},
				},
				plotOptions: {
					column: {
						borderWidth: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				tooltip: {
					shared: true,
					useHTML: true,
				},
				yAxis: {
					title: {
						text: "Total de Incidentes",
					},
					gridLineColor: "rgba(255, 255, 255, 0.1)",
					tickLength: 10,
				},
				xAxis: {},
			},
		};
	}

	componentDidMount() {
		// getApiStoredProcedureData(185, { IdEntidad: this.props.entidad }, 8, (response) => {
		// 	let dataArray = response.data[0].Result0;
		// 	let labels = response.data[1].Result1[0][0].split(',');
		// 	let dataChart = [];
		//  if(dataArray.length >= 1){
		//     dataArray.map((item, index) =>{
		//        if(item.includes("Capturado")){
		//           colores.push('#1d8cf8')
		//        }else if(item.includes("En Revisión")){
		//           colores.push('#ff8d72')
		//        }else if(item.includes("Resuelto")){
		//           colores.push('#2dce89')
		//        }else if(item.includes("Rechazado")){
		//           colores.push('#fd5d93')
		//        }
		//     })
		//  }
		// 	dataArray.map((item, index) => {
		// 		let arrayName = dataArray[index].splice(0, 1)[0];
		// 		let arrayItem = dataArray[index];
		// 		dataChart.push({
		// 			name: arrayName,
		// 			data: arrayItem,
		// 		})
		// 		return item;
		// 	});
		// 	let tempOptions = this.state.options;
		// 	tempOptions.series = dataChart;
		//  tempOptions.xAxis.categories = labels;
		//  tempOptions.colors = colores;
		// 	this.setState({
		// 		isLoaded: true,
		// 		options: tempOptions
		// 	});
		// });
	}

	arrayColumn(arr, n) {
		arr.map((x) => x[n]);
	}

	/**
	 *Carga los datos de la gráfica
	 *
	 * @static
	 */
	dataSeriesChart(array, labels) {
		array.map((item) => {
			let index = 0;
			console.log(item + " " + index);
			let data = [];

			array.forEach((item, key) => {
				data.push({
					name: labels[key],
					data: item,
				});

				index++;
			});

			return data;
		});
	}

	componentWillUnmount() {
		colores = [];
	}

	render() {
		if (this.state.isLoaded) {
			return <HighchartsReact highcharts={Highcharts} options={this.state.options} />;
		} else {
			return (
				<div className="text-center">
					<h3>Cargando...</h3>
				</div>
			);
		}
	}
}
