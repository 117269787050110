import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
	const domain = "krakenapi.us.auth0.com";
	const clientId = "lwmjMpu6h48YNdmKx4GepijUqcnTGqfL";
	const audience = "https://www.kraken-nueva-api.com";
	const history = useHistory();

	const onRedirectCallback = (appState) => {
		history.push(appState?.returnTo || window.location.pathname);
	};

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			redirectUri={window.location.origin + "/auth"}
			onRedirectCallback={onRedirectCallback}
			audience={audience}>
			{children}
		</Auth0Provider>
	);
};
Auth0ProviderWithHistory.propTypes = {
	children: PropTypes.any,
};
export default Auth0ProviderWithHistory;
