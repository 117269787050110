import React from "react";
import {
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	Form,
	FormGroup,
	Row,
	Col,
	Button,
	Label,
	UncontrolledTooltip,
	Input,
} from "reactstrap";
import { Formik, Field } from "formik";
import PropTypes from "prop-types";

import { Config } from "utils/Config";
import { getProducto, callApi, showSweetAlert, writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";

let perfiles = [];
//let tiposVistas = [];
let formularioDet = [];
export default class UsuarioMtto extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			buscar: false,
			nuevo: false,
			objUsuario: {},
			claUsuario: this.props.claUsuario,
			esGuardado: false,
			claEmpleado: this.props.claEmpleado,
		};

		//Si es un nuevo registro, claUsuario será igual a 0
		if (this.props.claUsuario === 0) {
			this.state = {
				isLoaded: true,
				nuevo: true,
				objUsuario: {
					ClaUsuario: 0,
					NomUsuario: "",
					ClaEmpleado: null,
					ClaRol: 2,
					VerComo: 0,
					Email: "",
				},
				claUsuario: 0,
				esNotificado: false,
			};
		}

		this.btnCancelar_Click = this.btnCancelar_Click.bind(this);
		this.handleSubmitAceptar = this.handleSubmitAceptar.bind(this);
		this.load = this.load.bind(this);
	}

	componentDidMount() {
		this.load();
	}

	load() {
		//Traer perfiles de usuario
		let urlApi = `${Config.urlApiService}/Catalogos/13`;
		callApi(urlApi, "GET", null, (status, response) => {
			perfiles = response;
		});

		//Traer info tooltips
		urlApi = `${Config.urlApiService}/CatFormularioDet/11`;
		callApi(urlApi, "GET", null, (status, response) => {
			formularioDet = response;
		});

		if (this.state.claUsuario !== 0 && this.state.objUsuario.NomUsuario !== null) {
			let data = {};

			let urlWebService =
				Config.urlApiService +
				"/Producto/Usuarios/" +
				getProducto() +
				"/" +
				this.state.claEmpleado +
				"?claUsuario=" +
				this.state.claUsuario +
				"&esExterno=1";

			callApi(urlWebService, "GET", data, (status, response) => {
				this.setState({
					isLoaded: true,
					nuevo: false,
					objUsuario: response[0],
					esNotificado: response[0].EsNotificado === 1 ? true : false,
				});
			});
		}
	}

	/**
	 * Función del botón para cancelar la operación
	 * y ocultar el modal sin guardar ningún cambio
	 */
	btnCancelar_Click() {
		this.props.hideModal();
	}

	/**
	 * Función del botón para guardar cambios en el objeto responsable
	 * @param {JSON} value los valores del formulario
	 */
	handleSubmitAceptar(value) {
		let data, method, urlWebService;
		if (value.email !== "" && !value.claEmpleado) {
			urlWebService = `${Config.urlApiService}/UsuarioExterno`;
			data = {
				email: value.email,
				nombre: value.nombre,
				apellido: value.apellido,
			};

			callApi(urlWebService, "POST", data, (status, response) => {
				if (status === 200) {
					this.setState({
						claUsuario: response.ClaUsuario,
					});
					data = {
						claUsuario: response.ClaUsuario,
						claEmpleado: 0,
						claProducto: getProducto(),
						claRol: value.claRol,
						verComo: value.verComo,
						esNotificado: this.state.esNotificado ? 1 : 0,
					};

					urlWebService = Config.urlApiService + "/Producto/Usuarios/";
					method = "PUT";
					callApi(
						urlWebService,
						method,
						data,
						(status, response) => {
							writeLog(
								Formularios.UsuariosMtto.ClaFuncionalidad,
								Formularios.UsuariosMtto.Funcionalidades.btnGuardar,
								urlWebService,
								data,
								response,
								undefined,
								0,
							);
							this.setState({
								isLoaded: true,
								objUsuario: {
									ClaUsuario: response.claUsuario,
								},
								esGuardado: true,
								claUsuario: response.claUsuario,
							});

							this.load();

							let mensaje =
								this.state.objUsuario.ClaUsuario === 0
									? translate("UsuarioMtto.alertRegistroGuardado")
									: translate("UsuarioMtto.alertCambiosRealizados");

							showSweetAlert(mensaje, "", "success");
							//this.btnCancelar_Click();
						},
						Formularios.UsuariosMtto.ClaFuncionalidad,
						method === "GET"
							? Formularios.UsuariosMtto.Funcionalidades.btnBuscar
							: Formularios.UsuariosMtto.Funcionalidades.btnGuardar,
					);
					this.btnCancelar_Click();
				}
			});
		} else {
			method = "PUT"; //Editar

			data = {
				claUsuario: this.props.claUsuario,
				claEmpleado: value.claEmpleado,
				claProducto: getProducto(),
				claRol: value.claRol,
				verComo: value.verComo,
				esNotificado: this.state.esNotificado ? 1 : 0,
			};
			urlWebService = Config.urlApiService + "/Producto/Usuarios/";

			if (this.state.claUsuario === 0 && !this.state.buscar) {
				method = "POST"; //Agregar
			} else if (this.state.buscar) {
				if (value.claEmpleado > 0) {
					method = "GET"; //Obtener datos usuario
					urlWebService = urlWebService + 0 + "/" + value.claEmpleado;
					this.setState({
						claEmpleado: value.claEmpleado,
					});
				} else {
					showSweetAlert("Warning", translate("UsuarioMtto.alertClaveValido"), "warning");
					return;
				}
			}

			callApi(
				urlWebService,
				method,
				data,
				(status, response) => {
					if (response.length <= 0) {
						this.setState({
							isLoaded: true,
							buscar: false,
						});

						showSweetAlert("Warning", translate("UsuarioMtto.alertUsuarioInvalido"), "warning");
					} else {
						if (method !== "GET") {
							writeLog(
								Formularios.UsuariosMtto.ClaFuncionalidad,
								Formularios.UsuariosMtto.Funcionalidades.btnGuardar,
								urlWebService,
								data,
								response,
								undefined,
								0,
							);
							this.setState({
								isLoaded: true,
								objUsuario: {
									ClaUsuario: response.claUsuario,
								},
								esGuardado: true,
								claUsuario: response.claUsuario,
							});

							this.load();

							let mensaje =
								this.state.objUsuario.ClaUsuario === 0
									? translate("UsuarioMtto.alertRegistroGuardado")
									: translate("UsuarioMtto.alertCambiosRealizados");

							showSweetAlert(mensaje, "", "success");
							this.btnCancelar_Click();
						} else {
							writeLog(
								Formularios.UsuariosMtto.ClaFuncionalidad,
								Formularios.UsuariosMtto.Funcionalidades.btnBuscar,
								urlWebService,
								data,
								response,
								undefined,
								0,
							);
							console.log(response[0]);
							this.setState({
								isLoaded: true,
								buscar: false,
								objUsuario: response[0],
							});
						}
					}
				},
				Formularios.UsuariosMtto.ClaFuncionalidad,
				method === "GET"
					? Formularios.UsuariosMtto.Funcionalidades.btnBuscar
					: Formularios.UsuariosMtto.Funcionalidades.btnGuardar,
			);
		}
	}

	renderDetTexto(nomControl) {
		let detalle = formularioDet.filter((detalle) => detalle.NomControl === nomControl);
		console.log(detalle);
		if (detalle[0]) return detalle[0].Texto;
		else return translate("UsuarioMtto.noDescripcion");
	}

	render() {
		if (!this.state.isLoaded) {
			return <div>{translate("UsuarioMtto.cargando")}</div>;
		}
		return (
			<div className="content">
				<Formik
					initialValues={{
						nomUsuario: this.state.objUsuario.ClaEmpleado === 0 ? "" : this.state.objUsuario.NomUsuario,
						claEmpleado: this.state.objUsuario.ClaEmpleado === 0 ? "" : this.state.objUsuario.ClaEmpleado,
						claRol: this.state.objUsuario.ClaRol,
						verComo: this.state.objUsuario.VerComo,
						email: this.state.objUsuario.ClaEmpleado === 0 ? this.state.objUsuario.Email : "",
						nombre:
							this.state.objUsuario.ClaEmpleado === 0
								? this.state.objUsuario.NomUsuario !== null
									? this.state.objUsuario.NomUsuario.split(" ")[0]
									: ""
								: "",
						apellido:
							this.state.objUsuario.ClaEmpleado === 0
								? this.state.objUsuario.NomUsuario !== null
									? this.state.objUsuario.NomUsuario.split(" ")[1]
									: ""
								: "",
					}}
					onSubmit={this.handleSubmitAceptar}>
					{(props) => (
						<Form onSubmit={props.handleSubmit}>
							<Card>
								<CardHeader>
									<h3 className="mb-0">
										{this.state.claUsuario <= 0
											? translate("UsuarioMtto.titulo")
											: this.state.objUsuario.ClaEmpleado
											? this.state.objUsuario.ClaEmpleado + " - " + this.state.objUsuario.NomUsuario
											: this.state.objUsuario.Email}
									</h3>
								</CardHeader>
								<CardBody>
									<Row>
										<Col md={5}>
											<FormGroup row>
												<Col md={9}>
													<Label for="claEmpleado">{translate("UsuarioMtto.lblClaveEmpleado")}</Label>
													<Field
														name="claEmpleado"
														type="input"
														className="form-control"
														placeholder={translate("UsuarioMtto.phClaveEmpleado")}
														disabled={!this.state.nuevo}
													/>
												</Col>
												<Col md={3} className="pb-3">
													<div style={{ position: "absolute", left: 0, bottom: 0 }}>
														<UncontrolledTooltip target={`btnBuscar`}>
															{translate("UsuarioMtto.ttBuscar")}
														</UncontrolledTooltip>
														<Button
															id="btnBuscar"
															name="btnBuscar"
															className="btn-simple btn-icon"
															color="info"
															size="sm"
															type="submit"
															onClick={() => {
																this.setState({ buscar: true });
															}}
															disabled={!this.state.nuevo}>
															<i className="fas fa-search" />
														</Button>
													</div>
												</Col>
											</FormGroup>
										</Col>
										<Col md={7}>
											<FormGroup>
												<Label for="nomUsuario">{translate("UsuarioMtto.lblNombreEmpleado")}</Label>
												<Field
													name="nomUsuario"
													type="input"
													className="form-control"
													placeholder={translate("UsuarioMtto.phNombreEmpleado")}
													value={
														this.state.objUsuario.ClaEmpleado === 0
															? ""
															: this.state.objUsuario.NomUsuario
													}
													disabled={true}
													required
												/>
											</FormGroup>
										</Col>
									</Row>
									<div className="notifPadding">
										<Row>
											<Col md={12}>
												<UncontrolledTooltip placement="right" target={`infoExterno`}>
													{translate("UsuarioMtto.ttExterno")}
												</UncontrolledTooltip>
												<Label>
													<span style={{ fontSize: "15px" }}>
														{" "}
														{translate("UsuarioMtto.ttAccesoExt")} &nbsp;{" "}
													</span>
													<span id="infoExterno">
														<i className="far fa-question-circle fa-lg"></i>
													</span>
												</Label>
											</Col>
										</Row>
										<Row>
											<Col md={6}>
												<FormGroup>
													<Label for="nombre">{translate("UsuarioMtto.lblNombreExterno")}</Label>
													<Field
														name="nombre"
														type="input"
														className="form-control"
														/*value={
															this.state.objUsuario.ClaEmpleado === 0
																? this.state.objUsuario.NomUsuario !== null
																	? this.state.objUsuario.NomUsuario.split(" ")[0]
																	: ""
																: ""
														}*/
														placeholder={translate("UsuarioMtto.phNombreExterno")}
														disabled={!this.state.nuevo}
													/>
												</FormGroup>
											</Col>
											<Col md={6}>
												<Label for="apellido">{translate("UsuarioMtto.lblApellidoExterno")}</Label>
												<Field
													name="apellido"
													type="input"
													className="form-control"
													/*value={
														this.state.objUsuario.ClaEmpleado === 0
															? this.state.objUsuario.NomUsuario !== null
																? this.state.objUsuario.NomUsuario.split(" ")[1]
																: ""
															: ""
													}*/
													placeholder={translate("UsuarioMtto.phApellidoExterno")}
													disabled={!this.state.nuevo}
												/>
											</Col>
										</Row>
										<Row>
											<Col md={6}>
												<Label for="email">{translate("UsuarioMtto.lblCorreo")}</Label>
												<Field
													name="email"
													type="input"
													className="form-control"
													placeholder={translate("UsuarioMtto.phCorreo")}
													/*value={
														this.state.objUsuario.ClaEmpleado === 0 ? this.state.objUsuario.Email : ""
													}*/
													disabled={!this.state.nuevo}
												/>
											</Col>
										</Row>
									</div>
									<Row>
										<Col md={6} style={{ "margin-top": "35px" }}>
											<FormGroup>
												<UncontrolledTooltip placement="right" target={`infoPerfiles`}>
													{perfiles &&
														perfiles.length > 0 &&
														perfiles.map((obj, index) => (
															<span key={index} style={{ textAlign: "justify" }}>
																{obj.ClaRol === 2 ? (
																	<b>{translate("UsuarioMtto.ttDesarrollador")}: </b>
																) : (
																	<b>{translate("UsuarioMtto.ttPM")} </b>
																)}
																{obj.ClaRol === 2
																	? translate("UsuarioMtto.ttDescripcionDesarrollador")
																	: translate("UsuarioMtto.ttPMDescripcion")}
																<br />
																{/* <b>{obj.NomRol}: </b>
																{obj.Descripcion} <br /> */}
															</span>
														))}
												</UncontrolledTooltip>
												<Label for="claRol">
													{translate("UsuarioMtto.lblPerfil")} &nbsp;
													<span id="infoPerfiles">
														<i className="far fa-question-circle fa-lg"></i>
													</span>
												</Label>
												<Field
													name="claRol"
													as="select"
													type="input"
													className="form-control"
													required
													disabled>
													{perfiles &&
														perfiles.length > 0 &&
														perfiles.map((obj, index) => (
															<option className="ddlOptions" key={index} value={obj.ClaRol}>
																{obj.NomRol}
															</option>
														))}
												</Field>
											</FormGroup>
										</Col>
										{/*<Col md={6}>
											 <FormGroup>
												<UncontrolledTooltip placement="right" target={`infoTipoVista`}>
													{this.renderDetTexto("txtTipoVista")}
												</UncontrolledTooltip>
												<Label for="verComo">
													Tipo de vista &nbsp;
													<span id="infoTipoVista">
														<i className="far fa-question-circle fa-lg"></i>
													</span>
												</Label>
												<Field name="verComo" as="select" type="input" className="form-control" required>
													{tiposVistas &&
														tiposVistas.length > 0 &&
														tiposVistas.map((obj, index) => (
															<option className="ddlOptions" key={index} value={obj.ClaTipoVista}>
																{obj.NomTipoVista}
															</option>
														))}
												</Field>
											</FormGroup> 
										</Col>*/}
										<Col md={6} style={{ "margin-top": "55px" }}>
											<UncontrolledTooltip placement="right" target={`infoNotificarCorreo`}>
												{translate("UsuarioMtto.ttCorreo")}
											</UncontrolledTooltip>
											<FormGroup check>
												<Label check>
													<Input
														type="checkbox"
														name="EsNotificado"
														checked={this.state.esNotificado}
														onChange={(event) => {
															this.setState({
																esNotificado: event.target.checked,
															});
														}}
														//disabled={!this.state.nuevo}
													/>
													{translate("UsuarioMtto.lblNotificarCorreo")}
													<span className="form-check-sign">
														<span className="check" />
													</span>{" "}
													&nbsp;
													<span id="infoNotificarCorreo">
														<i className="far fa-question-circle fa-lg"></i>
													</span>
												</Label>
											</FormGroup>
										</Col>
									</Row>
									{/*<Row>
										<Col>
											<UncontrolledTooltip placement="right" target={`infoNotificarCorreo`}>
												{translate("UsuarioMtto.ttCorreo")}
											</UncontrolledTooltip>
											<FormGroup check>
												<Label check>
													<Input
														type="checkbox"
														name="EsNotificado"
														checked={this.state.esNotificado}
														onChange={(event) => {
															this.setState({
																esNotificado: event.target.checked,
															});
														}}
														//disabled={!this.state.nuevo}
													/>
													{translate("UsuarioMtto.lblNotificarCorreo")}
													<span className="form-check-sign">
														<span className="check" />
													</span>{" "}
													&nbsp;
													<span id="infoNotificarCorreo">
														<i className="far fa-question-circle fa-lg"></i>
													</span>
												</Label>
											</FormGroup>
										</Col>
									</Row> */}
								</CardBody>
								<CardFooter>
									<Row>
										<Col md={12} className="text-right">
											<Button
												id="btnGuardar"
												className="animation-on-hover"
												color="success"
												size="sm"
												type="submit"
												onClick={() => {
													this.setState({ buscar: false });
												}}>
												{translate("UsuarioMtto.btnGuardar")}
											</Button>

											{this.state.esGuardado ? (
												<Button
													id="btnCancelar"
													className="animation-on-hover"
													color="default"
													size="sm"
													onClick={() => {
														writeLog(
															Formularios.UsuariosMtto.ClaFuncionalidad,
															Formularios.UsuariosMtto.Funcionalidades.btnCancelar,
														);
														this.btnCancelar_Click();
													}}>
													{translate("UsuarioMtto.btnCerrar")}
												</Button>
											) : (
												<Button
													id="btnCancelar"
													className="animation-on-hover"
													color="danger"
													size="sm"
													onClick={() => {
														writeLog(
															Formularios.UsuariosMtto.ClaFuncionalidad,
															Formularios.UsuariosMtto.Funcionalidades.btnCancelar,
														);
														this.btnCancelar_Click();
													}}>
													{translate("UsuarioMtto.btnCancelar")}
												</Button>
											)}
										</Col>
									</Row>
								</CardFooter>
							</Card>
						</Form>
					)}
				</Formik>
			</div>
		);
	}
}

UsuarioMtto.propTypes = {
	claUsuario: PropTypes.any,
	claEmpleado: PropTypes.any,
	hideModal: PropTypes.func,
};
