import React from "react";

import { callApi, showAlert, showConfirm, writeLog } from "utils/Utilerias";
import { Config } from "utils/Config";
import NotificationAlert from "react-notification-alert";
import { Formik, Field } from "formik";
import ReactTable from "react-table";
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Row,
	Col,
	Modal,
	UncontrolledTooltip,
	FormGroup,
	Form,
	Label,
	Badge,
} from "reactstrap";
import GlosarioMtto from "./GlosarioMtto";
import { Formularios } from "utils/funcionalidad";

let srcData = [];
let tableWidth;
let productos = [];
export default class Glosario extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			alert: null,
		};

		this.showConfirm = this.showConfirm.bind(this);
		this.hideModal = this.hideModal.bind(this);
		this.llenarTabla = this.llenarTabla.bind(this);
		this.desactivarTermino = this.desactivarTermino.bind(this);
		this.btnBuscarClick = this.btnBuscarClick.bind(this);
		this.clear = this.clear.bind(this);
	}

	componentDidMount() {
		let urlApi = `${Config.urlApiService}/Catalogos/11`;

		callApi(urlApi, "GET", null, (status, response) => {
			productos = response;
		});

		this.llenarTabla();
	}

	/**
	 * Función 	que oculta el modal
	 * correcta y refresca la pantalla
	 */
	hideModal(isOk) {
		this.llenarTabla();
		this.setState({
			showModal: false,
		});

		if (isOk) {
			var options = {
				place: "br",
				message: "Operación realizada correctamente.",
				type: "success",
				autoDismiss: 3,
			};

			this.notify.notificationAlert(options);
		}
	}

	/**
	 * Funcion para ocultar la alerta/mensaje que fue activado
	 */
	hideAlert() {
		this.setState({
			showAlert: null,
			showConfirm: null,
		});
	}

	/**
	 * Función que se muestra un mensaje de éxito en la operación
	 * @param {String} title titulo que tendrá el mensaje mostrado
	 * @param {String} text contenido del mensaje
	 * @param {String} icon icono que mostrará el mensaje
	 */
	successAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Función que desactiva a un termino
	 * @param {Object} objTermino Termino que se va a desactivar
	 */
	desactivarTermino(objTermino) {
		try {
			let urlWebService = Config.urlApiService + "/Glosario/" + objTermino.ClaTermino;

			let data = {
				ClaTermino: objTermino.ClaTermino,
				BajaLogica: 1,
				NomTermino: objTermino.NomTermino,
				Descripcion: objTermino.Descripcion,
				ClaProducto: objTermino.ClaProducto,
				Sinonimos: objTermino.Sinonimos,
				Abreviatura: objTermino.Abreviatura,
				NoUsar: objTermino.NoUsar,
			};

			callApi(
				urlWebService,
				"PUT",
				data,
				(status, response) => {
					this.hideAlert();

					if (response.claTermino === undefined) {
						throw Error(response.message);
					} else {
						writeLog(
							Formularios.GlosarioTerminos.ClaFuncionalidad,
							Formularios.GlosarioTerminos.Funcionalidades.btnEliminar,
							urlWebService,
							data,
							response,
							"El término se eliminó correctamente",
							0,
						);

						this.successAlert("El término se eliminó correctamente", undefined, "success");
						this.llenarTabla();
					}

					this.setState({
						isLoaded: true,
						objTermino: response,
					});
				},
				Formularios.GlosarioTerminos.ClaFuncionalidad,
				Formularios.GlosarioTerminos.Funcionalidades.btnEliminar,
			).catch((oErr) => {
				this.hideAlert();
				this.successAlert("Error", oErr, "error");
			});

			this.llenarTabla();
		} catch (err) {
			alert(err);
		}
	}

	/**
	 * Función 	para llenar la tabla incial
	 */
	llenarTabla() {
		let urlWebService = Config.urlApiService + "/Glosario";
		let data = {};

		callApi(urlWebService, "GET", data, (status, response) => {
			if (response.length <= 0) {
				srcData = [];
			} else {
				srcData = response.map((obj, key) => {
					return {
						...obj,
						CustomCell: (
							<div className="w-100 p-3">
								<Row>
									<h4>
										{obj.NomTermino}&nbsp;&nbsp; <Badge color="primary"> {obj.NomProducto}</Badge>
									</h4>
								</Row>
								<Row>
									<div style={{ wordWrap: "break-word" }}>{obj.Descripcion}</div>
								</Row>
							</div>
						),
						Actions: (
							<div className="actions-center">
								<UncontrolledTooltip target={`btnEditar_${key}`}> Editar</UncontrolledTooltip>
								<Button
									onClick={() => {
										writeLog(
											Formularios.GlosarioTerminos.ClaFuncionalidad,
											Formularios.GlosarioTerminos.Funcionalidades.btnEditar,
											undefined,
											obj,
										);

										this.setState({
											showModal: true,
											claTermino: obj.ClaTermino,
										});
									}}
									color="info"
									size="sm"
									className="btn-icon btn-link"
									id={`btnEditar_${key}`}>
									<i className="fa fa-edit" />
								</Button>

								<UncontrolledTooltip target={`btnEliminar_${key}`}> Eliminar</UncontrolledTooltip>
								<Button
									onClick={() => {
										this.showConfirm(
											() => this.desactivarTermino(obj),
											() => this.hideAlert(),
											`¿Desea desactivar el término?`,
											obj.NomTermino,
										);

										this.setState({
											claTermino: obj.ClaTermino,
										});
									}}
									color="danger"
									size="sm"
									className="btn-icon btn-link"
									id={`btnEliminar_${key}`}>
									<i className="fa fa-eraser" />
								</Button>
							</div>
						),
					};
				});
			}
			// this.getListaDeTerminos();
			this.setState({
				isLoaded: true,
				data: srcData,
			});
		});
	}

	clear() {
		this.llenarTabla();
	}

	btnBuscarClick(value) {
		let urlWebService = Config.urlApiService + "/Glosario/Buscar/" + value.ClaProducto;
		let data = {
			nomTermino: value.Nombre,
		};

		callApi(
			urlWebService,
			"POST",
			data,
			(status, response) => {
				if (response.length <= 0) {
					srcData = [];
				} else {
					srcData = response.map((obj, key) => {
						return {
							...obj,
							CustomCell: (
								<div className="w-100 p-3">
									<Row>
										<h4>
											{obj.NomTermino}&nbsp;&nbsp; <Badge color="primary"> {obj.NomProducto}</Badge>
										</h4>
									</Row>
									<Row>{obj.Descripcion}</Row>
								</div>
							),
							Actions: (
								<div className="actions-center">
									<UncontrolledTooltip target={`btnEditar_${key}`}> Editar</UncontrolledTooltip>
									<Button
										onClick={() => {
											this.setState({
												showModal: true,
												claTermino: obj.ClaTermino,
											});
										}}
										color="info"
										size="sm"
										className="btn-icon btn-link"
										id={`btnEditar_${key}`}>
										<i className="fa fa-edit" />
									</Button>

									<UncontrolledTooltip target={`btnEliminar_${key}`}> Eliminar</UncontrolledTooltip>
									<Button
										onClick={() => {
											this.showConfirm(
												() => this.desactivarTermino(obj),
												() => this.hideAlert(),
												`¿Desea desactivar el término?`,
												obj.NomTermino,
											);

											this.setState({
												claTermino: obj.ClaTermino,
											});
										}}
										color="danger"
										size="sm"
										className="btn-icon btn-link"
										id={`btnEliminar_${key}`}>
										<i className="fa fa-eraser" />
									</Button>
								</div>
							),
						};
					});
				}

				writeLog(
					Formularios.GlosarioTerminos.ClaFuncionalidad,
					Formularios.GlosarioTerminos.Funcionalidades.btnGuardar,
					urlWebService,
					data,
					srcData,
					undefined,
					0,
				);

				this.setState({
					isLoaded: true,
					data: srcData,
				});
			},
			Formularios.GlosarioTerminos.ClaFuncionalidad,
			Formularios.GlosarioTerminos.Funcionalidades.btnGuardar,
		);
	}

	/**
	 * Función 	que muestra un mensaje en el centro de la pantalla con poder de
	 * elegir una opción
	 * @param {Function} onConfirm Función que se ejecuta al presionar el boton de confirmar
	 * @param {Function} OnCancel Función que se ejecuta al presionar el botón de cancelar
	 * @param {String} title titulo del mensaje
	 * @param {String} text cuerpo del mensaje
	 */
	showConfirm(onConfirm, onCancel, title, text) {
		const warningAlert = showConfirm(onConfirm, onCancel, title, text);
		this.setState({
			showConfirm: warningAlert,
		});
	}

	render() {
		return (
			<>
				<NotificationAlert ref={(ref) => (this.notify = ref)} style={{ "z-index": "1" }} />
				{this.state.showAlert}
				{this.state.showConfirm}

				<div className="content">
					<Formik
						initialValues={{
							ClaProducto: 0,
							Nombre: "",
						}}
						onSubmit={this.btnBuscarClick}
						onReset={this.clear}>
						{(formikProps) => (
							<Card>
								<CardHeader>
									<CardTitle>
										<FormGroup row>
											<Col md={8}>
												<label className="lblHeader">Glosario de términos</label>
											</Col>
											<Col md={4} className="text-right">
												<UncontrolledTooltip target={`btnBuscar`}>Buscar</UncontrolledTooltip>
												<Button
													id="btnBuscar"
													color="info"
													size="sm"
													className="btn-simple btn-icon"
													type="submit">
													<i className="fas fa-search" />
												</Button>

												<UncontrolledTooltip target={`btnLimpiar`}>Limpiar</UncontrolledTooltip>
												<Button
													onClick={() => {
														writeLog(
															Formularios.GlosarioTerminos.ClaFuncionalidad,
															Formularios.GlosarioTerminos.Funcionalidades.btnLimpiar,
														);
														formikProps.handleReset();
													}}
													id="btnLimpiar"
													color="info"
													size="sm"
													className="btn-simple btn-icon"
													type="submit">
													<i className="fas fa-eraser" />
												</Button>

												<UncontrolledTooltip target={`btnNuevo`}>Agregar</UncontrolledTooltip>
												<Button
													id={`btnNuevo`}
													onClick={() => {
														writeLog(
															Formularios.GlosarioTerminos.ClaFuncionalidad,
															Formularios.GlosarioTerminos.Funcionalidades.btnNuevo,
														);
														this.setState({
															showModal: true,
															claTermino: 0,
														});
													}}
													color="info"
													size="sm"
													className="btn-simple btnAgregar">
													<i className="fa fa-plus" />
													{` Agregar`}
												</Button>
											</Col>
										</FormGroup>
									</CardTitle>
								</CardHeader>
								<CardBody>
									<Form
										onSubmit={formikProps.handleSubmit}
										ref={(el) => (this.myFormRef = el)}
										key="frmTerminoBuscar">
										<FormGroup row>
											<Col md={2}>
												<Label>Producto</Label>
												<Field name="ClaProducto" as="select" type="input" className="form-control">
													{productos &&
														productos.length > 0 &&
														productos.map((obj, index) => (
															<option className="ddlOptions" key={index} value={obj.ClaProducto}>
																{obj.NomProducto}
															</option>
														))}
												</Field>
											</Col>

											<Col md={4}>
												<Label>Buscar: </Label>
												<Field
													name="Nombre"
													type="input"
													className="form-control"
													placeholder="Buscar por nombre o descripción del término"></Field>
											</Col>
										</FormGroup>
									</Form>

									<div
										ref={(el) => {
											if (!el) return;
											tableWidth = el.getBoundingClientRect().width;
										}}>
										<ReactTable
											data={srcData}
											filterable={false}
											previousText="Anterior"
											nextText="Siguiente"
											loadingText="Cargando..."
											noDataText="No se encontró información"
											pageText="Página"
											ofText="de"
											rowsText="filas"
											columns={[
												{
													Header: " ",
													accessor: "CustomCell",
													style: { whiteSpace: "unset" },
													width: Math.round(tableWidth * 0.95),
												},
												{
													Header: " ",
													accessor: "Actions",
													sortable: false,
													filterable: false,
													width: Math.round(tableWidth * 0.05),
												},
											]}
											defaultPageSize={10}
											showPaginationTop={false}
											showPaginationBottom={true}
											className="-highlight secondary-pagination"
										/>
										{/* <ListGroup>{listaTerminos}</ListGroup> */}
									</div>
								</CardBody>
							</Card>
						)}
					</Formik>
				</div>

				<Modal centered={true} isOpen={this.state.showModal} size="lg">
					<Card>
						<CardHeader>
							<Row>
								<CardTitle>
									<Col>Términos del Glosario</Col>
								</CardTitle>
							</Row>
						</CardHeader>
						<GlosarioMtto hideModal={this.hideModal} claTermino={this.state.claTermino} />
					</Card>
				</Modal>
			</>
		);
	}
}
