const TablaReplicaMtto = {
	navGeneral: "General",
	navOrigenes: "Origenes",
	navDestinos: "Destinos",
	lblEsquema: "Esquema",
	lblNombre: "Nombre",
	lblProducto: "Producto",
	lblDescripción: "Descripción",
	lblColumnas: "Columnas",

	colNombre: "Nombre",
	colTipoDato: "Tipo Dato",
	colDescripcion: "Descripción",

	ttProducto: "Producto al cuál pertenece la tabla",
	ttDescripcion: "Descripción de la tabla",

	colServidor: "Servidor",
	colBaseDeDatos: "Base de Datos",
	colDescripcionServidor: "Descripción Servidor",
	colPublicacion: "Publicación",

	colCliente: "Cliente",

	btnGuardar: "Guardar",
	btnCerrar: "Cerrar",

	noInformacion: "No se encontró información",
};

export default TablaReplicaMtto;
