/** @format */

import React from "react";
import {
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	Card,
	CardBody,
	CardHeader,
	Form,
	FormGroup,
	Row,
	Col,
	Button,
	Label,
	CardFooter,
	UncontrolledTooltip,
} from "reactstrap";
import { Formik, Field } from "formik";

import {
	Grid,
	PagingPanel,
	VirtualTable,
	Table,
	TableHeaderRow,
	TableInlineCellEditing,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
	IntegratedPaging,
	IntegratedSorting,
	PagingState,
	SortingState,
	EditingState,
} from "@devexpress/dx-react-grid";
import { Config } from "utils/Config";
import { callApi, getProducto, writeLog } from "utils/Utilerias";
import { tableComponent, headerComponent } from "utils/gridConfig";
import NotificationAlert from "react-notification-alert";
import PropTypes from "prop-types";
import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";

let productos = [],
	formularioDet = [];

export default class ReplicaMtto extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTab: "general",
			claTabla: this.props.claTabla,
			isLoaded: false,
			isOwner: true,
			tabla: {},
			columnas: [],
			startEditAction: "click",
			selectTextOnEditStart: true,
			gvColumnas: {
				columns: [
					{ name: "NomColumna", title: translate("ReplicaMtto.colNombre") },
					{ name: "NomTipoDato", title: translate("ReplicaMtto.colTipoDato") },
					{ name: "DescripcionColumna", title: translate("ReplicaMtto.colDescripcion") },
				],
				tableColumnExtensions: [
					{ columnName: "NomColumna", width: "180px" },
					{ columnName: "NomTipoDato", width: "100px" },
					{ columnName: "DescripcionColumna", wordWrapEnabled: true },
				],

				editingStateColumnExtensions: [
					{ columnName: "NomColumna", editingEnabled: false },
					{ columnName: "NomTipoDato", editingEnabled: false },
				],
			},
			sortingTablaColumnas: [{ columnName: "NomColumna", direction: "asc" }],
			origenes: [],
			gvOrigenes: {
				columns: [
					{ name: "NomServidor", title: translate("ReplicaMtto.colServidor") },
					{ name: "NomBaseDatos", title: translate("ReplicaMtto.colBaseDeDatos") },
					//{ name: "DescripcionServidor", title: translate("ReplicaMtto.colDescripcionServidor") },
					{ name: "NomPublicacion", title: translate("ReplicaMtto.colPublicacion") },
				],
				tableColumnExtensions: [
					{ columnName: "NomServidor", width: "20%", align: "left" },
					{ columnName: "NomBaseDatos", width: "20%", align: "left" },
					{ columnName: "NomPublicacion", width: "auto", wordWrapEnabled: true },
				],
			},
			sortingTablaOrigenes: [{ columnName: "NomServidor", direction: "asc" }],
			currentPageOrigenes: 0,
			pageSize: 12,
			totalCount: 0,
			destinos: [],
			gvDestinos: {
				columns: [
					{ name: "NomServidorDestino", title: translate("ReplicaMtto.colServidor") },
					{ name: "NomBaseDatosDestino", title: translate("ReplicaMtto.colBaseDeDatos") },
					//{ name: "DescripcionServidor", title: "Descripción Servidor" },
					{ name: "NomCliente", title: translate("ReplicaMtto.colCliente") },
				],
				tableColumnExtensions: [
					{ columnName: "NomServidor", width: "6%", align: "center" },
					{ columnName: "NomBaseDatos", width: "10%", align: "center" },
					//{ columnName: "DescripcionServidor", width: "auto", wordWrapEnabled: true },
					{ columnName: "NomCliente", width: "10%", align: "center" },
				],
			},
			sortingTablaDestinos: [{ columnName: "NomServidor", direction: "asc" }],
			currentPageDestinos: 0,
			pageSizeDestinos: 12,
			totalCountDestinos: 0,
		};

		this.btnCancelarClick = this.btnCancelarClick.bind(this);
		this.btnGuardarClick = this.btnGuardarClick.bind(this);
		this.load = this.load.bind(this);
		this.toggle = this.toggle.bind(this);
		this.handler = this.handler.bind(this);
		this.FocusableCell = this.FocusableCell.bind(this);
	}

	FocusableCell = ({ onClick, ...restProps }) => <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />;

	componentDidMount() {
		this.load();
	}

	handler(changedRows) {
		this.setState({ columnas: changedRows });
	}

	/**
	 * Carga inicial de los catalogos en pantalla
	 *
	 * @returns
	 * @memberof EntidadMtto
	 */
	load() {
		let urlService = ``;

		//Obtener productos, no incluir kraken
		urlService = `${Config.urlApiService}/Catalogos/11`;
		callApi(urlService, "GET", null, (status, response) => {
			productos = response;
			productos = productos.filter((producto) => producto.ClaProducto !== 7);
		});

		//Obtener información de ayuda para tooltips
		urlService = `${Config.urlApiService}/CatFormularioDet/12`;
		callApi(urlService, "GET", null, (status, response) => {
			formularioDet = response;
		});

		if (this.props.claTabla && this.props.claTabla > 0) {
			//Traer información de la tabla seleccionada
			urlService = `${Config.urlApiService}/Replicas?ClaTabla=${this.props.claTabla}`;

			callApi(urlService, "GET", null, (status, response) => {
				let isOwner =
					parseInt(response.ClaProducto) === 0 || parseInt(response.ClaProducto) === parseInt(getProducto());

				this.setState({
					tabla: response,
					columnas: response.Columnas,
					isOwner: isOwner,
					isLoaded: true,
				});
			});
		} else {
			this.setState({
				isLoaded: true,
			});
		}
	}

	// fillDestinos(data) {
	// 	let srcData = [];

	// 	data.map((servidor) => {
	// 		if (servidor.ClaServidorDestino)
	// 			srcData.push({
	// 				ClaServidor: servidor.ClaServidorDestino,
	// 				ClaBaseDatos: servidor.ClaBaseDatosDestino,
	// 				ClaCliente: servidor.ClaCliente,
	// 				NomServidor: servidor.NomServidorDestino,
	// 				NomBaseDatos: servidor.NomBaseDatosDestino,
	// 				NomCliente: servidor.ClaCliente === 0 ? "N/A" : servidor.NomCliente,
	// 				DescripcionServidor: servidor.DescripcionServidor,
	// 			});
	// 		return null;
	// 	});

	// 	this.setState({ totalCountDestinos: srcData.length });
	// 	return srcData;
	// }

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}

	btnGuardarClick(value) {
		let urlService = `${Config.urlApiService}/Replicas`;
		const method = this.props.claTabla ? "PUT" : "POST";

		let data = {
			ClaTabla: this.props.claTabla,
			Esquema: value.Esquema,
			NomTablaReplica: value.NomTablaReplica,
			Descripcion: value.Descripcion,
			BajaLogica: 0,
			ClaProducto: value.ClaProducto ? value.ClaProducto : 0,
			ClaTipo: this.state.tabla.ClaTipo,
			Columnas: this.state.columnas,
		};

		callApi(
			urlService,
			method,
			data,
			(status, response) => {
				if (status === 200) {
					writeLog(
						Formularios.ReplicaMtto.ClaFuncionalidad,
						Formularios.ReplicaMtto.Funcionalidades.btnGuardar,
						urlService,
						data,
						response,
						"Operación realizada con éxito",
						0,
					);
					//this.btnCancelarClick(true);
				}
			},
			Formularios.ReplicaMtto.ClaFuncionalidad,
			Formularios.ReplicaMtto.Funcionalidades.btnGuardar,
		);
	}

	btnCancelarClick(isOk) {
		this.props.hideModal(isOk);
	}

	renderNavItem(tab) {
		return (
			<NavItem key={tab.Name + tab.id}>
				<NavLink
					id={tab.Id}
					className={this.state.activeTab === tab.Name ? "active" : ""}
					onClick={() => this.setState({ activeTab: tab.Name })}>
					{tab.Title}
				</NavLink>
			</NavItem>
		);
	}

	renderDetTexto(nomControl) {
		let detalle = formularioDet.filter((detalle) => detalle.NomControl === nomControl);
		if (detalle[0]) return detalle[0].Texto;
		else return translate("ReplicaMtto.noInformacion");
	}

	getRowId(row) {
		return row.ClaTablaColumna;
	}

	render() {
		const commitChanges = ({ added, changed, deleted }) => {
			let changedRows;
			let rows = this.state.columnas;

			if (added) {
				const startingAddedId = rows.length > 0 ? rows[rows.length - 1].ClaTablaColumna + 1 : 0;
				changedRows = [
					...rows,
					...added.map((row, index) => ({
						ClaTablaColumna: startingAddedId + index,
						...row,
					})),
				];
			}

			if (changed) {
				changedRows = rows.map((row) =>
					changed[row.ClaTablaColumna] ? { ...row, ...changed[row.ClaTablaColumna] } : row,
				);
			}

			if (deleted) {
				const deletedSet = new Set(deleted);
				changedRows = rows.filter((row) => !deletedSet.has(row.ClaTablaColumna));
			}
			//setRows(changedRows);
			this.setState({ columnas: changedRows });
		};

		const NAVITEMS = [
			{
				Name: "general",
				Title: translate("ReplicaMtto.navGeneral"),
				Id: 0,
			},
			{
				Name: "origenes",
				Title: translate("ReplicaMtto.navOrigenes"),
				Id: 1,
			},
			{
				Name: "destinos",
				Title: translate("ReplicaMtto.navDestinos"),
				Id: 2,
			},
		];

		if (!this.state.isLoaded) {
			return (
				<div className="spinner-border text-info" style={{ backgroundColor: "transparent" }} role="status">
					<span className="sr-only">Cargando...</span>
				</div>
			);
		}
		return (
			<>
				<NotificationAlert ref={(ref) => (this.notify = ref)} />
				<div className="content">
					<Formik
						enableReinitialize={true}
						initialValues={{
							...this.state.tabla,
						}}
						onSubmit={this.btnGuardarClick}>
						{(formikProps) => (
							<Form onSubmit={formikProps.handleSubmit} key="frmReplicaMtto">
								<Card style={{ height: "600px" }}>
									<CardHeader>
										<h2 className="mb-1">
											{this.state.tabla.Esquema + "." + this.state.tabla.NomTablaReplica}
										</h2>
									</CardHeader>

									<CardBody>
										<Row style={{ alignItems: "start" }}>
											<Col md={2} xs={2}>
												<Nav pills className="nav-pills-info flex-column highlight">
													{NAVITEMS.map((item) => {
														return this.renderNavItem(item);
													})}
												</Nav>
											</Col>

											<Col md={10} xs={10}>
												<TabContent activeTab={this.state.activeTab} className="tab-space">
													<TabPane tabId="general" className="tbody">
														<FormGroup row>
															<Col md={3}>
																<UncontrolledTooltip placement="right" target={`txtEsquema`}>
																	<span>{this.renderDetTexto("txtEsquemaTablaReplica")}</span>
																</UncontrolledTooltip>
																<Label for="Esquema">{translate("ReplicaMtto.lblEsquema")}</Label>
																<Field
																	id="txtEsquema"
																	name="Esquema"
																	type="input"
																	className="form-control"
																	placeholder="Esquema de la tabla"
																	disabled={this.props.readOnly}
																/>
															</Col>

															<Col md={5} sm={9}>
																<UncontrolledTooltip placement="right" target={`txtNombreTablaReplica`}>
																	<span>{this.renderDetTexto("txtNombreTablaReplica")}</span>
																</UncontrolledTooltip>
																<Label for="NombreTablaReplica">
																	{translate("ReplicaMtto.lblNombre")}
																</Label>
																<Field
																	id="txtNombreTablaReplica"
																	name="NomTablaReplica"
																	type="input"
																	className="form-control"
																	placeholder="Nombre de la tabla"
																	disabled={this.props.readOnly}
																/>
															</Col>

															<Col md={4} sm={12}>
																<UncontrolledTooltip
																	placement="right"
																	target={`txtNomProductoTablaReplica`}>
																	<span>{translate("ReplicaMtto.ttProducto")}</span>
																</UncontrolledTooltip>
																<Label for="ClaProducto">{translate("ReplicaMtto.lblProducto")}</Label>
																<Field
																	id="txtNomProductoTablaReplica"
																	//tabIndex="1"
																	name="ClaProducto"
																	as="select"
																	type="input"
																	className="form-control"
																	placeholder={translate("ReplicaMtto.ttProducto")}
																	disabled={!this.state.isOwner}>
																	{productos &&
																		productos.length > 0 &&
																		productos.map((obj, index) => (
																			<option
																				className="ddlOptions"
																				key={index}
																				value={obj.ClaProducto}>
																				{sessionStorage.getItem("ClaIdioma") === "2" &&
																				obj.NomProducto === "SELECCIONE"
																					? "SELECT"
																					: obj.NomProducto}
																			</option>
																		))}
																</Field>
															</Col>
														</FormGroup>

														<FormGroup row>
															<Col md={12}>
																<UncontrolledTooltip placement="right" target={`txtDescripcion`}>
																	<span>{translate("ReplicaMtto.ttDescripcion")}</span>
																</UncontrolledTooltip>
																<Label for="Descripcion">
																	{translate("ReplicaMtto.lblDescripción")}
																</Label>
																<Field
																	as="textarea"
																	//tabIndex="2"
																	id="txtDescripcion"
																	name="Descripcion"
																	placeholder="Descripción detallada de la tabla"
																	className="form-control"
																	disabled={!this.state.isOwner}
																	rows={4}
																/>
															</Col>
														</FormGroup>

														<Row>
															<Col md={6}>
																<h5>{translate("ReplicaMtto.lblColumnas")}</h5>
															</Col>
														</Row>

														<Grid
															rows={this.state.columnas}
															columns={this.state.gvColumnas.columns}
															getRowId={this.getRowId}>
															{this.state.isOwner && (
																<EditingState
																	onCommitChanges={commitChanges}
																	columnExtensions={this.state.gvColumnas.editingStateColumnExtensions}
																/>
															)}
															<SortingState
																sorting={this.state.sortingTablaColumnas}
																onSortingChange={(value) => {
																	this.setState({ sortingTablaColumnas: value });
																}}
															/>
															<IntegratedSorting />

															<VirtualTable
																cellComponent={this.FocusableCell}
																height={250}
																tableComponent={tableComponent}
																columnExtensions={this.state.gvColumnas.tableColumnExtensions}
																messages={{
																	noData: translate("ReplicaMtto.noInformacion"),
																}}
															/>

															<TableHeaderRow showSortingControls={true} />

															{this.state.isOwner && (
																<TableInlineCellEditing
																	startEditAction={this.state.startEditAction}
																	selectTextOnEditStart={this.state.selectTextOnEditStart}
																/>
															)}
														</Grid>
													</TabPane>

													<TabPane tabId="origenes">
														<div className="scrollModalServidores">
															<Grid
																rows={this.state.tabla.Origenes}
																columns={this.state.gvOrigenes.columns}>
																<SortingState
																	sorting={this.state.sortingTablaOrigenes}
																	onSortingChange={(value) => {
																		this.setState({ sortingTablaOrigenes: value });
																	}}
																/>

																{this.state.totalCount > 14 && (
																	<PagingState
																		currentPage={this.state.currentPageOrigenes}
																		onCurrentPageChange={(value) => {
																			this.setState({ currentPageOrigenes: value });
																		}}
																		pageSize={this.state.pageSize}
																		onPageSizeChange={(value) => {
																			this.setState({ pageSize: value });
																		}}
																	/>
																)}

																<IntegratedSorting />

																{this.state.totalCount > 14 && (
																	<>
																		<IntegratedPaging />
																		<PagingPanel />
																	</>
																)}

																<Table
																	tableComponent={tableComponent}
																	headComponent={headerComponent}
																	columnExtensions={this.state.gvOrigenes.tableColumnExtensions}
																	messages={{ noData: translate("ReplicaMtto.noInformacion") }}
																/>
																<TableHeaderRow showSortingControls={true} />
															</Grid>
														</div>
													</TabPane>

													<TabPane tabId="destinos">
														<Grid
															rows={this.state.tabla.Destinos}
															columns={this.state.gvDestinos.columns}>
															<SortingState
																sorting={this.state.sortingTablaDestinos}
																onSortingChange={(value) => {
																	this.setState({ sortingTablaDestinos: value });
																}}
															/>

															{this.state.totalCountDestinos > 14 && (
																<PagingState
																	currentPage={this.state.currentPageDestinos}
																	onCurrentPageChange={(value) => {
																		this.setState({ currentPageDestinos: value });
																	}}
																	pageSize={this.state.pageSizeDestinos}
																	onPageSizeChange={(value) => {
																		this.setState({ pageSizeDestinos: value });
																	}}
																/>
															)}

															<IntegratedSorting />

															{this.state.totalCountDestinos > 14 && (
																<>
																	<IntegratedPaging />
																	<PagingPanel />
																</>
															)}

															<Table
																tableComponent={tableComponent}
																headComponent={headerComponent}
																//columnExtensions={this.state.gvDestinos.tableColumnExtensions}
																messages={{ noData: translate("ReplicaMtto.noInformacion") }}
															/>
															<TableHeaderRow showSortingControls={true} />
														</Grid>
													</TabPane>
												</TabContent>
											</Col>
										</Row>
									</CardBody>

									<CardFooter className="pt-0">
										<Row>
											<Col md={12} className="text-right">
												<UncontrolledTooltip placement="left" target={`btnGuardar`}>
													<span>Guardar</span>
												</UncontrolledTooltip>
												<Button
													id="btnGuardar"
													className={`animation-on-hover`}
													color="success"
													size="sm"
													type="submit"
													hidden={!this.state.isOwner}>
													Guardar
												</Button>
												<UncontrolledTooltip placement="right" target={`btnCancelar`}>
													<span>Cerrar</span>
												</UncontrolledTooltip>
												<Button
													id="btnCancelar"
													className="animation-on-hover"
													color="default"
													size="sm"
													onClick={() => this.btnCancelarClick(false)}>
													Cerrar
												</Button>
											</Col>
										</Row>
									</CardFooter>
								</Card>
							</Form>
						)}
					</Formik>
				</div>
			</>
		);
	}
}

ReplicaMtto.propTypes = {
	claTabla: PropTypes.number,
	claBaseDatos: PropTypes.number,
	hideModal: PropTypes.func,
	readOnly: PropTypes.bool,
};
