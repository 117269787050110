const HomeDev = {
	titulo: "What do you want to do today?",
	lblPendientesPorRealizar: "My pending services",
	ttPendientesPorRealizar: "Assigned requests that have not been finalized",
	lblVerTodos: "See all",
	ttFinalizar: "Finalize",
	ttSiguiente: "Next Status",
	colSolicitud: "Request",
	colEstatus: "Status",
	colCliente: "Client",
	colFechaPromesa: "Promise Date",
	lblNoPendientes: "You have no pending requests",
	badgeAtrasado: "Overdue",
	alertAtencion: "Attention",
	descripcionAtencion: "To move to the next status, the service must first be registered",
	alertCambioExitoso: "Status changed successfully",
	alertAlgoSalioMal: "Something went wrong",
	lblNoDescripcion: "No description found",
	operacionExitosa: "Successful operation.",

	lblErroresIdentificados: "Identified Errors ",
	lblActualizar: "Update",
	colServicio: "Service",
	colErrores: "Errors",
	phErrores: "Errors that have been identified in the execution of my services",
	noErrores: "No errors found",

	lblEstatusMisSolicitudes: "Status of my requests ",
	ttEstatusMisSolicitudes: "Status of the requests I have made",
	lblCrearSolicitud: "Create Request",
	colProveedor: "Provider",
	ttInformacion: "Information",
	lblSolicitudesPendientes: "You have no pending requests",
	lblCargando: "Loading...",

	lblMisServiciosPendientesVerif: "My services pending verification ",
	ttMisServiciosPendientesVerif: "Services that need to be verified",

	lblInformacionDeMisServicios: "Information about my services",

	lblMisServiciosMasUtilizados: "My most used services in the last 6 days ",
	lblMisServiciosMenosUtilizados: "My least used services in the last 6 days  ",
	ttMisServiciosMasUtilizados: "Most used services in the last 6 days  ",
	ttMisServiciosMenosUtilizados: "Less used services in the last 6 days  ",
	lblTotalPeticiones: "Total requests",
	lblNoInformacion: "No information found",

	lblMenorTiempo: "My services with the lowest execution time in the last 24 hours ",
	lblMayorTiempo: "My services with the longest execution time in the last 24 hours ",
	ttMenorTiempo: "Services with less execution time in the last 24 hours ",
	ttMayorTiempo: "Services with the longest execution time in the last 24 hours ",
	lblTiempoMilisegundos: "Time in milliseconds",

	lblServiciosYSolicitudes: "Services and Requests pending authorization",
	lblPendientesEnviar: "My pending requests to send ",
	ttPendientesEnviar: "Requests that need to be approved to be sent",
	colTitulo: "Title",
	colProducto: "Product",
	colUsuario: "User",
	ttInformacionSolicitud: "Request information",

	lblSolicitudesRecibidas: "Requests received ",
	ttSolicitudesRecibidas: "Requests for subscription to private services",
	colCategoria: "Category",
};

export default HomeDev;
