import React from "react";
import {
	Card,
	CardBody,
	CardFooter,
	Form,
	FormGroup,
	Row,
	Col,
	Button,
	Label,
	Navbar,
	Container,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	NavbarBrand,
} from "reactstrap";
import { Redirect, Switch } from "react-router-dom";
import { Formik, Field } from "formik";
import { Config } from "utils/Config";
import PropTypes from "prop-types";
import { callApi, logOut, showSweetAlert, writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";

export default class AutorizarSuscripcion extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			datos: {},
			exit: false,
			esValido: false,
		};

		this.handleSubmitAceptar = this.handleSubmitAceptar.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
	}

	componentDidMount() {
		let params = new URLSearchParams(this.props.location.search);

		if (this.props.params !== "") {
			let method = "POST";
			let data = {
				idSuscripcion: params.get("idSuscripcion"),
			};

			if (data.idSuscripcion) {
				let urlWebService = Config.urlApiService + "/Entidades/ConsultarDatosSuscripcion";

				callApi(urlWebService, method, data, (status, res) => {
					if (status === 200 && res.length > 0) {
						this.setState({
							isLoaded: true,
							datos: res[0],
						});

						if (this.state.datos.EsSuscrito === 1) {
							showSweetAlert("La entidad indicada ya se encuentra suscrita", "", "error", this.hideAlert);
						}
					} else {
						showSweetAlert("Los datos proporcionados no son válidos", "", "error", this.hideAlert);
					}
				});
			} else {
				showSweetAlert("Favor de proporcionar el parámetro idSuscripcion", "", "error", this.hideAlert);
			}
		} else {
			showSweetAlert("Favor de proporcionar el parámetro idSuscripcion", "", "error", this.hideAlert);
		}
	}

	/**
	 * Función que cierra sesión y redirige a la pantalla de login
	 */
	cerrarSesion() {
		logOut();
		this.props.history.push({
			pathname: "/Login",
			isLogedOut: true,
		});
	}

	/**
	 * Funcion para ocultar la alerta/mensaje que fue activado
	 */
	hideAlert() {
		this.setState({
			exit: true,
		});
	}

	handleSubmitAceptar(value) {
		//Guardar mensaje en tabla de LogSuscripcion
		let data = {
			idSuscripcion: this.state.datos.IdSuscripcion,
			claProducto: this.state.datos.ClaProductoDuenio,
			claEntidad: this.state.datos.ClaEntidad,
			claCliente: this.state.datos.ClaProductoSolicita,
			mensajeRespuesta: value.mensaje,
			esAceptado: parseInt(value.acceso),
		};

		let urlWebService = `${Config.urlApiService}/Entidades/LogSuscripcion`;
		let method = "POST";
		callApi(
			urlWebService,
			method,
			data,
			(status, response) => {
				if (status === 200) {
					data = {
						claCliente: this.state.datos.ClaProductoSolicita,
						idEntidad: this.state.datos.ClaEntidad,
						claProducto: this.state.datos.ClaProductoDuenio,
						acceso: parseInt(value.acceso),
						bajaLogica: 0,
					};

					urlWebService = `${Config.urlApiService}/Entidades/Suscripcion`;
					method = "PUT";
					callApi(
						urlWebService,
						method,
						data,
						(status, response) => {
							writeLog(
								Formularios.AutorizarSuscripcion.ClaFuncionalidad,
								Formularios.AutorizarSuscripcion.Funcionalidades.btnEnviar,
								urlWebService,
								data,
								response,
								"Operación realizada con éxito",
								0,
							);
							showSweetAlert("El mensaje fue enviado con éxito", "", "success", this.hideAlert);
						},
						Formularios.AutorizarSuscripcion.ClaFuncionalidad,
						Formularios.AutorizarSuscripcion.Funcionalidades.btnEnviar,
					);
				} else {
					showSweetAlert(response, "", "error", this.hideAlert);
				}
			},
			Formularios.AutorizarSuscripcion.ClaFuncionalidad,
			Formularios.AutorizarSuscripcion.Funcionalidades.btnEnviar,
		);

		// if (this.state.esValido) {
		// 	console.log("antes de suscribir la entidad");
		// 	//Suscribir entidad
		// 	data = {
		// 		claCliente: this.state.datos.ClaProductoSolicita,
		// 		idEntidad: this.state.datos.ClaEntidad,
		// 		claProducto: this.state.datos.ClaProductoDuenio,
		// 		acceso: parseInt(value.acceso),
		// 		bajaLogica: 0,
		// 	};

		// 	urlWebService = `${Config.urlApiService}/Entidades/Suscripcion`;
		// 	method = "PUT";
		// 	callApi(
		// 		urlWebService,
		// 		method,
		// 		data,
		// 		(status, response) => {
		// 			writeLog(
		// 				Formularios.AutorizarSuscripcion.ClaFuncionalidad,
		// 				Formularios.AutorizarSuscripcion.Funcionalidades.btnEnviar,
		// 				urlWebService,
		// 				data,
		// 				response,
		// 				"Operación realizada con éxito",
		// 				0,
		// 			);
		// 			showSweetAlert("El mensaje fue enviado con éxito", "", "success", this.hideAlert);
		// 		},
		// 		Formularios.AutorizarSuscripcion.ClaFuncionalidad,
		// 		Formularios.AutorizarSuscripcion.Funcionalidades.btnEnviar,
		// 	);

		// 	console.log("DESPUES de suscribir la entidad");
		// }
	}

	render() {
		if (!this.state.isLoaded && !this.state.exit) {
			return (
				<>
					<div>Cargando...</div>
				</>
			);
		}
		if (this.state.exit) {
			return (
				// <Router history={this.props.history}><
				<Switch>
					{/* <Route path="/" render={(props) => <SeleccionProducto {...props} />} /> */}
					<Redirect from="/AutorizarSuscripcion" to="/SeleccionProducto" />
				</Switch>
			);
		}
		return (
			<>
				<div className="content">
					<div className="row">
						<div className="col-12 pb-2">
							<Navbar>
								<Container fluid>
									<NavbarBrand>
										<span style={{ color: "white", textTransform: "capitalize" }}>Autorizar suscripción</span>
									</NavbarBrand>
									<UncontrolledDropdown>
										<DropdownToggle nav caret>
											<i className="fas fa-cog fa-2x" style={{ color: "white" }}></i>
										</DropdownToggle>
										<DropdownMenu right>
											<DropdownItem
												className="nav-item"
												onClick={() => {
													this.cerrarSesion();
												}}>
												Salir
											</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								</Container>
							</Navbar>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-9">
							<Formik
								initialValues={{
									acceso: "1",
									mensaje: "",
								}}
								onSubmit={this.handleSubmitAceptar}>
								{(props) => (
									<Form onSubmit={props.handleSubmit}>
										<Card>
											<CardBody>
												<h2>Solicitud de acceso</h2>
												<Row>
													<Col md={12}>
														<h5>
															<b>{this.state.datos.NomProductoSolicita}</b> solicita acceso al servicio{" "}
															<b>{this.state.datos.NomEntidad}</b> por el siguiente motivo:
														</h5>
														<h5>
															<i>{this.state.datos.MensajeSolicitud}</i>
														</h5>
													</Col>
												</Row>
												<Row>
													<Col md={12}>
														<Label for="accessoRb" id="my-radio-group" style={{ fontSize: "13px" }}>
															¿Desea concederle acceso?
														</Label>
														<FormGroup>
															<div role="group" name="accesoRb" aria-labelledby="my-radio-group">
																<label>
																	<Field type="radio" name="acceso" value="1" />
																	&nbsp;Sí &nbsp;&nbsp;
																</label>
																<label>
																	<Field type="radio" name="acceso" value="0" />
																	&nbsp;No
																</label>
															</div>
														</FormGroup>
														<br />
													</Col>
												</Row>
												<Row>
													<Col md={12}>
														<FormGroup>
															<Label for="mensaje" style={{ fontSize: "13px" }}>
																Mensaje para el cliente
															</Label>
															<Field
																as="textarea"
																name="mensaje"
																className="form-control"
																placeholder="Ingrese mensaje"
																rows={6}
															/>
														</FormGroup>
													</Col>
												</Row>
											</CardBody>
											<CardFooter>
												<Row>
													<Col md={12} className="text-center">
														<Button
															id="btnEnviar"
															className="animation-on-hover"
															color="success"
															size="sm"
															type="submit">
															Enviar
														</Button>
													</Col>
												</Row>
											</CardFooter>
										</Card>
									</Form>
								)}
							</Formik>
						</div>
					</div>

					{/* {
                            this.props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (<Footer fluid />)
                        } */}
				</div>
			</>
		);
	}
}

AutorizarSuscripcion.propTypes = {
	location: PropTypes.any,
	params: PropTypes.any,
	history: PropTypes.any,
};
