const Replicas = {
	titulo: "Servidores",
	lblServidorOrigen: "Servidor Origen",
	phServidorOrigen: "Nombre del servidor",
	lblBaseDeDatos: "Base de datos Origen",
	phBaseDeDatos: "Nombre de la base de datos",
	lblEsquema: "Esquema",
	phEsquema: "Esquema",
	lblNombre: "Nombre",
	phNombre: "Nombre del elemento",
	ttBuscar: "Buscar",
	ttLimpiar: "Limpiar",
	btnSolicitar: "Solicitar",
	lblTotalDeRegistros: "Total de registros: ",
	colEsquema: "Esquema",
	colNombreTabla: "Nombre Tabla",
	colNombreServidor: "Nombre Servidor",
	colDetalle: "Detalle",
	colTipo: "Tipo",
	ttInformacion: "Información",
	notificacionExito: "Operación realizada correctamente.",
};

export default Replicas;
