const DashboardProveedor = {
	titulo: "Key Indicators",
	lblCargando: "Loading...",
	lblServiciosPublicados: "Total Published Services",
	lblProveedoresTotales: "Total Providers",
	lblClientesTotales: "Total Clients",
	lblTiempoMasLento: "Slowest time presented (ms) *",
	lblErrores: "Errors *",
	lblTotalSolicitudes: "Total requests *",
	lbl30dias: "* Data from the last 30 days",
	lblServiciosConsumidos: "Total Consumed Services",

	lblFechaDesde: "From",
	lblFechaHasta: "To",
	bntCliente: "Client",
	ttLimpiar: "Clear",
	lblErroresPresentados: "Presented Errors",
	lblPeticionesConError: "Requests with Errors",
	lblNoSeEncontroInformacion: "No information found",
	lblTop5MayorTiempo: "Top 5 services with the longest execution time (ms)",
	lblTiempoDeEjecucion: "Execution time (ms)",

	lblTop5MenosUtilizados: "Top 5 least used services",
	lblPeticiones: "Requests",

	lblTop5MasUtilizados: "Top 5 most used services",

	lblDesempenoEquipo: "Team performance",
	lblTiempoPromedio: "Average time (ms)",
	lblPeticionesPorUsuario: "Requests per user",
	lblErroresTitulo: "Errors",
	lblNoHayDatos: "There is no data",
};

export default DashboardProveedor;
