/**
 * Navbar que despliega información en la parte superior de la pantalla
 */

import React from "react";
import classNames from "classnames";
import {
	Button,
	Collapse,
	Navbar,
	Nav,
	Container,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	NavbarText,
} from "reactstrap";
import Idioma from "./Idioma";
import UsuarioNavbar from "./UsuarioNavbar";
import { Formularios } from "utils/funcionalidad";
import { getNomUsuario, logOut, writeLog } from "utils/Utilerias";
import { withAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";

class AdminNavbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapseOpen: false,
			modalSearch: false,
			color: "stickyNavBar",
		};

		this.updateColor = this.updateColor.bind(this);
	}
	componentDidMount() {
		window.addEventListener("resize", this.updateColor);
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.updateColor);
	}
	/**
	 * Función que agrega color al navbar, tambien se usa para dejar fijo
	 * el navbar en la parte superior de la pantalla
	 */
	updateColor() {
		if (window.innerWidth < 993 && this.state.collapseOpen) {
			this.setState({
				color: "bg-white",
			});
		} else {
			this.setState({
				color: "stickyNavBar",
			});
		}
	}

	/**
	 * Contrae el navbar en dispositivos con resolución más pequeña
	 */
	toggleCollapse() {
		if (this.state.collapseOpen) {
			this.setState({
				color: "stickyNavBar",
			});
		} else {
			this.setState({
				color: "bg-white",
			});
		}
		this.setState({
			collapseOpen: !this.state.collapseOpen,
		});
	}

	/**
	 * Función que activa y desactiva el modal de busqueda
	 */
	toggleModalSearch() {
		this.setState({
			modalSearch: !this.state.modalSearch,
		});
	}

	/**
	 * Función que cierra sesión y redirige a la pantalla de login
	 */
	cerrarSesion() {
		const { isAuthenticated, logout } = this.props.auth0;
		if (isAuthenticated) {
			logout({
				returnTo: window.location.origin,
			});
		}

		logOut();
		this.props.history.push({
			pathname: "/Login",
			isLogedOut: true,
		});
	}

	render() {
		return (
			<>
				<Navbar
					className={classNames("navbar-absolute", {
						[this.state.color]: this.props.location.pathname.indexOf("full-screen-map") === -1,
					})}
					expand="lg">
					<Container fluid>
						<div className="navbar-wrapper">
							<div className="navbar-minimize d-inline">
								<Button
									className="minimize-sidebar btn-just-icon"
									color="link"
									id="tooltip209599"
									onClick={this.props.handleMiniClick}>
									<i className="tim-icons icon-align-center visible-on-sidebar-regular" />
									<i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
								</Button>
							</div>
							<div
								className={classNames("navbar-toggle d-inline", {
									toggled: this.props.sidebarOpened,
								})}>
								<button className="navbar-toggler" type="button" onClick={this.props.toggleSidebar}>
									<span className="navbar-toggler-bar bar1" />
									<span className="navbar-toggler-bar bar2" />
									<span className="navbar-toggler-bar bar3" />
								</button>
							</div>

							<Collapse navbar isOpen={this.state.collapseOpen}>
								<Nav className="ml-auto" navbar>
									<UsuarioNavbar {...this.props}></UsuarioNavbar>
									<li className="separator d-lg-none" />
								</Nav>
								<Idioma
									claIdioma={this.props.claIdioma}
									onChangeIdiomaCallback={this.props.onChangeIdiomaCallback}></Idioma>
							</Collapse>
						</div>
						<button
							className="navbar-toggler"
							type="button"
							data-toggle="collapse"
							data-target="#navigation"
							aria-expanded="false"
							aria-label="Toggle navigation"
							onClick={this.toggleCollapse}>
							<span className="navbar-toggler-bar navbar-kebab" />
							<span className="navbar-toggler-bar navbar-kebab" />
							<span className="navbar-toggler-bar navbar-kebab" />
						</button>

						<UncontrolledDropdown>
							<DropdownToggle id="ctnFichaProducto" nav caret>
								<NavbarText style={{ paddingRight: "6%" }}>{getNomUsuario()}</NavbarText>
								<i className="fas fa-cog fa-2x" style={{ color: "white", verticalAlign: "middle" }}></i>
							</DropdownToggle>
							<DropdownMenu right>
								<DropdownItem
									id="DDIFichaProducto"
									className="nav-item"
									onClick={() => {
										//	alert(Formularios.AdminNavBar.ClaFuncionalidad);

										writeLog(
											Formularios.AdminNavBar.ClaFuncionalidad,
											Formularios.AdminNavBar.Funcionalidades.DDIFichaProducto,
										);
										document.location.href = "/admin/Producto";
										// <Switch>
										// 	<Redirect from="/" to="/admin/Producto" />
										// </Switch>;
									}}>
									<i className="fas fa-cog"> </i>{" "}
									{sessionStorage.getItem("ClaIdioma") === "1" ? "Configuración" : "Configuration"}
								</DropdownItem>
								<DropdownItem
									id="DDISalir"
									className="nav-item"
									onClick={() => {
										//	alert(Formularios.AdminNavBar.ClaFuncionalidad);

										writeLog(
											Formularios.AdminNavBar.ClaFuncionalidad,
											Formularios.AdminNavBar.Funcionalidades.DDISalir,
										);
										this.cerrarSesion();
									}}>
									<i className="fas fa-sign-out-alt"></i>{" "}
									{sessionStorage.getItem("ClaIdioma") === "1" ? "Salir" : "Log out"}
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</Container>
				</Navbar>
			</>
		);
	}
}

export default withAuth0(AdminNavbar);

AdminNavbar.propTypes = {
	history: PropTypes.any,
	location: PropTypes.any,
	handleMiniClick: PropTypes.func,
	sidebarOpened: PropTypes.bool,
	toggleSidebar: PropTypes.func,
	claIdioma: PropTypes.number,
	onChangeIdiomaCallback: PropTypes.func,
	auth0: PropTypes.any,
};
