const UsuariosMtto = {
	titulo: "New Usuario",
	lblClaveEmpleado: "Employee Key",
	phClaveEmpleado: "E.g. 909090",
	lblNombreEmpleado: " Employee Name",
	phNombreEmpleado: "Obtained by searching for a valid Employee Key",
	lblPerfil: "Profile",
	lblNotificarCorreo: "Notify by email",
	btnGuardar: "Save",
	btnCancelar: "Cancel",
	btnCerrar: "Close",
	lblNombreExterno: "First Name",
	lblApellidoExterno: "Last Name",
	lblCorreo: "Email",
	phNombreExterno: "Eg. John",
	phApellidoExterno: "Eg. Smith",
	phCorreo: "Eg. abcd@example.com",

	ttBuscar: "Search",
	ttDesarrollador: "Developer",
	ttDescripcionDesarrollador: "Standar User",
	ttPM: "Product Manager",
	ttPMDescripcion: " System administrator, has access to all pages",
	ttCorreo: "Notify by mail in case of errors in product services",
	ttExterno: "Put corresponding information for users who are not part of Deacero",
	ttAccesoExt: "Access for External Users",

	alertClaveValido: "Please enter a valid employee password",
	alertUsuarioInvalido: "The user entered does not exist",
	alertRegistroGuardado: "Record saved successfully",
	alertCambiosRealizados: "Changes made successfully",
	noDescripcion: "No description found",
	cargando: "Loading...",
};

export default UsuariosMtto;
