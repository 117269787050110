/* eslint-disable react/function-component-definition */

import React from "react";
import translate from "utils/translate";
export default function Footer() {
	return (
		<div className="footer" style={{ marginTop: "-85px", zIndex: "100" }}>
			<ul className="nav">
				<li className="nav-item">
					<a className="nav-link" href="https://www.deacero.com">
						DEACERO
					</a>
				</li>{" "}
				<li className="nav-item">
					<a className="nav-link" href="https://www.deacero.com/acerca-de/historia">
						{translate("Login.AcercaDe")}
					</a>
				</li>{" "}
				<li className="nav-item">
					<a className="nav-link" href="https://blog.deacero.com/">
						Blog
					</a>
				</li>
			</ul>
			<div className="copyright">
				© {new Date().getFullYear()} made with <i className="tim-icons icon-heart-2" /> by{" "}
				<a href="https://www.deacero.com/acerca-de/dlabs/" rel="noopener noreferrer" target="_blank">
					DLabs
				</a>{" "}
			</div>
		</div>
	);
}
