import React from "react";
import { getProducto, callApi } from "utils/Utilerias";
import { Config } from "utils/Config";
import translate from "utils/translate";

let srcData = [];

export default class Suscritos extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			errores: [],
		};

		this.load = this.load.bind(this);
	}

	componentDidMount() {
		this.load();
	}

	load() {
		var urlService = "";
		//Obtener información
		urlService = `${Config.urlApiService}/Entidades/ListaSuscritos/${getProducto()}/${this.props.IdEntidad}`;

		callApi(urlService, "GET", null, (status, response) => {
			if (status === 200) {
				srcData = response.map((obj) => {
					return {
						Producto: obj.Producto,
						Uso: obj.Uso,
						Error: obj.Error,
					};
				});
				this.setState({
					isLoaded: true,
					errores: srcData,
				});
			}
		});
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<div className="spinner-border text-info" role="status">
					<span className="sr-only">Cargando...</span>
				</div>
			);
		} else {
			return (
				<>
					{this.state.showAlert}

					<div className="erroresIdentificados">
						<table className="table table-sm table-noBorder">
							<thead>
								<tr>
									<th className="w-50" style={{ textAlign: "rigth" }}>
										{translate("EntidadMtto.Suscritos.lblProducto")}
									</th>
									<th className="w-25" style={{ textAlign: "rigth" }}>
										{translate("EntidadMtto.Suscritos.lbUso")}
									</th>
									<th className="w-25" style={{ textAlign: "rigth" }}>
										{translate("EntidadMtto.Suscritos.lblErrores")}
									</th>
								</tr>
							</thead>
							<tbody>
								{(this.state.errores.length > 0 &&
									this.state.errores.map((obj, index) => {
										return (
											<tr key={`err_${index}`}>
												<td>{obj.Producto}</td>
												<td
													style={{
														textAlign: "rigth",
													}}>
													{obj.Uso}
												</td>
												<td
													style={{
														textAlign: "center",
													}}>
													{obj.Error}
												</td>
											</tr>
										);
									})) || (
									<tr>
										<td colSpan={2} style={{ textAlign: "center", height: "105px" }}>
											<p>
												{translate("HomeDev.noErrores")}
												<span className="btn-icon btn-link text-success">
													<i className="fas fa-check"></i>
												</span>
											</p>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</>
			);
		}
	}
}
