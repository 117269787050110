import React from "react";
import { Row, Col } from "reactstrap";

import PendientesVerificacion from "./PendientesVerificacion";
import ErroresIdentificados from "./ErroresIdentificados";
import SolicitudesRecibidas from "./SolicitudesRecibidas";
import SolicitudesPorEnviar from "./SolicitudesPorEnviar";
import EntidadesMasYMenosUsadas from "views/Home/EntidadesMasYMenosUsadas";
import PendientesPorRealizar from "./PendientesPorRealizar";
import EstatusMisSolicitudes from "./EstatusMisSolicitudes";
import { getLoginInfo } from "utils/Utilerias";
import MayorYMenorTiempo from "./MayorYMenorTiempo";
import TourVirtual from "components/Tour/TourVirtual";
import { Tours } from "../../utils/Config";
import translate from "../../utils/translate";
export default class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			esAdmin: getLoginInfo().ClaRol === 5,
		};
	}

	componentDidMount() {}
	renderPM() {
		return (
			<>
				{getLoginInfo().NomGrupo === "Pruebas" ? (
					<TourVirtual PaginaID="19" ClaTour={Tours.NuevosFeaturesPM} />
				) : (
					<TourVirtual PaginaID="19" ClaTour={Tours.HomePm} />
				)}
				<Row>
					<Col md={12}>
						<h1>{translate("HomeDev.lblServiciosYSolicitudes")}</h1>
					</Col>
				</Row>

				<Row>
					<Col>
						<PendientesVerificacion esAdmin={this.state.esAdmin} />
					</Col>
					<Col>
						<ErroresIdentificados />
					</Col>
				</Row>
				<Row>
					<Col>
						<SolicitudesPorEnviar />
					</Col>
					<Col>
						<SolicitudesRecibidas />
					</Col>
				</Row>

				<Row>
					<Col md={12}>
						<h1>{translate("HomeDev.lblInformacionDeMisServicios")}</h1>
					</Col>
				</Row>
				<div id="ctnGraficasEntidadesPM">
					<Row>
						<Col md={6}>
							<EntidadesMasYMenosUsadas ref={this.chartRef} esTop={1} esAdmin={this.state.esAdmin} />
						</Col>
						<Col md={6}>
							<EntidadesMasYMenosUsadas ref={this.chartRef} esTop={0} esAdmin={this.state.esAdmin} />
						</Col>
					</Row>
				</div>
				<div id="ctnGraficasEntidadesTiempoPM">
					<Row>
						<Col md={6}>
							<MayorYMenorTiempo ref={this.chartRef} esTop={0} esAdmin={this.state.esAdmin} />
						</Col>
						<Col md={6}>
							<MayorYMenorTiempo ref={this.chartRef} esTop={1} esAdmin={this.state.esAdmin} />
						</Col>
					</Row>
				</div>
			</>
		);
	}

	renderDev() {
		return (
			<>
				{/*<TourVirtual PaginaID="19" ClaTour={Tours.HomeDev} />*/}
				{getLoginInfo().NomGrupo === "Pruebas" ? (
					<TourVirtual PaginaID="19" ClaTour={Tours.NuevosFeaturesDev} />
				) : (
					<TourVirtual PaginaID="19" ClaTour={Tours.HomeDev} />
				)}
				{/* <div style={{ position: "relative" }}> */}
				<Row>
					<Col md={12}>
						<h1>{translate("HomeDev.titulo")}</h1>
					</Col>
				</Row>
				<Row>
					<Col>
						<PendientesPorRealizar />
					</Col>
					<Col>
						<ErroresIdentificados />
					</Col>
				</Row>
				<Row>
					<Col>
						<EstatusMisSolicitudes />
					</Col>
					<Col>
						<PendientesVerificacion esAdmin={this.state.esAdmin} />
					</Col>
				</Row>
				{/* <Row>
					<Col md={6}>
						<PendientesPorRealizar />
					</Col>
					<Col
						md={6}
						style={{
							paddingLeft: "30px",
						}}>
						<Row>
							<EstatusMisSolicitudes />
						</Row>
						<Row>
							<PendientesVerificacion esAdmin={this.state.esAdmin} />
						</Row>
					</Col>
				</Row> */}
				<Row>
					<Col md={12}>
						<h1>{translate("HomeDev.lblInformacionDeMisServicios")}</h1>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<EntidadesMasYMenosUsadas ref={this.chartRef} esTop={1} />
					</Col>
					<Col md={6}>
						<EntidadesMasYMenosUsadas ref={this.chartRef} esTop={0} />
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<MayorYMenorTiempo ref={this.chartRef} esTop={1} />
					</Col>
					<Col md={6}>
						<MayorYMenorTiempo ref={this.chartRef} esTop={0} />
					</Col>
				</Row>
			</>
		);
	}

	render() {
		return (
			<>
				<div className="content">{this.state.esAdmin ? this.renderPM() : this.renderDev()}</div>
			</>
		);
	}
}
