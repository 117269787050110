import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { setSessionData, callApi } from "utils/Utilerias";
import { Config } from "utils/Config";

const Profile = () => {
	const loadingImg = "https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg";

	const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

	const history = useHistory();
	const routeChange = () => {
		let path = "/SeleccionProducto";
		history.push(path);
	};

	const callSecureApi = async () => {
		try {
			if (isAuthenticated) {
				const token = await getAccessTokenSilently();
				let nomUsuario, metadata;
				if (Object.prototype.hasOwnProperty.call(user, "https://kraken.namespace.com/user_metadata")) {
					console.log(user["https://kraken.namespace.com/user_metadata"]);
					metadata = user["https://kraken.namespace.com/user_metadata"];
					nomUsuario = metadata.first_name + " " + metadata.last_name;
					console.log(nomUsuario);
				} else {
					nomUsuario = user.name;
				}

				setSessionData({
					NomUsuario: nomUsuario,
					Token: token,

					ClaUsuario: 0,
					ClaEmpleado: 0,
				});
				console.log(user);

				console.log(token);

				const urlWebService = `${Config.urlApiService}/UsuarioExterno`;
				let usuarioExtPos = user.sub.indexOf("|");
				const data = {
					nombre: metadata ? metadata.first_name : user.given_name,
					apellido: metadata ? metadata.last_name : user.family_name,
					email: user.email,
					claUsuarioExt: user.sub.substring(usuarioExtPos + 1),
				};

				callApi(urlWebService, "PUT", data, () => {});

				routeChange();
			}
		} catch (error) {
			console.log(error);
		}
	};

	callSecureApi();

	return (
		<div className="spinner">
			<img src={loadingImg} alt="Loading..." />
		</div>
	);
};

export default Profile;
