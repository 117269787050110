import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import swal from "sweetalert";
import { Config } from "./Config";
import { io } from "socket.io-client";

require("utils/Prototipos");
const socket = io(Config.urlSocket, { forceNew: false });

socket.on("connection", (socket) => {
	console.log(`Conexion realizada al socket ${socket.id}`);
});

/**
 * Escribe en la bitacora de uso de la base de datos.
 * @param {number} claFuncionalidad Clave de la funcionalidad (Formulario/API)
 * @param {number} claFuncionalidadDet Clave del detalle o acción de la funcionalidad
 * @param {string} url Url de servicio en caso de existir
 * @param {object} parametros Parametros que se envian a la peticion (Url)
 * @param {object} respuesta Respuesta de la llamada a la API (Errores o cuerpo de respuesta)
 * @param {string} mensaje Mensaje personalizado, texto libre
 * @param {number} esError Indica si la peticion fue con error
 */

function writeLog(
	claFuncionalidad,
	claFuncionalidadDet,
	url = undefined,
	parametros = undefined,
	respuesta = undefined,
	mensaje = "",
	esError = 0,
) {
	mensaje = mensaje ? mensaje.replace(/'/g, '"') : "";
	parametros =
		parametros !== undefined && parametros !== null && parametros !== {}
			? JSON.stringify(parametros).replace(/'/g, '"')
			: "";

	respuesta =
		respuesta !== undefined && respuesta !== null && respuesta !== {}
			? JSON.stringify(respuesta).replace(/'/g, '"')
			: "";

	let objData = {
		claFuncionalidad: claFuncionalidad,
		claFuncionalidadDet: claFuncionalidadDet,
		url: url,
		parametros: parametros,
		respuesta: respuesta,
		mensaje: mensaje,
		esError: esError,
		loginInfo: getLoginInfo(),
		jwtToken: getToken(),
	};

	socket.emit("writeLog", objData);
}

/**
 * Realiza una peticion a una URL especificada.
 *
 * @param {String} url Dirección donde se realizara la peticioón
 * @param {String} method Tipo de peticion a ejecutar (POST, GET, PUT, DELETE)
 * @param {JSON} [data={}] Objeto que se adjuntará al body de la petición
 * @returns
 */
async function callApi(
	url,
	method,
	data = {},
	callBack,
	ClaFuncionalidad = undefined,
	ClaFuncionalidadDet = undefined,
) {
	method = method.toUpperCase();

	const headers = {
		"Content-Type": "application/json",
		"x-api-key": getApiKey(),
		"x-access-token": getToken(),
	};

	let statusReponse = 0;

	fetch(url, {
		method,
		headers,
		dataType: "json",
		body: method === "GET" ? null : JSON.stringify(data),
	})
		.then(async function (response) {
			let res = await response.json();

			statusReponse = response.status;

			if (response.status === 200) {
				callBack(response.status, res);
			} else {
				let errX = new Error();
				errX.message = res.message ?? res.mensaje;
				errX.name = response.status;
				errX.description = res.message;
				errX.status = response.status;

				throw errX;
			}
		})
		.catch((err) => {
			console.log(`Url: ${url}`, err.message, method === "GET" ? null : "JSON:" + JSON.stringify(data));
			//if (ClaFuncionalidad && ClaFuncionalidadDet) {
			//Se reemplazan todas las comillas simples, de lo contrario el cmdSql pierde estructura y no se guarda el error
			ClaFuncionalidad = ClaFuncionalidad ? ClaFuncionalidad : 0;
			ClaFuncionalidadDet = ClaFuncionalidadDet ? ClaFuncionalidadDet : 0;

			writeLog(ClaFuncionalidad, ClaFuncionalidadDet, url, JSON.stringify(data), null, err.message, 1);
			//}
			swal("Error", err.message, "error", {
				buttons: {
					confirm: {
						text: "Aceptar",
						className: "animation-on-hover btn btn-success",
					},
				},
			});
		})
		.finally(() => {
			if (statusReponse === 401) {
				document.location.href = "/Login";
			}
		});
}

function showSweetAlert(title, message, icon, onConfirm) {
	swal(title, message, icon, {
		buttons: {
			confirm: {
				text: "Aceptar",
				className: "animation-on-hover btn btn-success",
			},
		},
	}).then(onConfirm);
}

function isEmpty(obj) {
	for (var key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
	}
	return true;
}

function showAlert(onConfirm, title, text, icon) {
	return (
		<SweetAlert
			success={icon === "success"}
			error={icon === "error"}
			warning={icon === "warning"}
			style={{ display: "block", marginTop: "-50px" }}
			title={title}
			onConfirm={onConfirm}
			confirmBtnBsStyle="success highlight"
			confirmBtnText="Aceptar">
			{text}
		</SweetAlert>
	);
}

function showConfirm(onConfirm, onCancel, title, text) {
	return (
		<SweetAlert
			customIcon="fa fa-warning"
			reverseButtons
			warning
			showCancel
			cancelBtnBsStyle="danger"
			style={{ display: "block", marginTop: "-50px" }}
			title={title}
			text={text}
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmBtnBsStyle="success -highlight"
			confirmBtnText="Aceptar"
			cancelBtnText="Cancelar">
			{text}
		</SweetAlert>
	);
}

function showAlertInput(onConfirm, onCancel, title, placeholder, text) {
	return (
		<SweetAlert
			input
			showCancel
			reverseButtons
			cancelBtnBsStyle="danger"
			confirmBtnBsStyle="success -highlight"
			title={title}
			placeHolder={placeholder}
			onConfirm={(response) => onConfirm(response)}
			onCancel={onCancel}>
			{text}
		</SweetAlert>
	);
}

/**
 * Guarda en el sessionStorage el claCliente y nomCliente
 *
 * @param {Number} ClaCliente
 * @param {String} NomCliente
 */
function setCliente(claCliente, nomCliente) {
	sessionStorage.setItem("ClaCliente", claCliente);
	sessionStorage.setItem("NomCliente", nomCliente);
}

/**
 * Guarda en el sessionStorage el claProducto y nomProducto
 *
 * @param {Number} claProducto Clave del producto a almacenar
 * @param {String} nomProducto Nombre del producto a almacenar
 */
function setProducto(claProducto, nomProducto, apiKey, verComo, nomGrupo) {
	sessionStorage.setItem("ClaProducto", claProducto);
	sessionStorage.setItem("NomProducto", nomProducto);
	sessionStorage.setItem("VerComo", verComo);
	sessionStorage.setItem("ApiKey", apiKey);
	sessionStorage.setItem("NomGrupo", nomGrupo);
}

/**
 * Regresa del sessionStorage el producto
 *
 * @returns {object} Objeto {ClaProducto, NomProducto}
 */
function getLoginInfo() {
	let apiKey = sessionStorage.getItem("ApiKey");

	let sessionInfo = {
		NomUsuario: sessionStorage.getItem("NomUsuario"),
		ClaUsuario: sessionStorage.getItem("ClaUsuario"),
		ClaRol: parseInt(sessionStorage.getItem("ClaRol")),
		ClaProducto: sessionStorage.getItem("ClaProducto"),
		NomProducto: sessionStorage.getItem("NomProducto"),
		ClaCliente: sessionStorage.getItem("ClaCliente"),
		NomCliente: sessionStorage.getItem("NomCliente"),
		ApiKey: apiKey === null || apiKey.length === 0 ? Config.ApiKey : apiKey,
		ClaEmpleado: sessionStorage.getItem("ClaEmpleado"),
		NomGrupo: sessionStorage.getItem("NomGrupo"),
	};

	return sessionInfo;
}

/**
 * Regresa del sessionStorage el producto
 *
 * @returns {object} Objeto {ClaProducto, NomProducto}
 */
function getProducto() {
	return sessionStorage.getItem("ClaProducto");
}

/**
 * Regresa del sessionStorage el producto
 *
 * @returns {object} Objeto {ClaProducto, NomProducto}
 */
function getNomProducto() {
	return sessionStorage.getItem("NomProducto");
}

/**
 * Regresa del sessionStorage el ClaCliente
 *
 * @returns {Number} ClaCliente
 */
function getCliente() {
	return sessionStorage.getItem("ClaCliente");
}

/**
 * Regresa del sessionStorage el NomCliente
 *
 * @returns {string} NomCliente
 */
function getNomCliente() {
	return sessionStorage.getItem("NomCliente");
}

function getApiKey() {
	let apiKey = "";

	apiKey = sessionStorage.getItem("ApiKey");

	return apiKey === null || apiKey.length === 0 ? Config.ApiKey : sessionStorage.getItem("ApiKey");
}

/**
 * Guarda en el sessionStorage cualquier valor que se mande en un objeto
 *
 * @param {object} params objeto donde cada propiedad se va a guardar
 */
function setSessionData(params) {
	for (const key in params) {
		if (Object.prototype.hasOwnProperty.call(params, key)) {
			const element = params[key];

			if (typeof element === "object") {
				sessionStorage.setItem(key, JSON.stringify(element));
			} else {
				sessionStorage.setItem(key, element);
			}
		}
	}
}

/**
 * Regresa del sessionStorage cualquier valor que se pase como primer parametro,
 * en caso de no encontrarlo, regresa el valor por default del segundo parametro
 *
 * @param {string} key
 * @param {any} def
 */
function getSessionItem(key, def) {
	let value;

	try {
		value = JSON.parse(sessionStorage.getItem(key));
	} catch (error) {
		value = sessionStorage.getItem(key);
	}

	if (!value) {
		return def;
	}
	return value;
}

/**
 * Hace el decode del JWT token
 *
 * @param {string} token
 * @returns {object} payload del token
 */
function decodeToken(token) {
	const base64Url = token.split(".")[1];
	const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
			.join(""),
	);

	return JSON.parse(jsonPayload);
}

/**
 * Valida la si existe un token guardado en sessionStorage y en caso de existir
 * se valida su expiracion
 *
 * @returns {boolean} retorna si el token es valido o no
 */
function sessionAlive() {
	const jwtToken = sessionStorage.getItem("Token");

	if (jwtToken) {
		const { exp } = decodeToken(jwtToken);

		if (Date.now() >= exp * 1000) {
			return false;
		}
		return true;
	}

	return false;
}

/**
 * Regresa del sessionStorage las routes
 *
 * @returns {object}
 */
function getRoutes() {
	return sessionStorage.getItem("Routes");
}

/**
 * Regresa del sessionStorage el JWT token
 *
 * @returns {string}
 */
function getToken() {
	return sessionStorage.getItem("Token");
}

/**
 * Regresa del sessionStorage el nombre del usuario
 *
 * @returns {string}
 */
function getNomUsuario() {
	return sessionStorage.getItem("NomUsuario");
}

/**
 * Remueve del sessionStorage el JWT token y el nombre del usuario
 *
 */
function logOut() {
	sessionStorage.clear();
}

/**
 * Regresa el número de colores que indiques aleatoriamente
 *
 * @param {number} n número de colores que quiere obtenerse
 * @returns
 */
function getThemeColors(n = 13) {
	let themeColors = [
		"#1d8cf8",
		"#5e72e4",
		"#5603ad",
		"#8965e0",
		"#ba54f5",
		"#e14eca",
		"#f3a4b5",
		"#f5365c",
		"#fb6340",
		"#ffd600",
		"#2dce89",
		"#2bffc6",
		"#11cdef",
	];
	let opacityCode = ["BF", "80", "40", "0D"]; // ["CC", "99", "66", "33"];
	let colors = [],
		dif,
		k = 1;

	if (n <= 13) {
		k = Math.trunc(13 / n);
		for (let i = 0; i < 13; i += k) {
			colors.push(themeColors[i]);
		}
	} else {
		themeColors.forEach((color) => colors.push(color));
		dif = n - 13;
		k = k + Math.trunc(dif / 13);
		for (let i = 0; i < Math.trunc(dif / k); i++) {
			for (let j = 0; j < k; j++) {
				colors.push(themeColors[i] + opacityCode[j]);
			}
		}
	}

	return colors;
}

export {
	callApi,
	showAlert,
	showConfirm,
	setSessionData,
	getNomUsuario,
	getCliente,
	getRoutes,
	getApiKey,
	setCliente,
	setProducto,
	getProducto,
	getNomProducto,
	getNomCliente,
	logOut,
	sessionAlive,
	getSessionItem,
	decodeToken,
	getLoginInfo,
	showAlertInput,
	isEmpty,
	showSweetAlert,
	getThemeColors,
	writeLog,
};
