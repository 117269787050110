const BitacoraUso = {
	titulo: "Usage log",
	lblFolio: "Folio",
	phFolio: "Folio number",
	lblClaveServicio: "Service key",
	phClaveServicio: "Service number",
	lblFechaInicial: "Initial date",
	phFechaInicial: "Query start date",
	lblFechaFinal: "Final date",
	phFechaFinal: "Query end date",
	ttBuscar: "Search",
	ttLimpiar: "Clear",
	lblTotalDeRegistros: "Total records: ",
	colFolio: "Folio",
	colNoServicio: "Service No.",
	colServicio: "Service",
	colFecha: "Date",
	colCliente: "Client",
	rowRespuesta: "Response",
};

export default BitacoraUso;
