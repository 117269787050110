import React from "react";
import { Card, CardBody, CardHeader, Form, FormGroup, Col, Button, Label, CardFooter } from "reactstrap";
import PropTypes from "prop-types";
import { Formik, Field } from "formik";
//import { CallWebService } from "utils/Utilerias";

export default class RecuperaContraseña extends React.Component {
	/**
	 * Guarda la información capturada en el formulario
	 * @param {JSON} value Valores dentro del formulario
	 */
	handleSubmitAceptar(value) {
		if (!value.Usuario.isNullOrEmpty()) {
			// const urlSecurity = Config.UrlSecurity + "/RecoverPassword";
			// const data = {
			// 	UserName: value.Usuario,
			// };
			// CallWebService(urlSecurity, data)
			// 	.then((oResponse) => {
			// 		this.props.hideModal();
			// 	})
			// 	.catch((oError) => {
			// 		alert(oError);
			// 	});
		} else {
			alert("Favor de capturar el usuario.");
		}
	}

	render() {
		return (
			<Formik
				initialValues={{
					Usuario: "",
				}}
				onSubmit={this.handleSubmitAceptar}>
				{(props) => (
					<Form onSubmit={props.handleSubmit}>
						<Card>
							<CardHeader>Recuperacion de contraseña</CardHeader>
							<CardBody>
								<FormGroup row>
									<Col>
										<Label for="Esquema">Usuario</Label>
										<Field
											name="Usuario"
											type="input"
											className="form-control"
											placeholder="Ingrese un usuario"
										/>
									</Col>
								</FormGroup>
							</CardBody>
							<CardFooter>
								<FormGroup row>
									<Col>
										<Button
											className="animation-on-hover"
											color="default"
											size="sm"
											onClick={() => this.props.hideModal()}>
											Cancelar
										</Button>
									</Col>
									<Col className="text-right">
										<Button type="submit" className="animation-on-hover" color="info" size="sm">
											Enviar
										</Button>
									</Col>
								</FormGroup>
							</CardFooter>
						</Card>
					</Form>
				)}
			</Formik>
		);
	}
}

RecuperaContraseña.propTypes = {
	hideModal: PropTypes.func,
};
