const SolicitudMtto = {
	titulo: "Solicitud de Servicio",
	lblTitulo: "Titulo",
	lblProducto: "Producto",
	lblDescripcion: "Descripción",
	lblEntradasEsperadas: "Entradas esperadas",
	lblResultadoEsperado: "Resultado esperado",

	phTitulo: "Título de la solicitud",
	phDescripcion: "Descripción detallada de lo que se espera en el servicio",
	phEntradasEsperadas: "Descripción/sugerencia de lo que se espera mandar al servicio",
	phResultadoEsperado: "Descripción/sugerencia de lo que espera recibir el servicio",

	ttTitulo: "Título de la solicitud",
	ttProducto: "Nombre del producto al cuál se hará la solicitud",
	ttDescripcion: "Descripción de qué es el servicio",
	ttEntradasEsperadas: "Descripción detallada de la entrada",
	ttResultadoEsperado: "Descripción detallada del resultado esperado",

	colNombre: "Nombre",
	colDescripcion: "Descripción",
	colTipo: "Tipo",

	ttEntradaNombre: "Nombre de la entrada",
	ttEntradaDescripcion: "Descripción de qué es la entrada",
	ttEntradaTipo: "Tipo de dato de la entrada",

	ttSalidaNombre: "Nombre de la salida",
	ttSalidaDescripcion: "Descripción de qué es la salida",
	ttSalidaTipo: "Tipo de dato de la salida",

	btnGuardar: "Guardar",
	btnCancelar: "Cancelar",
	btnCerrar: "Cerrar",

	alertEntradasSalidas: "Debe seleccionar un tipo de dato en todas las entradas y salidas",
	alertProductoSolicitud: "Debe seleccionar el producto al que se le realizará la solicitud",
	notificacionOperacionExitosa: "Operación realizada con éxito.",
	alertRegistroGuardado: "Registro guardado con éxito",
	alertCambioGuardado: "Cambios realizados con éxito",
	alertEstatusCambiado: "Estatus cambiado con éxito",
	noInfo: "No se encontró información",

	navGeneral: "General",
	navEntradas: "Entradas",
	navSalidas: "Salidas",

	cargando: "Cargando...",

	ttEnviarSolicitud: "Enviar solicitud",
	alertEnviarSolicitud: "¿Desea enviar la solicitud?",
	btnEnviar: "Enviar",

	ttRechazarSolicitud: "Rechazar solicitud",
	alertRechazarSolicitud: "¿Desea rechazar la solicitud?",
	btnRechazar: "Rechazar",
	phProducto: "Producto al que pertenece",
	lblAgregarParametro: "Agregar un parámetro",
};

export default SolicitudMtto;
