const MisServiciosSuscritos = {
	titulo: "Servicios Disponibles",
	lblProducto: "Producto",
	lblServicio: "Servicio",
	phServicio: "Nombre, descripción o número de servicio",
	lblGlosario: "Glosario",
	phGlosario: "Términos relacionados",
	ttBuscar: "Buscar",
	ttLimpiar: "Limpiar",
	btnCrearSolicitud: " Crear solicitud",
	lblTotalDeRegistros: "Total de registros: ",
	colProducto: "Producto",
	colServicio: "Servicio",
	colTipo: "Tipo",
	ttVerificado: "Servicio verificado",
	ttNoVerificado: "El servicio no está verificado",
	ttSuscribirse: "Suscribirse",
	ttComentarios: "Comentarios",
	ttEliminar: "Eliminar suscripción",
	ttInformacion: "Información",
	ttPublico: "Servicio público",
	ttPrivado: "Servicio privado",
};

export default MisServiciosSuscritos;
