import React from "react";
import ReactTable from "react-table";
import BasesDatos from "./BasesDatos";
import ServidoresMtto from "./ServidoresMtto";
import { callApi, showAlert, showConfirm } from "utils/Utilerias";
import { Config } from "utils/Config";
import NotificationAlert from "react-notification-alert";
import { Formik, Field } from "formik";
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Col,
	Modal,
	UncontrolledTooltip,
	Label,
	FormGroup,
	Form,
} from "reactstrap";

let srcData = [];
let tableWidth;
// eslint-disable-next-line no-unused-vars
let basesDatos = [];
export default class Servidores extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			alert: null,
		};

		this.showConfirm = this.showConfirm.bind(this);
		this.hideModal = this.hideModal.bind(this);
		this.llenarTabla = this.llenarTabla.bind(this);
		this.desactivarServidor = this.desactivarServidor.bind(this);
		this.consultarBases = this.consultarBases.bind(this);
		this.clear = this.clear.bind(this);
		this.btnBuscarClick = this.btnBuscarClick.bind(this);
	}

	componentDidMount() {
		this.llenarTabla();
		this.consultarBases();
	}

	/**
	 * Función 	que oculta el modal
	 * correcta y refresca la pantalla
	 */
	hideModal(isOk) {
		this.llenarTabla();
		this.setState({
			showModal: false,
		});

		if (isOk) {
			var options = {
				place: "br",
				message: "Operación realizada correctamente.",
				type: "success",
				autoDismiss: 3,
			};

			this.notify.notificationAlert(options);
			this.cargaInicial();
		}
	}

	/**
	 * Función para llenar tabla después de limpiar campos de búsqueda.
	 */
	clear() {
		this.llenarTabla();
	}

	/**
	 * Funcion para ocultar la alerta/mensaje que fue activado
	 */
	hideAlert() {
		this.setState({
			showAlert: null,
			showConfirm: null,
		});
	}

	/**
	 * Función que se muestra un mensaje de éxito en la operación
	 * @param {String} title titulo que tendrá el mensaje mostrado
	 * @param {String} text contenido del mensaje
	 * @param {String} icon icono que mostrará el mensaje
	 */
	successAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Función que desactiva a un servidor
	 * @param {Object} objServidor Servidor que se va a desactivar
	 */
	desactivarServidor(objServidor) {
		let urlWebService = Config.urlApiService + "/Servidores/" + objServidor.ClaServidor;
		let data = {};

		callApi(urlWebService, "GET", data, (status, response) => {
			let newData = {
				claServidor: response[0].ClaServidor,
				claTipoSeguridad: response[0].ClaTipoSeguridad,
				contrasena: response[0].Contrasena,
				descripcion: response[0].Descripcion,
				encriptado: response[0].Encriptado,
				nomServidor: response[0].NomServidor,
				usuario: response[0].Usuario,
				bajaLogica: 1,
			};

			callApi(urlWebService, "PUT", newData, () => {
				this.setState({
					isLoaded: true,
				});

				this.hideAlert();
				this.successAlert("El servidor se eliminó correctamente", undefined, "success");

				this.llenarTabla();
			});
		});
	}

	btnBuscarClick(value) {
		let urlWebService = Config.urlApiService + "/Servidores/Buscar/";
		let data = {
			nomServidor: value.nomServidor,
		};

		callApi(urlWebService, "POST", data, (status, response) => {
			if (response.length <= 0) {
				srcData = [];
			} else {
				srcData = response.map((obj, key) => {
					return {
						claServidor: obj.ClaServidor,
						nomServidor: obj.NomServidor,
						descripcion: obj.Descripcion,
						Actions: (
							<div className="actions-right">
								<UncontrolledTooltip target={`btnEditar_${key}`}> Editar</UncontrolledTooltip>
								<Button
									onClick={() => {
										this.setState({
											showModal: true,
											claServidor: obj.ClaServidor,
										});
									}}
									color="info"
									size="sm"
									className="btn-icon btn-link"
									id={`btnEditar_${key}`}>
									<i className="fa fa-edit" />
								</Button>

								<UncontrolledTooltip target={`btnEliminar_${key}`}> Eliminar</UncontrolledTooltip>
								<Button
									onClick={() => {
										this.showConfirm(
											() => this.desactivarServidor(obj),
											() => this.hideAlert(),
											`¿Desea desactivar el Origen de datos?`,
											obj.nomServidor,
										);

										this.setState({
											claServidor: obj.claServidor,
										});
									}}
									color="danger"
									size="sm"
									className="btn-icon btn-link"
									id={`btnEliminar_${key}`}>
									<i className="fa fa-eraser" />
								</Button>
							</div>
						),
					};
				});

				this.setState({
					isLoaded: true,
					data: srcData,
				});
			}

			this.setState({
				isLoaded: true,
				data: srcData,
			});
		});
	}

	/**
	 * Función 	para llenar la tabla incial
	 */
	llenarTabla() {
		let urlWebService = Config.urlApiService + "/Servidores";
		let data = {};

		callApi(urlWebService, "GET", data, (status, response) => {
			if (response.length <= 0) {
				srcData = [];
			} else {
				srcData = response.map((obj, key) => {
					return {
						claServidor: obj.ClaServidor,
						nomServidor: obj.NomServidor,
						descripcion: obj.Descripcion,
						Actions: (
							<div className="actions-right">
								<UncontrolledTooltip target={`btnEditar_${key}`}> Editar</UncontrolledTooltip>
								<Button
									onClick={() => {
										this.setState({
											showModal: true,
											claServidor: obj.ClaServidor,
										});
									}}
									color="info"
									size="sm"
									className="btn-icon btn-link"
									id={`btnEditar_${key}`}>
									<i className="fa fa-edit" />
								</Button>

								<UncontrolledTooltip target={`btnEliminar_${key}`}> Eliminar</UncontrolledTooltip>
								<Button
									onClick={() => {
										this.showConfirm(
											() => this.desactivarServidor(obj),
											() => this.hideAlert(),
											`¿Desea desactivar el Origen de datos?`,
											obj.nomServidor,
										);

										this.setState({
											claServidor: obj.claServidor,
										});
									}}
									color="danger"
									size="sm"
									className="btn-icon btn-link"
									id={`btnEliminar_${key}`}>
									<i className="fa fa-eraser" />
								</Button>
							</div>
						),
					};
				});

				this.setState({
					isLoaded: true,
					data: srcData,
				});
			}
		});
	}

	/**
	 * Función 	que muestra un mensaje en el centro de la pantalla con poder de
	 * elegir una opción
	 * @param {Function} onConfirm Función que se ejecuta al presionar el boton de confirmar
	 * @param {Function} OnCancel Función que se ejecuta al presionar el botón de cancelar
	 * @param {String} title titulo del mensaje
	 * @param {String} text cuerpo del mensaje
	 */
	showConfirm(onConfirm, onCancel, title, text) {
		const warningAlert = showConfirm(onConfirm, onCancel, title, text);
		this.setState({
			showConfirm: warningAlert,
		});
	}

	/**
	 * Función 	para consultar las bases de datos
	 */
	consultarBases() {
		let urlWebService = Config.urlApiService + "/BasesDatos";
		let data = {};

		callApi(urlWebService, "GET", data, (status, response) => {
			if (response.length <= 0) {
				basesDatos = [];
			} else {
				basesDatos = response;
			}
		}).catch((err) => {
			alert(err);
		});
	}

	render() {
		return (
			<>
				<NotificationAlert ref={(ref) => (this.notify = ref)} style={{ "z-index": "1" }} />
				{this.state.showAlert}
				{this.state.showConfirm}

				<div className="content">
					<Formik
						initialValues={{
							nomServidor: "",
						}}
						onSubmit={this.btnBuscarClick}
						onReset={this.clear}>
						{(props) => (
							<Form onSubmit={props.handleSubmit} ref={(el) => (this.myFormRef = el)} key="frmUsuarioBuscar">
								<Card>
									<CardHeader>
										<CardTitle>
											<FormGroup row>
												<Col md={8}>Catálogo Orígenes de Datos</Col>

												<Col md={4} className="text-right">
													<UncontrolledTooltip target={`btnBuscar`}>Buscar</UncontrolledTooltip>
													<Button
														id="btnBuscar"
														color="info"
														size="sm"
														className="btn-simple btn-icon"
														type="submit">
														<i className="fas fa-search" />
													</Button>
													<UncontrolledTooltip target={`btnNuevo`}>Agregar</UncontrolledTooltip>
													<Button
														id={`btnNuevo`}
														onClick={() => {
															this.setState({
																showModal: true,
																claServidor: 0,
															});
														}}
														color="info"
														size="sm"
														className="btn-simple">
														<i className="fa fa-plus" />
														{` Agregar`}
													</Button>
													<UncontrolledTooltip target={`btnLimpiar`}>Limpiar</UncontrolledTooltip>
													<Button
														onClick={() => props.resetForm({ values: "" })}
														id="btnLimpiar"
														color="info"
														size="sm"
														className="btn-simple btn-icon"
														type="reset">
														<i className="fas fa-eraser" />
													</Button>
												</Col>
											</FormGroup>
										</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup row>
											<Col md={6}>
												<Label>Nombre: </Label>
												<Field
													name="nomServidor"
													type="input"
													className="form-control"
													placeholder="Nombre del Servidor"></Field>
											</Col>
											<Col md={4}></Col>
										</FormGroup>
										<div
											ref={(el) => {
												if (!el) return;
												tableWidth = el.getBoundingClientRect().width;
											}}>
											<ReactTable
												data={srcData}
												filterable={false}
												previousText="Anterior"
												nextText="Siguiente"
												loadingText="Cargando..."
												noDataText="No se encontró información"
												pageText="Página"
												ofText="de"
												rowsText="filas"
												id="table"
												SubComponent={(row) => {
													return (
														<div
															style={{
																border: "thin",
																borderColor: "white",
																borderStyle: "solid",
																padding: "2%",
															}}>
															<BasesDatos claServidor={row.original.claServidor}></BasesDatos>
														</div>
													);
												}}
												columns={[
													{
														Header: "Servidor",
														accessor: "nomServidor",
														width: Math.round(tableWidth * 0.2),
													},
													{
														Header: "Descripción",
														accessor: "descripcion",
														width: Math.round(tableWidth * 0.65),
													},
													{
														Header: " ",
														accessor: "Actions",
														sortable: false,
														filterable: false,
														width: Math.round(tableWidth * 0.05),
													},
												]}
												defaultPageSize={10}
												showPaginationTop={false}
												showPaginationBottom={true}
												className="-highlight secondary-pagination"
											/>
										</div>
									</CardBody>
								</Card>
							</Form>
						)}
					</Formik>
				</div>

				<Modal centered={true} isOpen={this.state.showModal} size="md">
					<ServidoresMtto hideModal={this.hideModal} claServidor={this.state.claServidor} />
				</Modal>
			</>
		);
	}
}
