/** @format */

import React from "react";
import {
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	CardTitle,
	Form,
	FormGroup,
	Row,
	Col,
	Button,
	Label,
	UncontrolledTooltip,
	Input,
} from "reactstrap";
import { Formik, Field } from "formik";
import { Config } from "utils/Config";
import { callApi, showAlert } from "utils/Utilerias";
import PropTypes from "prop-types";

export default class ServidoresMtto extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			guardar: false,
			ddBases: [],
			ddlTipoSeguridad: [],
			objServidor: {
				ClaServidor: 0,
				NomServidor: "",
				Descripcion: "",
				Usuario: "",
				ClaTipoSeguridad: 1,
				Contrasena: "",
				Encriptado: 0,
			},
			alert: null,
		};

		if (this.props.claServidor === 0) {
			this.setState({
				isLoaded: true,
				objServidor: {
					ClaServidor: 0,
					NomServidor: "",
					Descripcion: "",
					Usuario: "",
					ClaTipoSeguridad: 1,
					Contrasena: "",
					Encriptado: 0,
				},
			});
		}

		this.cargarTipoSeguridad = this.cargarTipoSeguridad.bind(this);
		this.cargarBasesDatos = this.cargarBasesDatos.bind(this);
		this.btnCancelar_Click = this.btnCancelar_Click.bind(this);
		this.handleSubmitAceptar = this.handleSubmitAceptar.bind(this);
	}

	componentDidMount() {
		// this.cargarTipoSeguridad();

		let data = {};
		let urlWebService = Config.urlApiService + "/Servidores/" + this.props.claServidor;

		callApi(urlWebService, "GET", data, (status, response) => {
			this.setState({
				objServidor: response[0],
				isLoaded: true,
			});
		});
	}

	/**
	 * Funcion para ocultar la alerta/mensaje que fue activado
	 */
	hideAlert() {
		this.setState({
			showAlert: null,
			showConfirm: null,
		});
	}

	/**
	 * Función que se muestra un mensaje de éxito en la operación
	 * @param {String} title titulo que tendrá el mensaje mostrado
	 * @param {String} text contenido del mensaje
	 * @param {String} icon icono que mostrará el mensaje
	 */
	successAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Función que valida que los datos se hayan ingresado correctamente
	 * @param {JSON} value Valores ingresados en el formulario
	 * @param {boolean} bases Determina si se debe validar si contiene bases de datos
	 * o no, esto para validar el boton de Verficar conexion
	 */
	validar(value, bases) {
		let huboErrores = false;
		let msg = "";
		if (bases) {
			if (value.baseDatos === null || value.baseDatos === undefined) {
				huboErrores = true;
				msg += "Debe especificar una base de datos ";
			}
		}

		if (value.nomServidor.isNullOrEmpty()) {
			huboErrores = true;
			msg += "Debe especificar un servidor ";
		}

		if (value.usuario.isNullOrEmpty()) {
			huboErrores = true;
			msg += "Debe ingresar una usuario ";
		}

		if (value.contrasena.isNullOrEmpty()) {
			huboErrores = true;
			msg += "Debe ingresar una contraseña ";
		}

		if (value.descripcion.isNullOrEmpty()) {
			huboErrores = true;
			msg += "Debe ingresar una descripción ";
		}

		if (value.claTipoSeguridad <= 0) {
			huboErrores = true;
			msg += "Debe especificar un tipo de seguridad. ";
		}

		if (huboErrores) {
			throw Error(msg);
		}
	}

	/**
	 * Función que envia los datos a la base de datos
	 * @param {JSON} value Valores ingresados en el formulario
	 */
	handleSubmitAceptar(value) {
		//try {
		if (value.baseDatos === "SELECCIONE") {
			value.baseDatos = null;
		}

		this.validar(value, true);

		let data = {
			claBaseDatos: 0,
			nomBaseDatos: value.baseDatos,
			descripcion: value.descripcion,
			claServidor: this.state.objServidor.ClaServidor,
			claTipoSeguridad: 1,
			bajaLogica: 0,
			contrasena: value.contrasena,
			usuario: value.usuario,
			nomServidor: value.nomServidor,
			encriptado: this.state.objServidor.Encriptado,
		};

		let urlWebServiceServ = Config.urlApiService + "/Servidores/" + value.claServidor;

		callApi(urlWebServiceServ, "PUT", data, (status, response) => {
			if (response.esValida) {
				//Primero guarda los cambios en el servidor, en caso de hacerlo
				//correctamente, guarda la base de datos
				let urlWebService = Config.urlApiService + "/BasesDatos/0/0";

				callApi(urlWebService, "POST", data, (status, response) => {
					if (response.status === 500) {
						throw Error(response.message);
					} else {
						console.log("El servidor y la base de datos fueron registradas correctamente");
						this.btnCancelar_Click(true);
					}
				});
				// .then((response) => {

				// })
				// .catch((oErr) => {
				//     let texto = oErr.toString();
				//     this.successAlert(texto, undefined, "error");
				// });
			} else {
				let texto = response.mensaje.toString();
				this.successAlert(texto, undefined, "error");
			}
		});

		//         .catch((oErr) => {
		//             alert(oErr);
		//         });
		// } catch (err) {
		//     let texto = err.toString();
		//     this.successAlert(texto, undefined, "warning");
		// }
	}

	/**
	 * Función que oculta el modal
	 */
	btnCancelar_Click(isOk) {
		this.props.hideModal(isOk);
	}

	btnVerificarConexion(values) {
		try {
			this.validar(values, false);
			let method;
			let data = {
				claServidor: this.state.objServidor.ClaServidor,
				nomServidor: values.nomServidor,
				descripcion: values.descripcion,
				bajaLogica: 0,
				usuario: values.usuario,
				contrasena: values.contrasena,
				claTipoSeguridad: 1,
				encriptado: this.state.objServidor.Encriptado,
			};

			if (this.state.objServidor.ClaServidor === 0) {
				method = "POST";
			} else {
				method = "PUT";
			}
			let urlWebService = Config.urlApiService + "/Servidores/" + this.state.objServidor.ClaServidor;

			callApi(urlWebService, method, data, (status, response) => {
				if (response.esValida) {
					//Guarda el nuevo id del servidor para poder consultar en la tabla de base de datos
					let servidorAux = this.state.objServidor;

					servidorAux.ClaServidor = response.claServidor;

					this.setState({
						objServidor: servidorAux,
					});
				} else {
					this.setState({
						ddBases: [],
					});

					throw Error(response.mensaje);
				}

				this.setState({
					guardar: response.esValida,
				});

				this.cargarBasesDatos(response.esValida);
			});
		} catch (err) {
			let texto = err.toString();

			if (texto.includes("Error:")) {
				texto = texto.substr(6);
			}
			this.successAlert(texto, undefined, "warning");
		}
	}

	/**
	 * Función que carga el Drop Down con las bases de datos
	 * @param {boolean} conexion
	 */
	cargarBasesDatos(conexion) {
		if (conexion) {
			let data = {};
			let urlWebService = Config.urlApiService + "/BasesDatos/" + this.state.objServidor.ClaServidor + "/0/1";

			callApi(urlWebService, "GET", data, (status, response) => {
				let objAux = this.state.objServidor;
				objAux.bases = response;
				this.setState({
					ddBases: response.bases,
				});
			});
		}
	}

	/**
	 * Carga los tipos de seguridad validos para la pantalla
	 */
	cargarTipoSeguridad() {
		// getApiStoredProcedureData(184, {}, 5, (response) => {
		// 	this.setState({
		// 		isLoaded: true,
		// 		ddlTipoSeguridad: response,
		// 	});
		// });
	}

	render() {
		if (!this.state.isLoaded) {
			return <div>Cargando...</div>;
		}

		return (
			<>
				{this.state.showAlert}
				<Formik
					initialValues={{
						claServidor: this.state.objServidor.ClaServidor,
						nomServidor: this.state.objServidor.NomServidor,
						descripcion: this.state.objServidor.Descripcion,
						usuario: this.state.objServidor.Usuario,
						claTipoSeguridad: this.state.objServidor.ClaTipoSeguridad,
						contrasena: this.state.objServidor.Contrasena,
						bases: this.state.objServidor.bases,
					}}
					onSubmit={this.handleSubmitAceptar}>
					{(props) => (
						<Form onSubmit={props.handleSubmit} key="frmServidoresMtto">
							<Card>
								<CardHeader>
									<Row>
										<CardTitle>
											<Col>Mantenimiento Origen de datos</Col>
										</CardTitle>
									</Row>
								</CardHeader>
								<CardBody>
									<FormGroup>
										<Row>
											<Col md={12}>
												<Label>Nombre</Label>
												<Field
													name="nomServidor"
													type="input"
													className="form-control"
													placeholder="Nombre del servidor"
													required></Field>
											</Col>
										</Row>
										<Row>
											<Col md={6}>
												<Label>Usuario</Label>
												<Field
													name="usuario"
													type="input"
													className="form-control"
													placeholder="Nombre de usuario"
													required></Field>
											</Col>
											<Col md={6}>
												<Label>Contraseña</Label>
												<Field
													name="contrasena"
													type="password"
													className="form-control"
													placeholder="Contrasena"
													required></Field>
											</Col>
										</Row>
										<Row>
											<Col md={6}>
												<Label>Tipo de seguridad</Label>
												<Field name="claTipoSeguridad" as="select" type="input" className="form-control">
													<option className="ddlOptions" key={1} value={1}>
														SQL
													</option>
													))
												</Field>
											</Col>

											<Col md={6}>
												<FormGroup
													check
													style={{
														marginTop: "25px",
													}}>
													<Label check>
														<Input
															type="checkbox"
															checked={this.state.objServidor.Encriptado === 1 ? true : false}
															onChange={(event) => {
																let objAux = this.state.objServidor;

																if (event.target.checked) {
																	objAux.Encriptado = 1;
																} else {
																	objAux.Encriptado = 0;
																}

																this.setState({
																	objServidor: objAux,
																});
															}}
														/>

														{"Conexión segura"}
														<span className="form-check-sign">
															<span className="check" />
														</span>
													</Label>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col md={12}>
												<Label
													for="Descripcion"
													style={{
														marginTop: "10px",
													}}>
													Descripción
												</Label>
												<Field
													as="textarea"
													name="descripcion"
													placeholder="Descripción detallada"
													className="form-control textarea"
													required
													style={{
														marginBottom: "10px",
													}}
												/>
											</Col>
										</Row>
										<Row>
											<Col md={9}>
												<Label>Base de datos</Label>
												<Field name="baseDatos" as="select" type="input" className="form-control">
													{this.state.ddBases &&
														this.state.ddBases.length > 0 &&
														this.state.ddBases.map((obj, index) => (
															<option className="ddlOptions" key={index} value={obj.name}>
																{obj.name}
															</option>
														))}
												</Field>
											</Col>
											<Col md={3}>
												<UncontrolledTooltip target={`btnConsultar`} placement="right">
													Consultar las bases de datos del servidor origen
												</UncontrolledTooltip>
												<Button
													size="sm"
													className="btn-simple"
													color="success"
													style={{
														marginTop: "28%",
													}}
													onClick={() => {
														this.btnVerificarConexion(props.values);
													}}
													id="btnConsultar">
													Consultar
												</Button>
											</Col>
										</Row>
									</FormGroup>
								</CardBody>
								<CardFooter>
									<Row>
										<Col md={12} className="text-right">
											<Button
												id="btnGuardar"
												className="animation-on-hover"
												color="success"
												size="sm"
												type="submit"
												disabled={!this.state.guardar}>
												Guardar
											</Button>

											<Button
												id="btnCancelar"
												className="animation-on-hover"
												color="default"
												size="sm"
												onClick={() => this.btnCancelar_Click(false)}>
												Cancelar
											</Button>
										</Col>
									</Row>
								</CardFooter>
							</Card>
						</Form>
					)}
				</Formik>
			</>
		);
	}
}

ServidoresMtto.propTypes = {
	claServidor: PropTypes.any,
	hideModal: PropTypes.func,
};
