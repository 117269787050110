module.exports = {
	Funcionalidades: [
		{
			Nombre: "Login",
			ClaFuncionalidad: 1,
			Detalles: [
				{
					Nombre: "btnLogin",
					ClaFundionalidadDet: 1,
				},
				{
					Nombre: "btnRecuperarContrasena",
					ClaFuncionalidadDet: 2,
				},
			],
		},
	],
};

module.exports = {
	Formularios: {
		Login: {
			ClaFuncionalidad: 1,
			Funcionalidades: {
				btnLogin: 1,
				btnRecuperarContrasena: 2,
			},
		},
		SeleccionProducto: {
			ClaFuncionalidad: 2,
			Funcionalidades: {
				btnSeleccionProducto: 1,
				btnBuscar: 2,
				btnLimpiar: 3,
			},
		},
		UsuarioNavBar: {
			ClaFuncionalidad: 3,
			Funcionalidades: {
				ctnSeleccionProducto: 1,
			},
		},
		AdminNavBar: {
			ClaFuncionalidad: 4,
			Funcionalidades: {
				DDIFichaProducto: 1,
				DDISalir: 2,
			},
		},
		PendientesPorRealizar: {
			ClaFuncionalidad: 5,
			Funcionalidades: {
				btnVerTodos: 1,
				btnComenzarPeticion: 2,
			},
		},
		ErroresIdentificados: {
			ClaFuncionalidad: 6,
			Funcionalidades: {
				btnRefrescar: 1,
			},
		},
		EstatusMisSolicitudes: {
			ClaFuncionalidad: 7,
			Funcionalidades: {
				btnNuevo: 1,
				btnVerDetalle: 2,
			},
		},
		PendientesVerificacion: {
			ClaFuncionalidad: 8,
			Funcionalidades: {
				btnComenzarPeticion: 1,
				btnVerTodos: 2,
			},
		},
		ServiciosPublicados: {
			ClaFuncionalidad: 9,
			Funcionalidades: {
				btnEditar: 1,
				btnComentarios: 2,
				btnEliminar: 3,
				btnBuscar: 4,
				btnLimpiar: 5,
				btnNuevo: 6,
				btnClonar: 7,
				btnSuscritos: 8,
			},
		},
		ServiciosSuscritos: {
			ClaFuncionalidad: 10,
			Funcionalidades: {
				btnInformacion: 1,
				btnComentarios: 2,
				btnEliminar: 3,
				btnLimpiar: 4,
				btnBuscar: 5,
			},
		},
		ServiciosDisponibles: {
			ClaFuncionalidad: 11,
			Funcionalidades: {
				btnSuscribirse: 1,
				btnInformacion: 2,
				btnComentarios: 3,
				btnLimpiar: 4,
				btnNuevo: 5,
				btnBuscar: 6,
			},
		},
		EntidadMtto: {
			ClaFuncionalidad: 12,
			Funcionalidades: {
				btnGuardar: 1,
				btnCancelar: 2,
				btnPublicar: 3,
				btnVerificar: 4,
			},
		},
		SolicitudesDeServicio: {
			ClaFuncionalidad: 13,
			Funcionalidades: {
				btnLimpiar: 1,
				btnNuevo: 2,
				btnAutorizar: 3,
				btnAsignar: 4,
				btnVerificar: 5,
				btnNoAutorizar: 6,
				btnInformacion: 7,
				btnAprobarSol: 8,
				btnSiguiente: 9,
				modificarEstatus: 10,
				cargaRealizadas: 11,
				cargaRecibidas: 12,
				cargaAsignadas: 13,
			},
		},
		AsignarUsuario: {
			ClaFuncionalidad: 14,
			Funcionalidades: {
				btnGuardar: 1,
				btnCancelar: 2,
			},
		},

		SolicitudServicioMtto: {
			ClaFuncionalidad: 15,
			Funcionalidades: {
				btnGuardar: 1,
				btnCancelar: 2,
				btnEnviar: 3,
				btnRechazar: 4,
			},
		},

		PendientesEnviar: {
			ClaFuncionalidad: 16,
			Funcionalidades: {
				btnVerDetalle: 1,
				btnEnviarPeticion: 2,
				btnVerTodos: 3,
			},
		},
		SolicitudesRecibidas: {
			ClaFuncionalidad: 17,
			Funcionalidades: {
				btnVerDetalle: 1,
				btnVerTodos: 2,
			},
		},
		DashboardProveedor: {
			ClaFuncionalidad: 18,
			Funcionalidades: {
				btnFiltrar: 1,
				btnLimpiar: 2,
				ErroresPresentadosCarga: 3,
				ErroresPresentadosClick: 4,
				MayorTiempoCarga: 5,
				MayorTiempoClick: 6,
				MenosUtilizados: 7,
				MenosUtilizadosClick: 8,
				MasUtilizados: 9,
				MasUtilizadosClick: 10,
				DesempenoEquipo: 11,
				PeticionesPorUsuario: 12,
				PeticionesPorUsuarioClick: 13,
			},
		},

		DashboardCliente: {
			ClaFuncionalidad: 19,
			Funcionalidades: {
				btnLimpiar: 1,
				PeticionesPorProovedor: 2,
				MayorTiempoCarga: 3,
				MayorTiempoClick: 4,
				DesempenoEquipo: 5,
				MasUtilizados: 6,
				MasUtilizadosClick: 7,
				PeticionesPorProovedorClick: 8,
			},
		},
		AdministracionUsuarios: {
			ClaFuncionalidad: 20,
			Funcionalidades: {
				btnBuscar: 1,
				btnLimpiar: 2,
				btnNuevo: 3,
				btnEditar: 4,
				btnEliminar: 5,
			},
		},

		UsuariosMtto: {
			ClaFuncionalidad: 21,
			Funcionalidades: {
				btnGuardar: 1,
				btnCancelar: 2,
				btnBuscar: 3,
			},
		},

		Replicas: {
			ClaFuncionalidad: 22,
			Funcionalidades: {
				btnBuscar: 1,
				btnLimpiar: 2,
				btnNuevo: 3,
				btnInformacion: 4,
				lstBasesDeDatos: 5,
			},
		},

		NuevaPeticionTabla: {
			ClaFuncionalidad: 23,
			Funcionalidades: {
				btnGuardar: 1,
				btnCancelar: 2,
			},
		},

		GlosarioTerminos: {
			ClaFuncionalidad: 24,
			Funcionalidades: {
				btnBuscar: 1,
				btnLimpiar: 2,
				btnNuevo: 3,
				btnEditar: 4,
				btnEliminar: 5,
			},
		},

		GlosarioMtto: {
			ClaFuncionalidad: 50,
			Funcionalidades: {
				btnGuardar: 1,
				btnCancelar: 2,
			},
		},

		ReplicaMtto: {
			ClaFuncionalidad: 51,
			Funcionalidades: {
				btnGuardar: 1,
				btnCancelar: 2,
			},
		},

		SuscripcionMtto: {
			ClaFuncionalidad: 52,
			Funcionalidades: {
				btnGuardar: 1,
				btnCancelar: 2,
			},
		},

		AutorizarPeticionTabla: {
			ClaFuncionalidad: 53,
			Funcionalidades: {
				btnEnviar: 1,
			},
		},

		FichaProducto: {
			ClaFuncionalidad: 54,
			Funcionalidades: {
				btnGuardar: 1,
			},
		},

		AutorizarSuscripcion: {
			ClaFuncionalidad: 55,
			Funcionalidades: {
				btnEnviar: 1,
			},
		},

		ComentariosMtto: {
			ClaFuncionalidad: 56,
			Funcionalidades: {
				btnAgregar: 1,
				btnCancelar: 2,
			},
		},

		ReplicaComentario: {
			ClaFuncionalidad: 57,
			Funcionalidades: {
				verReplicas: 1,
			},
		},
	},
};
