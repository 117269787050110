const TablaReplicaMtto = {
	titulo: "Nueva petición de tabla ",
	lblTitulo: "Título",
	lblProducto: "Producto",
	lblTabla: "Tabla",
	lblOrigenes: "Origenes",
	lblBaseDeDatos: "Base de datos",
	lblServidor: "Servidor",
	lblDestinos: "Destinos",
	lblMotivo: "Motivo de petición",

	phTitulo: "Título de la petición",
	ttTitulo: "Título de la petición",
	phTabla: "Nombre de la tabla",
	ttTabla: "Tabla que se quiere replicar",
	phBaseDeDatosOrigen: "Nombre de la base de datos origen",
	ttBaseDeDatosOrigen: "Base de datos origen",
	phServidorOrigen: "Nombre del servidor origen",
	ttServidorOrigen: "Servidor origen",

	phBaseDeDatosDestino: "Nombre de la base de datos destino",
	ttBaseDeDatosDestino: "Base de datos destino",
	phServidorDestino: "Nombre del servidor destino",
	ttServidorDestino: "Servidor destino",

	btnGuardar: "Guardar",
	btnCerrar: "Cerrar",
	noDescripcion: "No se encontró descripción",
	ttProducto: "Producto al que se le solicita la réplica",
	phMotivo: "Motivo detallado por el cuál se hace la petición",
};

export default TablaReplicaMtto;
