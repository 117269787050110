import React from "react";
// react component for creating dynamic tables
import { Config } from "utils/Config";
import SyntaxHighlighter from "react-syntax-highlighter";
import { lioshi } from "react-syntax-highlighter/dist/esm/styles/hljs";

import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Row,
	Col,
	Label,
	UncontrolledTooltip,
	Form,
	FormGroup,
} from "reactstrap";

import { callApi } from "utils/Utilerias";
import { Formik, Field } from "formik";
import { getProducto } from "utils/Utilerias";
import PropTypes from "prop-types";
import { Grid, PagingPanel, Table, TableHeaderRow, TableRowDetail } from "@devexpress/dx-react-grid-bootstrap4";
import {
	IntegratedPaging,
	IntegratedSorting,
	PagingState,
	SortingState,
	RowDetailState,
} from "@devexpress/dx-react-grid";

import { tableComponent, headerComponent, gvConfig } from "utils/gridConfig";

import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import translate from "utils/translate";
require("utils/Prototipos");

const RowDetail = ({ row }) => (
	<div
		style={{
			padding: "1%",
			marginLeft: "20px",
		}}>
		<Row>
			<Col md="12">
				<Label>Body</Label>
			</Col>
		</Row>
		<Row>
			<Col md="12">
				<SyntaxHighlighter
					className="round"
					language="json"
					style={lioshi}
					showLineNumbers={true}
					wrapLongLines={true}>
					{row.Body}
				</SyntaxHighlighter>
			</Col>
		</Row>
		<Row>
			<Col md="12">
				<Label>{translate("BitacoraUso.rowRespuesta")}</Label>
			</Col>
		</Row>
		<Row>
			<Col md="12">
				<SyntaxHighlighter className="round" language="json" style={lioshi} showLineNumbers={true}>
					{row.Mensaje}
				</SyntaxHighlighter>
			</Col>
		</Row>
		{/* <Col md="10">{row.Descripcion}</Col>
			<Col md="2">
				<div className="float-right text-right">
					<ReactStars
						count={5}
						size={20}
						edit={false}
						activeColor="#ffd700"
						isHalf={true}
						value={row.Calificacion}
					/>
				</div>
			</Col> */}
	</div>
);

export default class BitacoraUso extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			idEntidad: 0,
			isLoaded: false,
			claProducto: -1,
			pageSize: 20,
			data: [],
			gvBitacora: {
				columns: [
					{ name: "IdUso", title: "Folio" },
					{ name: "IdEntidad", title: "#Servicio" },
					{ name: "NomEntidad", title: "Servicio" },
					{ name: "FechaEjecucion", title: "Fecha" },
					{ name: "NomCliente", title: "Cliente" },
				],
				tableColumnExtensions: [
					{ columnName: "IdUso", width: "80px", align: "center" },
					{ columnName: "IdEntidad", width: "100px", align: "center" },
					{ columnName: "NomEntidad", width: "auto", align: "left" },
					{ columnName: "NomCliente", width: "20%", align: "center" },
					{ columnName: "FechaEjecucion", width: "15%", align: "center", wordWrapEnabled: true },
					// { columnName: "Actions", width: "10%", align: "right" },
				],
			},
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		};

		this.frmLoad = this.frmLoad.bind(this);
		this.btnBuscarClick = this.btnBuscarClick.bind(this);
		this.dgvFillTable = this.dgvFillTable.bind(this);
		this.clear = this.clear.bind(this);
	}

	componentDidMount() {
		this.frmLoad();
	}

	/**
	 * Función que oculta el modal de los comentarios.
	 */
	hideModalComentarios() {
		this.setState({
			showModalComentarios: false,
		});
	}

	clear() {
		//this.frmLoad();
		this.setState({
			data: [],
		});
	}

	frmLoad() {
		this.btnBuscarClick({ IdEntidad: 0 });
		this.setState({
			gvBitacora: {
				columns: [
					{ name: "IdUso", title: translate("BitacoraUso.colFolio") },
					{ name: "IdEntidad", title: translate("BitacoraUso.colNoServicio") },
					{ name: "NomEntidad", title: translate("BitacoraUso.colServicio") },
					{ name: "FechaEjecucion", title: translate("BitacoraUso.colFecha") },
					{ name: "NomCliente", title: translate("BitacoraUso.colCliente") },
				],
				// tableColumnExtensions: [
				// 	{ columnName: "IdUso", width: "10%", align: "left" },
				// 	{ columnName: "IdEntidad", width: "10%", align: "center" },
				// 	{ columnName: "NomEntidad", width: "auto", align: "left" },
				// 	{ columnName: "NomCliente", width: "20%", align: "center" },
				// 	{ columnName: "FechaEjecucion", width: "15%", align: "center", wordWrapEnabled: true },
				// 	// { columnName: "Actions", width: "10%", align: "right" },
				// ],
				tableColumnExtensions: [
					{ columnName: "IdUso", width: "80px", align: "center" },
					{ columnName: "IdEntidad", width: "100px", align: "center" },
					{ columnName: "NomEntidad", width: "auto", align: "left" },
					{ columnName: "NomCliente", width: "20%", align: "center" },
					{ columnName: "FechaEjecucion", width: "15%", align: "center", wordWrapEnabled: true },
					// { columnName: "Actions", width: "10%", align: "right" },
				],
			},
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		});
	}

	dgvFillTable(source) {
		let dgvSource;

		dgvSource = source.map((obj, key) => {
			return {
				...obj,
				key: key,
				id: obj.IdEntidad,
			};
		});

		//console.log(dgvSource);
		return dgvSource;
	}

	btnBuscarClick(value) {
		let srcData = [];

		const urlService = `${Config.urlApiService}/BitacoraUso?claProducto=${getProducto()}
		&idEntidad=${value.IdEntidad ? value.IdEntidad : 0}
		&idUso=${value.IdUso ? value.IdUso : 0}&esError=1`;

		callApi(urlService, "GET", null, (status, response) => {
			if (response.length > 0) {
				srcData = this.dgvFillTable(response);
			}

			this.setState({
				data: srcData,
			});
		});
	}

	render() {
		return (
			<>
				{sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.frmLoad()}
				<div className="content">
					<Formik
						id="ctnBitacoraUso"
						onSubmit={this.btnBuscarClick}
						onReset={this.clear}
						initialValues={{
							Folio: "",
							IdEntidad: "",
							FechaInicial: Date.now(),
							FechaFinal: Date.now(),
						}}>
						{(props) => (
							<Form onSubmit={props.handleSubmit} ref={(el) => (this.myFormRef = el)} key="frmUsuarioBuscar">
								<Card>
									<CardHeader>
										<CardTitle>
											<FormGroup row>
												<Col md={8}>
													<h2>{translate("BitacoraUso.titulo")}</h2>
												</Col>
												<Col md={4} className="text-right">
													<UncontrolledTooltip target={`btnBuscar`}>
														{translate("BitacoraUso.ttBuscar")}
													</UncontrolledTooltip>
													<Button
														id="btnBuscar"
														color="info"
														size="sm"
														className="btn-simple btn-icon"
														type="submit">
														<i className="fas fa-search" />
													</Button>

													<UncontrolledTooltip target={`btnLimpiar`}>
														{translate("BitacoraUso.ttLimpiar")}
													</UncontrolledTooltip>
													<Button
														onClick={() => props.resetForm({ values: "" })}
														id="btnLimpiar"
														color="info"
														size="sm"
														className="btn-simple btn-icon"
														type="reset">
														<i className="fas fa-eraser" />
													</Button>
												</Col>
											</FormGroup>
										</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup row>
											<Col md={2}>
												<Label>{translate("BitacoraUso.lblFolio")}</Label>
												<Field
													name="IdUso"
													type="input"
													className="form-control"
													placeholder={translate("BitacoraUso.phFolio")}></Field>
											</Col>
											<Col md={2}>
												<Label>{translate("BitacoraUso.lblClaveServicio")}</Label>
												<Field
													name="IdEntidad"
													type="input"
													className="form-control"
													placeholder={translate("BitacoraUso.phClaveServicio")}></Field>
											</Col>
											<Col md={2}>
												<Label>{translate("BitacoraUso.lblFechaInicial")}</Label>
												<Field
													name="Fecha Inicial"
													type="input"
													className="form-control"
													placeholder={translate("BitacoraUso.phFechaInicial")}></Field>
											</Col>
											<Col md={2}>
												<Label>{translate("BitacoraUso.lblFechaFinal")}</Label>
												<Field
													name="Fecha Final"
													type="input"
													className="form-control"
													placeholder={translate("BitacoraUso.phFechaFinal")}></Field>
											</Col>
										</FormGroup>
										<Row>
											<Col md={12} className="text-right">
												<label>
													{translate("BitacoraUso.lblTotalDeRegistros")} {this.state.data.length}
												</label>
											</Col>
										</Row>
										<Grid rows={this.state.data} columns={this.state.gvBitacora.columns}>
											<SortingState
												sorting={this.state.sorting}
												onSortingChange={(value) => {
													this.setState({ sorting: value });
												}}
											/>

											<RowDetailState />

											<PagingState
												currentPage={this.state.currentPage}
												onCurrentPageChange={(value) => {
													this.setState({ currentPage: value });
												}}
												pageSize={this.state.pageSize}
												onPageSizeChange={(value) => {
													this.setState({ pageSize: value });
												}}
											/>

											<IntegratedSorting />
											<IntegratedPaging />

											<Table
												tableComponent={tableComponent}
												columnExtensions={this.state.gvBitacora.tableColumnExtensions}
												headComponent={headerComponent}
												messages={{ noData: gvConfig.noData }}
											/>

											<TableHeaderRow showSortingControls={true} />
											<TableRowDetail contentComponent={RowDetail} />
											<PagingPanel pageSizes={gvConfig.pageSizes} />
										</Grid>
									</CardBody>
								</Card>
							</Form>
						)}
					</Formik>
				</div>
			</>
		);
	}
}

BitacoraUso.propTypes = {
	history: PropTypes.any,
};

RowDetail.propTypes = {
	row: {
		Descripcion: PropTypes.string,
		NomTipoEntidad: PropTypes.string,
	},
};
