import React from "react";
import {
	TabContent,
	TabPane,
	Card,
	CardBody,
	CardHeader,
	Form,
	FormGroup,
	Row,
	Col,
	Button,
	Label,
	CardFooter,
	UncontrolledTooltip,
} from "reactstrap";
import { Formik, Field } from "formik";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import NotificationAlert from "react-notification-alert";
import PropTypes from "prop-types";
import { Config } from "utils/Config";
import { callApi, getProducto, writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";

let productos = [],
	formularioDet = [];

export default class TablaReplicaMtto extends React.Component {
	constructor(props) {
		super(props);

		this.selTabla = null;
		this.nomTabla = null;
		//origenes
		this.selBaseDatos = null;
		this.selServidor = null;
		this.nomBaseDatos = null;
		this.nomServidor = null;
		//destinos
		this.selBaseDatosDestino = null;
		this.selServidorDestino = null;
		this.nomBaseDatosDestino = null;
		this.nomServidorDestino = null;

		this.state = {
			activeTab: "general",
			isLoaded: false,
			basesDatos: [],
			servidores: [],
			servidoresDestino: [],
			tablas: [],
		};

		this.btnGuardarClick = this.btnGuardarClick.bind(this);
		this.btnCancelarClick = this.btnCancelarClick.bind(this);
	}

	componentDidMount() {
		this.load();
	}

	load() {
		let urlService = ``;

		//Obtener productos, no incluir producto cliente ni kraken
		urlService = `${Config.urlApiService}/Catalogos/11`;
		callApi(urlService, "GET", null, (status, response) => {
			productos = response;
			let claveProducto = parseInt(getProducto());
			productos = productos.filter(
				(producto) => producto.ClaProducto !== claveProducto && producto.ClaProducto !== 7,
			);
		});

		//Obtener bases de datos replica, no incluir kraken
		urlService = `${Config.urlApiService}/Catalogos/16`;
		callApi(urlService, "GET", null, (status, response) => {
			let responseDB = response;
			responseDB = responseDB.filter((baseDatos) => baseDatos.NomBaseDatos !== "KRAKEN");
			this.setState({ basesDatos: responseDB });
		});

		//Obtener servidores replica
		urlService = `${Config.urlApiService}/Catalogos/17`;
		callApi(urlService, "GET", null, (status, response) => {
			this.setState({ servidores: response, servidoresDestino: response });
		});

		//Obtener tablas replica
		urlService = `${Config.urlApiService}/Catalogos/18`;
		callApi(urlService, "GET", null, (status, response) => {
			this.setState({ tablas: response });
		});

		//Obtener mensajes de ayuda para tooltips
		urlService = `${Config.urlApiService}/CatFormularioDet/13`;
		callApi(urlService, "GET", null, (status, response) => {
			formularioDet = response;
		});

		this.setState({ isLoaded: true });
	}

	handleChangeBaseDatos(baseDatos) {
		//Cambiar la selección actual de Base de Datos
		this.selBaseDatos = baseDatos;

		//Obtener servidores estén relacionados con la base de datos (si es que existen)
		let claBaseDatos = baseDatos ? baseDatos.ClaBaseDatos : 0;
		let urlWebService = `${Config.urlApiService}/Replicas?ClaBaseDatos=${claBaseDatos}&EsOrigen=1`;

		callApi(urlWebService, "GET", null, (status, response) => {
			//if (status === 200 && response.length > 0) {
			this.setState({ servidores: response });
			//}
		});
	}

	handleChangeBaseDatosDestino(baseDatos) {
		//Cambiar la selección actual de Base de Datos
		this.selBaseDatosDestino = baseDatos;

		//Obtener servidores estén relacionados con la base de datos (si es que existen)
		let claBaseDatos = baseDatos ? baseDatos.ClaBaseDatos : 0;
		let urlWebService = `${Config.urlApiService}/Replicas?ClaBaseDatos=${claBaseDatos}&EsOrigen=0`;

		callApi(urlWebService, "GET", null, (status, response) => {
			//if (status === 200 && response.length > 0) {
			this.setState({ servidoresDestino: response });
			//}
		});
	}

	btnGuardarClick(value) {
		let urlService = `${Config.urlApiService}/Replicas/SolicitudTabla`;
		const method = this.props.idPeticion ? "PUT" : "POST";

		let data = {
			IdPeticion: this.props.idPeticion,
			ClaTabla: this.selTabla ? this.selTabla.ClaTabla : 0,
			ClaBaseDatos: this.selBaseDatos ? this.selBaseDatos.ClaBaseDatos : 0,
			ClaServidor: this.selServidor ? this.selServidor.ClaServidor : 0,
			ClaProducto: value.txtClaProducto ? value.txtClaProducto : 0,
			ClaCliente: getProducto(),
			ClaEstatus: 1,
			EsAceptado: 0,
			Titulo: value.Titulo,
			NomBaseDatos: this.selBaseDatos ? "" : this.nomBaseDatos,
			NomServidor: this.selServidor ? "" : this.nomServidor,
			NomBaseDatosDestino: this.selBaseDatosDestino
				? this.selBaseDatosDestino.NomBaseDatos
				: this.nomBaseDatosDestino,
			NomServidorDestino: this.selServidorDestino ? this.selServidorDestino.NomServidor : this.nomServidorDestino,
			NomTabla: this.selTabla ? "" : this.nomTabla,
			MensajeSolicitud: value.MensajeSolicitud,
		};

		callApi(urlService, method, data, (status, response) => {
			if (status === 200) {
				writeLog(
					Formularios.NuevaPeticionTabla.ClaFuncionalidad,
					Formularios.NuevaPeticionTabla.Funcionalidades.btnGuardar,
					urlService,
					data,
					response,
					undefined,
					0,
				);

				this.btnCancelarClick(true);
			}
		});
	}

	btnCancelarClick(isOk) {
		this.props.hideModal(isOk);
	}

	renderDetTexto(nomControl) {
		let detalle = formularioDet.filter((detalle) => detalle.NomControl === nomControl);
		if (detalle[0]) return detalle[0].Texto;
		else return translate("TablaReplicaMtto.noDescripcion");
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<div className="row justify-content-center  p-5">
					<div className="spinner-border text-info" style={{ backgroundColor: "transparent" }} role="status">
						<span className="sr-only">Cargando...</span>
					</div>
				</div>
			);
		}
		return (
			<>
				<NotificationAlert ref={(ref) => (this.notify = ref)} />
				<div className="content">
					<Formik
						enableReinitialize={true}
						initialValues={{
							...this.state.tabla,
						}}
						onSubmit={this.btnGuardarClick}>
						{(formikProps) => (
							<Form onSubmit={formikProps.handleSubmit} key="frmReplicaMtto">
								<Card style={{ height: "600px" }}>
									<CardHeader>
										<h2 className="mb-1">{translate("TablaReplicaMtto.titulo")}</h2>
									</CardHeader>

									<CardBody>
										<Row style={{ alignItems: "start" }}>
											<Col md={12} xs={12}>
												<TabContent activeTab={this.state.activeTab} className="tab-space">
													<TabPane tabId="general" className="tbody">
														<Row>
															<Col md={6} sm={12}>
																<UncontrolledTooltip placement="right" target={`txtTituloPeticion`}>
																	<span>{translate("TablaReplicaMtto.ttTitulo")}</span>
																</UncontrolledTooltip>
																<Label for="Titulo">{translate("TablaReplicaMtto.lblTitulo")}</Label>
																<Field
																	id="txtTituloPeticion"
																	name="Titulo"
																	type="input"
																	className="form-control"
																	placeholder={translate("TablaReplicaMtto.phTitulo")}
																/>
															</Col>

															<Col md={6} sm={12}>
																<UncontrolledTooltip placement="right" target={`txtClaProducto`}>
																	<span>{translate("TablaReplicaMtto.ttProducto")}</span>
																</UncontrolledTooltip>
																<Label for="txtClaProducto">
																	{translate("TablaReplicaMtto.lblProducto")}
																</Label>
																<Field
																	id="txtClaProducto"
																	name="txtClaProducto"
																	as="select"
																	type="input"
																	className="form-control"
																	placeholder="Producto al que pertenece">
																	{productos &&
																		productos.length > 0 &&
																		productos.map((obj, index) => (
																			<option
																				className="ddlOptions"
																				key={index}
																				value={obj.ClaProducto}>
																				{sessionStorage.getItem("ClaIdioma") === "2" &&
																				obj.NomProducto === "SELECCIONE"
																					? "SELECT"
																					: obj.NomProducto}
																			</option>
																		))}
																</Field>
															</Col>
														</Row>

														<Row className="mt-1 mb-1">
															<Col md={6} sm={12}>
																<UncontrolledTooltip placement="right" target={`ClaTabla`}>
																	<span>{translate("TablaReplicaMtto.ttTabla")}</span>
																</UncontrolledTooltip>
																<Label for="ClaTabla">{translate("TablaReplicaMtto.lblTabla")}</Label>
																<Autocomplete
																	freeSolo
																	id="ClaTabla"
																	size="small"
																	onChange={(_, value) => (this.selTabla = value)}
																	options={this.state.tablas}
																	getOptionLabel={(option) => option.NomTablaReplica}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			name="ClaTabla"
																			placeholder={translate("TablaReplicaMtto.phTabla")}
																			variant="outlined"
																			onChange={(event) => {
																				this.nomTabla = event.target.value;
																				this.selTabla = null;
																			}}
																		/>
																	)}
																/>
															</Col>
														</Row>

														<Row>
															<Col md={6}>
																<h5 className="mt-2 mb-1">
																	{translate("TablaReplicaMtto.lblOrigenes")}
																</h5>
															</Col>
														</Row>
														<Row className="mt-1 mb-1">
															<Col md={6} sm={12}>
																<UncontrolledTooltip placement="right" target={`ClaBaseDatos`}>
																	<span>{translate("TablaReplicaMtto.ttBaseDeDatosOrigen")}</span>
																</UncontrolledTooltip>
																<Label for="ClaBaseDatos">
																	{translate("TablaReplicaMtto.lblBaseDeDatos")}
																</Label>
																<Autocomplete
																	freeSolo
																	id="ClaBaseDatos"
																	size="small"
																	onChange={(_, value) => this.handleChangeBaseDatos(value)}
																	options={this.state.basesDatos}
																	getOptionLabel={(option) => option.NomBaseDatos}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			name="ClaBaseDatos"
																			placeholder={translate("TablaReplicaMtto.phBaseDeDatosOrigen")}
																			variant="outlined"
																			onChange={(event) => {
																				this.nomBaseDatos = event.target.value;
																				this.handleChangeBaseDatos(null);
																			}}
																		/>
																	)}
																/>
															</Col>
															<Col md={6} sm={12}>
																<UncontrolledTooltip placement="right" target={`ClaServidor`}>
																	<span>{translate("TablaReplicaMtto.phServidorOrigen")}</span>
																</UncontrolledTooltip>
																<Label for="ClaServidor">
																	{translate("TablaReplicaMtto.lblServidor")}
																</Label>
																<Autocomplete
																	freeSolo
																	id="ClaServidor"
																	size="small"
																	onChange={(_, value) => (this.selServidor = value)}
																	options={this.state.servidores}
																	getOptionLabel={(option) => option.NomServidor}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			name="ClaServidor"
																			placeholder={translate("TablaReplicaMtto.phServidorOrigen")}
																			variant="outlined"
																			onChange={(event) => {
																				this.nomServidor = event.target.value;
																				this.selServidor = null;
																			}}
																		/>
																	)}
																/>
															</Col>
														</Row>

														<Row>
															<Col md={6}>
																<h5 className="mt-2 mb-1">
																	{translate("TablaReplicaMtto.lblDestinos")}
																</h5>
															</Col>
														</Row>
														<Row className="mt-1 mb-1">
															<Col md={6} sm={12}>
																<UncontrolledTooltip placement="right" target={`ClaBaseDatosDestino`}>
																	<span>{translate("TablaReplicaMtto.ttBaseDeDatosDestino")}</span>
																</UncontrolledTooltip>
																<Label for="ClaBaseDatosDestino">
																	{translate("TablaReplicaMtto.lblBaseDeDatos")}
																</Label>
																<Autocomplete
																	freeSolo
																	id="ClaBaseDatosDestino"
																	size="small"
																	onChange={(_, value) => this.handleChangeBaseDatosDestino(value)}
																	options={this.state.basesDatos}
																	getOptionLabel={(option) => option.NomBaseDatos}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			name="ClaBaseDatosDestino"
																			placeholder={translate(
																				"TablaReplicaMtto.ttBaseDeDatosDestino",
																			)}
																			variant="outlined"
																			onChange={(event) => {
																				this.nomBaseDatosDestino = event.target.value;
																				this.handleChangeBaseDatosDestino(null);
																			}}
																		/>
																	)}
																/>
															</Col>
															<Col md={6} sm={12}>
																<UncontrolledTooltip placement="right" target={`ClaServidorDestino`}>
																	<span>{translate("TablaReplicaMtto.ttServidorDestino")}</span>
																</UncontrolledTooltip>
																<Label for="ClaServidorDestino">
																	{translate("TablaReplicaMtto.lblServidor")}
																</Label>
																<Autocomplete
																	freeSolo
																	id="ClaServidorDestino"
																	size="small"
																	onChange={(_, value) => (this.selServidorDestino = value)}
																	options={this.state.servidoresDestino}
																	getOptionLabel={(option) => option.NomServidor}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			name="ClaServidorDestino"
																			placeholder={translate("TablaReplicaMtto.phServidorDestino")}
																			variant="outlined"
																			onChange={(event) => {
																				this.nomServidorDestino = event.target.value;
																				this.selServidorDestino = null;
																			}}
																		/>
																	)}
																/>
															</Col>
														</Row>

														<FormGroup row className="mt-2">
															<Col md={12}>
																<UncontrolledTooltip placement="right" target={`txtMensajeSolicitud`}>
																	<span>{translate("TablaReplicaMtto.phMotivo")}</span>
																</UncontrolledTooltip>
																<Label for="MensajeSolicitud">
																	{translate("TablaReplicaMtto.lblMotivo")}
																</Label>
																<Field
																	as="textarea"
																	id="txtMensajeSolicitud"
																	name="MensajeSolicitud"
																	placeholder={translate("TablaReplicaMtto.lblMotivo")}
																	className="form-control"
																	rows={4}
																/>
															</Col>
														</FormGroup>
													</TabPane>
												</TabContent>
											</Col>
										</Row>
									</CardBody>

									<CardFooter className="pt-0">
										<Row>
											<Col md={12} className="text-right">
												<Button
													id="btnGuardar"
													className="animation-on-hover"
													color="success"
													size="sm"
													type="submit">
													{translate("TablaReplicaMtto.btnGuardar")}
												</Button>
												<Button
													id="btnCancelar"
													className="animation-on-hover"
													color="default"
													size="sm"
													onClick={() => {
														writeLog(
															Formularios.NuevaPeticionTabla.ClaFuncionalidad,
															Formularios.NuevaPeticionTabla.Funcionalidades.btnCancelar,
														);

														this.btnCancelarClick(false);
													}}>
													{translate("TablaReplicaMtto.btnCerrar")}
												</Button>
											</Col>
										</Row>
									</CardFooter>
								</Card>
							</Form>
						)}
					</Formik>
				</div>
			</>
		);
	}
}

TablaReplicaMtto.propTypes = {
	idPeticion: PropTypes.number,
	hideModal: PropTypes.func,
};
