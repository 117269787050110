const EntidadMtto = {
	titulo: "New Service",
	noInfo: "No information found",

	lblTipo: "Type",
	lblEsquema: "Scheme",
	lblNombre: "Name",
	lblTitulo: "Title",
	lblServidorProduccion: "Production Server",
	lblServidorSandbox: "Sandbox Server",
	lblGlosario: "Glossary",
	lblDescripcion: "Description in spanish",
	lblDescripcionIngles: "Description in english",
	lblUrlProduccion: "Production Url",
	lblUrlSandbox: "Sandbox Url",
	phUrl: "Production url address for API endpoints",
	phUrlSandbox: "Sandbox url address for API endpoints",
	phEsquema: "Container scheme",
	phNombre: "Service name",
	phTitulo: "Service title",
	phGlosario: "Terms related to the service",
	phDescripcion: "Detailed description of the service in spanish",
	phDescripcionIngles: "Detailed description of the service in english",
	lblServicioPrivado: "The service is private",
	btnGuardar: "Save",
	btnCancelar: "Cancel",
	btnCerrar: "Close",
	colNombre: "Name",
	colDescripcion: "Description in spanish",
	colDescripcionIngles: "Description in english",
	colTipo: "Type",
	ttNombreEntrada: "Input name",
	ttDescripcionEntrada: "Description of what the input is (in spanish)",
	ttDescripcionEntradaIngles: "Description of what the input is (in english)",
	ttTipoEntrada: "Input data type",
	ttAgregarParametro: "Add a parameter",
	lblConjunto: "Set",
	ttNombreSalida: "Output name",
	ttDescripcionSalida: "Description of what the output is (in spanish)",
	ttDescripcionSalidaIngles: "Description of what the output is (in english)",
	ttTipoSalida: "Output data type",
	ttNombreRegla: "Rule name",
	ttDescripcionRegla: "Description of what the rule is (in spanish)",
	ttDescripcionReglaIngles: "Description of what the rule is (in english)",
	ttDescripcionServicio: "Description of what the service is",
	ttAgregarRegla: "Add a business rule",
	btnPublicar: " Publish",
	txtEjemploConsumirPost: "To consume the service, a ",
	txtEjemploConsumirPost2: "request must be made to:",
	txtEjemploConsumirHeader: "The",
	txtEjemploConsumirHeader2: "of the request must contain the following parameters: ",
	txtCommentApi: "//Enter a valid API Key from the client system",
	txtCommentToken: "// Enter a valid token",

	btnExportar: " Export",
	ttPublicar: "Publish the service in a productive environment",

	navGeneral: "General",
	navEntradas: "Inputs",
	navSalidas: "Outputs",
	navReglas: "Rules",
	navEjemplo: "Example",
	navResumen: "Summary",
	navSuscritos: "Subscribed",

	alertPublicar: "Do you want to publish the entity?",
	alertPublicarDescripcion: "This action can not be undone",

	ttExportar: "Export to Postman 2.1",

	lblNoVerificado: "This service is not verified",
	lblDeseaAprobar: "Do you want to approve it?",
	lblVerificar: "Verify",

	alertVerificar: "Do you want to approve the service?",
	alertVerificarDescripcion: "By verifying this service it will be published in a production environment",

	ttRechazar: "Reject",
	alertRechazo: "Verification rejection",
	alertRechazoDescripcion: "For example: incorrect data",
	alertRechazoMotivo: "Enter the reason for the denial of service",

	ttTipo: "Type of service",
	ttEsquema: "Schema in the database",
	ttNombre: "Service name",
	ttTitulo: "Service title",
	ttTituloAux: "New",
	ttServidorProduccion: "Server where the service will be executed in a productive environment",
	ttServidorSandbox: "Server where the service will be executed in test environment ",
	ttGlosario: "Glossary Terms Related to Service",

	errorEntradas: "All inputs must have a minimum description of 20 characters",
	errorSalidas: "All oututs must have a minimum description of 20 characters",

	alertNuevoServicioExito: "Service saved successfully",
	alertCambioServicioExito: "Changes made successfully",

	lblProduccion: "Production",

	txtEjemploConsumirBody: "The ", //body
	txtEjemploConsumirBody2: " of the request must contain a structure similar to the following where, ", //tipoEstructura
	txtEjemploConsumirBody3:
		" is a numerical value between 1 and 8 which indicates the format that the response will have and ", // parameters
	txtEjemploConsumirBody4: "  are the input values that the service requires:",

	txtEjemploConsumirBody5:
		" is a numerical value between 1 and 8 which indicates the format that the response will have, ",
	txtEjemploConsumirBody6: " are the input values ​​required by the service and ",
	txtEjemploConsumirBody7: " if the query has a condition for the execution of the view.",

	txtEjemploConsumirJSON: "The service will return an object in ", // JSON
	txtEjemploConsumirJSON2: " format with the following format:",

	commentEstructura: "// The type of structure (tipoEstructura) varies from 1 to 8",
	lblNoInfoIdioma: "No information found in English",

	lblNoDescripcion: "No description found",

	Suscritos: {
		Titulo: "Suscritos",
		lblProducto: "Producto",
		lbUso: "% de uso",
		lblErrores: "% de errores",
	},
};

export default EntidadMtto;
