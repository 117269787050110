import React from "react";
import { Route, Switch } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

import AdminNavbar from "../../components/Navbars/AdminNavbar";
import Footer from "../../components/Core/Footer";
import Sidebar from "../../components/Core/Sidebar";

import logo from "assets/img/kraken_icon.png";
import { getNomUsuario, getProducto, callApi, getSessionItem } from "utils/Utilerias";
import { getUserMenu } from "Menu";
import { GetToken, Config } from "utils/Config";
import PropTypes from "prop-types";

class Admin extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeColor: "blue",
			sidebarMini: true,
			opacity: 2,
			sidebarOpened: false,
			menuLoaded: false,
			routes: [],
			prodPrev: 0,
			claIdioma: getSessionItem("ClaIdioma", "1"),
		};

		this.cargaImagenProducto = this.cargaImagenProducto.bind(this);
		this.closeSidebar = this.closeSidebar.bind(this);
		this.handleMiniClick = this.handleMiniClick.bind(this);
		this.onChangeIdiomaCallback = this.onChangeIdiomaCallback.bind(this);
	}

	componentDidMount() {
		this.cargaImagenProducto();
		//alert(this.props.location.state.mensaje);
		if (document.body.classList.contains("sidebar-mini")) this.handleMiniClick();
		sessionStorage.setItem("ClaIdioma", this.state.claIdioma);
	}

	cargaImagenProducto() {
		const urlService = `${Config.urlApiService}/Producto?claProducto=${getProducto()}`;

		if (this.state.prodPrev !== getProducto()) {
			callApi(urlService, "GET", null, (status, response) => {
				this.setState({
					imagen: response.Imagen,
					prodPrev: response.ClaProducto,
				});
			});
		}
	}

	/**
	 * Función que obtiene las rutas a las qe el usuario tiene acceso
	 * @param {Array} routes rutas de las pantallas a las que el usuario tiene
	 * acceso
	 */
	getRoutes(routes) {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return this.getRoutes(prop.views);
			}

			if (prop.layout === "/admin") {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
				// return <Redirect from="*" to={prop.layout + prop.path} />;
			} else {
				return null;
			}
		});
	}

	/**
	 * Función que obtiene las rutas a las que el usuario tiene acceso
	 * @param {Array} routes rutas de las pantallas a las que el usuario tiene
	 * acceso
	 */
	getActiveRoute(routes) {
		let activeRoute = "Default Brand Text";

		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = this.getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				if (window.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}

		return activeRoute;
	}

	/**
	 * Función que administra la dimensión del sidebar al darle click
	 */
	handleMiniClick() {
		if (document.body.classList.contains("sidebar-mini")) {
			this.setState({ sidebarMini: false });
		} else {
			this.setState({ sidebarMini: true });
		}

		document.body.classList.toggle("sidebar-mini");
	}

	/**
	 * Función que abre y cierra la barra lateral
	 */
	toggleSidebar() {
		this.setState({
			sidebarOpened: !this.state.sidebarOpened,
		});
		document.documentElement.classList.toggle("nav-open");
	}

	/**
	 * Función  cierra la barra lateral
	 */
	closeSidebar() {
		this.setState({
			sidebarOpened: false,
		});
		document.documentElement.classList.remove("nav-open");
	}

	/**
	 * Función enviada a el componente idioma para que cambie el idioma en el session item
	 */
	onChangeIdiomaCallback(claIdiomaParam) {
		this.setState({
			claIdioma: claIdiomaParam,
		});

		getUserMenu(GetToken(), (usrMenu) => {
			this.setState({
				menuLoaded: true,
				usrMenu: usrMenu,
			});
		});

		sessionStorage.setItem("ClaIdioma", claIdiomaParam);
	}

	render() {
		if (this.state.menuLoaded) {
			let usrRoutes = this.getRoutes(this.state.usrMenu);
			return (
				<div className="wrapper">
					<div className="rna-container">
						<NotificationAlert ref={(ref) => (this.notificationAlert = ref)} />
					</div>
					<div className="navbar-minimize-fixed" style={{ opacity: this.state.opacity }}>
						<button className="minimize-sidebar btn btn-link btn-just-icon" onClick={this.handleMiniClick}>
							<i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
							<i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
						</button>
					</div>
					<Sidebar
						{...this.props}
						routes={this.state.usrMenu}
						activeColor={this.state.activeColor}
						logo={{
							innerLink: "/admin/Home",
							text: sessionStorage.getItem("NomProducto"),
							imgSrc: this.state.imagen === null ? logo : this.state.imagen,
						}}
						closeSidebar={this.closeSidebar}
					/>
					<div className="main-panel" ref={(ref) => (this.mainPanel = ref)} data={this.state.activeColor}>
						<AdminNavbar
							{...this.props}
							routes={this.state.usrMenu}
							nomUsuario={getNomUsuario()}
							handleMiniClick={this.handleMiniClick}
							brandText={this.getActiveRoute(this.state.routes)}
							sidebarOpened={this.state.sidebarOpened}
							toggleSidebar={this.toggleSidebar}
							onChangeIdiomaCallback={this.onChangeIdiomaCallback}
							claIdioma={this.state.claIdioma}
						/>

						<Switch>{usrRoutes}</Switch>
					</div>
					{this.props.location.pathname.indexOf("full-screen-map") !== -1 ? null : <Footer fluid />}
				</div>
			);
		} else {
			getUserMenu(GetToken(), (usrMenu) => {
				this.setState({
					menuLoaded: true,
					usrMenu: usrMenu,
				});
			});

			return <div>Cargando...</div>;
		}
	}
}

export default Admin;

Admin.propTypes = {
	location: PropTypes.any,
};
