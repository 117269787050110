import React from "react";
import { Modal, Button, Card, CardBody, Form, FormGroup, Label, CardHeader, Row, Col, Container } from "reactstrap";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import { Formik, Field } from "formik";

import { Config } from "utils/Config";
import { setSessionData, callApi, sessionAlive, decodeToken, writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";
import krakenIcon from "assets/img/kraken_icon.png";
import RecuperaContrasena from "views/Login/RecuperaContraseña";
import Footer from "./Footer";
import Idioma from "components/Navbars/Idioma";
import translate from "utils/translate";

import { withAuth0 } from "@auth0/auth0-react";
import CardFooter from "reactstrap/lib/CardFooter";
require("utils/Prototipos");

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			numUsuario: "",
			existParams: false,
			claIdioma: 1,
		};
		sessionStorage.setItem("ClaIdioma", 1);
		this.handleSubmitAceptar = this.handleSubmitAceptar.bind(this);
		this.hideModal = this.hideModal.bind(this);
		this.onChangeIdiomaCallback = this.onChangeIdiomaCallback.bind(this);
	}

	/**
	 * Función 	que oculta el modal
	 * correcta y refresca la pantalla
	 */
	hideModal() {
		this.setState({
			showModal: false,
		});
	}

	/**
	 * Función que es enviada al componente idioma para cambiar el valor del idioma
	 * @param {JSON} value los valores del formulario
	 */
	onChangeIdiomaCallback(claIdiomaParam) {
		this.setState({
			claIdioma: claIdiomaParam,
		});

		sessionStorage.setItem("ClaIdioma", claIdiomaParam);
	}

	/**
	 * Función del botón para enviar los datos del formulario login
	 * @param {JSON} value los valores del formulario
	 */
	handleSubmitAceptar(value) {
		console.log("handleSubmitAceptar, value", value);
		const urlWebService = Config.urlLoginService;

		const data = {
			userName: value.Usuario,
			password: value.Password,
		};

		// socket.emit("writeLog", "El usuario dio click en log", data);
		callApi(
			urlWebService,
			"POST",
			data,
			(status, response) => {
				let objetoUsuario = decodeToken(response.token);
				setSessionData({
					NomUsuario: response.nombreUsuario,
					Token: response.token,

					ClaUsuario: objetoUsuario.ClaUsuario,
					ClaEmpleado: data.userName,
				});
				//function writeLog(claFuncionalidad, claFuncionalidadDet, url, parametros, respuesta, mensaje, esError = 0) {

				//let funcionalidad = Funcionalidades.find((oX) => oX.Nombre === "Login");

				writeLog(
					Formularios.Login.ClaFuncionalidad,

					Formularios.Login.Funcionalidades.btnLogin,
					urlWebService,
					data,
					response,
					undefined,
					0,
				);

				this.setState({
					numUsuario: data.userName,
				});
			},
			Formularios.Login.ClaFuncionalidad,
			Formularios.Login.Funcionalidades.btnLogin,
		);
	}

	render() {
		let params = new URLSearchParams(this.props.location.search);
		const { loginWithRedirect } = this.props.auth0;

		if (params.get("idUso") && sessionAlive()) {
			return <Redirect from="/Login" to={`/Error${this.props.location.search}`} />;
		}

		if (params.get("frm") === "SolicitudSuscripcion" && sessionAlive()) {
			return <Redirect from="/Login" to={`/AutorizarSuscripcion${this.props.location.search}`} />;
		}

		if (params.get("frm") === "PeticionTablaReplica" && sessionAlive()) {
			return <Redirect from="/Login" to={`/AutorizarPeticionTabla${this.props.location.search}`} />;
		}

		if (sessionAlive()) {
			return <Redirect from="/Login" to="/SeleccionProducto" />;
		}
		return (
			<>
				{this.state.showAlert}
				<Formik
					initialValues={{
						Usuario: "",
						Password: "",
					}}
					onSubmit={this.handleSubmitAceptar}>
					{(props) => (
						<Form onSubmit={props.handleSubmit}>
							<Idioma
								claIdioma={this.state.claIdioma}
								onChangeIdiomaCallback={this.onChangeIdiomaCallback}></Idioma>
							<div className="d-flex justify-content-center">
								<Container fluid className="vh-100 min-vh-100">
									<Row style={{ margin: "5%" }}>
										<Col md={{ size: 4, offset: 4 }}>
											<Card className="w-100">
												<CardHeader>
													<Row>
														<Col md={6}>
															<h2>{translate("Login.lblBienvenido")}</h2>
														</Col>
														<Col md={6} className="text-right">
															<h5>v6.1.2</h5>
														</Col>
													</Row>
												</CardHeader>
												<CardBody>
													<Row>
														<Col md={12} className="d-flex justify-content-center">
															<img src={krakenIcon} width="150px" alt="" />
														</Col>
													</Row>

													<Row>
														<Col md={12} className="d-flex justify-content-center">
															<h4>Kraken</h4>
														</Col>
													</Row>

													<FormGroup row>
														<Col md={{ offset: 2, size: 8 }}>
															<Label for="usuario">{translate("Login.lblUsuario")}</Label>
															<Field
																name="Usuario"
																type="input"
																className="form-control"
																placeholder={translate("Login.lblUsuario")}
																required
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Col md={{ offset: 2, size: 8 }}>
															<Label for="password">{translate("Login.lblContraseña")}</Label>
															<Field
																name="Password"
																type="password"
																className="form-control"
																placeholder={translate("Login.lblContraseña")}
																required
															/>
														</Col>
													</FormGroup>
													<FormGroup className="text-right">
														<div className="float-right w-50">
															<Button type="submit" className="animation-on-hover" color="info">
																{translate("Login.btnEntrar")}
															</Button>
														</div>
														<div className="float-left w-50">
															<Button
																style={{ color: "#FFFFFF" }}
																className="btn-link"
																onClick={() => {
																	//function writeLog(claFuncionalidad, claFuncionalidadDet, url, parametros, respuesta, mensaje, esError = 0) {
																	writeLog(
																		Formularios.Login.ClaFuncionalidad,
																		Formularios.Login.Funcionalidades.btnRecuperarContrasena,
																	);

																	this.setState({
																		showModal: true,
																	});
																}}>
																{translate("Login.lblOlvidasteTuContraseña")}
															</Button>
														</div>
													</FormGroup>
												</CardBody>
												<CardFooter>
													<div className="float-left w-50">
														<Button
															style={{ color: "#FFFFFF" }}
															className="btn-link"
															onClick={() => {
																loginWithRedirect();
															}}>
															Login/Sign Up
														</Button>
													</div>
												</CardFooter>
											</Card>
										</Col>
									</Row>
								</Container>
							</div>
						</Form>
					)}
				</Formik>

				<Modal centered={true} isOpen={this.state.showModal} size="md">
					<RecuperaContrasena hideModal={this.hideModal} />
				</Modal>
				<Footer />
			</>
		);
	}
}

Login.propTypes = {
	location: PropTypes.any,
	auth0: PropTypes.any,
};
export default withAuth0(Login);
