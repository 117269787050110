import DashboardProveedor from "./views/Dashboard/DashboardProveedor";
import DashboardCliente from "./views/Dashboard/DashboardCliente";
import Entidades from "./views/Entidades/Entidades";
import MisEntidades from "./views/Entidades/MisEntidades";

import Responsables from "./views/Responsables/Responsables";
import Servidores from "./views/Servidores/Servidores";
import ServiciosDisponibles from "./views/Entidades/ServiciosDisponibles";
import Glosarios from "./views/Glosario/Glosario";
import Productos from "./views/Producto/ProductoF";
import SolicitudServicios from "./views/SolicitudServicios/SolicitudServicio";
import Replicas from "./views/Replicas/Replicas";
import Home from "./views/Home/Home";
import BitacoraUso from "./views/Entidades/BitacoraUso";

import Incidentes from "./views/Incidentes/Incidentes";
import Usuarios from "./views/Seguridad/Usuarios";

import { Config } from "./utils/Config";
import { callApi } from "./utils/Utilerias";

var Componentes = [
	{
		Name: "Entidades",
		Componente: Entidades,
	},
	{
		Name: "BitacoraUso",
		Componente: BitacoraUso,
	},
	{
		Name: "MisEntidades",
		Componente: MisEntidades,
	},
	{
		Name: "Seguridad",
		Componente: Usuarios,
	},
	{
		Name: "DashboardCliente",
		Componente: DashboardCliente,
	},
	{
		Name: "DashboardProveedor",
		Componente: DashboardProveedor,
	},
	{
		Name: "Incidentes",
		Componente: Incidentes,
	},
	{
		Name: "Responsables",
		Componente: Responsables,
	},
	{
		Name: "ServiciosDisponibles",
		Componente: ServiciosDisponibles,
	},
	{
		Name: "Servidores",
		Componente: Servidores,
	},
	{
		Name: "Glosario",
		Componente: Glosarios,
	},
	{
		Name: "Producto",
		Componente: Productos,
	},
	{
		Name: "SolicitudServicio",
		Componente: SolicitudServicios,
	},
	{
		Name: "Replicas",
		Componente: Replicas,
	},
	{
		Name: "Home",
		Componente: Home,
	},
];

function obtenerComponente(name) {
	try {
		return Componentes.find((oX) => oX.Name === name).Componente;
	} catch {
		return undefined;
	}
}

function getUserMenu(token, callback) {
	const urlWebService = Config.urlApiService + "/ObtenerMenu";

	let usrMenu = [];
	let mnuItem = [];

	callApi(urlWebService, "GET", null, (status, response) => {
		response.Result0.map(function (item, key) {
			mnuItem = {
				path: item.Ruta,
				component: obtenerComponente(item.Component),
				name: sessionStorage.getItem("ClaIdioma") === "1" ? item.NomMenu : item.NomMenuIng,
				icon: item.CssClase,
				layout: "/admin",
				esVisible: item.EsVisible,
				views: [],
				claMenu: item.ClaMenu,
			};

			if (item.Items.length > 0) {
				let childItem = {};

				item.Items.map(function (subItem, key) {
					childItem = {
						path: subItem.Ruta,
						component: obtenerComponente(subItem.Component),
						name: sessionStorage.getItem("ClaIdioma") === "1" ? subItem.NomMenu : subItem.NomMenuIng,
						//icon: y.CssClase,
						layout: "/admin",
						esVisible: subItem.EsVisible,
						mini: getMins(sessionStorage.getItem("ClaIdioma") === "1" ? subItem.NomMenu : subItem.NomMenuIng),
						views: [],
						claMenu: subItem.ClaMenu,
					};

					mnuItem.views[key] = childItem;
					mnuItem.collapse = true;
					mnuItem.state = item.NomMenu + "_collapse";
					return null;
				});
			}

			usrMenu[key] = mnuItem;
			return null;
		});

		mnuItem = {
			path: "/admin/ayuda",
			component: null,
			name: sessionStorage.getItem("ClaIdioma") === "1" ? "Ayuda" : "Help",
			icon: "fas fa-question-circle",
			layout: "/admin",
			esVisible: 1,
			esAyuda: 1,
			views: [],
			claMenu: 99,
		};

		usrMenu[usrMenu.length + 1] = mnuItem;

		callback(usrMenu);
	});
}

function getMins(nombreCom) {
	try {
		let letrasMin = "";
		let palabras = nombreCom.split(" ");

		palabras.forEach((palabra) => {
			letrasMin = letrasMin + palabra.charAt(0).toUpperCase();
		});

		return letrasMin;
	} catch {
		return "";
	}
}

export { getUserMenu };
