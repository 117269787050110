const Usuarios = {
	titulo: "Administración de Usuarios",
	lblClaveEmpleado: "Clave Empleado",
	phClaveEmpleado: "Clave del empleado",
	lblNombre: "Nombre",
	phNombre: "Nombre del usuario",
	btnAgregar: " Agregar",
	ttAgregar: "Agregar",
	ttBuscar: "Buscar",
	ttLimpiar: "Limpiar",
	colClaveEmpleado: "Clave Empleado",
	colNombre: "Nombre",
	colPerfil: "Perfil",
	ttEditar: "Editar",
	ttEliminar: "Eliminar",
};

export default Usuarios;
