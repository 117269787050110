/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import {
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	Card,
	CardBody,
	CardHeader,
	Form,
	FormGroup,
	Row,
	Col,
	Button,
	Label,
	CardFooter,
	CardTitle,
	Input,
	UncontrolledTooltip,
	Badge,
} from "reactstrap";
import { Formik, Field, FieldArray } from "formik";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Config, GetToken, Tours } from "utils/Config";

import PropTypes from "prop-types";
import { Formularios } from "utils/funcionalidad";
import TourVirtual from "../../components/Tour/TourVirtual";

import {
	showAlert,
	showConfirm,
	callApi,
	getCliente,
	getProducto,
	getNomProducto,
	getApiKey,
	getLoginInfo,
	showAlertInput,
	writeLog,
	showSweetAlert,
} from "utils/Utilerias";
import NotificationAlert from "react-notification-alert";
import EntidadEjemplo from "./Ejemplo";
import Dependencias from "./Dependencias";
import Suscritos from "./Suscritos";
import translate from "utils/translate";

require("utils/Prototipos");

let tipo;
let tipoDato;
let terminosGlosario = [];
let formularioDet = [];
let servidoresProducto = [];
// eslint-disable-next-line no-unused-vars
let errorEntradas = false;
// eslint-disable-next-line no-unused-vars
let origen;
// eslint-disable-next-line no-unused-vars
let categoria;

let agregarEntradasIndex = 51;
let agregarSalidasIndex = 60;
let agregarReglasIndex = 70;
let newConjunto = false;
export default class EntidadMtto extends React.Component {
	constructor(props) {
		super(props);

		this.tabsSalidas = [];
		this.state = {
			activeTab: this.props.isSubscribed ? "suscritos" : "general",
			descripcionTab: parseInt(sessionStorage.getItem("ClaIdioma")) === 1 ? "esp" : "eng",
			idEntidad: this.props.idEntidad,
			isLoaded: false,
			isOwner: true,
			isAddParam: false,
			claTipoEntidad: 0,
			entidad: {},
			verBajaEntradas: false,
			verBajaSalidas: false,
			verBajaReglas: false,
			esSuscrito: false,
			alert: null,
			nuevosTerminos: [],
			selectedTab: 0,
			tabsOptions: this.getTabsOptions(),
			maxIdGrupo: 0,
			esPrivado: false,
			openTour: false,
			isRendered: false,
			esGuardado: false,
			descripcion: parseInt(sessionStorage.getItem("ClaIdioma")),
			tabIndexEntradas: 45,
			tabIndexSalidas: 54,
			tabIndexReglas: 64,
			counterIndex: 0,
			counterIndexSalidas: 0,
			counterIndexReglas: 0,
			/*agregarEntradaIndex: 51,*/
			newRow: false,
			myRefs: [],
		};

		this.btnCancelarClick = this.btnCancelarClick.bind(this);
		this.btnGuardarClick = this.btnGuardarClick.bind(this);
		this.btnSuscribirseClick = this.btnSuscribirseClick.bind(this);
		this.btnDescargarClick = this.btnDescargarClick.bind(this);
		//this.handleFormChange = this.handleFormChange.bind(this);
		this.toggle = this.toggle.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);
		this.getTabsOptions = this.getTabsOptions.bind(this);
		this.updateTabs = this.updateTabs.bind(this);
		this.addTab = this.addTab.bind(this);
		this.deleteTab = this.deleteTab.bind(this);
		this.rechazarVerificacion = this.rechazarVerificacion.bind(this);
		this.cargarTour = this.cargarTour.bind(this);
		this.changeActiveTab = this.changeActiveTab.bind(this);
		this.inputNombreRef = React.createRef();
		this.nodes = new Map();
		this.nodesSalidas = new Map();
		this.nodesReglas = new Map();
	}

	/**
	 * Carga inicial de los catalogos en pantalla
	 *
	 * @returns
	 * @memberof EntidadMtto
	 */
	load() {
		let urlService = ``;

		urlService = `${Config.urlApiService}/Catalogos/12/` + getProducto();
		callApi(urlService, "GET", null, (status, response) => {
			origen = response;
		});

		urlService = `${Config.urlApiService}/Catalogos/2`;
		callApi(urlService, "GET", null, (status, response) => {
			tipo = response;
		});

		urlService = `${Config.urlApiService}/Catalogos/9`;
		callApi(urlService, "GET", null, (status, response) => {
			categoria = response;
		});

		urlService = `${Config.urlApiService}/Catalogos/3`;
		callApi(urlService, "GET", null, (status, response) => {
			tipoDato = response;
		});

		urlService = `${Config.urlApiService}/Glosario`;
		callApi(urlService, "GET", null, (status, response) => {
			terminosGlosario = response;
		});

		urlService = `${Config.urlApiService}/CatFormularioDet/1`;
		callApi(urlService, "GET", null, (status, response) => {
			formularioDet = response;
		});

		urlService = `${Config.urlApiService}/Producto/Servidor?claProducto=${this.props.claProducto}`;
		callApi(urlService, "GET", null, (status, response) => {
			servidoresProducto = response;
		});

		if (this.props.idEntidad && this.props.idEntidad > 0) {
			// Si la entidad existe se trae el ejemplo de consumo, por eso se agrega el /1 al final
			let urlService = `${Config.urlApiService}/Entidades/${this.props.claProducto}/${this.props.idEntidad}?EsClone=${this.props.isClone}`;

			callApi(urlService, "GET", null, (status, response) => {
				let isOwner = parseInt(response.ClaProducto) === parseInt(getProducto());

				let entidadCopy = { ...response };
				if (response.EsTransaccional === 1) {
					entidadCopy.EsTransaccional = "1";
				} else {
					entidadCopy.EsTransaccional = "0";
				}

				this.setState({
					isLoaded: true,
					entidad: entidadCopy,
					isOwner: isOwner,
					claTipoEntidad: response.ClaTipoEntidad,
					nuevosTerminos: response.Terminos,
					maxIdGrupo: Math.max.apply(
						Math,
						response.Detalles.map(function (detalles) {
							return detalles.IdGrupo;
						}),
					),
					esPrivado: response.EsPrivado === 1 ? true : false,
				});

				if (this.state.maxIdGrupo > 0) {
					for (let i = 0; i <= this.state.maxIdGrupo; i++) {
						this.addTab();
					}
				} else {
					this.addTab();
				}

				this.setState({
					isRendered: true,
				});
			});
		} else {
			this.setState({
				isLoaded: true,
				entidad: {
					NomEntidad: "",
					IdEntidad: 0,
					Esquema: "",
					ClaEstatus: 1,
					ClaTipoEntidad: 0,
					ClaSistemaDueño: 0,
					ClaProducto: getProducto(),
					NomProducto: getNomProducto(),
					ClaBaseDatos: 0,
					ClaCategoria: 0,
					IdGrupo: 0,
					Descripcion: "",
					Titulo: "",
					Detalles: [],
					ReglasNegocio: [],
					EsVerificado: 0,
					EsTransaccional: "0",
					EsPrivado: 0,
					DescripcionIngles: "",
					// ejemplo: [],
				},
			});

			this.addTab();

			if (this.props.objPeticion !== undefined) this.setState({ objPeticion: this.props.objPeticion });
		}
	}

	async componentDidMount() {
		await this.load();
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}

	handleTabChange(_, value) {
		this.setState({
			selectedTab: value,
		});
	}

	getTabsOptions() {
		var options = [];
		for (var i = 0; i < this.tabsSalidas.length; i++) {
			options.push({});
		}
		return options;
	}

	addTab() {
		let len = this.tabsSalidas.length;
		this.tabsSalidas.push({ id: len, value: len + 1 });
		this.updateTabs();
		newConjunto = true;
	}

	deleteTab(tabIndex) {
		let len = this.tabsSalidas.length;
		let detallesCopy = [...this.state.entidad.Detalles];
		let updateIdGrupo = false;
		//Cuando solo hay 1 tab
		if (len === 1) {
			this.tabsSalidas = [{ id: 0, value: 1 }];
			this.updateTabs();
		} else if (len - 1 === tabIndex) {
			//Eliminar última tab
			this.tabsSalidas.pop();
			this.updateTabs();
		} else {
			this.tabsSalidas.splice(tabIndex, 1);
			this.updateTabs();
			updateIdGrupo = true;
		}

		//Eliminar salidas en tab
		if (detallesCopy.length > 0) {
			let detallesLen = detallesCopy.length;
			for (let i = 0; i < detallesLen; i++) {
				if (detallesCopy[i].ClaTipoEntidadDet === 2) {
					if (detallesCopy[i].IdGrupo === tabIndex) {
						detallesCopy.splice(i, 1);
						detallesLen -= 1;
						i -= 1;
					} else if (updateIdGrupo && detallesCopy[i].IdGrupo > tabIndex) {
						detallesCopy[i].IdGrupo -= 1;
					}
				}
			}

			let entidadCopy = { ...this.state.entidad };
			entidadCopy.Detalles = detallesCopy;

			this.setState({
				entidad: entidadCopy,
			});
		}
	}

	updateTabs() {
		this.setState({
			tabsOptions: this.getTabsOptions(),
		});
	}

	/**
	 * Se ejecuta cuando se rechaza la verificación de un servicio
	 * @param {String} value valor que se capturo en el input
	 */
	rechazarVerificacion(value) {
		let urlService = `${Config.urlApiService}/EntidadesValidar/1`;
		const method = "PUT";
		let objEntidad = this.state.entidad;

		objEntidad.EsVerificado = 0;
		objEntidad.Mensaje = value;
		objEntidad.BajaLogica = 0;
		objEntidad.ClaEstatus = this.state.entidad.ClaEstatus;
		objEntidad.Terminos = this.state.nuevosTerminos;

		callApi(urlService, method, objEntidad, (status, response) => {
			if (status !== 200) {
				alert(response);
				return;
			} else {
				if (this.props.peticionCallBack) {
					this.props.peticionCallBack(response, this.props.objPeticion, true);
				}
			}
		});
		this.load();
		this.hideAlertInput();
	}

	/**
	 * Funcion que se ejecuta al aprobar un servicio
	 */
	aprobarVerificacion() {
		let urlService = `${Config.urlApiService}/EntidadesValidar/1`;
		let method = "PUT";
		let objEntidad = this.state.entidad;
		objEntidad.EsVerificado = 1;
		objEntidad.Mensaje = null;
		objEntidad.BajaLogica = 0;
		objEntidad.ClaEstatus = this.state.entidad.ClaEstatus;
		objEntidad.Terminos = this.state.nuevosTerminos;
		objEntidad.EsClon = this.props.isClone ? 1 : 0;

		callApi(
			urlService,
			method,
			objEntidad,
			(status, response) => {
				writeLog(
					Formularios.EntidadMtto.ClaFuncionalidad,
					Formularios.EntidadMtto.Funcionalidades.btnVerificar,
					urlService,
					objEntidad,
					response,
					undefined,
					0,
				);
				if (this.props.peticionCallBack) {
					this.props.objPeticion.ClaEstatus = 8;
					this.props.peticionCallBack(response, this.props.objPeticion);
				}

				this.consultaSolicitud(objEntidad);

				this.setState({
					esGuardado: true,
				});

				this.btnPublicarClick();
			},
			Formularios.EntidadMtto.ClaFuncionalidad,
			Formularios.EntidadMtto.Funcionalidades.btnVerificar,
		);

		//Enviar correo a usuario que realizó la ultima modificación del servicio
		//Enviar correo a dueño del producto
		/*		let asuntoCorreo = Verificacion.asunto;
		let cuerpoCorreo = Verificacion.texto;
		let htmlCorreo = Verificacion.html;

		asuntoCorreo = asuntoCorreo.replace("{nomEntidad}", this.state.entidad.NomEntidad);

		cuerpoCorreo = cuerpoCorreo.replace("{nomUsuario}", this.state.entidad.NomUsuarioMod);
		cuerpoCorreo = cuerpoCorreo.replace("{nomEntidad}", this.state.entidad.NomEntidad);

		htmlCorreo = htmlCorreo.replace("{nomUsuario}", this.state.entidad.NomUsuarioMod);
		htmlCorreo = htmlCorreo.replace("{nomEntidad}", this.state.entidad.NomEntidad);

		if (
			this.state.entidad.EmailUsuarioMod === "vpinales@deacero.com" ||
			this.state.entidad.EmailUsuarioMod === "jflores@deacero.com"
		) {
			let datosCorreo = {
				destinatario: this.state.entidad.EmailUsuarioMod,
				asunto: asuntoCorreo,
				texto: cuerpoCorreo,
				html: htmlCorreo,
			};

			method = "POST";
			urlService = Config.urlApiService + "/EnviarCorreo";

			callApi(urlService, method, datosCorreo, (status) => {
				if (status === 200) {
					console.log("Email enviado con éxito");
				}
			});
		}*/

		this.load();
		this.hideAlert();
	}

	/**
	 * Consulta si la entidad proviene de una solicitud
	 */

	consultaSolicitud(objEntidad) {
		let urlService = `${Config.urlApiService}/SolicitudServicio/${this.props.claProducto}`;

		callApi(urlService, "GET", null, (status, response) => {
			let solicitud = response.recordset.filter((solicitud) => {
				return solicitud.IdEntidad === objEntidad.IdEntidad && solicitud.ClaProducto === this.props.claProducto;
			});

			if (solicitud.length > 0) {
				let obj = solicitud[0];
				obj.ClaEstatus = 8;
				let urlWebService = Config.urlApiService + "/SolicitudServicio/" + obj.ClaProducto + "/" + obj.IdPeticion;
				let data = {
					...obj,
				};
				if (obj.FechaPromesa !== null) {
					obj.FechaPromesa = new Date(obj.FechaPromesa);
					data.FechaPromesa = obj.FechaPromesa.toSqlDateTime();
					obj.FechaCaptura = new Date(obj.FechaCaptura);
					data.FechaCaptura = obj.FechaCaptura.toSqlDateTime();
				} else {
					data.FechaPromesa = null;
				}

				data.BajaLogica = 0;

				callApi(
					urlWebService,
					"PUT",
					data,
					(status, response) => {
						if (status === 200) {
							writeLog(
								Formularios.SolicitudesDeServicio.ClaFuncionalidad,
								Formularios.SolicitudesDeServicio.Funcionalidades.modificarEstatus,
								urlWebService,
								data,
								response,
								"Estatus de la solicitud cambiado con éxito",
								0,
							);
						}
					},
					Formularios.SolicitudesDeServicio.ClaFuncionalidad,
					Formularios.SolicitudesDeServicio.Funcionalidades.modificarEstatus,
				);
			}
		});
	}

	/**
	 * Publica la entidad en otro servidor
	 *
	 * @param {*} JSON Representa el objeto Entidad de manera completa.
	 */
	btnPublicarClick() {
		const data = {
			claProducto: getProducto(),
			idEntidad: this.props.idEntidad,
			bajaLogica: this.state.esSuscrito ? 1 : 0,
		};

		const urlService = `${Config.urlApiService}/Entidades/Publicar/${getProducto()}/${this.props.idEntidad}`;

		callApi(
			urlService,
			"PUT",
			data,
			(status, response) => {
				if (status === 200) {
					writeLog(
						Formularios.EntidadMtto.ClaFuncionalidad,
						Formularios.EntidadMtto.Funcionalidades.btnPublicar,
						urlService,
						data,
						response,
						undefined,
						0,
					);
					this.btnCancelarClick(true);
				} else {
					throw new Error(response);
				}
			},
			Formularios.EntidadMtto.ClaFuncionalidad,
			Formularios.EntidadMtto.Funcionalidades.btnPublicar,
		);
	}

	/**
	 * Oculta los promts de alertas
	 */
	hideAlert() {
		this.setState({
			showAlert: null,
			showConfirm: null,
		});
	}

	/**
	 * Oculta el input con el prompt
	 */
	hideAlertInput() {
		this.setState({
			showAlertInput: null,
		});
	}

	/**
	 * Muestra los prompts de alertas
	 */
	showAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Muestra alerta con input
	 */
	showAlertInput(title, placeholder, text) {
		const msgAlert = showAlertInput(
			(value) => this.rechazarVerificacion(value),
			() => this.hideAlertInput(),
			title,
			placeholder,
			text,
		);

		this.setState({
			showAlertInput: msgAlert,
		});
	}

	/**
	 * Oculta los promts de alertas con pregunta de confirmacion
	 */
	showConfirm(onConfirm, onCancel, title, text) {
		const warningAlert = showConfirm(onConfirm, onCancel, title, text);

		this.setState({
			showConfirm: warningAlert,
		});
	}

	btnSuscribirseClick() {
		const data = {
			claCliente: getCliente(),
			idEntidad: this.props.idEntidad,
			bajaLogica: this.state.esSuscrito ? 1 : 0,
		};

		const urlService = `${Config.urlApiService}/EntidadesCliente`;

		callApi(urlService, "PUT", data, (status, response) => {
			this.setState({
				esSuscrito: response.BajaLogica !== 1,
			});
		});
	}

	btnCancelarClick(isOk) {
		agregarEntradasIndex = 51;
		agregarSalidasIndex = 60;
		agregarReglasIndex = 70;
		this.props.hideModal(isOk);
	}

	cargarTour() {
		if (
			getLoginInfo().ClaRol === 5 &&
			this.state.isOwner === true &&
			this.state.isLoaded &&
			this.props.idEntidad > 0
			// && this.state.entidad.EsVerificado === 1
		) {
			return <TourVirtual PaginaID="2" ClaTour={Tours.Publicar} />;
		}
	}

	btnGuardarClick(value) {
		let urlService = `${Config.urlApiService}/Entidades/${getProducto()}/${this.props.idEntidad}`;
		const method = this.props.idEntidad && !this.props.isClone ? "PUT" : "POST";

		value.BajaLogica = 0;
		value.ClaEstatus = this.state.entidad.ClaEstatus;
		value.Terminos = this.state.nuevosTerminos;
		value.EsTransaccional = parseInt(value.EsTransaccional);
		value.EsClon = this.props.isClone ? 1 : 0;

		this.state.esPrivado ? (value.EsPrivado = 1) : (value.EsPrivado = 0);

		//console.log(value);
		callApi(
			urlService,
			method,
			value,
			(status, response) => {
				if (status === 200) {
					if (this.props.peticionCallBack !== undefined) {
						if (method === "POST") {
							//Se reenvia el objeto peticion para trabajar con el en el callback y no perderlo
							this.props.peticionCallBack(response, this.props.objPeticion);
						} else {
							//Se envia un parametro como verdadero para conservar el estatus y evitar que cambie cuando guardan
							//una edicion en la entidad, sin manifestar cambios en el estatus de la peticion
							this.props.peticionCallBack(response, this.props.objPeticion, false, true);
						}
					}

					writeLog(
						Formularios.EntidadMtto.ClaFuncionalidad,
						Formularios.EntidadMtto.Funcionalidades.btnGuardar,
						urlService,
						value,
						response,
						undefined,
						0,
					);
					if (this.props.idEntidad === 0) {
						showSweetAlert(translate("EntidadMtto.alertNuevoServicioExito"), "", "success");
						//this.btnCancelarClick(false);
					} else {
						this.props.updateData();
						showSweetAlert(translate("EntidadMtto.alertCambioServicioExito"), "", "success");

						this.setState({
							esGuardado: true,
						});
					}
				} else {
					alert(response);
					return;
				}
			},
			Formularios.EntidadMtto.ClaFuncionalidad,
			Formularios.EntidadMtto.Funcionalidades.btnGuardar,
		);
	}

	btnDescargarClick(value) {
		let apiUrl = "";
		let bodyRaw = {};

		bodyRaw.tipoEstructura = 1;

		try {
			switch (parseInt(value.ClaTipoEntidad)) {
				case 1:
				case 2:
					apiUrl = `${Config.urlApi}/${value.ClaProducto}/${value.IdEntidad}`;
					break;

				case 5:
					apiUrl = `${Config.urlApi}/${value.ClaProducto}/${value.IdEntidad}`;
					break;

				case 7:
					apiUrl = `${Config.urlApi}/${value.ClaProducto}/${value.IdEntidad}`;
					break;

				default:
					apiUrl = `No se reconoce el tipo de servicio`;
					break;
			}

			let uri = new URL(apiUrl);
			let array = [];

			if (value.Detalles && value.Detalles.length > 0) {
				array = value.Detalles.filter((oX) => oX.ClaTipoEntidadDet === 1 && oX.BajaLogica === 0); //Solo me interesan las entradas activas
			}

			if (value.ClaTipoEntidad <= 2) {
				//Vista/Tablas
				let columnas = "";

				array.forEach((item) => {
					columnas = item.NomEntidadDet + ",";
				});

				columnas = array.length > 0 ? columnas.substr(0, columnas.length - 1) : columnas;
				bodyRaw.columnas = columnas;
				bodyRaw.condicion = `${array[0].NomEntidadDet} > 1 And ${array[0].NomEntidadDet} <= 10`;
			} else {
				//SP/API

				let tmpString = "{";

				array.forEach((item) => {
					let nomEntidadDet = item.NomEntidadDet;

					if (item.NomEntidadDet.indexOf("@p") >= 0) {
						nomEntidadDet = item.NomEntidadDet.substr(3, item.nomEntidadDet); //Quita los @pn, @pd, @ps en caso de que vengan en el nombre del parametro
					}

					tmpString += `"${nomEntidadDet.trimEnd()}": "${item.Descripcion.trimEnd()}",`;
				});

				tmpString = array.length > 0 ? tmpString.substr(0, tmpString.length - 1) : tmpString;

				tmpString += "}";

				bodyRaw.parameters = JSON.stringify(JSON.parse(tmpString));
			}

			let jSonToDownload = {
				info: {
					// _postman_id: "e3bb7aa2-ea25-4e71-935c-7c1dd24b6643",
					name: getNomProducto() + "-" + value.NomEntidad,
					schema: "https://schema.getpostman.com/json/collection/v2.1.0/collection.json",
				},
				item: [
					{
						name: apiUrl,
						request: {
							method: "POST",
							header: [
								{
									key: "x-access-token",
									value: GetToken(),
									type: "text",
								},
								{
									key: "x-api-key",
									value: getApiKey(),
									type: "text",
								},
							],
							body: {
								mode: "raw",
								raw: JSON.stringify(bodyRaw),
								options: {
									raw: {
										language: "json",
									},
								},
							},
							url: {
								raw: uri.href,
								protocol: uri.protocol.replace(":", ""),
								host: [uri.hostname],
								port: uri.port,
								path: uri.pathname
									.substr(1, uri.pathname.length)
									.split("/")
									.map((item) => {
										return item;
									}),
							},
						},
						response: [],
					},
				],
				protocolProfileBehavior: {},
			};

			const a = document.createElement("a");

			const file = new Blob([JSON.stringify(jSonToDownload)], {
				type: "text/json",
			});
			a.href = URL.createObjectURL(file);
			a.download = value.NomEntidad + ".json";

			a.click();
		} catch (err) {
			alert(err);
		}
	}

	toggleViaEnter = (tab) => (event) => {
		if (event.keyCode === 13) {
			this.toggle(tab);
		}
	};

	renderNavItem(tab) {
		return (
			<NavItem aria-hidden="true">
				<NavLink
					tabindex={tab.Index}
					id={tab.Name}
					disabled={
						(tab.Name === "ejemplo" && this.props.idEntidad <= 0) ||
						(tab.Name === "resumen" && this.props.idEntidad <= 0)
						// (tab.Name === "pruebas" && this.props.idEntidad <= 0) ||
						// (tab.Name === "pruebas" && this.state.entidad.ClaTipoEntidad === 2 && this.props.idEntidad > 0)
					}
					className={this.state.activeTab === tab.Name ? "active" : ""}
					onClick={() => this.setState({ activeTab: tab.Name, openTour: tab.Name === "ejemplo" ? true : false })}
					onKeyDown={this.toggleViaEnter(tab.Name)}>
					{tab.Title}
				</NavLink>
			</NavItem>
		);
	}

	renderDetallesInfo(item, index) {
		return (
			<tr key={index}>
				<td
					className="pl-3 pr-4 pt-3 pb-0"
					style={{
						borderTop: "1px solid #2b3553",
						borderBottom: "1px solid #2b3553",
						width: "2000px",
					}}>
					<div className="row">
						<h5>
							{item.NomRegla ?? item.NomEntidadDet + "   "}
							{item.NomTipoDato ? <Badge color="info">{`${item.NomTipoDato}`}</Badge> : null}
						</h5>
					</div>
					<div className="row pl-3" style={{ wordWrap: "break-word" }}>
						{sessionStorage.getItem("ClaIdioma") === "1"
							? item.Descripcion === null || item.Descripcion === undefined || item.Descripcion === ""
								? translate("EntidadMtto.lblNoInfoIdioma")
								: item.Descripcion
							: item.DescripcionIngles === null ||
							  item.DescripcionIngles === undefined ||
							  item.DescripcionIngles === ""
							? translate("EntidadMtto.lblNoInfoIdioma")
							: item.DescripcionIngles}
					</div>
				</td>
			</tr>
		);
	}

	componentDidUpdate() {
		const refs = Array.from(this.nodes.values());
		const newestRef = refs[refs.length - 1];

		if (newestRef !== undefined && newestRef !== null && this.state.activeTab === "entradas") {
			newestRef.focus();
		}

		if (newestRef !== undefined && newestRef !== null && this.state.activeTab === "salidas") {
			newestRef.focus();
		}
		let ref_reglas = Array.from(this.nodesReglas.values());
		let newestRef_reglas = ref_reglas[ref_reglas.length - 1];
		if (newestRef_reglas !== undefined && newestRef_reglas !== null && this.state.activeTab === "reglas") {
			if (newestRef_reglas !== undefined && newestRef_reglas !== null) {
				newestRef_reglas.focus();
			}
		}
		if (newConjunto) {
			const refss = Array.from(this.nodesSalidas.values());
			const newestReff = refss[refss.length - 1];

			if (newestReff !== undefined && newestReff !== null) {
				newestReff.focus();
			}
			newConjunto = false;
		}
	}

	renderDetallesRow(formikProps, arrayHelpers, item, index) {
		return (
			<tr key={index}>
				<td className="w-25">
					<Field
						as="input"
						tabIndex={
							this.state.activeTab === "salidas"
								? this.state.tabIndexSalidas + index * 6 + 1
								: this.state.tabIndexEntradas + index * 6 + 1
						}
						name={`Detalles.${index}.NomEntidadDet`}
						className="form-control"
						key={index}
						innerRef={(ref) => this.nodes.set(index, ref)}
					/>
				</td>
				<td className="w-30">
					<Field
						tabIndex={
							this.state.activeTab === "salidas"
								? this.state.tabIndexSalidas + index * 6 + 2
								: this.state.tabIndexEntradas + index * 6 + 2
						}
						name={`Detalles.${index}.Descripcion`}
						className="form-control"
					/>
				</td>
				<td className="w-30">
					<Field
						tabIndex={
							this.state.activeTab === "salidas"
								? this.state.tabIndexSalidas + index * 6 + 3
								: this.state.tabIndexEntradas + index * 6 + 3
						}
						name={`Detalles.${index}.DescripcionIngles`}
						className="form-control"
					/>
				</td>
				<td className="w-15">
					<Field
						tabIndex={
							this.state.activeTab === "salidas"
								? this.state.tabIndexSalidas + index * 6 + 4
								: this.state.tabIndexEntradas + index * 6 + 4
						}
						name={`Detalles.${index}.ClaTipoDato`}
						as="select"
						className="form-control ddlOptions-noBorder">
						{tipoDato.map((obj, index) => (
							<option className="ddlOptions" key={index} value={obj.ClaTipoDato}>
								{obj.NomTipoDato}
							</option>
						))}
					</Field>
				</td>
				<td>
					<Button
						onClick={() => {
							arrayHelpers.remove(index);
						}}
						tabIndex={
							this.state.activeTab === "salidas"
								? this.state.tabIndexSalidas + index * 6 + 5
								: this.state.tabIndexEntradas + index * 6 + 5
						}
						name={`Detalles.${index}.Remove`}
						className="btn-icon btn-link"
						color={item.BajaLogica === 0 ? "danger" : "info"}
						size="sm">
						<i className={item.BajaLogica === 1 ? "fa fa-plus" : "fa fa-times"} />
					</Button>
				</td>
			</tr>
		);
	}

	renderReglas(formikProps, arrayHelpers, item, index) {
		return (
			<tr key={index}>
				<td className="w-25">
					<Field
						tabIndex={this.state.tabIndexReglas + index * 6 + 1}
						name={`ReglasNegocio.${index}.NomRegla`}
						className="form-control"
						innerRef={(ref) => this.nodesReglas.set(index, ref)}
					/>
				</td>
				<td className="w-40">
					<Field
						tabIndex={this.state.tabIndexReglas + index * 6 + 2}
						name={`ReglasNegocio.${index}.Descripcion`}
						className="form-control"
						//validate={this.validaReglas}
					/>
				</td>
				<td className="w-40">
					<Field
						tabIndex={this.state.tabIndexReglas + index * 6 + 3}
						name={`ReglasNegocio.${index}.DescripcionIngles`}
						className="form-control"
						//validate={this.validaReglas}
					/>
				</td>
				<td>
					<Button
						tabIndex={this.state.tabIndexReglas + index * 6 + 4}
						onClick={() => {
							let oX = formikProps.values.ReglasNegocio[index];
							oX.BajaLogica = oX.BajaLogica === 1 ? 0 : 1;

							//arrayHelpers.splice(index, 1);
							arrayHelpers.remove(index);
						}}
						className="btn-icon btn-link"
						color={item.BajaLogica === 0 ? "danger" : "info"}
						size="sm">
						<i className={item.BajaLogica === 1 ? "fa fa-plus" : "fa fa-times"} />
					</Button>
				</td>
			</tr>
		);
	}

	renderDetallesHeader(arrayHelpers, formikProps, claTipoDato, tooltip, btnTarget) {
		return (
			<tr>
				<UncontrolledTooltip placement="right" target={claTipoDato === 2 ? `helpNombreS` : `helpNombreE`}>
					<span>
						{claTipoDato === 2
							? translate("EntidadMtto.ttNombreSalida")
							: translate("EntidadMtto.ttNombreEntrada")}
					</span>
				</UncontrolledTooltip>
				<th className="w-25">
					{translate("EntidadMtto.colNombre")} &nbsp;
					<span id={claTipoDato === 2 ? "helpNombreS" : "helpNombreE"}>
						<i className="far fa-question-circle fa-lg"></i>
					</span>
				</th>
				<UncontrolledTooltip placement="right" target={claTipoDato === 2 ? `helpDescripcionS` : `helpDescripcionE`}>
					<span>
						{claTipoDato === 2
							? translate("EntidadMtto.ttDescripcionSalida")
							: translate("EntidadMtto.ttDescripcionEntrada")}
					</span>
				</UncontrolledTooltip>

				<th className="w-30">
					{translate("EntidadMtto.lblDescripcion")} &nbsp;
					<span id={claTipoDato === 2 ? "helpDescripcionS" : "helpDescripcionE"}>
						<i className="far fa-question-circle fa-lg"></i>
					</span>
				</th>

				<UncontrolledTooltip
					placement="right"
					target={claTipoDato === 2 ? `helpDescripcionSE` : `helpDescripcionEE`}>
					<span>
						{claTipoDato === 2
							? translate("EntidadMtto.ttDescripcionSalidaIngles")
							: translate("EntidadMtto.ttDescripcionEntradaIngles")}
					</span>
				</UncontrolledTooltip>

				<th className="w-30">
					{translate("EntidadMtto.lblDescripcionIngles")} &nbsp;
					<span id={claTipoDato === 2 ? "helpDescripcionSE" : "helpDescripcionEE"}>
						<i className="far fa-question-circle fa-lg"></i>
					</span>
				</th>
				<UncontrolledTooltip placement="right" target={claTipoDato === 2 ? `helpTipoDatoS` : `helpTipoDatoE`}>
					<span>
						{claTipoDato === 1 ? translate("EntidadMtto.ttTipoEntrada") : translate("EntidadMtto.ttTipoSalida")}
					</span>
				</UncontrolledTooltip>
				<th className="w-15">
					{translate("EntidadMtto.colTipo")} &nbsp;
					<span id={claTipoDato === 2 ? "helpTipoDatoS" : "helpTipoDatoE"}>
						<i className="far fa-question-circle fa-lg"></i>
					</span>
				</th>
				<th>
					<UncontrolledTooltip target={btnTarget}>{tooltip}</UncontrolledTooltip>
					<Button
						id={btnTarget}
						tabIndex={btnTarget === "btnAgregarSalidas" ? agregarSalidasIndex : agregarEntradasIndex}
						/*onKeyPress={this.handleButtonClick}*/
						onClick={() => {
							arrayHelpers.push({
								IdEntidad: formikProps.values.IdEntidad,
								IdEntidadDet: formikProps.values.Detalles.length + 2,
								NomEntidadDet: "",
								Descripcion: "",
								ClaTipoEntidadDet: claTipoDato,
								ClaTipoDato: 1,
								ClaProducto: getProducto(),
								IdGrupo: claTipoDato === 2 ? this.state.selectedTab : 0,
								FechaIns: new Date(),
								BajaLogica: 0,
							});
							if (btnTarget === "btnAgregarSalidas") {
								this.setState({
									tabIndexSalidas: this.state.tabIndexSalidas + 6,
									counterIndex: this.state.counterIndex + 1,
									newRow: true,
								});
							} else {
								this.setState({
									tabIndexEntradas: this.state.tabIndexEntradas + 6,
									tabIndexSalidas: this.state.tabIndexSalidas + 6,
									counterIndex: this.state.counterIndex + 1,
									newRow: true,
								});
							}

							agregarSalidasIndex += (this.state.counterIndex + 1) * 6;
							agregarReglasIndex += (this.state.counterIndex + 1) * 6;
							agregarEntradasIndex += btnTarget === "btnAgregarSalidas" ? 0 : (this.state.counterIndex + 1) * 6;
							this.setState({
								tabIndexReglas: agregarSalidasIndex + 3,
							});
						}}
						className="btn-icon btn-link"
						color="info"
						innerRef={(ref) => this.nodesSalidas.set(this.state.counterIndex, ref)}
						size="sm">
						<i className="fa fa-plus" />
					</Button>
				</th>
			</tr>
		);
	}

	renderDetalles(formikProps, arrayHelpers, claTipoEntidadDet, verBajaLogica) {
		let values = [];
		if (formikProps.values.Detalles) {
			formikProps.values.Detalles.forEach((item, index) => {
				if (
					item.ClaTipoEntidadDet === claTipoEntidadDet &&
					(item.BajaLogica === 0 || verBajaLogica) &&
					item.IdGrupo === this.state.selectedTab
				) {
					if (this.props.readOnly) {
						values.push(this.renderDetallesInfo(item, index));
					} else {
						values.push(this.renderDetallesRow(formikProps, arrayHelpers, item, index));
					}
				}
			});
		} else {
			return this.renderNoInfo();
		}

		return values;
	}

	renderNoInfo() {
		return (
			<tr>
				<td colSpan={3} className="text-center">
					{translate("EntidadMtto.noInfo")}
				</td>
			</tr>
		);
	}

	renderDetTexto(nomControl) {
		let detalle = formularioDet.filter((detalle) => detalle.NomControl === nomControl);
		if (detalle[0]) return detalle[0].Texto;
		else return translate("EntidadMtto.lblNoDescripcion");
	}

	/**
	 * BATERIA DE PRUEBAS
	 */

	/**
	 *
	 * @param {*} arrayHelpers
	 * @param {*} formikProps
	 * @param {*} claTipoDato
	 * @param {*} tooltip
	 * @param {*} btnTarget
	 */
	renderDetallesHeaderParams(arrayHelpers, formikProps, esEntrada, tooltip, btnTarget) {
		return (
			<tr tabIndex="51">
				<th className="w-25">
					Nombre &nbsp;
					<span id={esEntrada === 1 ? "helpNombreE" : "helpNombreS"}>
						<i className="far fa-question-circle fa-lg"></i>
					</span>
				</th>
				<th className="w-25">
					Tipo &nbsp;
					<span id={esEntrada === 1 ? "helpTipoDatoE" : "helpTipoDatoS"}>
						<i className="far fa-question-circle fa-lg"></i>
					</span>
				</th>

				<UncontrolledTooltip placement="right" target={esEntrada === 1 ? `helpDatoE` : `helpDatoS`}>
					<span>{esEntrada === 1 ? this.renderDetTexto("hdrDatoE") : this.renderDetTexto("hdrDatoS")}</span>
				</UncontrolledTooltip>
				<th className="w-45">
					Valor &nbsp;
					<span id={esEntrada === 1 ? "helpDatoE" : "helpDatoS"}>
						<i className="far fa-question-circle fa-lg"></i>
					</span>
				</th>
				<th tabIndex="51">
					<UncontrolledTooltip target={btnTarget}>{tooltip}</UncontrolledTooltip>
					<Button
						tabIndex="51"
						id={btnTarget}
						onClick={() => {
							arrayHelpers.push({
								IdEntidad: formikProps.values.IdEntidad,
								IdPrueba: formikProps.values.Pruebas[0].IdPrueba,
								IdPruebaDet: formikProps.values.Pruebas[0].DetallesParametros.length + 1,
								NomParametro: "",
								Valor: "",
								ClaTipoDato: 56,
								ClaProducto: getProducto(),
								FechaIns: new Date(),
								BajaLogica: 0,
								EsEntrada: esEntrada,
							});
						}}
						className="btn-icon btn-link"
						color="info"
						size="sm">
						<i className="fa fa-plus" />
					</Button>
				</th>
			</tr>
		);
	}

	/**
	 *
	 * @param {JSON} formikProps Propiedades
	 * @param {JSON} arrayHelpers Propiedades
	 */
	renderDetallesParams(formikProps, arrayHelpers, esEntrada) {
		let values = [];

		if (formikProps.values.Pruebas[0].DetallesParametros.length > 0) {
			formikProps.values.Pruebas[0].DetallesParametros.forEach((item, index) => {
				if (item.EsEntrada === esEntrada) {
					if (this.props.readOnly) {
						values.push(this.renderDetallesInfoParams(item, index));
					} else {
						values.push(this.renderDetallesRowParams(formikProps, arrayHelpers, item, index));
					}
				}
			});
		} else {
			return this.renderNoInfo();
		}

		return values;
	}

	/**
	 *
	 * @param {JSON} item Record traido desde la base de datos
	 * @param {JSON} index  Indice
	 */
	renderDetallesInfoParams(item, index) {
		return (
			<tr key={index}>
				<td
					className="pl-3 pr-4 pt-3 pb-0"
					style={{
						borderTop: "1px solid #2b3553",
						borderBottom: "1px solid #2b3553",
					}}>
					<div className="row">
						<h5>
							{item.NomParametro + "   "}
							{item.NomTipoDato ? <Badge color="info">{`${item.NomTipoDato}`}</Badge> : null}
						</h5>
					</div>
					<div className="row pl-3" style={{ wordWrap: "break-word" }}>
						{item.Valor}
					</div>
				</td>
			</tr>
		);
	}

	/**
	 *
	 * @param {*} formikProps
	 * @param {*} arrayHelpers
	 * @param {*} item
	 * @param {*} index
	 */
	renderDetallesRowParams(formikProps, arrayHelpers, item, index) {
		return (
			<tr key={index}>
				<td className="w-25">
					<Field name={`Pruebas[0].DetallesParametros.${index}.NomParametro`} className="form-control" />
				</td>
				<td className="w-15">
					<Field
						name={`Pruebas[0].DetallesParametros.${index}.ClaTipoDato`}
						as="select"
						className="form-control ddlOptions-noBorder">
						<option className="ddlOptions" key={index + 1} value={0}>
							SELECCIONE
						</option>
						{tipoDato.map((obj, index) => (
							<option className="ddlOptions" key={index + 1} value={obj.ClaTipoDato}>
								{obj.NomTipoDato}
							</option>
						))}
					</Field>
				</td>
				<td className="w-15">
					<Field name={`Pruebas[0].DetallesParametros.${index}.Valor`} className="form-control" />
				</td>
				<td>
					<Button
						ref={this.myRef}
						onClick={() => {
							arrayHelpers.remove(index);
						}}
						className="btn-icon btn-link"
						color={item.BajaLogica === 0 ? "danger" : "info"}
						size="sm">
						<i className={item.BajaLogica === 1 ? "fa fa-plus" : "fa fa-times"} />
					</Button>
				</td>
			</tr>
		);
	}

	/**
	 *
	 * @param {*} nomControl
	 */
	renderDetTextoParams(nomControl) {
		let detalle = formularioDet.filter((detalle) => detalle.NomControl === nomControl);
		if (detalle[0]) return detalle[0].Texto;
		else return translate("EntidadMtto.lblNoDescripcion");
	}

	// with this function we change the active tab for all the tabs in this page
	changeActiveTab(e, tabState, tadName) {
		e.preventDefault();
		this.setState({
			[tabState]: tadName,
		});
	}

	render() {
		const NAVITEMS = [
			{
				Name: "general",
				Title: translate("EntidadMtto.navGeneral"),
				Id: 0,
				Index: 1,
			},
			{
				Name: "entradas",
				Title: translate("EntidadMtto.navEntradas"),
				Id: 1,
				Index: 50,
			},
			{
				Name: "salidas",
				Title: translate("EntidadMtto.navSalidas"),
				Id: 2,
				Index: agregarEntradasIndex + 1, //this.state.tabIndexEntradas + this.state.counterIndex * 6 + 7,
			},
			{
				Name: "reglas",
				Title: translate("EntidadMtto.navReglas"),
				Id: 3,
				Index: agregarSalidasIndex + 2,
			},
			{
				Name: "ejemplo",
				Title: translate("EntidadMtto.navEjemplo"),
				Id: 4,
				Index: -1,
			},
			{
				Name: "resumen",
				Title: translate("EntidadMtto.navResumen"),
				Id: 5,
				Index: -1,
			},
			{
				Name: "suscritos",
				Title: translate("EntidadMtto.navSuscritos"),
				Id: 6,
			},
			// {
			// 	Name: "pruebas",
			// 	Title: "Pruebas",
			// 	Id: 6,
			// },
		];

		const TabContentSalidas = (props) => {
			const { children, show } = props;

			return show ? <div className="tabContent">{children}</div> : null;
		};
		if (!this.state.isLoaded) {
			return (
				<div className="spinner-border text-info" style={{ backgroundColor: "transparent" }} role="status">
					<span className="sr-only">Cargando...</span>
				</div>
			);
		}

		return (
			<>
				<NotificationAlert ref={(ref) => (this.notify = ref)} />
				{this.state.showAlert}
				{this.state.showConfirm}
				{this.state.showAlertInput}
				<div className="content">
					<Formik
						enableReinitialize={true}
						initialValues={{
							...this.state.entidad,
						}}
						onSubmit={this.btnGuardarClick}>
						{(formikProps) => (
							<Form onSubmit={formikProps.handleSubmit} key="frmEntidadMtto">
								<Card style={{ height: "600px" }}>
									<CardHeader>
										<Row>
											<Col md={12}>
												<CardTitle>
													<Row>
														<Col md={8}>
															<h2>
																{this.state.entidad.IdEntidad <= 0
																	? translate("EntidadMtto.titulo")
																	: `${this.state.entidad.NomProducto} - ${
																			this.state.entidad.NomEntidad === ""
																				? translate("EntidadMtto.ttTituloAux")
																				: this.state.entidad.NomEntidad
																	  }`}
															</h2>
														</Col>
														<Col md={4} className="text-right">
															{getLoginInfo().ClaRol === 5 &&
																this.state.isOwner === true &&
																this.state.entidad.EsVerificado === 1 &&
																this.state.entidad.IdEntidad > 0 && (
																	<span>
																		{this.state.isRendered && (
																			<TourVirtual PaginaID="2" ClaTour={Tours.Publicar} />
																		)}
																		<UncontrolledTooltip target={`btnPublicar`}>
																			{translate("EntidadMtto.ttPublicar")}
																		</UncontrolledTooltip>
																		<Button
																			id={`btnPublicar`}
																			color="info"
																			size="sm"
																			className="animation-on-hover btn-simple "
																			hidden={
																				this.state.entidad.IdEntidad <= 0 || !this.state.isOwner
																			}
																			de
																			onClick={() => {
																				this.showConfirm(
																					() => this.btnPublicarClick(formikProps),
																					() => this.hideAlert(),
																					translate("EntidadMtto.alertPublicar"),
																					translate("EntidadMtto.alertPublicarDescripcion"),
																				);
																			}}>
																			<i className="fa fa-upload" />
																			{translate("EntidadMtto.btnPublicar")}
																		</Button>
																	</span>
																)}

															<UncontrolledTooltip target={`btnJson`}>
																{translate("EntidadMtto.ttExportar")}
															</UncontrolledTooltip>
															<Button
																id={`btnJson`}
																className="animation-on-hover btn-simple"
																color="info"
																size="sm"
																hidden={formikProps.values.IdEntidad <= 0 || !this.state.isOwner}
																onClick={() => {
																	this.btnDescargarClick(formikProps.values);
																}}>
																<i className="fas fa-cloud-download-alt" />
																{translate("EntidadMtto.btnExportar")}
															</Button>
														</Col>
													</Row>
												</CardTitle>
											</Col>
										</Row>
										{this.state.entidad.EsVerificado === 0 && this.state.entidad.IdEntidad > 0 && (
											<Row className="hdrEsVerificado">
												<Col md={6} className="align-self-center">
													<i className="fas fa-exclamation-triangle" /> &nbsp;{" "}
													{translate("EntidadMtto.lblNoVerificado")}
												</Col>
												{getLoginInfo().ClaRol === 5 && this.state.isOwner === true && (
													<Col
														md={6}
														style={{
															textAlign: "right",
															paddingRight: "5%",
														}}>
														{translate("EntidadMtto.lblDeseaAprobar")} &nbsp;
														<UncontrolledTooltip target="btnVerificar">
															{translate("EntidadMtto.lblVerificar")}
														</UncontrolledTooltip>
														<Button
															id="btnVerificar"
															onClick={() =>
																this.showConfirm(
																	() => this.aprobarVerificacion(),
																	() => this.hideAlert(),
																	translate("EntidadMtto.lblVerificar"),
																	translate("EntidadMtto.alertVerificarDescripcion"),
																)
															}
															className="btn-icon"
															color="info"
															size="sm">
															<i className="fa fa-check" />
														</Button>
														&nbsp;&nbsp;
														<UncontrolledTooltip target="btnRechazar">
															{translate("EntidadMtto.ttRechazar")}
														</UncontrolledTooltip>
														<Button
															id="btnRechazar"
															onClick={() =>
																this.showAlertInput(
																	translate("EntidadMtto.alertRechazo"),
																	translate("EntidadMtto.alertRechazoDescripcion"),
																	translate("EntidadMtto.alertRechazoMotivo"),
																)
															}
															className="btn-icon"
															color="warning"
															size="sm">
															<i className="fa fa-times" />
														</Button>
													</Col>
												)}
											</Row>
										)}
									</CardHeader>

									<CardBody className="entidadCardBody">
										<Row style={{ alignItems: "start" }}>
											<Col md={2} xs={2}>
												<Nav pills className="nav-pills-info flex-column highlight">
													{NAVITEMS.map((item) => {
														return this.renderNavItem(item);
													})}
												</Nav>
											</Col>

											<Col md={10} xs={10}>
												<TabContent activeTab={this.state.activeTab} className="tab-space">
													<TabPane tabId="general" className="tbody">
														<FormGroup row>
															<Col md={3}>
																<UncontrolledTooltip placement="right" target={`txtClaTipoEntidad`}>
																	<span>{translate("EntidadMtto.ttTipo")}</span>
																</UncontrolledTooltip>
																<Label for="ClaTipoEntidad">{translate("EntidadMtto.lblTipo")}</Label>
																<Field
																	id="txtClaTipoEntidad"
																	tabIndex="2"
																	name="ClaTipoEntidad"
																	as="select"
																	type="input"
																	className="form-control"
																	disabled={this.props.readOnly}
																	onChange={(event) => {
																		let entidad = formikProps.values;
																		entidad.ClaTipoEntidad = event.target.value;

																		this.setState({
																			entidad: entidad,
																		});
																	}}>
																	{tipo &&
																		tipo.length > 0 &&
																		tipo.map((obj, index) => (
																			<option
																				className="ddlOptions"
																				key={index}
																				value={obj.ClaTipoEntidad}>
																				{obj.NomTipoEntidad}
																			</option>
																		))}
																</Field>
															</Col>

															<Col
																md={2}
																hidden={
																	parseInt(formikProps.values.ClaTipoEntidad) === 7 ||
																	parseInt(formikProps.values.ClaTipoEntidad) === 6
																}>
																<UncontrolledTooltip placement="right" target={`Esquema`}>
																	<span>{translate("EntidadMtto.ttEsquema")}</span>
																</UncontrolledTooltip>
																<Label for="Esquema">{translate("EntidadMtto.lblEsquema")}</Label>
																<Field
																	id="Esquema"
																	tabIndex="3"
																	name="Esquema"
																	type="input"
																	className="form-control"
																	placeholder={translate("EntidadMtto.phEsquema")}
																	disabled={this.props.readOnly}
																/>
															</Col>

															<Col
																md={7}
																hidden={
																	parseInt(formikProps.values.ClaTipoEntidad) === 7 ||
																	parseInt(formikProps.values.ClaTipoEntidad) === 6
																}>
																<UncontrolledTooltip placement="right" target={`txtNomEntidad`}>
																	<span>{translate("EntidadMtto.ttNombre")}</span>
																</UncontrolledTooltip>
																<Label for="NomEntidad">{translate("EntidadMtto.lblNombre")}</Label>
																<Field
																	id="txtNomEntidad"
																	tabIndex="4"
																	name="NomEntidad"
																	type="input"
																	className="form-control"
																	placeholder={translate("EntidadMtto.phNombre")}
																	disabled={this.props.readOnly}
																/>
															</Col>

															<Col
																md={9}
																hidden={
																	parseInt(formikProps.values.ClaTipoEntidad) !== 7 &&
																	parseInt(formikProps.values.ClaTipoEntidad) !== 6
																}>
																<UncontrolledTooltip placement="right" target={`txtUrl`}>
																	<span>{translate("EntidadMtto.phUrl")}</span>
																</UncontrolledTooltip>
																<Label for="url">{translate("EntidadMtto.lblUrlProduccion")}</Label>
																<Field
																	id="txtUrl"
																	tabIndex="5"
																	name="Url"
																	type="input"
																	className="form-control"
																	placeholder={translate("EntidadMtto.phUrl")}
																	disabled={this.props.readOnly}
																/>
															</Col>
														</FormGroup>
														<FormGroup row>
															<Col
																md={
																	parseInt(formikProps.values.ClaTipoEntidad) !== 7 &&
																	parseInt(formikProps.values.ClaTipoEntidad) !== 6
																		? 4
																		: 3
																}>
																<UncontrolledTooltip placement="right" target={`txtTitulo`}>
																	<span>{translate("EntidadMtto.ttTitulo")}</span>
																</UncontrolledTooltip>
																<Label for="Titulo">{translate("EntidadMtto.lblTitulo")}</Label>
																<Field
																	id="txtTitulo"
																	tabIndex="6"
																	name="Titulo"
																	type="input"
																	className="form-control"
																	placeholder={translate("EntidadMtto.phTitulo")}
																	disabled={this.props.readOnly}
																/>
															</Col>
															<Col
																md={4}
																hidden={
																	parseInt(formikProps.values.ClaTipoEntidad) === 7 ||
																	parseInt(formikProps.values.ClaTipoEntidad) === 6
																}>
																<UncontrolledTooltip placement="right" target={`ClaServidorProduccion`}>
																	<span>{translate("EntidadMtto.ttServidorProduccion")}</span>
																</UncontrolledTooltip>
																<Label for="ClaServidorProduccion">
																	{translate("EntidadMtto.lblServidorProduccion")}
																</Label>
																<Field
																	id="ClaServidorProduccion"
																	tabIndex="7"
																	name="ClaServidorProduccion"
																	as="select"
																	type="input"
																	className="form-control"
																	disabled={this.props.readOnly}
																	onChange={(event) => {
																		let entidad = formikProps.values;
																		entidad.ClaServidorProduccion = event.target.value;

																		this.setState({
																			entidad: entidad,
																		});
																	}}>
																	<option className="ddlOptions" key={0} value={0}>
																		DLABSDB01 - KRAKEN
																	</option>
																	{servidoresProducto &&
																		servidoresProducto.map((obj, index) => {
																			if (obj.EsProductivo === 1) {
																				return (
																					<option
																						className="ddlOptions"
																						key={index}
																						value={obj.ClaServidor}>
																						{`${obj.NomServidor} - ${obj.NomBaseDatos}`}
																					</option>
																				);
																			}
																			return null;
																		})}
																</Field>
															</Col>

															<Col
																md={4}
																hidden={
																	parseInt(formikProps.values.ClaTipoEntidad) === 7 ||
																	parseInt(formikProps.values.ClaTipoEntidad) === 6
																}>
																<UncontrolledTooltip placement="right" target={`ClaServidorSandbox`}>
																	<span>{translate("EntidadMtto.ttServidorSandbox")}</span>
																</UncontrolledTooltip>
																<Label for="ClaServidorSandbox">
																	{translate("EntidadMtto.lblServidorSandbox")}
																</Label>
																<Field
																	id="ClaServidorSandbox"
																	name="ClaServidorSandbox"
																	tabIndex="8"
																	as="select"
																	type="input"
																	className="form-control"
																	disabled={this.props.readOnly}
																	onChange={(event) => {
																		let entidad = formikProps.values;
																		entidad.ClaServidorSandbox = event.target.value;

																		this.setState({
																			entidad: entidad,
																		});
																	}}>
																	<option className="ddlOptions" key={0} value={0}>
																		DLABSSANDBOX - KRAKEN
																	</option>
																	{servidoresProducto &&
																		servidoresProducto.map((obj, index) => {
																			if (obj.EsProductivo === 0) {
																				return (
																					<option
																						className="ddlOptions"
																						key={index}
																						value={obj.ClaServidor}>
																						{`${obj.NomServidor} - ${obj.NomBaseDatos}`}
																					</option>
																				);
																			}
																			return null;
																		})}
																</Field>
															</Col>
															<Col
																md={9}
																/*style={{ "margin-left": "30px" }}*/
																hidden={
																	parseInt(formikProps.values.ClaTipoEntidad) !== 7 &&
																	parseInt(formikProps.values.ClaTipoEntidad) !== 6
																}>
																<UncontrolledTooltip placement="right" target={`txtUrlS`}>
																	<span>{translate("EntidadMtto.phUrlSandbox")}</span>
																</UncontrolledTooltip>
																<Label for="url">{translate("EntidadMtto.lblUrlSandbox")}</Label>
																<Field
																	id="txtUrlS"
																	name="UrlSandbox"
																	tabIndex="9"
																	type="input"
																	className="form-control"
																	placeholder={translate("EntidadMtto.phUrlSandbox")}
																	disabled={this.props.readOnly}
																/>
															</Col>
														</FormGroup>
														<FormGroup row>
															<Col md={12}>
																<UncontrolledTooltip placement="right" target={`TerminosGlosario`}>
																	<span>{translate("EntidadMtto.ttGlosario")}</span>
																</UncontrolledTooltip>
																<Label for="TerminosGlosario">
																	{translate("EntidadMtto.lblGlosario")}
																</Label>
																<Autocomplete
																	multiple
																	disableCloseOnSelect
																	//limitTags={6}
																	id="TerminosGlosario"
																	tabIndex="10"
																	size="small"
																	disabled={this.props.readOnly}
																	onChange={(_, value) =>
																		this.setState({
																			nuevosTerminos: value,
																		})
																	}
																	options={terminosGlosario}
																	getOptionLabel={(option) => option.NomTermino}
																	getOptionSelected={(option, value) =>
																		option.ClaTermino === value.ClaTermino
																	}
																	defaultValue={
																		this.state.nuevosTerminos ? this.state.nuevosTerminos : ""
																	}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			multiline
																			name="TerminosGlosario"
																			placeholder={translate("EntidadMtto.phGlosario")}
																			variant="outlined"
																			rowsMax="6"
																		/>
																	)}
																/>
															</Col>
														</FormGroup>
														<FormGroup row>
															<Col md={12}>
																{/* <UncontrolledTooltip placement="right" target={`txtDescripcion`}>
																	<span>{translate("EntidadMtto.ttDescripcionServicio")}</span>
																</UncontrolledTooltip> */}
																{sessionStorage.getItem("ClaIdioma") === "1" ? (
																	<>
																		<Nav>
																			<NavItem>
																				<NavLink
																					id="descripcionTab"
																					tabIndex="11"
																					data-toggle="tab"
																					onKeyPress={(e) =>
																						this.changeActiveTab(e, "descripcionTab", "esp")
																					}
																					className={
																						this.state.descripcionTab === "esp"
																							? "active bold underline"
																							: ""
																					}
																					onClick={(e) =>
																						this.changeActiveTab(e, "descripcionTab", "esp")
																					}>
																					{translate("EntidadMtto.lblDescripcion")}
																				</NavLink>
																			</NavItem>
																			<NavItem>
																				<NavLink
																					id="descripcionTab"
																					tabIndex="13"
																					onKeyPress={(e) =>
																						this.changeActiveTab(e, "descripcionTab", "eng")
																					}
																					data-toggle="tab"
																					className={
																						this.state.descripcionTab === "eng"
																							? "active bold underline"
																							: ""
																					}
																					onClick={(e) =>
																						this.changeActiveTab(e, "descripcionTab", "eng")
																					}>
																					{translate("EntidadMtto.lblDescripcionIngles")}
																				</NavLink>
																			</NavItem>
																		</Nav>
																	</>
																) : (
																	<>
																		<Nav>
																			<NavItem>
																				<NavLink
																					id="descripcionTab"
																					data-toggle="tab"
																					tabIndex="13"
																					onKeyPress={(e) =>
																						this.changeActiveTab(e, "descripcionTab", "eng")
																					}
																					className={
																						this.state.descripcionTab === "eng"
																							? "active bold underline"
																							: ""
																					}
																					onClick={(e) =>
																						this.changeActiveTab(e, "descripcionTab", "eng")
																					}>
																					{translate("EntidadMtto.lblDescripcionIngles")}
																				</NavLink>
																			</NavItem>
																			<NavItem>
																				<NavLink
																					id="descripcionTab"
																					data-toggle="tab"
																					tabIndex="11"
																					onKeyPress={(e) =>
																						this.changeActiveTab(e, "descripcionTab", "esp")
																					}
																					className={
																						this.state.descripcionTab === "esp"
																							? "active bold underline"
																							: ""
																					}
																					onClick={(e) =>
																						this.changeActiveTab(e, "descripcionTab", "esp")
																					}>
																					{translate("EntidadMtto.lblDescripcion")}
																				</NavLink>
																			</NavItem>
																		</Nav>
																	</>
																)}

																<TabContent className="tab-space" activeTab={this.state.descripcionTab}>
																	<UncontrolledTooltip placement="right" target={`txtDescripcion`}>
																		<span>Descripcion</span>
																	</UncontrolledTooltip>
																	<TabPane tabId="esp">
																		<Field
																			as="textarea"
																			tabIndex="12"
																			id="txtDescripcion"
																			name="Descripcion"
																			placeholder={translate("EntidadMtto.phDescripcion")}
																			className="form-control"
																			disabled={this.props.readOnly}
																			rows={6}
																		/>
																	</TabPane>
																	<TabPane tabId="eng">
																		<Field
																			as="textarea"
																			tabIndex="14"
																			id="txtDescripcion"
																			name="DescripcionIngles"
																			placeholder={translate("EntidadMtto.phDescripcionIngles")}
																			className="form-control"
																			disabled={this.props.readOnly}
																			rows={6}
																		/>
																	</TabPane>
																</TabContent>
															</Col>
															{/*</FormGroup>
														<FormGroup row>*/}
															<Col md={6}>
																<FormGroup check>
																	<Label check>
																		<UncontrolledTooltip placement="left" target={`check`}>
																			<span>Es Privado</span>
																		</UncontrolledTooltip>
																		<Input
																			id="check"
																			type="checkbox"
																			name="EsPrivado"
																			onKeyPress={(event) => {
																				this.setState({
																					esPrivado: event.target.checked,
																				});
																			}}
																			checked={this.state.esPrivado}
																			onChange={(event) => {
																				this.setState({
																					esPrivado: event.target.checked,
																				});
																			}}
																			disabled={this.props.readOnly}
																		/>
																		{translate("EntidadMtto.lblServicioPrivado")}
																		<span className="form-check-sign">
																			<span className="check" />
																		</span>
																	</Label>
																</FormGroup>
															</Col>
														</FormGroup>
													</TabPane>

													<TabPane tabId="entradas" className="tbody">
														{/* <Row>
															<Col md={8}><h4>Parámetros de entrada para el servicio</h4></Col>
														</Row> */}
														<FieldArray
															name="Detalles"
															render={(arrayHelpers) => (
																<table className="table table-hover table-sm scroll table-striped w-100">
																	<thead hidden={this.props.readOnly}>
																		{this.renderDetallesHeader(
																			arrayHelpers,
																			formikProps,
																			1,
																			translate("EntidadMtto.ttAgregarParametro"),
																			"btnAgregarEntradas",
																		)}
																	</thead>
																	<tbody>
																		{this.renderDetalles(
																			formikProps,
																			arrayHelpers,
																			1,
																			this.state.verBajaEntradas,
																		)}
																	</tbody>

																	{formikProps.errors.Detalles &&
																		(formikProps.errors.Detalles.filter((item) => {
																			return item.Descripcion <= 1;
																		}).length > 0 ? (
																			<div style={{ marginBottom: "-100px" }}>
																				{(errorEntradas = true)}
																				{translate("EntidadMtto.errorEntradas")}
																			</div>
																		) : (
																			<div>{(errorEntradas = false)}</div>
																		))}
																</table>
															)}
														/>
													</TabPane>

													<TabPane tabId="salidas">
														<Row>
															<Col md={12}>
																<Row>
																	<Col md={8}>{/* <h4>Valores de salida del servicio</h4> */}</Col>
																</Row>
																<Row>
																	<AppBar position="static" color="default">
																		<Tabs
																			value={this.state.selectedTab}
																			onChange={this.handleTabChange}
																			indicatorColor="primary"
																			textColor="primary"
																			variant="scrollable"
																			scrollButtons="auto">
																			{this.state.tabsOptions.map((tab, index) => (
																				<Tab
																					key={`tab_${index}`}
																					label={
																						translate("EntidadMtto.lblConjunto") +
																						` ${index + 1} `
																					}
																					value={tab.value}
																					icon={
																						<span
																							className="deleteTabIcon"
																							onClick={() => this.deleteTab(index)}>
																							<i className="far fa-times-circle fa-lg" />
																						</span>
																					}
																					wrapped={false}
																					disabled={this.props.readOnly}
																				/>
																			))}
																			<Tab
																				className="addTab"
																				icon={<i className="fa fa-plus" />}
																				onClick={this.addTab}
																				disabled={this.props.readOnly}
																				tabIndex={agregarSalidasIndex + 1}
																			/>
																		</Tabs>
																	</AppBar>
																</Row>
																<Row>
																	<Col md={12}>
																		{this.state.tabsOptions.map((tab, index) => (
																			<TabContentSalidas
																				key={`contentSalidas_${index}`}
																				show={this.state.selectedTab === index}>
																				<FieldArray
																					key={`tabSalidas_${index}`}
																					name="Detalles"
																					render={(arrayHelpers) => (
																						<table className="table table-hover table-sm scroll table-striped w-100">
																							<thead hidden={this.props.readOnly}>
																								{this.renderDetallesHeader(
																									arrayHelpers,
																									formikProps,
																									2,
																									translate("EntidadMtto.ttAgregarParametro"),
																									"btnAgregarSalidas",
																								)}
																							</thead>
																							<tbody className="tbodySalidas">
																								{this.renderDetalles(
																									formikProps,
																									arrayHelpers,
																									2,
																									this.verBajaSalidas,
																								)}
																							</tbody>

																							{formikProps.errors.Detalles &&
																								(formikProps.errors.Detalles.filter((item) => {
																									return item.Descripcion === 2;
																								}).length > 0 ? (
																									<div
																										style={{
																											marginBottom: "-100px",
																										}}>
																										{translate("EntidadMtto.errorSalidas")}
																									</div>
																								) : (
																									<div></div>
																								))}
																						</table>
																					)}
																				/>
																			</TabContentSalidas>
																		))}
																	</Col>
																</Row>
															</Col>
														</Row>
													</TabPane>

													<TabPane tabId="reglas" className="tbody">
														<FieldArray
															name="ReglasNegocio"
															render={(arrayHelpers) => (
																<table className="table table-hover table-sm scroll table-striped w-100">
																	<thead hidden={this.props.readOnly}>
																		<tr>
																			<UncontrolledTooltip placement="right" target={`helpNombreR`}>
																				<span>{translate("EntidadMtto.ttNombreRegla")}</span>
																			</UncontrolledTooltip>
																			<th className="w-25">
																				{translate("EntidadMtto.colNombre")} &nbsp;
																				<span id="helpNombreR">
																					<i className="far fa-question-circle fa-lg"></i>
																				</span>
																			</th>
																			<UncontrolledTooltip
																				placement="right"
																				target={`helpDescripcionR`}>
																				<span>{translate("EntidadMtto.ttDescripcionRegla")}</span>
																			</UncontrolledTooltip>
																			<th className="w-40">
																				{translate("EntidadMtto.colDescripcion")} &nbsp;
																				<span id="helpDescripcionR">
																					<i className="far fa-question-circle fa-lg"></i>
																				</span>
																			</th>
																			<UncontrolledTooltip
																				placement="right"
																				target={`helpDescripcionR`}>
																				<span>
																					{translate("EntidadMtto.ttDescripcionReglaIngles")}
																				</span>
																			</UncontrolledTooltip>
																			<th className="w-40">
																				{translate("EntidadMtto.colDescripcionIngles")} &nbsp;
																				<span id="helpDescripcionR">
																					<i className="far fa-question-circle fa-lg"></i>
																				</span>
																			</th>
																			<th>
																				<UncontrolledTooltip target="btnAgregarReglas">
																					{translate("EntidadMtto.ttAgregarRegla")}
																				</UncontrolledTooltip>
																				<Button
																					id="btnAgregarReglas"
																					tabIndex={agregarReglasIndex}
																					onClick={() => {
																						arrayHelpers.push({
																							IdEntidad: formikProps.values.IdEntidad,
																							IdRegla:
																								formikProps.values.ReglasNegocio.length + 1,
																							NomRegla: "",
																							Descripcion: "",
																							ClaProducto: getProducto(),
																							BajaLogica: 0,
																						});
																						this.setState({
																							tabIndexReglas: this.state.tabIndexReglas + 6,
																							counterIndex: this.state.counterIndex + 1,
																							newRow: true,
																						});
																						agregarReglasIndex +=
																							(this.state.counterIndex + 1) * 6;
																					}}
																					className="btn-icon btn-link"
																					color="info"
																					/*innerRef={(ref) =>
																						this.nodesReglas.set(this.state.counterIndex, ref)
																					}*/
																					size="sm">
																					<i className="fa fa-plus" />
																				</Button>
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		{formikProps.values.ReglasNegocio &&
																		formikProps.values.ReglasNegocio.length > 0
																			? formikProps.values.ReglasNegocio.map(
																					(obj, index) =>
																						(obj.BajaLogica === 0 || this.state.verBajaReglas) &&
																						(this.props.readOnly
																							? this.renderDetallesInfo(obj, index)
																							: this.renderReglas(
																									formikProps,
																									arrayHelpers,
																									obj,
																									index,
																							  )),
																			  )
																			: this.renderNoInfo()}
																	</tbody>
																</table>
															)}
														/>
													</TabPane>

													<TabPane tabId="ejemplo" className="tbody">
														{this.props.idEntidad > 0 && (
															<EntidadEjemplo
																openTour={this.state.openTour}
																entidad={formikProps.values}
															/>
														)}
													</TabPane>

													<TabPane tabId="resumen" className="tbody">
														<Dependencias entidad={this.state.entidad} />
													</TabPane>
													<TabPane tabId="suscritos" className="tbody">
														<Suscritos IdEntidad={this.props.idEntidad} />
													</TabPane>
												</TabContent>
											</Col>
										</Row>
									</CardBody>

									<CardFooter>
										<Row>
											<Col md={12} className="text-right">
												<UncontrolledTooltip placement="left" target={`btnGuardar`}>
													<span>Guardar</span>
												</UncontrolledTooltip>
												<Button
													id="btnGuardar"
													tabIndex={agregarReglasIndex + 1}
													className={`animation-on-hover`}
													color="success"
													size="sm"
													type="submit"
													hidden={!this.state.isOwner || this.props.readOnly}>
													{translate("EntidadMtto.btnGuardar")}
												</Button>
												<UncontrolledTooltip placement="right" target={`btnCancelar`}>
													<span>Cancelar</span>
												</UncontrolledTooltip>
												{this.state.esGuardado ? (
													<Button
														id="btnCancelar"
														className="animation-on-hover"
														color="default"
														tabIndex={agregarReglasIndex + 2}
														size="sm"
														onClick={() => {
															writeLog(
																Formularios.EntidadMtto.ClaFuncionalidad,
																Formularios.EntidadMtto.Funcionalidades.btnCancelar,
															);
															this.btnCancelarClick(false);
														}}>
														{translate("EntidadMtto.btnCerrar")}
													</Button>
												) : (
													<Button
														id="btnCancelar"
														tabIndex={agregarReglasIndex + 3}
														className="animation-on-hover"
														color={this.props.readOnly ? "default" : "danger"}
														size="sm"
														onClick={() => {
															writeLog(
																Formularios.EntidadMtto.ClaFuncionalidad,
																Formularios.EntidadMtto.Funcionalidades.btnCancelar,
															);
															this.btnCancelarClick(false);
														}}>
														{this.props.readOnly
															? translate("EntidadMtto.btnCerrar")
															: translate("EntidadMtto.btnCancelar")}
													</Button>
												)}
											</Col>
										</Row>
									</CardFooter>
								</Card>
							</Form>
						)}
					</Formik>
				</div>
			</>
		);
	}
}

EntidadMtto.propTypes = {
	idEntidad: PropTypes.number,
	claProducto: PropTypes.number,
	peticionCallBack: PropTypes.any,
	objPeticion: PropTypes.any,
	hideModal: PropTypes.func,
	updateData: PropTypes.any,
	readOnly: PropTypes.bool,
	children: PropTypes.any,
	isClone: PropTypes.any,
	isSubscribed: PropTypes.any,
};
