import React from "react";
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Label, UncontrolledTooltip, Button, Modal } from "reactstrap";
import Datetime from "react-datetime";
import IndicadoresGenerales from "./IndicadoresGenerales";
import IndicadoresEntidad from "./IndicadoresEntidad";
import Errores from "./Errores";
import ServiciosMasUtilizados from "./ServiciosMasUtilizados";
import { getProducto, writeLog } from "utils/Utilerias";
import ServiciosMayorTiempo from "./ServiciosMayorTiempo";
import Peticiones from "./Peticiones";
import DesempenoEquipoProducto from "./DesempenoEquipoProducto";

import TourVirtual from "components/Tour/TourVirtual";
import { Tours } from "../../utils/Config";

import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";

const dHoy = new Date(),
	d6DiasAntes = new Date(dHoy.getFullYear(), dHoy.getMonth(), dHoy.getDate() - 6);
export default class DashboardCliente extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			claCliente: parseInt(getProducto()),
			nomProducto: "",
			nomEntidad: "",
			fechaDet: undefined,
			fechaInicio: d6DiasAntes,
			fechaFin: dHoy,
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		};
		this.chartRef = React.createRef();
		this.filtrarProveedor = this.filtrarProveedor.bind(this);
		this.filtrarProducto = this.filtrarProducto.bind(this);
		this.hideModalEntidad = this.hideModalEntidad.bind(this);
		this.clear = this.clear.bind(this);
		this.clearProducto = this.clear.bind(this);
		this.handleFechaFin = this.handleFechaFin.bind(this);
		this.handleFechaInicio = this.handleFechaInicio.bind(this);
	}

	clear() {
		this.setState({
			fechaInicio: d6DiasAntes,
			fechaFin: dHoy,
			nomProducto: "",
		});
	}

	clearProducto() {
		this.setState({
			nomProducto: "",
		});
	}

	filtrarProducto(nomProducto = "") {
		this.setState({ nomProducto: nomProducto });
	}

	filtrarProveedor(claProducto = 0) {
		this.setState({ claProducto: claProducto });
	}

	hideModalEntidad(hide = true, nomEntidad = "", fecha = undefined) {
		this.setState({
			nomEntidad: nomEntidad,
			fechaDet: fecha,
			showModal: !hide,
		});
	}

	handleFechaInicio(date) {
		let fechaFinal = this.state.fechaFin !== undefined ? this.state.fechaFin : dHoy;
		//let fechaInicial = this.state.fechaInicio !== undefined ? this.state.fechaInicio : d6DiasAntes;
		let d15DiasAntes = new Date(fechaFinal.getFullYear(), fechaFinal.getMonth(), fechaFinal.getDate() - 15);
		let valid = true;
		if (date._d < d15DiasAntes) {
			date = d15DiasAntes;
			valid = false;
		}
		if (date._d > fechaFinal) {
			date = fechaFinal;
			valid = false;
		}
		this.setState({ fechaInicio: valid ? date._d : date });
	}

	handleFechaFin(date) {
		let fechaFinal = this.state.fechaFin !== undefined ? this.state.fechaFin : dHoy;
		let fechaInicial = this.state.fechaInicio !== undefined ? this.state.fechaInicio : d6DiasAntes;
		let d15DiasDespues = new Date(fechaFinal.getFullYear(), fechaFinal.getMonth(), fechaFinal.getDate() + 15);
		let valid = true;
		if (date._d > dHoy) {
			date = dHoy;
			valid = false;
		} else if (date._d > d15DiasDespues) {
			date = d15DiasDespues;
			valid = false;
		}
		if (date._d < fechaInicial) {
			date = fechaInicial;
			valid = false;
		}
		this.setState({ fechaFin: valid ? date._d : date });
	}

	render() {
		return (
			<div className="content">
				<TourVirtual PaginaID="21" ClaTour={Tours.DashboardCliente} />
				<Row>
					<Col md={6}>
						<h1>{translate("DashboardCliente.titulo")}</h1>
					</Col>
				</Row>
				<Row>
					<div className="float-left w-100" style={{ paddingRight: "10px", paddingBottom: "10px" }}>
						<Card>
							<CardBody>
								<IndicadoresGenerales
									claCliente={this.state.claCliente}
									filtrarCliente={this.filtrarProveedor}
								/>
							</CardBody>
						</Card>
					</div>
				</Row>
				<Row>
					<div className="float-left w-100" style={{ paddingRight: "10px", paddingBottom: "10px" }}>
						<Card>
							<CardHeader>
								<FormGroup row>
									<Col md={3}>
										<Label for="fechaInicio">{translate("DashboardCliente.lblFechaDesde")}</Label>
										<Datetime
											name="fechaInicio"
											inputProps={{
												placeholder: "Fecha inicial",
											}}
											value={this.state.fechaInicio}
											onChange={this.handleFechaInicio}
											timeFormat={false}
											locale="es"
											id="fechaInicio"
										/>
									</Col>
									<Col md={3}>
										<Label for="fechaFin">{translate("DashboardCliente.lblFechaHasta")}</Label>
										<Datetime
											inputProps={{ placeholder: "Fecha final" }}
											id="fechaFin"
											name="fechaFin"
											value={this.state.fechaFin}
											onChange={this.handleFechaFin}
											timeFormat={false}
											locale="es"
										/>
									</Col>
									<Col md={6} className="text-right">
										<UncontrolledTooltip placement="left" target={`btnLimpiar`}>
											{translate("DashboardCliente.ttLimpiar")}
										</UncontrolledTooltip>
										<Button
											onClick={() => {
												writeLog(
													Formularios.DashboardCliente.ClaFuncionalidad,
													Formularios.DashboardCliente.Funcionalidades.btnLimpiar,
												);
												this.clear();
											}}
											id="btnLimpiar"
											color="info"
											size="sm"
											className="btn-simple btn-icon">
											<i className="fas fa-eraser" />
										</Button>
									</Col>
								</FormGroup>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md={3}></Col>
									<Col md={9}>
										<DesempenoEquipoProducto
											claCliente={this.state.claCliente}
											nomProducto={this.state.nomProducto}
											fechaInicio={this.state.fechaInicio}
											fechaFin={this.state.fechaFin}
										/>
									</Col>
								</Row>
								<Row>
									<Col md={3}>
										<h2>{translate("DashboardCliente.lblPeticionesPorProveedor")}</h2>
										<Peticiones claCliente={this.state.claCliente} filtrarProducto={this.filtrarProducto} />
									</Col>
									<Col md={9}>
										<h2>{translate("DashboardCliente.lblErroresTitulo")}</h2>
										<Errores
											claCliente={this.state.claCliente}
											nomProducto={this.state.nomProducto}
											fechaInicio={this.state.fechaInicio}
											fechaFin={this.state.fechaFin}
											hideModalEntidad={this.hideModalEntidad}
										/>
									</Col>
								</Row>

								<h2>{translate("DashboardCliente.lblTop5MayorTiempo")}</h2>
								<Row className="pb-4">
									<Col>
										<ServiciosMayorTiempo
											claCliente={this.state.claCliente}
											nomProducto={this.state.nomProducto}
											fechaInicio={this.state.fechaInicio}
											fechaFin={this.state.fechaFin}
											hideModalEntidad={this.hideModalEntidad}
										/>
									</Col>
								</Row>

								<h2>{translate("DashboardCliente.lblTop5MasUtilizados")}</h2>
								<Row className="pb-4">
									<Col>
										<ServiciosMasUtilizados
											claCliente={this.state.claCliente}
											nomProducto={this.state.nomProducto}
											fechaInicio={this.state.fechaInicio}
											fechaFin={this.state.fechaFin}
											hideModalEntidad={this.hideModalEntidad}
										/>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</div>
				</Row>

				<Modal centered isOpen={this.state.showModal} size="lg">
					<IndicadoresEntidad
						nomProducto={this.state.nomProducto}
						claCliente={this.state.claCliente}
						nomEntidad={this.state.nomEntidad}
						fechaDet={this.state.fechaDet}
						hideModalEntidad={this.hideModalEntidad}
					/>
				</Modal>
			</div>
		);
	}
}
