import React from "react";
import { callApi } from "utils/Utilerias";
import { Config } from "utils/Config";
import ReplicaMtto from "./ReplicaMtto";
import TablaReplicaMtto from "./TablaReplicaMtto";
import {
	Button,
	Card,
	CardHeader,
	UncontrolledTooltip,
	CardBody,
	CardTitle,
	Row,
	Col,
	Modal,
	Label,
	FormGroup,
	Form,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { Grid, PagingPanel, Table, TableHeaderRow } from "@devexpress/dx-react-grid-bootstrap4";
import { IntegratedPaging, IntegratedSorting, PagingState, SortingState } from "@devexpress/dx-react-grid";
import { Formik, Field } from "formik";
import { tableComponent, headerComponent, gvConfig } from "utils/gridConfig";
import TourVirtual from "components/Tour/TourVirtual";
import { Tours } from "../../utils/Config";
import { writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";
let srcData = [];

export default class Replicas extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			claIdioma: sessionStorage.getItem("ClaIdioma"),
			isLoaded: false,
			showModal: false,
			alert: null,
			rows: [],
			columns: [
				{ name: "esquema", title: "Esquema" },
				{ name: "nombreTabla", title: "Nombre Tabla" },
				{ name: "nombreServidor", title: "Nombre Servidor" },
				{ name: "descripcion", title: "Detalle" },
				{ name: "tipo", title: "Tipo" },
				{ name: "Actions", title: " " },
			],
			tableColumnExtensions: [
				{ columnName: "esquema", width: "10%" },
				{ columnName: "nombreTabla", width: "20%" },
				{ columnName: "descripcion", width: "auto" },
				{ columnName: "nombreServidor", width: "20%" },
				{ columnName: "tipo", width: "7%" },
				{ columnName: "Actions", width: "3%", align: "center" },
			],
			currentPage: 0,
			sorting: [{ columnName: "nombreTabla", direction: "asc" }],
			pageSize: 20,
			pageSizes: [5, 10, 20, 50],
			totalCount: 0,
			activeDB: 0,
			esquema: "",
			nomTabla: "",
			nomServidor: "",
			nomBaseDatos: "",
		};

		this.hideModal = this.hideModal.bind(this);
		this.hideModalAgregar = this.hideModalAgregar.bind(this);
		this.loadTable = this.loadTable.bind(this);
		this.btnBuscarClick = this.btnBuscarClick.bind(this);
		this.clear = this.clear.bind(this);
	}

	componentDidMount() {
		this.loadTable();
	}

	changePageSize(value) {
		const totalPages = Math.ceil(this.state.totalCount / value);
		const updatedCurrentPage = Math.min(this.state.currentPage, totalPages - 1);

		this.setState({
			pageSize: value,
			currentPage: updatedCurrentPage,
		});
	}

	handleDBChange(claBD) {
		if (this.state.activeDB === claBD) {
			this.setState({ activeDB: 0 });
			this.loadTable();
		} else {
			this.loadTable(claBD);
		}
	}

	/**
	 * Función 	para llenar la tabla incial
	 */
	loadTable(claBaseDatos = 0) {
		let urlWebService = Config.urlApiService + "/Replicas/" + claBaseDatos;
		let data = {};

		this.setState({
			claIdioma: sessionStorage.getItem("ClaIdioma"),
			activeDB: claBaseDatos,
			esquema: "",
			nomTabla: "",
			nomServidor: "",
			nomBaseDatos: "",
		});

		callApi(
			urlWebService,
			"GET",
			data,
			(status, response) => {
				if (status === 200) {
					if (response.length <= 0) {
						srcData = [];
					} else {
						srcData = response.map((obj, key) => {
							return {
								claTabla: obj.ClaTabla,
								esquema: obj.Esquema,
								nombreTabla: obj.NomTablaReplica,
								descripcion: obj.DescripcionTabla,
								tipo: obj.Tipo,
								nombreServidor: obj.NomServidor,
								nombreBaseDatos: obj.NomBaseDatos,
								Actions: (
									<div className="actions-center">
										<UncontrolledTooltip placement="left" target={`btnInformacion_${key}`}>
											{translate("Replicas.ttInformacion")}
										</UncontrolledTooltip>
										<Button
											id={`btnInformacion_${key}`}
											onClick={() => {
												writeLog(
													Formularios.Replicas.ClaFuncionalidad,
													Formularios.Replicas.Funcionalidades.btnInformacion,
													undefined,
													obj,
												);

												this.setState({
													showModal: true,
													claBaseDatos: this.state.activeDB,
													claTabla: obj.ClaTabla,
												});
											}}
											color="info"
											size="sm"
											className="btn-icon btn-link">
											<i className="fas fa-info-circle" />
										</Button>
									</div>
								),
							};
						});
					}

					writeLog(
						Formularios.Replicas.ClaFuncionalidad,
						Formularios.Replicas.Funcionalidades.lstBasesDeDatos,
						urlWebService,
						data,
						srcData,
						undefined,
						0,
					);

					this.setState({
						isLoaded: true,
						totalCount: response.length,
						rows: srcData,
						claIdioma: sessionStorage.getItem("ClaIdioma"),
						columns: [
							{ name: "esquema", title: translate("Replicas.colEsquema") },
							{ name: "nombreTabla", title: translate("Replicas.colNombreTabla") },
							{ name: "nombreServidor", title: translate("Replicas.colNombreServidor") },
							{ name: "descripcion", title: translate("Replicas.colDetalle") },
							{ name: "tipo", title: translate("Replicas.colTipo") },
							{ name: "Actions", title: " " },
						],
					});
				}
			},
			Formularios.Replicas.ClaFuncionalidad,
			Formularios.Replicas.Funcionalidades.lstBasesDeDatos,
		);
	}

	/**
	 * Función para llenar tabla después de limpiar campos de búsqueda.
	 */
	clear() {
		this.loadTable();
	}

	/**
	 * Función 	que oculta el modal
	 * correcta y refresca la pantalla
	 */
	hideModal(isOk) {
		if (isOk) {
			const options = {
				place: "br",
				message: translate("Replicas.notificacionExito"),
				type: "success",
				autoDismiss: 3,
				// icon: icon
			};

			this.notify.notificationAlert(options);
			//this.loadTable();
		}

		this.setState({
			showModal: false,
		});
	}

	/**
	 * Función que oculta el modal agregar
	 */
	hideModalAgregar(isOk) {
		if (isOk) {
			const options = {
				place: "br",
				message: translate("Replicas.notificacionExito"),
				type: "success",
				autoDismiss: 3,
				// icon: icon
			};

			this.notify.notificationAlert(options);
			//this.loadTable();
		}

		this.setState({
			showModalAgregar: false,
		});
	}

	btnBuscarClick() {
		let urlWebService = Config.urlApiService + "/Replicas/Buscar";

		let data = {
			ClaBaseDatos: this.state.activeDB,
			NomTabla: this.state.nomTabla,
			Esquema: this.state.esquema,
			NomServidor: this.state.nomServidor,
			NomBaseDatos: this.state.nomBaseDatos,
		};

		callApi(
			urlWebService,
			"POST",
			data,
			(status, response) => {
				if (status === 200) {
					if (response.length <= 0) {
						srcData = [];
					} else {
						srcData = response.map((obj, key) => {
							return {
								claTabla: obj.ClaTabla,
								esquema: obj.Esquema,
								nombreTabla: obj.NomTablaReplica,
								descripcion: obj.DescripcionTabla,
								tipo: obj.Tipo,
								nombreServidor: obj.NomServidor,
								nombreBaseDatos: obj.NomBaseDatos,
								Actions: (
									<div className="actions-center">
										<UncontrolledTooltip placement="left" target={`btnInformacion_${key}`}>
											{translate("Replicas.ttInformacion")}
										</UncontrolledTooltip>
										<Button
											id={`btnInformacion_${key}`}
											onClick={() => {
												this.setState({
													showModal: true,
													claBaseDatos: this.state.activeDB,
													claTabla: obj.ClaTabla,
												});
											}}
											color="info"
											size="sm"
											className="btn-icon btn-link">
											<i className="fas fa-info-circle" />
										</Button>
									</div>
								),
							};
						});
					}

					writeLog(
						Formularios.Replicas.ClaFuncionalidad,
						Formularios.Replicas.Funcionalidades.btnBuscar,
						urlWebService,
						data,
						srcData,
						undefined,
						0,
					);

					this.setState({
						isLoaded: true,
						totalCount: response.length,
						rows: srcData,
					});
				}
			},
			Formularios.Replicas.ClaFuncionalidad,
			Formularios.Replicas.Funcionalidades.btnBuscar,
		);
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<>
					<NotificationAlert ref={(ref) => (this.notify = ref)} />
					<div className="content">
						<Card>
							<CardHeader>
								<Row>
									<Col>
										<CardTitle style={{ fontSize: "16px" }}>{translate("Replicas.titulo")}</CardTitle>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<div
									className="spinner-border text-info text-center"
									style={{ backgroundColor: "transparent" }}
									role="status">
									<span className="sr-only">Cargando...</span>
								</div>
							</CardBody>
						</Card>
					</div>
				</>
			);
		} else {
			return (
				<>
					{sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.loadTable()}
					<NotificationAlert ref={(ref) => (this.notify = ref)} />
					<TourVirtual PaginaID="17" ClaTour={Tours.Replicas} />
					<div className="content align-content-start">
						<Formik
							initialValues={{
								Esquema: this.state.esquema,
								NomTablaReplica: this.state.nomTabla,
								NomServidor: this.state.nomServidor,
								NomBaseDatos: this.state.nomBaseDatos,
							}}
							id="ctnReplicas"
							onSubmit={this.btnBuscarClick}
							onReset={this.clear}>
							{(props) => (
								<Form onSubmit={props.handleSubmit} ref={(el) => (this.myFormRef = el)} key="frmUsuarioBuscar">
									<Card>
										<CardHeader>
											<CardTitle>
												<FormGroup row>
													<Col md={8}>
														<label className="lblHeader">{translate("Replicas.titulo")}</label>
													</Col>
													<Col md={4} className="text-right">
														<UncontrolledTooltip target={`btnBuscar`}>
															{translate("Replicas.ttBuscar")}
														</UncontrolledTooltip>
														<Button
															id="btnBuscar"
															color="info"
															size="sm"
															className="btn-simple btn-icon"
															type="submit">
															<i className="fas fa-search" />
														</Button>

														<UncontrolledTooltip target={`btnLimpiar`}>
															{translate("Replicas.ttLimpiar")}
														</UncontrolledTooltip>
														<Button
															onClick={() => {
																writeLog(
																	Formularios.Replicas.ClaFuncionalidad,
																	Formularios.Replicas.Funcionalidades.btnLimpiar,
																);
																props.resetForm({ values: "" });
															}}
															id="btnLimpiar"
															color="info"
															size="sm"
															className="btn-simple btn-icon"
															type="reset">
															<i className="fas fa-eraser" />
														</Button>

														<UncontrolledTooltip target={`btnNuevo`}>
															{translate("Replicas.btnSolicitar")}
														</UncontrolledTooltip>
														<Button
															id="btnNuevo"
															onClick={() => {
																writeLog(
																	Formularios.Replicas.ClaFuncionalidad,
																	Formularios.Replicas.Funcionalidades.btnNuevo,
																);

																this.setState({
																	showModalAgregar: true,
																	idPeticion: 0,
																});
															}}
															color="info"
															size="sm"
															className="btn-simple btnAgregar">
															<i className="fa fa-plus" />
															{translate("Replicas.btnSolicitar")}
														</Button>
													</Col>
												</FormGroup>
											</CardTitle>
										</CardHeader>

										<CardBody>
											<div className="cardBodyReplicas">
												<div id="grdReplicas">
													<FormGroup row>
														<Col md={2}>
															<Label>{translate("Replicas.lblServidorOrigen")}</Label>
															<Field
																name="NomServidor"
																type="input"
																className="form-control"
																placeholder={translate("Replicas.phServidorOrigen")}
																value={this.state.nomServidor}
																onChange={(event) => {
																	this.setState({
																		nomServidor: event.target.value,
																	});
																}}
															/>
														</Col>
														<Col md={3}>
															<Label>{translate("Replicas.lblBaseDeDatos")}</Label>
															<Field
																name="BaseDeDatos"
																type="input"
																className="form-control"
																placeholder={translate("Replicas.phBaseDeDatos")}
																value={this.state.nomBaseDatos}
																onChange={(event) => {
																	this.setState({
																		nomBaseDatos: event.target.value,
																	});
																}}
															/>
														</Col>
														<Col md={2}>
															<Label>{translate("Replicas.lblEsquema")} </Label>
															<Field
																name="Esquema"
																type="input"
																className="form-control"
																placeholder={translate("Replicas.phEsquema")}
																value={this.state.esquema}
																onChange={(event) => {
																	this.setState({
																		esquema: event.target.value,
																	});
																}}
															/>
														</Col>
														<Col md={3}>
															<Label>{translate("Replicas.lblNombre")} </Label>
															<Field
																name="NomTablaReplica"
																type="input"
																className="form-control"
																placeholder={translate("Replicas.phNombre")}
																value={this.state.nomTabla}
																onChange={(event) => {
																	this.setState({
																		nomTabla: event.target.value,
																	});
																}}
															/>
														</Col>
													</FormGroup>

													<Row>
														<Col md={12} className="text-right">
															<label>
																{translate("Replicas.lblTotalDeRegistros")} {this.state.totalCount}
															</label>
														</Col>
													</Row>
													<Grid rows={this.state.rows} columns={this.state.columns}>
														<SortingState
															sorting={this.state.sorting}
															onSortingChange={(value) => {
																this.setState({ sorting: value });
															}}
														/>

														<PagingState
															currentPage={this.state.currentPage}
															onCurrentPageChange={(value) => {
																this.setState({ currentPage: value });
															}}
															pageSize={this.state.pageSize}
															onPageSizeChange={(value) => {
																this.setState({ pageSize: value });
															}}
														/>

														<IntegratedSorting />
														<IntegratedPaging />

														<Table
															tableComponent={tableComponent}
															headComponent={headerComponent}
															columnExtensions={this.state.tableColumnExtensions}
															messages={{
																noData: gvConfig.noData,
															}}
														/>
														<TableHeaderRow showSortingControls={true} />

														<PagingPanel pageSizes={this.state.pageSizes} />
													</Grid>
												</div>
											</div>
										</CardBody>
									</Card>
								</Form>
							)}
						</Formik>
					</div>

					<Modal centered={true} isOpen={this.state.showModal} size="lg">
						<ReplicaMtto
							hideModal={this.hideModal}
							claBaseDatos={this.state.claBaseDatos}
							claTabla={this.state.claTabla}
							readOnly={true}
						/>
					</Modal>
					<Modal centered={true} isOpen={this.state.showModalAgregar} size="lg">
						<TablaReplicaMtto hideModal={this.hideModalAgregar} idPeticion={0} />
					</Modal>
				</>
			);
		}
	}
}
