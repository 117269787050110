import React from "react";
import { Config } from "utils/Config";

import {
	Row,
	Col,
	Button,
	Container,
	Navbar,
	Nav,
	UncontrolledDropdown,
	DropdownToggle,
	// Collapse,
	DropdownMenu,
	DropdownItem,
	Form,
	UncontrolledTooltip,
	Label,
} from "reactstrap";
import { Switch, Redirect } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Formularios } from "utils/funcionalidad";
import { callApi, setSessionData, getSessionItem, setProducto, logOut, sessionAlive } from "utils/Utilerias";
import { writeLog } from "utils/Utilerias";
import { Grid, Table, TableHeaderRow } from "@devexpress/dx-react-grid-bootstrap4";
import { IntegratedSorting, SortingState } from "@devexpress/dx-react-grid";
import { Formik, Field } from "formik";
import FormGroup from "reactstrap/lib/FormGroup";
import UsuarioNavbar from "../../components/Navbars/UsuarioNavbar";
import Idioma from "components/Navbars/Idioma";
import translate from "utils/translate";
import { withAuth0 } from "@auth0/auth0-react";

class SeleccionProducto extends React.Component {
	constructor(props) {
		super(props);
		//this.refTabla = React.createRef();

		this.state = {
			esSinProductos: false,
			Productos: [],
			sortingColumns: [{ columnName: "NomProducto", direction: "asc" }],
			columns: [
				{ name: "Imagen", title: " " },
				{ name: "NomProducto", title: translate("SeleccionProducto.colNombre") },
				{ name: "Descripcion", title: translate("SeleccionProducto.colDescripcion") },
				{ name: "PM", title: translate("SeleccionProducto.colResponsable") },
			],
			tableColumnExtensions: [
				{ columnName: "Imagen", width: "10%", align: "center" },
				{ columnName: "NomProducto", width: "auto" },
				{ columnName: "Descripcion", width: "50%", wordWrapEnabled: true },
				{ columnName: "PM", width: "30%" },
			],

			isSelected: getSessionItem("isSelected", false),
			color: "stickyNavBar",
			clampresa: 0,
			claIdioma: getSessionItem("ClaIdioma", "1"),
			//empresas: [{ ClaEmpresa: 1, NomEmpresa: "DeAcero" }],
		};

		this.onChangeIdiomaCallback = this.onChangeIdiomaCallback.bind(this);
		this.btnBuscarClick = this.btnBuscarClick.bind(this);
		this.frmLoad = this.frmLoad.bind(this);
		this.clear = this.clear.bind(this);
		this.rowComponent = this.rowComponent.bind(this);
	}

	componentDidMount() {
		this.frmLoad(Formularios.SeleccionProducto.Funcionalidades.btnSeleccionProducto);
	}

	clear() {
		this.frmLoad(Formularios.SeleccionProducto.Funcionalidades.btnLimpiar);
	}

	frmLoad(idAccion) {
		let urlService = ``;
		let objBit;

		urlService = `${Config.urlApiService}/Empresa`;
		let empresas;

		callApi(urlService, "GET", null, (status, response) => {
			empresas = response;

			urlService = `${Config.urlApiService}/Producto/Usuarios`;

			callApi(urlService, "GET", null, (status, response) => {
				if (response.length === 1 && empresas.length === 1) {
					let obj = response[0];

					objBit = JSON.parse(JSON.stringify(obj));
					//JSON.parse(objBit);
					objBit.Imagen = null;
					setProducto(obj.ClaProducto, obj.NomProducto, obj.ApiKey, obj.VerComo, obj.NomGrupo);

					setSessionData({
						isSelected: true,
						ClaRol: obj.ClaRol,
					});

					this.setState({
						isSelected: true,
						empresas: empresas,
						columns: [
							{ name: "Imagen", title: " " },
							{ name: "NomProducto", title: translate("SeleccionProducto.colNombre") },
							{ name: "Descripcion", title: translate("SeleccionProducto.colDescripcion") },
							{ name: "PM", title: translate("SeleccionProducto.colResponsable") },
						],
						//	claIdioma: sessionStorage.getItem("ClaIdioma"),
					});

					writeLog(
						Formularios.SeleccionProducto.ClaFuncionalidad,
						idAccion,
						urlService,
						undefined,
						objBit,
						undefined,
						0,
					);
				} else {
					if (response.length === 0) {
						if (!this.state.esSinProductos) {
							this.setState({
								esSinProductos: true,
							});
						}
					} else {
						response = response.map(function (obj) {
							if (obj.Imagen) {
								obj.Imagen = <img width="50px" alt={obj.NomProducto} src={obj.Imagen} />;
							}

							return obj;
						});

						let respuestaBit = [];
						respuestaBit = JSON.parse(JSON.stringify(response));
						respuestaBit.map((resp) => {
							resp.Imagen = "";
							return resp;
						});

						writeLog(
							Formularios.SeleccionProducto.ClaFuncionalidad,
							idAccion,
							urlService,
							undefined,
							respuestaBit,
							undefined,
							0,
						);

						this.setState({
							Productos: response,
							ProductosBD: response,
							empresas: empresas,
							claEmpresa: empresas[0].ClaEmpresa,
							columns: [
								{ name: "Imagen", title: " " },
								{ name: "NomProducto", title: translate("SeleccionProducto.colNombre") },
								{ name: "Descripcion", title: translate("SeleccionProducto.colDescripcion") },
								{ name: "PM", title: translate("SeleccionProducto.colResponsable") },
							],
							//claIdioma: sessionStorage.getItem("ClaIdioma"),
						});
					}
				}
			});
		});
	}

	tableComponent({ ...restProps }) {
		return <Table.Table {...restProps} id="tblProducto" className="table-hover table-striped"></Table.Table>;
	}

	rowComponent({ row, ...restProps }) {
		return (
			<Table.Row
				{...restProps}
				onClick={() => {
					setProducto(row.ClaProducto, row.NomProducto, row.ApiKey, row.VerComo, row.NomGrupo);
					setSessionData({
						isSelected: true,
						ClaRol: row.ClaRol,
					});

					let objBit = JSON.parse(JSON.stringify(row));

					objBit.Imagen = null;

					writeLog(
						Formularios.SeleccionProducto.ClaFuncionalidad,
						Formularios.SeleccionProducto.Funcionalidades.btnSeleccionProducto,
						undefined,
						objBit,
						undefined,
						undefined,
						0,
					);

					this.setState({
						isSelected: true,
					});
				}}
			/>
		);
	}

	btnBuscarClick(value) {
		let claEmpresa = this.state.claEmpresa;

		let filteredProductos;

		//Realiza el filtro sobre el estado de ProductosBD, aqui se encuentran los productos originales sin procesar.
		if (value.target) {
			claEmpresa = parseInt(value.target.value);

			filteredProductos = this.state.ProductosBD.filter((oX) => oX.ClaEmpresa === claEmpresa);
		} else {
			filteredProductos = this.state.ProductosBD.filter(
				(oX) =>
					oX.NomProducto.toUpperCase().includes(value.NomProducto.toUpperCase()) && oX.ClaEmpresa === claEmpresa,
			);
		}

		let respuestaBit = [];
		respuestaBit = JSON.parse(JSON.stringify(filteredProductos));
		respuestaBit.map((resp) => {
			resp.Imagen = "";
			return resp;
		});

		writeLog(
			Formularios.SeleccionProducto.ClaFuncionalidad,
			Formularios.SeleccionProducto.Funcionalidades.btnBuscar,
			undefined,
			value.target.value,
			respuestaBit,
			undefined,
			0,
		);

		this.setState({
			Productos: filteredProductos,
			claEmpresa: claEmpresa,
		});
	}
	/**
	 * Función que cierra sesión y redirige a la pantalla de login
	 */
	cerrarSesion() {
		const { isAuthenticated, logout } = this.props.auth0;
		if (isAuthenticated) {
			logout({
				returnTo: window.location.origin,
			});
		}
		logOut();

		this.props.history.push({
			pathname: "/Login",
			isLogedOut: true,
		});
	}
	/**
	 * Función enviada a el componente idioma para que cambie el idioma en el session item
	 */
	onChangeIdiomaCallback(claIdiomaParam) {
		this.setState({
			claIdioma: claIdiomaParam,
		});

		sessionStorage.setItem("ClaIdioma", claIdiomaParam);
	}

	render() {
		if (!sessionAlive()) {
			return <Redirect from="/SeleccionProducto" to="/Login" />;
		}

		if (getSessionItem("isSelected")) {
			//var url = getSessionItem("url", "/admin/Home");
			var url = "/admin/Home";
			return (
				<Switch>
					<Redirect from="/SeleccionProducto" to={url} />
					{/* <Redirect
						from="/SeleccionProducto"
						to={{ pathname: url, state: { mensaje: "esto es una propiedad a layout" } }}
					/> */}
				</Switch>
			);
		} else {
			return (
				<>
					{this.state.claIdioma !== sessionStorage.getItem("ClaIdioma") &&
						this.frmLoad(Formularios.SeleccionProducto.Funcionalidades.btnSeleccionProducto)}

					<Navbar
						className={classNames("navbar-absolute", {
							[this.state.color]: this.props.location.pathname.indexOf("full-screen-map") === -1,
						})}
						expand="lg">
						<Container fluid>
							<div className="navbar-wrapper">
								{/* <Collapse navbar isOpen={false}> */}
								<Nav className="ml-auto" navbar>
									<UsuarioNavbar {...this.props} pantallaProducto={true}></UsuarioNavbar>
									{/* <li className="separator d-lg-none" /> */}
								</Nav>
								{/* </Collapse> */}
								<Idioma
									claIdioma={this.state.claIdioma}
									onChangeIdiomaCallback={this.onChangeIdiomaCallback}></Idioma>
							</div>

							<UncontrolledDropdown>
								<DropdownToggle nav caret>
									<i className="fas fa-cog fa-2x" style={{ color: "white" }}></i>
								</DropdownToggle>
								<DropdownMenu right>
									<DropdownItem
										className="nav-item"
										onClick={() => {
											this.cerrarSesion();
										}}>
										{translate("SeleccionProducto.btnSalir")}
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Container>
					</Navbar>
					{this.state.esSinProductos ? (
						<>
							<Container>
								<div
									style={{
										position: "absolute",
										left: "30%",
										top: "35%",
										transform: "translate(-20%, -50%)",
									}}>
									<div style={{ paddingLeft: "45%", marginBottom: "5%" }}>
										<i style={{ color: "white" }} className="fas fa-user-lock fa-7x"></i>
									</div>
									<h1>
										{translate("SeleccionProducto.msjNoProducto1")} <br />
										{translate("SeleccionProducto.msjNoProducto2")}
									</h1>
									<Row>
										<Col md={12} className="text-right">
											<Button
												color="primary"
												onClick={() => {
													this.cerrarSesion();
												}}>
												{translate("SeleccionProducto.btnSalir")}
											</Button>
										</Col>
									</Row>
								</div>
							</Container>
						</>
					) : (
						<Container>
							<div
								style={{
									position: "absolute",
									left: "30%",
									top: "50%",
									transform: "translate(-20%, -50%)",
								}}>
								<Formik
									onSubmit={this.btnBuscarClick}
									onReset={this.clear}
									initialValues={{
										NomProducto: "",
									}}>
									{(props) => (
										<Form onSubmit={props.handleSubmit}>
											<FormGroup row>
												<Col md={1} className="pr-5">
													<span
														className="lblHeader" //style={{ marginRight: "15px" }}
													>
														{translate("SeleccionProducto.lblProductos")}
													</span>
												</Col>
												<Col md={3} className="pl-5 pr-0">
													<Field
														name="NomProducto"
														type="input"
														className="form-control"
														placeholder={translate("SeleccionProducto.phProducto")}
													/>
												</Col>
												<Col md={2} className="pl-2">
													<UncontrolledTooltip target={`btnBuscar`}>
														{translate("SeleccionProducto.ttBuscar")}
													</UncontrolledTooltip>
													<Button
														id="btnBuscar"
														color="info"
														size="sm"
														className="btn-simple btn-icon"
														type="submit">
														<i className="fas fa-search" />
													</Button>

													<UncontrolledTooltip target={`btnLimpiar`}>
														{translate("SeleccionProducto.ttLimpiar")}
													</UncontrolledTooltip>
													<Button
														onClick={() => {
															writeLog(
																Formularios.SeleccionProducto.ClaFuncionalidad,
																Formularios.SeleccionProducto.Funcionalidades.btnLimpiar,
															);
															props.handleReset();
														}}
														id="btnLimpiar"
														color="info"
														size="sm"
														className="btn-simple btn-icon"
														type="reset">
														<i className="fas fa-eraser" />
													</Button>
												</Col>
												<Col md={1}></Col>
												<Col md={5}>
													<FormGroup row>
														<Col md={3}></Col>
														<Label for="ClaEmpresa" md={3} className="text-right">
															<h4 className="mb-0">{translate("SeleccionProducto.lblEmpresa")}</h4>
														</Label>
														<Col md={6} className="pl-0">
															<Field
																name="ClaEmpresa"
																as="select"
																type="input"
																className="form-control"
																id="selEmpresa"
																onChange={this.btnBuscarClick}>
																{this.state.empresas &&
																	this.state.empresas.length > 0 &&
																	this.state.empresas.map((obj, index) => (
																		<option className="ddlOptions" key={index} value={obj.ClaEmpresa}>
																			{obj.NomEmpresa}
																		</option>
																	))}
															</Field>
														</Col>
													</FormGroup>
												</Col>
											</FormGroup>
										</Form>
									)}
								</Formik>
								<div className="seleccionProducto">
									<Grid rows={this.state.Productos} columns={this.state.columns}>
										<SortingState
											sorting={this.state.sortingColumns}
											onSortingChange={(value) => {
												this.setState({ sortingColumns: value });
											}}
										/>
										<IntegratedSorting />

										<Table
											className="tablaProducto table-hover table-striped"
											//ref={this.refTabla}
											tableComponent={this.tableComponent}
											height={"40vh"}
											columnExtensions={this.state.tableColumnExtensions}
											messages={{ noData: "No se encontró información" }}
											rowComponent={this.rowComponent}
										/>
										<TableHeaderRow showSortingControls={true} />
									</Grid>
								</div>
							</div>
						</Container>
					)}
				</>
			);
		}
	}
}

SeleccionProducto.propTypes = {
	history: PropTypes.any,
	location: PropTypes.any,
	auth0: PropTypes.any,
};

export default withAuth0(SeleccionProducto);
