const SolicitudServicio = {
	titulo: "Service requests",
	lblProducto: "Product",
	lblFechaDesde: "From: ",
	lblHasta: "To: ",
	phFechaDesde: "Request creation date",
	phHasta: "Date to",
	ttBuscar: "Search",
	ttLimpiar: "Clear",
	btnCrearSolicitud: " Create Request",
	lblSolicitudesRealizadas: "Requests Made:",
	lblVerMisSolicitudes: "See only my requests",
	colRealizadasDevServicio: "Service",
	colRealizadasDevSolicitadoA: "Requested To",
	colRealizadasDevUsuarioSolicita: "User Requesting",
	colRealizadasDevEstatus: "Status",
	colRealizadasDevFechaSolicita: "Request creation date",
	colRealizadasDevFechaPromesa: "Promise date",
	ttInformacion: "Information",
	colAsignadasDevServicio: "Service",
	colAsignadasDevSolicitadoA: "Requested To",
	colAsignadasDevUsuarioSolicita: "User Requesting",
	colAsignadasEstatus: "Status",
	colAsignadasFechaSolicita: "Request Creation Date",
	colAsignadasFechaPromesa: "Promise Date",
	lblVerSoloPendientes: "See only pendings",
	lblSolicitudesRecibidas: "Requests Received",
	colRecibidasPMServicio: "Service",
	colRecibidasPMSolicitante: "Requester",
	colRecibidasPMUsuarioSolicita: "User Requesting",
	colRecibidasPMEstatus: "Status",
	colRecibidasPMFechaSolicitud: "Request Creation Date",
	colRecibidasPMFechaPromesa: "Promise Date",
	ttVerificar: "Verify",
	ttAutorizar: "Authorize",
	ttAprobarSolicitud: "Approve Request",
	ttRechazar: "Reject",
	ttEliminar: "Delete",
	lblSolSal: "Outgoing Requests",
	colSolSalPMServicio: "Service",
	colSolSalPMSolicitadoA: "Requested To",
	colSolSalPMUsuarioSolicita: "User Requesting",
	colSolSalPMEstatus: "Status",
	colSolSalPMFechaSolicitud: "Request creation date",

	lblSiguienteEstatus: "Next Status",
	ttSiguienteEstatus: "Move to next status",
	ttAsignar: "Assign",
	alertCambio: "Status changed successfully",
	alertError: "Something went wrong",
	noInfo: "No information found",

	lblSolicitudesAsignadas: "Assigned Requests:",
	lblSolicitudesSalientes: "Outgoing Requests:",
	alertRegistroEliminado: "The record was successfully deleted",
	notificacion: "Successful operation.",

	alertAtencion: "Attention",
	alertAtencionDescripcion: "To move to the next status, the service must first be registered",

	alertEstatusCambiado: "Status changed successfully",
	alertAlgoSalioMal: "Something went wrong",
};

export default SolicitudServicio;
