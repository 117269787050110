const MisServiciosPublicados = {
	titulo: "My Services",
	lblTipo: "Type",
	lblServicio: "Service",
	lblGlosario: "Glossary",
	btnAgregar: " Add",
	lblTotalDeRegistros: "Total records",
	colNombre: "Name",
	phNombre: " Name, description or id of the service",
	phTerminosRelacionados: "Related terms",
	ttLimpiar: "Clear",
	ttBuscar: "Search",
	ttVerificado: "Verified service",
	ttNoVerificado: "The service is not verified",
	ttEditar: "Edit",
	ttComentarios: "Comments",
	ttEliminar: "Delete",
	lblBusqueda: "Search for available services",
	btnCerrar: "Close",
	alertEliminar: "The service was successfully removed",
	alertDesactivar: "Do you want to deactivate the service?",
	operacionExitosa: "Successful operation",
	ttClonar: "Clone",
	ttSuscritos: "Subscribed",
	ttOtrasFn: "Other functions:",
};

export default MisServiciosPublicados;
