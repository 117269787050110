import React from "react";
import {
	Card,
	CardBody,
	CardFooter,
	Form,
	FormGroup,
	Row,
	Col,
	Button,
	Label,
	Navbar,
	Container,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	NavbarBrand,
} from "reactstrap";
import { Redirect, Switch } from "react-router-dom";
import { Formik, Field } from "formik";
import { Config } from "utils/Config";
import PropTypes from "prop-types";
import { callApi, logOut, showSweetAlert, writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";
export default class AutorizarPeticionTabla extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			datos: {},
			exit: false,
		};

		this.handleSubmitAceptar = this.handleSubmitAceptar.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
	}

	componentDidMount() {
		let params = new URLSearchParams(this.props.location.search);

		if (this.props.params !== "") {
			let data = {
				idPeticion: params.get("idPeticion"),
			};

			if (data.idPeticion) {
				let urlWebService = Config.urlApiService + "/Replicas/SolicitudTabla/" + data.idPeticion;

				callApi(urlWebService, "GET", null, (status, response) => {
					if (status === 200 && response.length > 0) {
						this.setState({
							isLoaded: true,
							datos: response[0],
						});

						if (this.state.datos.EsAutorizado === 1) {
							showSweetAlert("La entidad indicada ya se fue autorizada", "", "error", this.hideAlert);
						}
					} else {
						showSweetAlert("Los datos proporcionados no son válidos", "", "error", this.hideAlert);
					}
				});
			} else {
				showSweetAlert(
					"Falta parámetro",
					"Favor de proporcionar el parámetro: idPeticion",
					"error",
					this.hideAlert,
				);
			}
		} else {
			showSweetAlert("Falta parámetro", "Favor de proporcionar el parámetro: idPeticion", "error", this.hideAlert);
		}
	}

	/**
	 * Función que cierra sesión y redirige a la pantalla de login
	 */
	cerrarSesion() {
		logOut();
		this.props.history.push({
			pathname: "/Login",
			isLogedOut: true,
		});
	}

	/**
	 * Funcion para ocultar la alerta/mensaje que fue activado
	 */
	hideAlert() {
		this.setState({
			exit: true,
		});
	}

	handleSubmitAceptar(value) {
		let urlWebService = `${Config.urlApiService}/Replicas/SolicitudTabla`;

		let data = {
			IdPeticion: this.state.datos.IdPeticion,
			ClaBaseDatos: this.state.datos.ClaBaseDatos,
			ClaServidor: this.state.datos.ClaServidor,
			ClaTabla: this.state.datos.ClaTabla,
			ClaProducto: this.state.datos.ClaProducto,
			ClaCliente: this.state.datos.ClaCliente,
			ClaEstatus: 2,
			Titulo: this.state.datos.Titulo,
			NomBaseDatos: this.state.datos.NomBaseDatos,
			NomServidor: this.state.datos.NomServidor,
			NomBaseDatosDestino: this.state.datos.NomBaseDatosDestino,
			NomServidorDestino: this.state.datos.NomServidorDestino,
			NomTabla: this.state.datos.NomTabla,
			MensajeSolicitud: this.state.datos.MensajeSolicitud,
			MensajeRespuesta: value.mensaje,
			EsAceptado: parseInt(value.acceso),
		};

		callApi(
			urlWebService,
			"PUT",
			data,
			(status, response) => {
				if (status === 200 && response.IdPeticion > 0) {
					writeLog(
						Formularios.AutorizarPeticionTabla.ClaFuncionalidad,
						Formularios.AutorizarPeticionTabla.Funcionalidades.btnEnviar,
						urlWebService,
						data,
						response,
						"El mensaje fue enviado con éxito",
						0,
					);
					showSweetAlert("El mensaje fue enviado con éxito", "", "success", this.hideAlert);
				} else {
					showSweetAlert("Error", response.message, "error");
				}
			},
			Formularios.AutorizarPeticionTabla.ClaFuncionalidad,
			Formularios.AutorizarPeticionTabla.Funcionalidades.btnEnviar,
		);
	}

	render() {
		if (!this.state.isLoaded && !this.state.exit) {
			return (
				<div className="row justify-content-center p-5">
					<div className="spinner-border text-info" style={{ backgroundColor: "transparent" }} role="status">
						<span className="sr-only">Cargando...</span>
					</div>
				</div>
			);
		}
		if (this.state.exit) {
			return (
				<Switch>
					<Redirect from="/AutorizarPeticionTabla" to="/SeleccionProducto" />
				</Switch>
			);
		}
		return (
			<>
				<div className="content">
					<div className="row">
						<div className="col-12 pb-2">
							<Navbar>
								<Container fluid>
									<NavbarBrand>
										<span style={{ color: "white", textTransform: "capitalize" }}>Autorizar petición</span>
									</NavbarBrand>
									<UncontrolledDropdown>
										<DropdownToggle nav caret>
											<i className="fas fa-cog fa-2x" style={{ color: "white" }}></i>
										</DropdownToggle>
										<DropdownMenu right>
											<DropdownItem
												className="nav-item"
												onClick={() => {
													this.cerrarSesion();
												}}>
												Salir
											</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								</Container>
							</Navbar>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-5">
							<Formik
								initialValues={{
									acceso: "1",
									mensaje: "",
								}}
								onSubmit={this.handleSubmitAceptar}>
								{(props) => (
									<Form onSubmit={props.handleSubmit}>
										<Card>
											<CardBody>
												<h2>Petición de Réplica</h2>
												<h5>
													<b>{this.state.datos.NomCliente}</b> solicita replicar la tabla{" "}
													<b>{this.state.datos.NomTabla}</b>
												</h5>
												<h5>
													<b>Origenes</b>
												</h5>
												<Row className="text-default mb-3">
													<Col md={3}>Base Datos</Col>
													<Col md={5}>{this.state.datos.NomBaseDatos}</Col>
													<Col md={4}></Col>
													<Col md={3}>Servidor</Col>
													<Col md={5}>{this.state.datos.NomServidor}</Col>
												</Row>
												<h5>
													<b>Destinos</b>
												</h5>
												<Row className="text-default mb-4">
													<Col md={3}>Base Datos</Col>
													<Col md={5}>{this.state.datos.NomBaseDatosDestino}</Col>
													<Col md={4}></Col>
													<Col md={3}>Servidor</Col>
													<Col md={5}>{this.state.datos.NomServidorDestino}</Col>
												</Row>

												<h5>
													<b>Motivo solicitud</b>
												</h5>
												<h5 className="pl-2">
													<i>{this.state.datos.MensajeSolicitud}</i>
												</h5>

												<Row>
													<Col md={12}>
														<Label className="text-default" for="accessoRb" id="my-radio-group">
															¿Desea autorizar la réplica?
														</Label>
														<FormGroup>
															<div role="group" name="accesoRb" aria-labelledby="my-radio-group">
																<label>
																	<Field type="radio" name="acceso" value="1" />
																	&nbsp;Sí &nbsp;&nbsp;
																</label>
																<label>
																	<Field type="radio" name="acceso" value="0" />
																	&nbsp;No
																</label>
															</div>
														</FormGroup>
													</Col>
												</Row>
												<FormGroup row>
													<Col md={12}>
														<Label className="text-default" for="mensaje">
															Mensaje para el cliente
														</Label>
														<Field
															as="textarea"
															name="mensaje"
															className="form-control"
															placeholder="Ingrese mensaje"
															rows={6}
														/>
													</Col>
												</FormGroup>
											</CardBody>
											<CardFooter>
												<Row>
													<Col md={12} className="text-center">
														<Button
															id="btnEnviar"
															className="animation-on-hover"
															color="success"
															size="sm"
															type="submit">
															Enviar
														</Button>
													</Col>
												</Row>
											</CardFooter>
										</Card>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</>
		);
	}
}

AutorizarPeticionTabla.propTypes = {
	location: PropTypes.any,
	params: PropTypes.any,
	history: PropTypes.any,
};
