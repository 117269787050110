import React from "react";
import { callApi, showAlert, showConfirm } from "utils/Utilerias";
import { Config } from "utils/Config";
import UsuarioMtto from "./UsuariosMtto";
import NotificationAlert from "react-notification-alert";
import {
	Button,
	Card,
	CardHeader,
	UncontrolledTooltip,
	CardBody,
	CardTitle,
	Col,
	Modal,
	Label,
	FormGroup,
	Form,
} from "reactstrap";
import { Formik, Field } from "formik";
import { getProducto } from "utils/Utilerias";

import { Grid, PagingPanel, Table, TableHeaderRow } from "@devexpress/dx-react-grid-bootstrap4";
import { IntegratedPaging, IntegratedSorting, PagingState, SortingState } from "@devexpress/dx-react-grid";
import { tableComponent, headerComponent, gvConfig } from "utils/gridConfig";
import TourVirtual from "components/Tour/TourVirtual";
import { Tours } from "../../utils/Config";
import { writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";
let srcData = [];

export default class Usuarios extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			claIdioma: sessionStorage.getItem("ClaIdioma"),
			showModal: false,
			alert: null,

			data: [],
			currentPage: 0,
			pageSize: 20,
			sorting: [{ columnName: "ClaEmpleado", direction: "asc" }],

			gvUsuarios: {
				columns: [
					{ name: "ClaEmpleado", title: "Clave Empleado" },
					{ name: "NomUsuario", title: "Nombre" },
					{ name: "Rol", title: "Perfil" },
					{ name: "Actions", title: " " },
				],
				tableColumnExtensions: [
					{ columnName: "ClaEmpleado", width: "200px", align: "center" },
					{ columnName: "NomUsuario", width: "auto" },
					//{ columnName: "TipoVista", width: "4%", align: "center" },
					{ columnName: "Rol", width: "150px", align: "center" },
					{ columnName: "Actions", width: "100px", align: "center" },
				],
			},
		};

		this.showConfirm = this.showConfirm.bind(this);
		this.hideModal = this.hideModal.bind(this);
		this.llenarTabla = this.llenarTabla.bind(this);
		this.desactivarUsuario = this.desactivarUsuario.bind(this);
		this.btnBuscarClick = this.btnBuscarClick.bind(this);
		this.clear = this.clear.bind(this);
	}

	componentDidMount() {
		this.llenarTabla();
	}

	/**
	 * Función 	que oculta el modal
	 * correcta y refresca la pantalla
	 */
	hideModal() {
		this.setState({
			showModal: false,
		});
	}

	/**
	 * Funcion para ocultar la alerta/mensaje que fue activado
	 */
	hideAlert() {
		this.setState({
			showAlert: null,
			showConfirm: null,
		});
	}

	/**
	 * Función que se muestra un mensaje de éxito en la operación
	 * @param {String} title titulo que tendrá el mensaje mostrado
	 * @param {String} text contenido del mensaje
	 * @param {String} icon icono que mostrará el mensaje
	 */
	successAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Función que desactiva a un usuario del producto seleccionado
	 * @param {Object} objUsuario Usuario que se va a desactivar
	 */
	desactivarUsuario(objUsuario) {
		let urlWebService =
			Config.urlApiService + "/Producto/Usuarios/" + objUsuario.ClaProducto + "/" + objUsuario.ClaEmpleado;
		let data = {
			claUsuario: objUsuario.ClaUsuario,
			claEmpleado: objUsuario.ClaEmpleado,
			claProducto: objUsuario.ClaProducto,
			claRol: objUsuario.ClaRol,
			verComo: objUsuario.VerComo,
			bajaLogica: 1,
			esNotificado: objUsuario.EsNotificado,
		};

		callApi(
			urlWebService,
			"PUT",
			data,
			(status, response) => {
				this.setState({
					isLoaded: true,
					objUsuario: {
						ClaUsuario: response[0],
					},
				});

				this.hideAlert();
				let empleado = objUsuario.ClaEmpleado === 0 ? "externo" : objUsuario.ClaEmpleado;
				let empleadoIngles = objUsuario.ClaEmpleado === 0 ? "from Google" : objUsuario.ClaEmpleado;

				let mensaje =
					sessionStorage.getItem("ClaIdioma") === "1"
						? "El usuario " + empleado + " se eliminó correctamente."
						: "The user " + empleadoIngles + " was successfully removed.";

				this.successAlert(mensaje, undefined, "success");
				writeLog(
					Formularios.AdministracionUsuarios.ClaFuncionalidad,
					Formularios.AdministracionUsuarios.Funcionalidades.btnEliminar,
					urlWebService,
					data,
					response,
					"El usuario " + objUsuario.ClaEmpleado + " se eliminó correctamente.",
					0,
				);
			},
			Formularios.AdministracionUsuarios.ClaFuncionalidad,
			Formularios.AdministracionUsuarios.Funcionalidades.btnEliminar,
		);
		this.llenarTabla();
	}

	/**
	 * Función 	para llenar la tabla incial
	 */
	llenarTabla() {
		let urlWebService = Config.urlApiService + "/Producto/Usuarios/" + getProducto();
		let data = {};

		callApi(urlWebService, "GET", data, (status, response) => {
			if (response.length <= 0) {
				srcData = [];
			} else {
				srcData = response.map((obj, key) => {
					return {
						ClaUsuario: obj.ClaUsuario,
						NomUsuario: obj.NomUsuario ? obj.NomUsuario : obj.Email,
						ClaEmpleado: obj.ClaEmpleado === 0 ? "Externo" : obj.ClaEmpleado,
						ClaProducto: obj.ClaProducto,
						NomProducto: obj.NomProducto,
						TipoVista: obj.TipoVista,
						Rol: obj.Rol,
						ClaRol: obj.ClaRol,
						VerComo: obj.VerComo,
						Actions: (
							<div className="actions-center">
								<UncontrolledTooltip placement="left" target={`btnEditar_${key}`}>
									{translate("Usuarios.ttEditar")}
								</UncontrolledTooltip>
								<Button
									onClick={() => {
										writeLog(
											Formularios.AdministracionUsuarios.ClaFuncionalidad,
											Formularios.AdministracionUsuarios.Funcionalidades.btnEditar,
											undefined,
											obj,
										);
										this.setState({
											showModal: true,
											claUsuario: obj.ClaUsuario,
											claProducto: obj.ClaProducto,
											claEmpleado: obj.ClaEmpleado,
										});
									}}
									color="info"
									size="sm"
									className="btn-icon btn-link"
									id={`btnEditar_${key}`}>
									<i className="fa fa-edit" />
								</Button>
								<UncontrolledTooltip placement="left" target={`btnEliminar_${key}`}>
									{translate("Usuarios.ttEliminar")}
								</UncontrolledTooltip>
								<Button
									onClick={() => {
										this.showConfirm(
											() => this.desactivarUsuario(obj),
											() => this.hideAlert(),
											sessionStorage.getItem("ClaIdioma") === "1"
												? `¿Desea desactivar el Usuario?`
												: `Do you want to deactivate the User?`,
											obj.ClaEmpleado === 0
												? "Externo - " + obj.NomUsuario
												: obj.ClaEmpleado + " - " + obj.NomUsuario,
										);
										this.setState({
											claUsuario: obj.ClaUsuario,
											claProducto: getProducto(),
										});
									}}
									color="danger"
									size="sm"
									className="btn-icon btn-link"
									id={`btnEliminar_${key}`}
									disabled={obj.ClaRol === 5 ? true : false}>
									<i className="fas fa-trash-alt" />
								</Button>
							</div>
						),
					};
				});

				this.setState({
					isLoaded: true,
					data: srcData,
					claIdioma: sessionStorage.getItem("ClaIdioma"),
					gvUsuarios: {
						columns: [
							{ name: "ClaEmpleado", title: translate("Usuarios.colClaveEmpleado") },
							{ name: "NomUsuario", title: translate("Usuarios.colNombre") },
							{ name: "Rol", title: translate("Usuarios.colPerfil") },
							{ name: "Actions", title: " " },
						],
						// tableColumnExtensions: [
						// 	{ columnName: "ClaEmpleado", width: "7%", align: "center" },
						// 	{ columnName: "NomUsuario", width: "auto" },
						// 	{ columnName: "Rol", width: "5%", align: "center" },
						// 	{ columnName: "Actions", width: "3%", align: "center" },
						// ],
						tableColumnExtensions: [
							{ columnName: "ClaEmpleado", width: "200px", align: "center" },
							{ columnName: "NomUsuario", width: "auto" },
							//{ columnName: "TipoVista", width: "4%", align: "center" },
							{ columnName: "Rol", width: "150px", align: "center" },
							{ columnName: "Actions", width: "100px", align: "center" },
						],
					},
				});
			}
		});
		// .catch((err) => {
		//     alert(err);
		// });
	}

	/**
	 * Función para llenar tabla después de limpiar campos de búsqueda.
	 */
	clear() {
		this.llenarTabla();
	}

	btnBuscarClick(value) {
		let urlWebService = Config.urlApiService + "/Producto/BuscarUsuarios/";

		let data = {
			claProducto: getProducto(),
			claEmpleado: value.ClaEmpleado ? value.ClaEmpleado : 0,
			nomUsuario: value.NomUsuario ? value.NomUsuario : "",
		};

		callApi(urlWebService, "POST", data, (status, response) => {
			if (response.length <= 0) {
				srcData = [];
				this.setState({
					isLoaded: true,
					data: srcData,
				});
			} else {
				srcData = response.map((obj, key) => {
					return {
						ClaUsuario: obj.ClaUsuario,
						NomUsuario: obj.NomUsuario ? obj.NomUsuario : obj.Email,
						ClaEmpleado: obj.ClaEmpleado === 0 ? "Externo" : obj.ClaEmpleado,
						ClaProducto: obj.ClaProducto,
						NomProducto: obj.NomProducto,
						Rol: obj.Rol,
						//TipoVista: obj.TipoVista,
						ClaRol: obj.ClaRol,
						VerComo: obj.VerComo,
						Actions: (
							<div className="actions-center">
								<UncontrolledTooltip placement="left" target={`btnEditar_${key}`}>
									{translate("Usuarios.ttEditar")}
								</UncontrolledTooltip>
								<Button
									onClick={() => {
										this.setState({
											showModal: true,
											claUsuario: obj.ClaUsuario,
											claProducto: obj.ClaProducto,
											claEmpleado: obj.ClaEmpleado,
										});
									}}
									color="info"
									size="sm"
									className="btn-icon btn-link"
									id={`btnEditar_${key}`}>
									<i className="fa fa-edit" />
								</Button>
								<UncontrolledTooltip placement="left" target={`btnEliminar_${key}`}>
									{translate("Usuarios.ttEliminar")}
								</UncontrolledTooltip>
								<Button
									onClick={() => {
										this.showConfirm(
											() => this.desactivarUsuario(obj),
											() => this.hideAlert(),
											sessionStorage.getItem("ClaIdioma") === "1"
												? `¿Desea desactivar el Usuario?`
												: `Do you want to deactivate the User?`,
											obj.ClaEmpleado + " - " + obj.NomUsuario,
										);
										this.setState({
											claUsuario: obj.ClaUsuario,
											claProducto: getProducto(),
										});
									}}
									color="danger"
									size="sm"
									className="btn-icon btn-link"
									id={`btnEliminar_${key}`}
									disabled={obj.ClaRol === 5 ? true : false}>
									<i className="fas fa-trash-alt" />
								</Button>
							</div>
						),
					};
				});

				this.setState({
					isLoaded: true,
					data: srcData,
				});
			}

			writeLog(
				Formularios.AdministracionUsuarios.ClaFuncionalidad,
				Formularios.AdministracionUsuarios.Funcionalidades.btnBuscar,
				urlWebService,
				data,
				response,
				undefined,
				0,
			);
		});
	}

	/**
	 * Función 	que muestra un mensaje en el centro de la pantalla con poder de
	 * elegir una opción
	 * @param {Function} onConfirm Función que se ejecuta al presionar el boton de confirmar
	 * @param {Function} OnCancel Función que se ejecuta al presionar el botón de cancelar
	 * @param {String} title titulo del mensaje
	 * @param {String} text cuerpo del mensaje
	 */
	showConfirm(onConfirm, onCancel, title, text) {
		const warningAlert = showConfirm(onConfirm, onCancel, title, text);

		this.setState({
			showConfirm: warningAlert,
		});
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<>
					<div className="spinner-border text-info" style={{ backgroundColor: "transparent" }} role="status">
						{sessionStorage.getItem("ClaIdioma") === "1" ? (
							<span className="sr-only">Cargando...</span>
						) : (
							<span className="sr-only">Loading...</span>
						)}
					</div>
				</>
			);
		} else {
			return (
				<>
					<TourVirtual PaginaID="4" ClaTour={Tours.Usuarios} />
					<NotificationAlert ref={(el) => (this.myFormRef = el)} />
					{this.state.showAlert}
					{this.state.showConfirm}
					{sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.llenarTabla()}
					<div className="content">
						<Formik
							id="ctnAdmUsuarios"
							initialValues={{
								Nombre: "",
								ClaEmpleado: undefined,
							}}
							onSubmit={this.btnBuscarClick}
							onReset={this.clear}>
							{(props) => (
								<Form onSubmit={props.handleSubmit} ref={(el) => (this.myFormRef = el)} key="frmUsuarioBuscar">
									<Card>
										<CardHeader>
											<CardTitle>
												<FormGroup row>
													<Col md={8}>
														<Label className="lblHeader">{translate("Usuarios.titulo")}</Label>
													</Col>
													<Col md={4} className="text-right">
														<UncontrolledTooltip target={`btnBuscar`}>
															{translate("Usuarios.ttBuscar")}
														</UncontrolledTooltip>
														<Button
															id="btnBuscar"
															color="info"
															size="sm"
															className="btn-simple btn-icon"
															type="submit">
															<i className="fas fa-search" />
														</Button>

														<UncontrolledTooltip target={`btnLimpiar`}>
															{translate("Usuarios.ttLimpiar")}
														</UncontrolledTooltip>
														<Button
															onClick={() => {
																writeLog(
																	Formularios.AdministracionUsuarios.ClaFuncionalidad,
																	Formularios.AdministracionUsuarios.Funcionalidades.btnLimpiar,
																);
																props.resetForm({ values: "" });
															}}
															id="btnLimpiar"
															color="info"
															size="sm"
															className="btn-simple btn-icon"
															type="reset">
															<i className="fas fa-eraser" />
														</Button>

														<UncontrolledTooltip target={`btnNuevo`}>
															{translate("Usuarios.ttAgregar")}
														</UncontrolledTooltip>
														<Button
															id={`btnNuevo`}
															onClick={() => {
																writeLog(
																	Formularios.AdministracionUsuarios.ClaFuncionalidad,
																	Formularios.AdministracionUsuarios.Funcionalidades.btnNuevo,
																);

																this.setState({
																	showModal: true,
																	claUsuario: 0,
																	claProducto: 0,
																	claEmpleado: 0,
																});
															}}
															color="info"
															size="sm"
															className="btn-simple">
															<i className="fa fa-plus" />
															{translate("Usuarios.btnAgregar")}
														</Button>
													</Col>
												</FormGroup>
											</CardTitle>
										</CardHeader>
										<CardBody>
											<FormGroup row>
												<Col md={2}>
													<Label>{translate("Usuarios.lblClaveEmpleado")}</Label>
													<Field
														name="ClaEmpleado"
														type="input"
														className="form-control"
														placeholder={translate("Usuarios.phClaveEmpleado")}></Field>
												</Col>
												<Col md={4}>
													<Label>{translate("Usuarios.lblNombre")} </Label>
													<Field
														name="NomUsuario"
														type="input"
														className="form-control"
														placeholder={translate("Usuarios.phNombre")}></Field>
												</Col>
											</FormGroup>

											<Grid rows={this.state.data} columns={this.state.gvUsuarios.columns}>
												<SortingState
													sorting={this.state.sorting}
													onSortingChange={(value) => {
														this.setState({ sorting: value });
													}}
												/>

												<PagingState
													currentPage={this.state.currentPage}
													onCurrentPageChange={(value) => {
														this.setState({ currentPage: value });
													}}
													pageSize={this.state.pageSize}
													onPageSizeChange={(value) => {
														this.setState({ pageSize: value });
													}}
												/>

												<IntegratedSorting />
												<IntegratedPaging />

												<Table
													tableComponent={tableComponent}
													columnExtensions={this.state.gvUsuarios.tableColumnExtensions}
													headComponent={headerComponent}
													messages={{ noData: gvConfig.noData }}
												/>

												<TableHeaderRow showSortingControls={true} />

												<PagingPanel pageSizes={gvConfig.pageSizes} />
											</Grid>
										</CardBody>
									</Card>
								</Form>
							)}
						</Formik>
					</div>

					<Modal centered={true} isOpen={this.state.showModal} size="md">
						<UsuarioMtto
							hideModal={() => {
								this.hideModal();
							}}
							claUsuario={this.state.claUsuario}
							claProducto={this.state.claProducto}
							claEmpleado={this.state.claEmpleado}
						/>
					</Modal>
				</>
			);
		}
	}
}
