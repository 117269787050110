import React from "react";
import PropTypes from "prop-types";
import { writeLog } from "./Utilerias";

export default class ErrorHandler extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		//error.stack.split("\n")[1].trim().split(" ")[1].split(".");
		//[0] - Funcionalidad/Pantalla
		//[1] - Detalle

		//console.log(error, errorInfo);
		writeLog(0, 0, undefined, undefined, errorInfo.componentStack.toString(), error.message.toString(), 1);

		this.setState({
			error,
			errorInfo,
		});
		// You can also log error messages to an error reporting service here
	}

	render() {
		if (this.state.errorInfo) {
			// Error path
			return (
				<div className="container">
					<h2>Oh oh.. algo salio mal :( .</h2>
					<details style={{ whiteSpace: "pre-wrap" }}>
						{this.state.error && this.state.error.toString()}

						<br />
						{this.state.errorInfo.componentStack}
					</details>
				</div>
			);
		}
		// Normally, just render children
		return this.props.children;
	}
}

ErrorHandler.propTypes = {
	children: PropTypes.any,
};
