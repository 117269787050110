import React from "react";
import {
	Card,
	CardBody,
	CardHeader,
	Form,
	FormGroup,
	Row,
	Col,
	Button,
	Label,
	CardFooter,
	CardTitle,
} from "reactstrap";
import { Formik, Field } from "formik";
import PropTypes from "prop-types";
import { Config } from "utils/Config";
import { callApi } from "utils/Utilerias";
import { getProducto } from "utils/Utilerias";

let listaEstatusSelect = [];
let estatusActual = 0;

//se usa para evitar tener que mover siempre el select y que guarde una opcion
export default class IncidenteMtto extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			Incidente: this.props.Incidente,
			IsLoaded: true,
		};

		estatusActual = this.props.Incidente.Detalles[this.props.Incidente.Detalles.length - 1].ClaEstatusIncidente;

		this.btnCancelar_Click = this.btnCancelar_Click.bind(this);
		this.handleSubmitAceptar = this.handleSubmitAceptar.bind(this);
		this.ddlEstatus_Fill = this.ddlEstatus_Fill.bind(this);
	}

	componentDidMount() {
		this.ddlEstatus_Fill();
	}

	/**
	 * Función del botón para cancelar la operación
	 * y ocultar el modal sin guardar ningún cambio
	 * @param {boolean} isOk define si se realizó correctamente la operación
	 */
	btnCancelar_Click(isOk) {
		this.props.hideModal(isOk, this.state.Incidente);
	}

	/**
	 * Función que carga el drop down list con los estatus al momento de editar
	 * @returns {JSX} Regresa un drop down list con los estatus correspodientes
	 * al incidente
	 */
	ddlEstatus_Fill() {}

	/**
	 * Función del botón para guardar cambios en el objeto incidente
	 * @param {JSON} value los valores del formulario
	 */
	handleSubmitAceptar(value) {
		try {
			let incidente = this.state.Incidente;
			let urlWebService = `${Config.urlApiService}/Incidentes/${getProducto()}/${incidente.IdEntidad}/${
				incidente.ClaIncidente
			}`;

			if (!Array.isArray(incidente.Detalles)) {
				incidente.Detalles = [];
			}

			let lastIndex = this.state.Incidente.Detalles.length - 1;

			if (lastIndex === -1) {
				lastIndex = 0;
			}

			let date = new Date();

			incidente.Detalles.push({
				ClaIncidente: incidente.ClaIncidente,
				ClaIncidenteDet: incidente.Detalles[lastIndex].ClaIncidenteDet + 1,
				Comentarios: value.Comentarios,
				ClaEstatusIncidente: value.ClaEstatusIncidente,
				ClaUsuarioAsignacion: incidente.Detalles[lastIndex].ClaUsuarioAsignacion,
				ClaUsuarioMod: incidente.Detalles[lastIndex].ClaUsuarioMod,
				NomArchivo: null,
				Archivo: null,
				ExtensionArchivo: null,
				FechaUltimaMod: date.toISOString(),
			});
			incidente.ClaIncidenteEstatus = value.ClaEstatusIncidente;

			let data = {
				ApiKey: Config.ApiKey,
				...incidente,
			};

			estatusActual = value.ClaEstatusIncidente;

			callApi(urlWebService, "PUT", data, (status, response) => {
				if (response.ClaIncidente === undefined) {
					let msg = "Internal server error";
					throw msg;
				} else {
					this.btnCancelar_Click(true);
				}
			}).catch((err) => {
				alert("Error en " + urlWebService + "\n\r" + err);
			});
		} catch (err) {
			alert("ERROR GENERAL:" + err);
		}
	}

	render() {
		if (!this.state.IsLoaded) {
			return <div>Cargando...</div>;
		}
		return (
			<>
				<div className="content">
					<Formik
						initialValues={{
							NomIncidente: this.props.Incidente.Titulo,
							NomCliente: this.props.Incidente.NomCliente,
							Descripcion: this.props.Incidente.Descripcion,
							DescripcionExtendida: this.props.Incidente.DescripcionExtendida,
							Estatus: this.props.Incidente.NomIncidenteEstatus,
							UsuarioAsignado:
								this.props.Incidente.Detalles[this.props.Incidente.Detalles.length - 1].NomUsuarioAsignacion,
							ClaEstatusIncidente: estatusActual + 1,
							NomProducto: this.state.NomProducto,
						}}
						onSubmit={this.handleSubmitAceptar}>
						{(props) => (
							<Form onSubmit={props.handleSubmit}>
								<Card>
									<CardHeader>
										<CardTitle>
											Incidente #<b>{this.props.Incidente.ClaIncidente}</b>
										</CardTitle>
									</CardHeader>
									<CardBody className="h-100">
										<FormGroup>
											<Row>
												<Col md={4}>
													<Label for="NomIncidente">Título</Label>
													<Field
														name="NomIncidente"
														type="input"
														className="form-control"
														placeholder="Título del incidente"
														value={this.props.Incidente.Titulo}
														required
													/>
												</Col>
												<Col md={4}>
													<Label for="Estatus">Estatus</Label>
													<Field
														name="Estatus"
														type="input"
														className="form-control"
														value={this.props.Incidente.NomIncidenteEstatus}
														placeholder="Nombre del Estatus"
													/>
												</Col>
												<Col md={4}>
													<Label for="ClaEstatusIncidente">Siguiente estatus</Label>
													<Field
														name="ClaEstatusIncidente"
														as="select"
														type="input"
														className="form-control"
														placeholder="Nombre del estatus">
														{listaEstatusSelect}
													</Field>
												</Col>
											</Row>
											<Row>
												<Col md={6}>
													<Label for="UsuarioAsignado">Asignado a </Label>
													<Field
														name="UsuarioAsignado"
														type="input"
														className="form-control"
														value={
															this.props.Incidente.Detalles[this.props.Incidente.Detalles.length - 1]
																.NomUsuarioAsignacion
														}
														placeholder="Usuario al que se le asignó la tarea"
													/>
												</Col>
												<Col md={3}>
													<Label for="NomCliente">Cliente</Label>
													<Field
														name="NomCliente"
														type="input"
														className="form-control"
														value={this.props.Incidente.NomCliente}
														placeholder="Nombre del Cliente"
													/>
												</Col>
												<Col md={3}>
													<Label for="nomProducto">Responsable </Label>
													<Field
														name="nomProducto"
														type="input"
														className="form-control"
														value={this.state.NomProducto}
														placeholder="Nombre del Responsable (Producto) de la entidad"
													/>
												</Col>
											</Row>
											<Row>
												<Col md={12}>
													<Label for="Descripcion">Descripción </Label>
													<Field
														name="Descripcion"
														type="input"
														className="form-control"
														placeholder="Descripción breve del incidente"
														value={this.props.Incidente.Descripcion}
														required
													/>
												</Col>
											</Row>

											<Row className={{ padding: 10 }}>
												<Col md={12}>
													<FormGroup>
														<Label for="DescripcionExtendida">Descripción Extendida</Label>
														<Field
															as="textarea"
															name="DescripcionExtendida"
															placeholder="Descripción detallada del incidente"
															id="DescripcionExtendida"
															value={this.props.Incidente.DescripcionExtendida}
															className="form-control textarea"
															required
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col md={12}>
													<FormGroup>
														<Label for="Comentarios">Comentarios</Label>
														<Field
															as="textarea"
															name="Comentarios"
															placeholder="Comentarios sobre el cambio"
															id="Comentarios"
															className="form-control "
															required
														/>
													</FormGroup>
												</Col>
											</Row>
										</FormGroup>
									</CardBody>
									<CardFooter>
										<Row>
											<Col className="text-right">
												<Button
													id="btnGuardar"
													className="animation-on-hover"
													color="success"
													size="sm"
													type="submit"
													value="guardar"
													disabled={estatusActual >= 3}>
													Guardar{" "}
												</Button>

												<Button
													id="btnCancelar"
													className="animation-on-hover"
													color="default"
													size="sm"
													onClick={() => this.btnCancelar_Click(false)}>
													Cancelar{" "}
												</Button>
											</Col>
										</Row>
									</CardFooter>
								</Card>
							</Form>
						)}
					</Formik>
				</div>
			</>
		);
	}
}

IncidenteMtto.propTypes = {
	Incidente: PropTypes.object,
	hideModal: PropTypes.func,
};
