const EntidadMtto = {
	titulo: "Nuevo Servicio",
	noInfo: "No se encontró información",
	lblTipo: "Tipo",
	lblEsquema: "Esquema",
	lblNombre: "Nombre",
	lblTitulo: "Título",
	lblServidorProduccion: "Servidor Producción",
	lblServidorSandbox: "Servidor Sandbox",
	lblGlosario: "Glosario",
	lblDescripcion: "Descripción en español",
	lblDescripcionIngles: "Descripción en inglés",
	lblUrlProduccion: "Url Producción",
	lblUrlSandbox: "Url Sandbox",

	phEsquema: "Esquema contenedor",
	phNombre: "Nombre para el servicio",
	phTitulo: "Título para el servicio",
	phGlosario: "Términos relacionados al servicio",
	phDescripcion: "Descripción detallada del servicio en español",
	phDescripcionIngles: "Descripción detallada del servicio en inglés",
	lblServicioPrivado: "El servicio es privado",
	btnGuardar: "Guardar",
	btnCancelar: "Cancelar",
	btnCerrar: "Cerrar",
	colNombre: "Nombre",
	colDescripcion: "Descripción en español",
	colDescripcionIngles: "Descripción en inglés",
	colTipo: "Tipo",
	ttNombreEntrada: "Nombre de la entrada",
	ttDescripcionEntrada: "Descripción en español  de qué es la entrada ",
	ttDescripcionEntradaIngles: "Descripción en inglés de qué es la entrada ",
	ttTipoEntrada: "Tipo de dato de la entrada",
	ttAgregarParametro: "Agregar un parámetro",
	lblConjunto: "Conjunto",
	ttNombreSalida: "Nombre de la salida",
	ttDescripcionSalida: "Descripción en español de qué es la salida ",
	ttDescripcionSalidaIngles: "Descripción en inglés de qué es la salida ",
	ttTipoSalida: "Tipo de dato de la salida",
	ttNombreRegla: "Nombre de la regla",
	ttDescripcionRegla: "Descripción de que es la regla",
	ttDescripcionReglaIngles: "Descripción en inglés de que es la regla:",
	ttAgregarRegla: "Agregar una regla de negocio",
	ttDescripcionServicio: "Descripción de que es el servicio",
	txtEjemploConsumirPost: "Para consumir el servicio se debe realizar una petición tipo",
	txtEjemploConsumirPost2: "a:",
	txtEjemploConsumirHeader: "El Encabezado o ",
	txtEjemploConsumirHeader2: "de la petición debe contener los siguientes parámetros:",
	txtCommentApi: "//Introducir un Api Key valido del sistema cliente",
	txtCommentToken: "// Introducir un token vigente",
	txtCommentBody: "//El tipo de estructura varia del 1 al 8",

	btnExportar: " Exportar",
	btnPublicar: " Publicar",
	ttPublicar: "Publica el servicio en ambiente productivo",
	navGeneral: "General",
	navEntradas: "Entradas",
	navSalidas: "Salidas",
	navReglas: "Reglas",
	navEjemplo: "Ejemplo",
	navResumen: "Resumen",
	navSuscritos: "Suscritos",

	alertPublicar: "¿Desea publicar la entidad?",
	alertPublicarDescripcion: "Esta acción no se puede deshacer",

	ttExportar: "Exportar a Postman 2.1",

	lblNoVerificado: "Este servicio no se encuentra verificado",
	lblDeseaAprobar: "¿Desea aprobarlo?",
	lblVerificar: "Verificar",

	alertVerificar: "¿Desea aprobar el servicio?",
	alertVerificarDescripcion: "Al verificar este servicio será publicado en un ambiente de producción",

	ttRechazar: "Rechazar",
	alertRechazo: "Rechazo de verificación",
	alertRechazoDescripcion: "Por ejemplo: datos incorrectos",
	alertRechazoMotivo: "Ingrese el motivo del rechazo del servicio",

	ttTipo: "Tipo de servicio",
	ttEsquema: "Esquema en la base de datos",
	ttNombre: "Nombre del servicio",
	ttTitulo: "Título del servicio",
	ttTituloAux: "Nuevo",
	ttServidorProduccion: "Servidor donde se ejecutará el servicio en ambiente productivo",
	ttServidorSandbox: "Servidor donde se ejecutará el servicio en ambiente de pruebas",

	ttGlosario: "Términos del glosario relacionados al servicio",
	phUrl: "Dirección url productiva para servicios API",
	phUrlSandbox: "Dirección url Sandbox para servicios API",
	errorEntradas: "Todas las entradas deben tener una descripción mínima de 20 caracteres",
	errorSalidas: "Todas las salidas deben tener una descripción mínima de 20 caracteres",

	alertNuevoServicioExito: "Servicio guardado con éxito",
	alertCambioServicioExito: "Cambios realizados con éxito",
	lblProduccion: "Producción",

	/**Ejemplo */
	txtEjemploConsumirBody: "El cuerpo o ", //body
	txtEjemploConsumirBody2: " de la petición debe contener una estructura similar a la siguiente donde, ", //tipoEstructura
	txtEjemploConsumirBody3: " es un valor numerico entre 1 y 8 el cual indica el formato que tendra la respuesta y ", // parameters
	txtEjemploConsumirBody4: " son los valores de entrada que requiere el servicio:",

	txtEjemploConsumirBody5: " es un valor numerico entre 1 y 8 el cual indica el formato que tendra la respuesta, ",
	txtEjemploConsumirBody6: " son los valores de entrada que requiere el servicio y ",
	txtEjemploConsumirBody7: " si cuenta con una condición para la ejecución de la vista.",

	txtEjemploConsumirJSON: "El servicio regresará un objeto en formato", // JSON
	txtEjemploConsumirJSON2: "con el siguiente formato:",
	commentEstructura: "//El tipo de estructura varia del 1 al 8",
	lblNoInfoIdioma: "No se encontró información en español",

	lblNoDescripcion: "No se encontró descripción",

	Suscritos: {
		Titulo: "Suscritos",
		lblProducto: "Producto",
		lbUso: "% de uso",
		lblErrores: "% de errores",
	},
};

export default EntidadMtto;
