const HomeDev = {
	titulo: "¿Qué quieres hacer hoy?",
	lblPendientesPorRealizar: "Mis servicios pendientes de realizar",
	ttPendientesPorRealizar: "Solicitudes asignadas que no han sido finalizadas",
	lblVerTodos: "Ver Todos",
	ttFinalizar: "Finalizar",
	ttSiguiente: "Siguiente Estatus",
	colSolicitud: "Solicitud",
	colEstatus: "Estatus",
	colCliente: "Cliente",
	colFechaPromesa: "Fecha Promesa",
	lblNoPendientes: "No tienes solicitudes pendientes",
	badgeAtrasado: "Atrasado",
	alertAtencion: "Atención",
	descripcionAtencion: "Para pasar al siguiente estatus primero se debe registrar el servicio",
	alertCambioExitoso: "Estatus cambiado con éxito",
	alertAlgoSalioMal: "Algo salió mal",
	lblNoDescripcion: "No se encontró descripción",
	operacionExitosa: "Operación realizada correctamente.",

	lblErroresIdentificados: "Errores identificados ",
	lblActualizar: "Actualizar",
	colServicio: "Servicio",
	colErrores: "Errores",
	phErrores: "Errores que han sido identificados en la ejecución de mis servicios",
	noErrores: "No se han encontrado errores",

	lblEstatusMisSolicitudes: "Estatus de mis solicitudes ",
	ttEstatusMisSolicitudes: "Estatus de las solicitudes que he realizado",
	lblCrearSolicitud: "Crear Solicitud",
	colProveedor: "Proveedor",
	ttInformacion: "Información",
	lblSolicitudesPendientes: "No tienes solicitudes pendientes",
	lblCargando: "Cargando...",

	lblMisServiciosPendientesVerif: "Mis servicios pendientes de verificación ",
	ttMisServiciosPendientesVerif: "Servicios que necesitan ser verificados",
	lblInformacionDeMisServicios: "Información de mis servicios",

	lblMisServiciosMasUtilizados: "Mis servicios más utilizados en los últimos 6 días  ",
	lblMisServiciosMenosUtilizados: "Mis servicios menos utilizados en los últimos 6 días  ",
	ttMisServiciosMasUtilizados: "Servicios más utilizados en los últimos 6 días  ",
	ttMisServiciosMenosUtilizados: "Servicios menos utilizados en los últimos 6 días  ",
	lblTotalPeticiones: "Total de peticiones",
	lblNoInformacion: "No se encontró información",

	lblMenorTiempo: "Mis servicios con menor tiempo de ejecución en las últimas 24 horas ",
	lblMayorTiempo: "Mis servicios con mayor tiempo de ejecución en las últimas 24 horas ",
	ttMenorTiempo: "Servicios con menor tiempo de ejecución en las últimas 24 horas ",
	ttMayorTiempo: "Servicios con mayor tiempo de ejecución en las últimas 24 horas",
	lblTiempoMilisegundos: "Tiempo en milisegundos",

	/**HOME PM */
	lblServiciosYSolicitudes: "Servicios y Solicitudes pendientes de autorizar ",
	lblPendientesEnviar: "Mis solicitudes pendientes de enviar ",
	ttPendientesEnviar: "Solicitudes que necesitan ser aprobadas para ser enviadas",
	colTitulo: "Título",
	colProducto: "Producto",
	colUsuario: "Usuario",
	ttInformacionSolicitud: "Información de la solicitud",

	lblSolicitudesRecibidas: "Solicitudes recibidas",
	ttSolicitudesRecibidas: "Solicitudes de suscripción a servicios privados",
	colCategoria: "Categoría",
};

export default HomeDev;
