import React from "react";
import { Row, Col, Button, Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { callApi } from "utils/Utilerias";
import { Config } from "../../utils/Config";
import translate from "utils/translate";

let srcData = { TotalPeticiones: 0 };
export default class IndicadoresEntidad extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			options: {},
			isLoaded: false,
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		};

		this.btnCancelarClick = this.btnCancelarClick.bind(this);
	}

	getChartOpstions() {
		return {
			chart: {
				type: "area",
				backgroundColor: "#27293d",
			},
			colors: ["#9AC5FC", "#e14eca"],
			title: {
				text: "",
			},
			legend: {
				itemStyle: {
					fontSize: "9pt",
					font: "9pt Trebuchet MS, Verdana, sans-serif",
					color: "#A0A0A0",
				},
			},
			plotOptions: {
				series: {
					label: {
						connectorAllowed: false,
					},
					allowPointSelect: true,
				},
			},
			tooltip: {
				shared: true,
				backgroundColor: "rgb(49, 51, 77, .80)",
				style: {
					color: "#FFFFFF",
				},
			},
			yAxis: {
				title: {
					text: translate("DashboardCliente.lblPeticiones"),
				},
				gridLineColor: "rgba(255, 255, 255, 0.1)",
				tickLength: 10,
			},
			xAxis: {},
			responsive: {
				rules: [
					{
						condition: {
							maxWidth: 500,
						},
						chartOptions: {
							legend: {
								layout: "horizontal",
								align: "center",
								verticalAlign: "bottom",
							},
						},
					},
				],
			},
			credits: {
				enabled: false,
			},
		};
	}

	componentDidMount() {
		this.fillData();
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.claProducto !== prevProps.claProducto ||
			this.props.claCliente !== prevProps.claCliente ||
			this.props.nomEntidad !== prevProps.nomEntidad ||
			this.props.nomProducto !== prevProps.nomProducto ||
			this.props.fechaDet !== prevProps.fechaDet
		) {
			this.setState({
				options: {},
				isLoaded: false,
			});
			this.fillData();
		}
	}

	fillData() {
		//Obtener información general de la entidad
		let urlService = `${Config.urlApiService}/Dashboard2`;
		let fecha = this.props.fechaDet;
		let fechaTiempo = fecha ? new Date(fecha.slice(2, -2).replace(/-/g, "/")) : undefined;
		let data = {
			ClaProducto: this.props.claProducto,
			ClaCliente: this.props.claCliente,
			NomEntidad: this.props.nomEntidad,
			NomProducto: this.props.nomProducto,
			FechaDet: fechaTiempo ? fechaTiempo.toSqlDateTime() : fechaTiempo,
			ClaConsulta: 3,
		};

		callApi(urlService, "POST", data, (status, response) => {
			if (status === 200 && response.length > 0) {
				srcData = response[0];

				this.setState({
					isLoaded: true,
					claIdioma: sessionStorage.getItem("ClaIdioma"),
				});
			}
		});

		//Obtener info de gráfica
		data = {
			ClaProducto: this.props.claProducto,
			ClaCliente: this.props.claCliente,
			NomEntidad: this.props.nomEntidad,
			NomProducto: this.props.nomProducto,
			FechaDet: fechaTiempo ? fechaTiempo.toSqlDateTime() : fechaTiempo,
			ClaConsulta: 4,
		};

		callApi(urlService, "POST", data, (status, response) => {
			let dataArray = response.data[0].Result0;
			let tempOptions = {};
			tempOptions = this.getChartOpstions();

			if (dataArray && response.data.length >= 2) {
				if (dataArray[0][dataArray[0].length - 1] !== "") {
					let labels = response.data[1].Result1[0][0].split(",");
					let dataChart = [];

					dataArray.map((item, index) => {
						let arrayName = dataArray[index].splice(dataArray[index].length - 1, 1)[0];
						let arrayItem = dataArray[index];

						dataChart.push({
							name: arrayName,
							data: arrayItem,
						});

						return item;
					});

					tempOptions.series = dataChart.reverse();
					tempOptions.xAxis.categories = labels;
				}
			} else {
				tempOptions.series = [{ name: "No se encontró información", data: 0 }];
				tempOptions.xAxis.categories = ["No se encotró información"];
			}

			this.setState({
				isLoaded: true,
				options: tempOptions,
				claIdioma: sessionStorage.getItem("ClaIdioma"),
			});
		});
	}

	btnCancelarClick() {
		this.props.hideModalEntidad();
	}

	render() {
		const PeticionesPorHora = () => {
			return (
				<>
					{sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.fillData()}
					<HighchartsReact
						containerProps={{ style: { height: "100%" } }}
						highcharts={Highcharts}
						options={this.state.options}
					/>
				</>
			);
		};

		if (!this.state.isLoaded) {
			return (
				<div className="row justify-content-center p-5">
					<div className="spinner-border text-info" style={{ backgroundColor: "transparent" }} role="status">
						<span className="sr-only">Cargando...</span>
					</div>
				</div>
			);
		} else {
			return (
				<Card style={{ height: "600px" }}>
					<CardHeader>
						<h2 className="pb-0">
							{srcData.NomProducto} - {srcData.NomEntidad} - {this.props.fechaDet}
						</h2>
					</CardHeader>
					<CardBody className="entidadCardBody">
						<Row className="text-default text-center justify-content-center">
							<Col md={2}>
								<span id="txtTotalPeticiones" className="display-3 text-primary">
									{srcData.TotalPeticiones.toFormatNumber()}
								</span>
								<br />
								{translate("DashboardCliente.lblPeticiones")}
							</Col>
							<Col md={2}>
								<span className="display-3 text-primary">{srcData.FrecuenciaUsoProm}</span>
								<br /> {translate("DashboardCliente.lblSolicitudesPorHora")}
							</Col>
							<Col md={2}>
								<span className="display-3 text-primary">{srcData.FrecuenciaErroresProm} </span>
								<br /> {translate("DashboardCliente.lblErroresPorHora")}
							</Col>
							<Col md={2}>
								<span className="display-3 text-primary">{srcData.PorcentajeError}</span>
								<br /> {translate("DashboardCliente.lblPorcentajeError")}
							</Col>
							{this.props.claProducto !== undefined && (
								<Col md={2}>
									<span className="display-3 text-primary">{srcData.Clientes}</span> <br /> Clientes totales
								</Col>
							)}
							{this.props.claProducto !== undefined && (
								<Col md={2}>
									<span className="display-3 text-primary">{srcData.ClientesAfectados}</span> <br />
									{translate("DashboardCliente.lblClientesAfectados")}
								</Col>
							)}
						</Row>
						<br />
						<Row>
							<Col>
								<PeticionesPorHora />
							</Col>
						</Row>
					</CardBody>
					<CardFooter>
						<Row>
							<Col md={12} className="text-right">
								<Button
									id="btnCancelar"
									className="animation-on-hover"
									color="default"
									size="sm"
									onClick={() => this.btnCancelarClick()}>
									{translate("DashboardCliente.lblCerrar")}
								</Button>
							</Col>
						</Row>
					</CardFooter>
				</Card>
			);
		}
	}
}

IndicadoresEntidad.propTypes = {
	claProducto: PropTypes.number,
	claCliente: PropTypes.number,
	nomEntidad: PropTypes.string,
	nomProducto: PropTypes.string,
	fechaDet: PropTypes.string,
	hideModalEntidad: PropTypes.func,
};
