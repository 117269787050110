const SolicitudServicio = {
	titulo: "Solicitudes de servicio",
	lblProducto: "Producto",
	lblFechaDesde: "Fecha Desde: ",
	lblHasta: "Hasta: ",
	phFechaDesde: "Fecha inicio de solicitud",
	phHasta: "Fecha final",
	ttBuscar: "Buscar",
	ttLimpiar: "Limpiar",
	btnCrearSolicitud: " Crear Solicitud",
	lblSolicitudesRealizadas: "Solicitudes Realizadas:",
	lblVerMisSolicitudes: "Ver solo mis solicitudes",
	colRealizadasDevServicio: "Servicio",
	colRealizadasDevSolicitadoA: "Solicitado a",
	colRealizadasDevUsuarioSolicita: "Usuario Solicita",
	colRealizadasDevEstatus: "Estatus",
	colRealizadasDevFechaSolicita: "Fecha Solicitud",
	colRealizadasDevFechaPromesa: "Fecha Promesa",
	ttInformacion: "Información",
	colAsignadasDevServicio: "Servicio",
	colAsignadasDevSolicitadoA: "Solicitado a",
	colAsignadasDevUsuarioSolicita: "Usuario Solicita",
	colAsignadasEstatus: "Estatus",
	colAsignadasFechaSolicita: "Fecha Solicita",
	colAsignadasFechaPromesa: "Fecha promesa",
	lblVerSoloPendientes: "Ver solo pendientes",
	lblSolicitudesRecibidas: "Solicitudes Recibidas",
	colRecibidasPMServicio: "Servicio",
	colRecibidasPMSolicitante: "Solicitante",
	colRecibidasPMUsuarioSolicita: "Usuario Solicita",
	colRecibidasPMEstatus: "Estatus",
	colRecibidasPMFechaSolicitud: "Fecha Solicitud",
	colRecibidasPMFechaPromesa: "Fecha Promesa",
	ttVerificar: "Verificar",
	ttAutorizar: "Autorizar",
	ttAprobarSolicitud: "Aprobar Solicitud",
	ttRechazar: "Rechazar",
	ttEliminar: "Eliminar",
	lblSolSal: "Solicitudes Salientes",
	colSolSalPMServicio: "Servicio",
	colSolSalPMSolicitadoA: "Solicitado a",
	colSolSalPMUsuarioSolicita: "Usuario Solicita",
	colSolSalPMEstatus: "Estatus",
	colSolSalPMFechaSolicitud: "Fecha Solicitud",

	lblSiguienteEstatus: "Siguiente Estatus",
	ttSiguienteEstatus: "Mover al siguiente estatus",
	ttAsignar: "Asignar",
	alertCambio: "Estatus cambiado con éxito",
	alertError: "Algo salió mal",
	noInfo: "No se encontró información",

	lblSolicitudesAsignadas: "Solicitudes Asignadas:",
	lblSolicitudesSalientes: "Solicitudes Salientes:",
	alertRegistroEliminado: "El registro se eliminó exitosamente",
	notificacion: "Operación realizada correctamente.",

	alertAtencion: "Atención",
	alertAtencionDescripcion: "Para pasar al siguiente estatus primero se debe registrar el servicio",

	alertEstatusCambiado: "Estatus cambiado con éxito",
	alertAlgoSalioMal: "Algo salió mal",
};

export default SolicitudServicio;
