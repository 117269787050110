/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import defaultAvatar from "assets/img/placeholder.jpg";
import translate from "utils/translate";

class ImageUpload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			file: null,
			imagePreviewUrl: this.props.image ? this.props.image : defaultAvatar,
		};
		this.handleImageChange = this.handleImageChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
	}

	handleImageChange(e) {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			this.setState({
				file,
				imagePreviewUrl: reader.result,
			});
			this.props.onChange(reader.result);
		};
		reader.readAsDataURL(file);
	}

	handleSubmit(e) {
		e.preventDefault();
		// this.state.file is the file/image uploaded
		// in this function you can save the image (this.state.file) on form submit
		// you have to call it yourself
	}

	handleClick() {
		this.fileInput.click();
	}

	handleRemove() {
		this.setState({
			file: null,
			imagePreviewUrl: this.props.image ? this.props.image : defaultAvatar,
		});
		this.fileInput.value = null;
	}

	render() {
		return (
			<div className="fileinput text-center">
				<input
					type="file"
					accept="image/x-png,image/jpeg"
					onChange={this.handleImageChange}
					ref={(ref) => (this.fileInput = ref)}
				/>
				<div className={`thumbnail${this.props.image ? " img-circle" : ""}`}>
					<img src={this.state.imagePreviewUrl} alt="..." width={100} />
				</div>
				<div>
					{this.state.file === null ? (
						<Button
							color={this.props.addBtnColor}
							className={this.props.addBtnClasses}
							onClick={() => this.handleClick()}>
							{this.props.image ? translate("Producto.btnActualizar") : translate("Producto.btnAgregar")}
						</Button>
					) : (
						<span>
							<Button
								color={this.props.changeBtnColor}
								className={this.props.changeBtnClasses}
								onClick={() => this.handleClick()}>
								Cambiar
							</Button>
							{this.props.image ? <br /> : null}
							<Button
								color={this.props.removeBtnColor}
								className={this.props.removeBtnClasses}
								onClick={() => this.handleRemove()}>
								<i className="fa fa-times" /> Remover
							</Button>
						</span>
					)}
				</div>
			</div>
		);
	}
}

ImageUpload.defaultProps = {
	avatar: false,
	removeBtnClasses: "btn-round",
	removeBtnColor: "danger",
	addBtnClasses: "btn-round",
	addBtnColor: "primary",
	changeBtnClasses: "btn-round",
	changeBtnColor: "primary",
};

ImageUpload.propTypes = {
	avatar: PropTypes.bool,
	removeBtnClasses: PropTypes.string,
	removeBtnColor: PropTypes.oneOf(["default", "primary", "secondary", "success", "info", "warning", "danger", "link"]),
	addBtnClasses: PropTypes.string,
	addBtnColor: PropTypes.oneOf(["default", "primary", "secondary", "success", "info", "warning", "danger", "link"]),
	changeBtnClasses: PropTypes.string,
	changeBtnColor: PropTypes.oneOf(["default", "primary", "secondary", "success", "info", "warning", "danger", "link"]),
};

export default ImageUpload;

ImageUpload.propTypes = {
	image: PropTypes.any,
	onChange: PropTypes.func,
};
