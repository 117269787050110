const Config = {
	LoginLogoUrl: "https://kraken.deacero.com/Images/Kraken_Logo.png",
	LoginMensaje: "Powered by DLabs",
	LoginLogoTamanio: "48px",
	ApiKey: "C5D663F3-7687-4EE1-B225-96967FD8BFC8",

	// ** Produccion
	urlSocket: "https://krakenui.deacero.com",
	urlApiService: "https://krakenui.deacero.com/KrakenWebService",
	urlApi: "https://krakenapi.deacero.com/KrakenServices",
	urlNoProductivo: "https://krakenapi.deacero.com/SandboxServices",
	urlLoginService: "https://sweetsrv.azurewebsites.net/Login/authenticate",

	// ** Desarrollo
	// urlSocket: "http://dlabsdbdev:2022",
	// urlApiService: "http://dlabsdbdev:2022/KrakenWebService",
	// urlApi: "http://dlabsdbdev:2022/KrakenServices",
	// urlNoProductivo: "http://dlabsdbdev:2022/SandboxServices",
	// urlLoginService: "http://dlabsdbdev:2622/Login/authenticate",

	// ** LocalHost
	// urlSocket: "http://localhost:3300",
	// urlApiService: "http://localhost:3300/KrakenWebService",
	// urlApi: "http://localhost:3301/KrakenServices",
	// urlNoProductivo: "http://localhost:3301/SandboxServices",
	// urlLoginService: "https://sweetsrv.azurewebsites.net/Login/authenticate",

	// ** Azure
	// urlSocket: "http://172.17.0.1:3300",
	// urlApiService: "http://172.17.0.1:3300/KrakenWebService",
	// urlApi: "http://172.17.0.1:3301/KrakenServices",
	// urlLoginService: "https://sweetsrv.azurewebsites.net/Login/authenticate",
	// urlNoProductivo: "http://172.17.0.1:3301/SandboxServices",

	// ** Sandbox
	// urlSocket: "http://dlabssandbox:3300",
	// urlApiService: "http://dlabssandbox:2022/KrakenWebService",
	// urlApi: "http://dlabssandbox:2022/KrakenServices",
	// urlNoProductivo: "http://dlabssandbox:2022/SandboxServices",
	// urlLoginService: "http://dlabssandbox:2622/Login/authenticate",

	UrlWebServer: "http://dlabsdbdev:2020/",
};

const Tours = {
	HomePm: 1,
	HomeDev: 2,
	Replicas: 3,
	ServiciosDisponibles: 4,
	SolicitudServicioPM: 5,
	SolicitudServicioDev: 6,
	Usuarios: 7,
	ServiciosPublicados: 8,
	Suscripciones: 9,
	Ejemplo: 10,
	NuevosFeatureServiciosDisponibles: 11,
	NuevosFeatureSuscritos: 12,
	NuevosFeaturePublicados: 13,
	NuevosFeaturesPM: 14,
	DashboardProveedor: 15,
	DashboardCliente: 16,
	NuevosFeaturesDev: 17,
	Publicar: 18,
};

function GetToken() {
	return sessionStorage.getItem("Token");
}

export { Config, GetToken, Tours };
