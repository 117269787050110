import React from "react";
import { Card, CardHeader, CardBody, Modal, Button, UncontrolledTooltip, Row, Col } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { getProducto, callApi, showAlert, writeLog } from "utils/Utilerias";
import { Config } from "utils/Config";
import { Link } from "react-router-dom";
import SolicitudServicioMtto from "../SolicitudServicios/SolicitudServicioMtto";
import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";

let srcData = [];
let formularioDet = [];

export default class PendientesPorEnviar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			peticiones: [],
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		};

		this.load = this.load.bind(this);
		this.hideModal = this.hideModal.bind(this);
		this.showAlert = this.showAlert.bind(this);
	}

	componentDidMount() {
		this.load();
	}

	load() {
		//Help tooltips
		let urlService = `${Config.urlApiService}/CatFormularioDet/4`;
		callApi(urlService, "GET", null, (status, response) => {
			formularioDet = response;
		});

		//Obtener información
		urlService = `${Config.urlApiService}/Home/PendientesEnviar/${getProducto()}`;

		callApi(urlService, "GET", null, (status, response) => {
			if (status === 200) {
				srcData = response.map((obj, key) => {
					return {
						Titulo: obj.Titulo,
						Revisar: (
							<>
								<UncontrolledTooltip placement="left" target={`btnVerDetalle_${key}`}>
									{translate("HomeDev.ttInformacionSolicitud")}
								</UncontrolledTooltip>
								<Button
									id={`btnVerDetalle_${key}`}
									className="animation-on-hover float-right btn btn-link btn-img"
									color="info"
									size="sm"
									onClick={() => {
										writeLog(
											Formularios.PendientesEnviar.ClaFuncionalidad,
											Formularios.PendientesEnviar.Funcionalidades.btnVerDetalle,
											undefined,
											obj,
										);
										this.setState({
											showModal: true,
											idPeticion: obj.IdPeticion,
										});
									}}>
									<i className="fas fa-clipboard-list" />
								</Button>
							</>
						),
						NomProducto: obj.NomProducto,
						NomUsuarioSolicita: obj.NomUsuarioSolicita,
					};
				});
				this.setState({
					isLoaded: true,
					peticiones: srcData,
					claIdioma: sessionStorage.getItem("ClaIdioma"),
				});
			}
		});
	}

	renderDetTexto(nomControl) {
		let detalle = formularioDet.filter((detalle) => detalle.NomControl === nomControl);

		if (detalle[0]) {
			return detalle[0].Texto;
		} else {
			return "No se encontró descripción";
		}
	}

	/**
	 * Muestra los prompts de alertas
	 */
	showAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Oculta los promts de alertas
	 */
	hideAlert() {
		this.setState({
			showAlert: null,
			showConfirm: null,
		});
	}

	/**
	 * Función 	que oculta el modal
	 * @param {Boolean} isOk establece si la operación fue realizada de manera
	 * correcta y refresca la pantalla
	 */
	hideModal(isOk) {
		if (isOk) {
			const options = {
				place: "br",
				message: "Operación realizada correctamente.",
				type: "success",
				autoDismiss: 3,
				// icon: icon
			};

			this.notify.notificationAlert(options);

			this.load();
		}

		this.setState({
			showModal: false,
		});
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<div className="spinner-border text-info" role="status">
					<span className="sr-only">{translate("HomeDev.lblCargando")}</span>
				</div>
			);
		} else {
			return (
				<>
					{sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.load()}
					<NotificationAlert ref={(ref) => (this.notify = ref)} />

					{this.state.showAlert}
					<Card id="ctnSolicitudesPorEnviar" style={{ maxHeight: "inherit", minHeight: "inherit" }}>
						<CardHeader>
							<UncontrolledTooltip placement="right" target={`helpPendientesDeEnviar`}>
								<span>{translate("HomeDev.ttPendientesEnviar")}</span>
							</UncontrolledTooltip>

							<Row>
								<Col md={9}>
									<h2>
										{translate("HomeDev.lblPendientesEnviar")}
										<span id="helpPendientesDeEnviar">
											<i className="far fa-question-circle"></i>
										</span>{" "}
									</h2>
								</Col>
								<Col md={3}>
									<Link to="/admin/SolicitudServicio">
										<Button
											id="btnVerTodos"
											className="btn-link btn-home float-right"
											size="sm"
											onClick={() => {
												writeLog(
													Formularios.PendientesEnviar.ClaFuncionalidad,
													Formularios.PendientesEnviar.Funcionalidades.btnVerTodos,
												);
												this.setState({ showSolitudServicios: true });
											}}>
											{translate("HomeDev.lblVerTodos")}
										</Button>
									</Link>
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							<div className="pendientesEnviar">
								<table className="table table-sm table-noBorder">
									<thead>
										<tr>
											<th className="w-35">{translate("HomeDev.colTitulo")}</th>
											<th className="w-20">{translate("HomeDev.colProducto")}</th>
											<th className="w-35">{translate("HomeDev.colUsuario")}</th>
											<th className="w-10"></th>
											{/* <th></th> */}
										</tr>
									</thead>
									<tbody>
										{(this.state.peticiones.length > 0 &&
											this.state.peticiones.map((obj, index) => {
												return (
													<tr key={`pet_${index}`}>
														<td>{obj.Titulo}</td>
														<td>{obj.NomProducto}</td>
														<td>{obj.NomUsuarioSolicita}</td>
														<td>
															{obj.Revisar}
															{/* {obj.Enviar} */}
														</td>
														{/* <td>{obj.Enviar}</td> */}
													</tr>
												);
											})) || (
											<tr>
												<td colSpan={4} style={{ textAlign: "center", height: "105px" }}>
													<p>
														{translate("HomeDev.lblSolicitudesPendientes")}
														<span className="btn-icon btn-link text-success">
															<i className="fas fa-check"></i>
														</span>
													</p>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</CardBody>
					</Card>

					<Modal centered={true} isOpen={this.state.showModal} size="lg">
						<SolicitudServicioMtto
							hideModal={this.hideModal}
							pantallaEntidad={false}
							idPeticion={this.state.idPeticion}
							claProducto={getProducto()}
						/>
					</Modal>
				</>
			);
		}
	}
}
