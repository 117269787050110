import React from "react";
import PropTypes from "prop-types";
import SyntaxHighlighter from "react-syntax-highlighter";
import { lioshi } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { callApi, showSweetAlert, logOut } from "utils/Utilerias";
import { Redirect, Switch } from "react-router-dom";
import { Config } from "utils/Config";
import {
	Card,
	CardBody,
	CardFooter,
	Form,
	Row,
	Col,
	Button,
	Navbar,
	Container,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	NavbarBrand,
	Label,
} from "reactstrap";
import { Formik } from "formik";

require("utils/Prototipos");

let params;
export default class Error extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			datos: {},
			existenciaDet: {},
			errorEnProceso: false,
			existeIdUso: false,
			exit: false,
		};
		this.handleSubmitAceptar = this.handleSubmitAceptar.bind(this);
		this.handleSubmitResolverError = this.handleSubmitResolverError.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
	}
	componentDidMount() {
		this.load();
	}

	load() {
		params = new URLSearchParams(this.props.location.search);
		let urlApi = `${Config.urlApiService}/Error/${params.get("idUso")}`;
		callApi(urlApi, "GET", null, (status, response) => {
			this.setState({
				datos: response[0],
			});
			if (this.state.datos !== undefined) {
				this.setState({
					existeIdUso: true,
				});
			} else {
				this.setState({
					existeIdUso: false,
				});
			}
		});
		urlApi = `${Config.urlApiService}/Error/RevisarExistencia/${params.get("idUso")}`;
		callApi(urlApi, "GET", null, (status, response) => {
			this.setState({
				existenciaDet: response[0],
			});

			if (this.state.existenciaDet !== undefined) {
				this.setState({
					errorEnProceso: true,
				});
			} else {
				this.setState({
					errorEnProceso: false,
				});
			}
		});
	}

	/**
	 * Función que cierra sesión y redirige a la pantalla de login
	 */
	cerrarSesion() {
		logOut();
		this.props.history.push({
			pathname: "/Login",
			isLogedOut: true,
		});
	}

	renderSyntaxHighligter(text) {
		return (
			<SyntaxHighlighter className="round" language="json" style={lioshi} showLineNumbers={true}>
				{text}
			</SyntaxHighlighter>
		);
	}

	/**
	 * Funcion para ocultar la alerta/mensaje que fue activado
	 */
	hideAlert() {
		this.setState({
			exit: true,
		});
	}

	handleSubmitAceptar() {
		//Guardar mensaje en tabla de KraLogUso
		let data = {
			idUso: this.state.datos.IdUso,
			idEntidad: this.state.datos.IdEntidad,
			claProducto: this.state.datos.ClaProducto,
			claCliente: this.state.datos.ClaCliente,
			claUsuario: this.state.datos.ClaUsuarioMod,
			codigoError: this.state.datos.Mensaje,
			nombrePcMod: this.state.datos.NombrePcMod,
		};

		let urlWebService = `${Config.urlApiService}/Error/LogError`;
		let method = "PUT";

		callApi(urlWebService, method, data, () => {
			showSweetAlert(
				"Se detuvo el envío de correos.",
				"Se detienen los correos correspondientes a este error, sin embargo la bitácora seguirá activa",
				"warning",
				this.hideAlert,
			);
		});
	}

	handleSubmitResolverError() {
		let data = {
			idUso: this.state.datos.IdUso,
			codigoError: this.state.datos.Mensaje,
		};

		let urlWebService = `${Config.urlApiService}/Error/UpdateError`;
		let method = "POST";

		callApi(urlWebService, method, data, () => {
			showSweetAlert("Se registró el cierre del error correctamente.", "", "success", this.hideAlert);
		});
	}

	renderNoSeEncuentraId() {
		return (
			<div className="row justify-content-center">
				<div className="col-5">
					<Card justify="center">
						<CardBody justify="center">
							<h2>Error</h2>
							<Row>
								<Col md={12}>
									<h5>
										El folio: <b>{params.get("idUso")} </b> no se encuentra en la base de datos.
									</h5>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</div>
			</div>
		);
	}

	renderPantallaResolverError() {
		let estatus = this.state.existenciaDet.ClaEstatusError === 1;
		return (
			<div className="row justify-content-center">
				<div className="col-5">
					<Formik initialValues={{}} onSubmit={this.handleSubmitResolverError}>
						{(props) => (
							<Form onSubmit={props.handleSubmit}>
								<Card justify="center">
									<CardBody>
										<h2>
											{this.state.existenciaDet.ClaEstatusError === 1
												? "Solucionar Error"
												: "Error Solucionado"}
										</h2>
										<Row>
											<Col md="2">
												<Label>Folio:</Label>
											</Col>
											<Col md="10">
												<Label>{params.get("idUso")}</Label>
											</Col>
										</Row>
										<Row>
											<Col md="2">
												<Label>Cliente:</Label>
											</Col>
											<Col md="10">
												<Label>{this.state.datos.NomCliente}</Label>
											</Col>
										</Row>
										<Row>
											<Col md="2">
												<Label>Proveedor:</Label>
											</Col>
											<Col md="10">
												<Label>{this.state.datos.NomProveedor}</Label>
											</Col>
										</Row>
										<Row>
											<Col>
												<br></br>
											</Col>
										</Row>
										<Row>
											<Col md="12">
												<Label>Mensaje</Label>
											</Col>
										</Row>
										<Row>
											<Col md="12">{this.renderSyntaxHighligter(this.state.datos.Mensaje)}</Col>
										</Row>
										<Row>
											<Col md="12">
												<Label>Detalle</Label>
											</Col>
										</Row>
										<Row>
											<Col md="12"> {this.renderSyntaxHighligter(this.state.datos.Detalle)}</Col>
										</Row>
										<Row>
											<Col md="12">
												{estatus ? <Label>Para cerrar el incidente da click aquí.</Label> : null}
											</Col>
										</Row>
									</CardBody>
									{this.state.existenciaDet.ClaEstatusError === 1 && (
										<CardFooter>
											<Row>
												<Col md={12} className="text-center">
													<Button
														id="btnEnviar"
														className="animation-on-hover"
														color="success"
														size="sm"
														type="submit">
														Terminar
													</Button>
												</Col>
											</Row>
										</CardFooter>
									)}
								</Card>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		);
	}

	renderPantallaError() {
		return (
			<div className="row justify-content-center">
				<div className="col-5">
					<Formik initialValues={{}} onSubmit={this.handleSubmitAceptar}>
						{(props) => (
							<Form onSubmit={props.handleSubmit}>
								<Card justify="center">
									<CardBody justify="center">
										<h2>Error</h2>
										<Row>
											<Col md="2">
												<Label>Folio:</Label>
											</Col>
											<Col md="10">
												<Label>{params.get("idUso")}</Label>
											</Col>
										</Row>
										<Row>
											<Col md="2">
												<Label>Cliente:</Label>
											</Col>
											<Col md="10">
												<Label>{this.state.datos.NomCliente}</Label>
											</Col>
										</Row>
										<Row>
											<Col md="2">
												<Label>Proveedor:</Label>
											</Col>
											<Col md="10">
												<Label>{this.state.datos.NomProveedor}</Label>
											</Col>
										</Row>
										<Row>
											<Col>
												<br></br>
											</Col>
										</Row>
										<Row>
											<Col md="12">
												<Label>Mensaje</Label>
											</Col>
										</Row>
										<Row>
											<Col md="12">{this.renderSyntaxHighligter(this.state.datos.Mensaje)}</Col>
										</Row>
										<Row>
											<Col md="12">
												<Label>Detalle</Label>
											</Col>
										</Row>
										<Row>
											<Col md="12"> {this.renderSyntaxHighligter(this.state.datos.Detalle)}</Col>
										</Row>
										<Row>
											<Col md="12">
												<Label>Para hacerte cargo del incidente da click aquí.</Label>
											</Col>
										</Row>
									</CardBody>
									<CardFooter>
										<Row>
											<Col md={12} className="text-center">
												<Button
													id="btnEnviar"
													className="animation-on-hover"
													color="success"
													size="sm"
													type="submit">
													Atender Error
												</Button>
											</Col>
										</Row>
									</CardFooter>
								</Card>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		);
	}

	render() {
		params = new URLSearchParams(this.props.location.search);
		if (this.state.exit) {
			return (
				<Switch>
					<Redirect from="/Error" to="/SeleccionProducto" />
				</Switch>
			);
		}

		return (
			<div className="content">
				<div className="row">
					<div className="col-12 pb-2">
						<Navbar>
							<Container fluid>
								<NavbarBrand>
									<span style={{ color: "white", textTransform: "capitalize" }}>Detalles del Error</span>
								</NavbarBrand>
								<UncontrolledDropdown>
									<DropdownToggle nav caret>
										<i className="fas fa-cog fa-2x" style={{ color: "white" }}></i>
									</DropdownToggle>
									<DropdownMenu right>
										<DropdownItem
											className="nav-item"
											onClick={() => {
												this.cerrarSesion();
											}}>
											Salir
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
							</Container>
						</Navbar>
					</div>
				</div>
				{!this.state.existeIdUso
					? this.renderNoSeEncuentraId()
					: this.state.errorEnProceso
					? this.renderPantallaResolverError()
					: this.renderPantallaError()}
			</div>
		);
	}
}

Error.propTypes = {
	location: PropTypes.any,
	params: PropTypes.any,
	history: PropTypes.any,
};
