const BitacoraUso = {
	titulo: "Bitácora de uso",
	lblFolio: "Folio",
	phFolio: "Número de folio",
	lblClaveServicio: "Clave del servicio",
	phClaveServicio: "Número del servicio",
	lblFechaInicial: "Fecha inicial",
	phFechaInicial: "Fecha inicial de la consulta",
	lblFechaFinal: "Fecha final",
	phFechaFinal: "Fecha final de la consulta",
	ttBuscar: "Buscar",
	ttLimpiar: "Limpiar",
	lblTotalDeRegistros: "Total de registros: ",
	colFolio: "Folio",
	colNoServicio: "No. Servicio",
	colServicio: "Servicio",
	colFecha: "Fecha",
	colCliente: "Cliente",
	rowRespuesta: "Respuesta",
};

export default BitacoraUso;
