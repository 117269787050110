import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { callApi, getThemeColors, writeLog } from "utils/Utilerias";
import { Config } from "../../utils/Config";
import PropTypes from "prop-types";
import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";

export default class ServiciosMayorTiempo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		};
	}

	getChartOpstions() {
		return {
			chart: {
				type: "column",
				backgroundColor: "#27293d",
			},
			colors: ["#1d8cf8", "#2dce89", "#f3a4b5", "#f5365c", "#8965e0"],
			title: {
				text: "",
			},
			legend: {
				itemStyle: {
					fontSize: "10pt",
					font: "10pt Trebuchet MS, Verdana, sans-serif",
					color: "#A0A0A0",
				},
			},
			plotOptions: {
				column: {
					borderWidth: 0,
					dataLabels: {
						enabled: false,
					},
				},
				series: {
					cursor: "pointer",
					point: {
						events: {
							click: (e) => {
								if (this.props.claProducto !== undefined) {
									writeLog(
										Formularios.DashboardProveedor.ClaFuncionalidad,
										Formularios.DashboardProveedor.Funcionalidades.MayorTiempoClick,
									);
								} else {
									writeLog(
										Formularios.DashboardCliente.ClaFuncionalidad,
										Formularios.DashboardCliente.Funcionalidades.MayorTiempoClick,
									);
								}

								this.props.hideModalEntidad(false, e.point.series.name, e.point.category);
							},
						},
					},
				},
			},
			tooltip: {
				shared: true,
				backgroundColor: "rgb(49, 51, 77, .80)",
				style: {
					color: "#FFFFFF",
				},
				valueSuffix: " milisegundos",
			},
			yAxis: {
				title: {
					text: translate("DashboardProveedor.lblTiempoDeEjecucion"),
				},
				gridLineColor: "rgba(255, 255, 255, 0.1)",
				tickLength: 10,
			},
			xAxis: {},
			credits: {
				enabled: false,
			},
		};
	}

	componentDidMount() {
		this.fillData();
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.claCliente !== prevProps.claCliente ||
			this.props.claProducto !== prevProps.claProducto ||
			this.props.nomProducto !== prevProps.nomProducto ||
			this.props.fechaInicio !== prevProps.fechaInicio ||
			this.props.fechaFin !== prevProps.fechaFin
		) {
			this.setState({
				options: {},
				isLoaded: false,
			});
			this.fillData();
		}
	}

	fillData() {
		const urlService = `${Config.urlApiService}/Dashboard2`;
		const payload = {
			ClaProducto: this.props.claProducto,
			ClaCliente: this.props.claCliente,
			ClaConsulta: 13,
			NomProducto: this.props.nomProducto,
			FechaInicial: this.props.fechaInicio ? this.props.fechaInicio.toSqlDateTime() : undefined,
			FechaFinal: this.props.fechaFin ? this.props.fechaFin.toSqlDateTime() : undefined,
		};

		callApi(
			urlService,
			"POST",
			payload,
			(status, response) => {
				let dataArray = response.data[0].Result0;
				let tempOptions = {};
				tempOptions = this.getChartOpstions();

				if (dataArray && response.data.length >= 2) {
					if (dataArray[0][0] !== "") {
						let labels = response.data[1].Result1[0][0].split(",");

						let dataChart = [];

						dataArray.map((item, index) => {
							let arrayName = dataArray[index].splice(0, 1)[0];
							let arrayItem = dataArray[index];

							dataChart.push({
								name: arrayName,
								data: arrayItem,
							});

							return item;
						});

						tempOptions.series = dataChart;
						tempOptions.xAxis.categories = labels;
					}
					if (this.props.claProducto !== undefined) {
						writeLog(
							Formularios.DashboardProveedor.ClaFuncionalidad,
							Formularios.DashboardProveedor.Funcionalidades.MayorTiempoCarga,
							urlService,
							payload,
							response,
							undefined,
							0,
						);
					} else {
						writeLog(
							Formularios.DashboardCliente.ClaFuncionalidad,
							Formularios.DashboardCliente.Funcionalidades.MayorTiempoCarga,
							urlService,
							payload,
							response,
							undefined,
							0,
						);
					}
				} else {
					tempOptions.series = [{ name: translate("DashboardProveedor.lblNoSeEncontroInformacion"), data: 0 }];
					tempOptions.xAxis.categories = translate("DashboardProveedor.lblNoSeEncontroInformacion");
				}

				this.setState({
					isLoaded: true,
					totalAGraficar: dataArray ? dataArray.length : 1,
				});

				tempOptions.colors = getThemeColors(this.state.totalAGraficar);

				this.setState({
					options: tempOptions,
					claIdioma: sessionStorage.getItem("ClaIdioma"),
				});
			},
			this.props.claProducto !== undefined
				? Formularios.DashboardProveedor.ClaFuncionalidad
				: Formularios.DashboardCliente.ClaFuncionalidad,
			this.props.claProducto !== undefined
				? Formularios.DashboardProveedor.Funcionalidades.MayorTiempoCarga
				: Formularios.DashboardCliente.ClaFuncionalidad,
		);
	}

	render() {
		if (this.state.isLoaded) {
			return (
				<>
					{sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.fillData()}
					<HighchartsReact
						containerProps={{ style: { height: "100%" } }}
						highcharts={Highcharts}
						options={this.state.options}
					/>
				</>
			);
		} else {
			return (
				<div className="text-center">
					<h3>{translate("DashboardProveedor.lblCargando")}</h3>
				</div>
			);
		}
	}
}

ServiciosMayorTiempo.propTypes = {
	claProducto: PropTypes.number,
	claCliente: PropTypes.number,
	nomProducto: PropTypes.string,
	fechaInicio: PropTypes.object,
	fechaFin: PropTypes.object,
	hideModalEntidad: PropTypes.func,
};
