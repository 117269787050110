const Login = {
	lblBienvenido: "Welcome",
	lblUsuario: "User",
	lblContraseña: "Password",
	lblOlvidasteTuContraseña: "Did you forget your password?",
	btnEntrar: "Log In",
	AcercaDe: "About",
};

export default Login;
