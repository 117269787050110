const MisServiciosPublicados = {
	titulo: "Mis Servicios Publicados",
	lblTipo: "Tipo",
	lblServicio: "Servicio",
	lblGlosario: "Glosario",
	btnAgregar: " Agregar",
	lblTotalDeRegistros: "Total de registros",
	colNombre: "Nombre",
	phNombre: "Nombre, descripción o número del servicio",
	phTerminosRelacionados: "Términos relacionados",
	ttLimpiar: "Limpiar",
	ttBuscar: "Buscar",
	ttVerificado: "Servicio verificado",
	ttNoVerificado: "El servicio no está verificado",
	ttEditar: "Editar",
	ttComentarios: "Comentarios",
	ttEliminar: "Eliminar",
	lblBusqueda: "Buscar servicios disponibles",
	btnCerrar: "Cerrar",
	alertEliminar: "El servicio se eliminó correctamente",
	alertDesactivar: "¿Desea desactivar el servicio?",
	operacionExitosa: "Operación realizada correctamente",
	ttClonar: "Clonar",
	ttSuscritos: "Suscritos",
	ttOtrasFn: "Otras funciones:",
};

export default MisServiciosPublicados;
