import React from "react";
import {
	Card,
	CardBody,
	Form,
	FormGroup,
	Col,
	Row,
	CardHeader,
	Label,
	CardFooter,
	Button,
	NavItem,
	NavLink,
	Nav,
	TabContent,
	TabPane,
	Input,
} from "reactstrap";

import { Formik, Field, FieldArray } from "formik";
import ImageUpload from "components/Core/ImageUpload";
import { Config } from "utils/Config";
import { callApi, showAlert, getProducto, writeLog } from "utils/Utilerias";
import { showSweetAlert } from "utils/Utilerias";
import PropTypes from "prop-types";
import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";
export default class ProductoF extends React.Component {
	constructor(props) {
		super(props);
		this.tabs = [];
		this.state = {
			isLoaded: false,
			Imagen: null,
			horizontalTabs: "general",
			producto: {},
			res: {},
			// objProducto: {
			// 	NomProducto: "",
			// 	Descripcion: "",
			// 	BajaLogica: 0,
			// 	Imagen: null,
			// 	ClaProducto: 0,
			// 	selectedTab: 0,
			// },
			alert: null,
		};

		this.btnCancelar_Click = this.btnCancelar_Click.bind(this);
		this.handleSubmitAceptar = this.handleSubmitAceptar.bind(this);
		this.crearServidorVacio = this.crearServidorVacio.bind(this);
		this.renderCapturaServidor = this.renderCapturaServidor.bind(this);

		this.handleBotonEliminar = this.handleBotonEliminar.bind(this);
		this.renderServidoresTransaccionales = this.renderServidoresTransaccionales.bind(this);
		this.renderServidoresConsulta = this.renderServidoresConsulta.bind(this);
		this.onInputChage = this.onInputChage.bind(this);
		this.renderCapturaServidor = this.renderCapturaServidor.bind(this);
		this.componenteError = this.componenteError.bind(this);
	}

	componentDidMount() {
		let data = {};
		let urlWebService = Config.urlApiService + "/Producto?claProducto=" + sessionStorage.getItem("ClaProducto");

		callApi(urlWebService, "GET", data, (status, response) => {
			this.setState({
				producto: response,
				isLoaded: true,
			});
		});
	}

	handleBotonEliminar(value) {
		let urlWebServiceServ = Config.urlApiService + "/Producto/Servidor/" + value.ClaProducto;
		callApi(
			urlWebServiceServ,
			"PUT",
			value,
			(status, response) => {
				showSweetAlert(
					translate("Producto.alertCambioExitoso"),
					translate("Producto.alertCambioExitosoDescripcion"),
					"success",
				);
				writeLog(
					Formularios.FichaProducto.ClaFuncionalidad,
					Formularios.FichaProducto.Funcionalidades.btnGuardar,
					urlWebServiceServ,
					value,
					response,
					"Operación realizada con éxito",
					0,
				);
				this.setState({
					isLoaded: true,
				});
			},
			Formularios.FichaProducto.ClaFuncionalidad,
			Formularios.FichaProducto.Funcionalidades.btnGuardar,
		);
	}

	renderServidoresConsulta(arrayHelpers, formikProps) {
		let response;
		response = formikProps.values.ServidoresConsulta.map((object, index) => {
			if (object.BajaLogica === 0) {
				return (
					<tr key={`sCon_${index}`}>
						<td className="w-20">{object.NomServidor}</td>
						<td className="w-20">{object.NomBaseDatos}</td>
						<td className="w-20">{object.Usuario}</td>
						<td className="w-20">{object.Descripcion}</td>
						<td className="w-10" style={{ textAlign: "center" }}>
							{object.EsProductivo === 1 ? <i className="fas fa-star"></i> : <i className="far fa-star"></i>}
						</td>
						<td className="w-10" style={{ textAlign: "right" }}>
							<Button
								id={`btnEliminarConsulta_${index}`}
								onClick={() => {
									let objServidor = formikProps.values.ServidoresConsulta[index];

									objServidor.BajaLogica = 1;

									arrayHelpers.replace(index, objServidor);
									this.handleBotonEliminar(objServidor);
									//arrayHelpers.push(objServidor);
								}}
								color="danger"
								size="sm"
								className="btn-icon btn-link">
								<i className="fa fa-trash" />
							</Button>
						</td>
					</tr>
				);
			}
			return null;
		});

		return response;
	}

	renderServidoresTransaccionales(arrayHelpers, formikProps) {
		let response;
		response = formikProps.values.ServidoresTransaccionales.map((object, index) => {
			if (object.BajaLogica === 0) {
				return (
					<tr key={`sTran_${index}`}>
						<td className="w-20">{object.NomServidor}</td>
						<td className="w-20">{object.NomBaseDatos}</td>
						<td className="w-20">{object.Usuario}</td>
						<td className="w-20">{object.Descripcion}</td>
						<td className="w-10" style={{ textAlign: "center" }}>
							{object.EsProductivo === 1 ? <i className="fas fa-star"></i> : <i className="far fa-star"></i>}
						</td>
						<td className="w-10" style={{ textAlign: "right" }}>
							<Button
								id={`btnEliminarTransaccional_${index}`}
								onClick={() => {
									let objServidor = formikProps.values.ServidoresTransaccionales[index];

									objServidor.BajaLogica = 1;

									arrayHelpers.replace(index, objServidor);
									//this.handleSubmitAceptar(objServidor);
									this.handleBotonEliminar(objServidor);
									//arrayHelpers.push(objServidor);
								}}
								color="danger"
								size="sm"
								className="btn-icon btn-link">
								<i className="fa fa-trash" />
							</Button>
						</td>
					</tr>
				);
			}
			return null;
		});

		return response;
	}

	// with this function we change the active tab for all the tabs in this page
	changeActiveTab(e, tabState, tadName) {
		e.preventDefault();
		this.setState({
			[tabState]: tadName,
		});
	}

	/**
	 * Crea un JSON vacio con los campos necesarios para un servidor
	 * @param {Int} esTransaccional si el servidor es transaccional
	 * @param {Int} esProductivo si el servidor es productivo
	 */
	crearServidorVacio(esTransaccional, esProductivo) {
		let usaKraken = false;
		if (esTransaccional === 0) usaKraken = true;

		return {
			ClaProducto: getProducto(),
			ClaServidor: 0,
			ClaTipoSeguridad: 1,
			Contrasena: "",
			Descripcion: "",
			EsEncriptado: false,
			EsProductivo: esProductivo,
			EsTransaccional: esTransaccional,
			NomBaseDatos: "",
			NomServidor: "",
			Usuario: "",
			UsaKraken: usaKraken,
		};
	}

	/**
	 * Funcion para ocultar la alerta/mensaje que fue activado
	 */
	hideAlert() {
		this.setState({
			showAlert: null,
			showConfirm: null,
		});
	}

	/**
	 * Función que se muestra un mensaje de éxito en la operación
	 * @param {String} title titulo que tendrá el mensaje mostrado
	 * @param {String} text contenido del mensaje
	 * @param {String} icon icono que mostrará el mensaje
	 */
	successAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Función que envia los datos a la base de datos
	 * @param {JSON} value Valores ingresados en el formulario
	 */
	handleSubmitAceptar(value) {
		let urlWebServiceServ = Config.urlApiService + "/Producto?claProducto=" + value.claProducto;
		console.log(this.state.producto);
		callApi(
			urlWebServiceServ,
			"PUT",
			this.state.producto,
			(status, response) => {
				showSweetAlert(
					translate("Producto.alertCambioExitoso"),
					translate("Producto.alertCambioExitosoDescripcion"),
					"success",
				);
				writeLog(
					Formularios.FichaProducto.ClaFuncionalidad,
					Formularios.FichaProducto.Funcionalidades.btnGuardar,
					urlWebServiceServ,
					this.state.producto,
					response,
					"Operación realizada con éxito",
					0,
				);
				this.setState({
					isLoaded: true,
				});
			},
			Formularios.FichaProducto.ClaFuncionalidad,
			Formularios.FichaProducto.Funcionalidades.btnGuardar,
		);
	}

	/**
	 * Función que oculta el modal
	 */
	btnCancelar_Click(isOk) {
		this.props.hideModal(isOk);
	}

	onInputChage(value) {
		this.setState({
			Imagen: value,
		});
	}

	renderCapturaServidor(titulo, esTransaccional, esProductivo, props) {
		let index = this.state.objProducto.Servidores.findIndex(
			(oX) => (oX.EsTransaccional === esTransaccional) & (oX.EsProductivo === esProductivo),
		);

		return (
			<div>
				<Col md={12}>
					<em>
						<strong>
							<h5>{titulo}</h5>
						</strong>{" "}
					</em>
					<hr></hr>
				</Col>
				<FormGroup className="text-left" row>
					<Col md={6}>
						<Label>{translate("Producto.lblServidor")}</Label>
						<Field
							as="input"
							placeholder={translate("Producto.phServidor")}
							className="form-control form-control-sm"
							name={`servidores[${index}].NomServidor`}
							disabled={this.state.objProducto.Servidores[index].UsaKraken}
							validate={
								!this.state.objProducto.Servidores[index].UsaKraken
									? (value) =>
											this.validaNomServidor(
												esTransaccional,
												esProductivo,
												value,
												this.state.objProducto.Servidores[index].UsaKraken,
											)
									: null
							}
						/>
					</Col>
					<Col md={6}>
						<Label>{translate("Producto.lblBaseDatos")}</Label>
						<Field
							as="input"
							placeholder={translate("Producto.phBaseDatos")}
							className="form-control form-control-sm"
							name={`servidores[${index}].NomBaseDatos`}
							disabled={this.state.objProducto.Servidores[index].UsaKraken}
							validate={
								!this.state.objProducto.Servidores[index].UsaKraken
									? (value) =>
											this.validaNomBaseDatos(
												esTransaccional,
												esProductivo,
												value,
												this.state.objProducto.Servidores[index].UsaKraken,
											)
									: null
							}
						/>
					</Col>
				</FormGroup>
				<FormGroup className="text-left" row>
					<Col md={6}>
						<Label>{translate("Producto.lblUsuario")}</Label>
						<Field
							as="input"
							placeholder={translate("Producto.phUsuario")}
							className="form-control form-control-sm"
							disabled={this.state.objProducto.Servidores[index].UsaKraken}
							name={`servidores[${index}]Usuario`}
							validate={
								!this.state.objProducto.Servidores[index].UsaKraken
									? (value) =>
											this.validaUsuario(
												esTransaccional,
												esProductivo,
												value,
												this.state.objProducto.Servidores[index].UsaKraken,
											)
									: null
							}
						/>
					</Col>
					<Col md={6}>
						<Label>{translate("Producto.lblPassword")}</Label>
						<Field
							as="input"
							placeholder={translate("Producto.phBaseDatos")}
							className="form-control form-control-sm"
							disabled={this.state.objProducto.Servidores[index].UsaKraken}
							type="password"
							name={`servidores[${index}].Contrasena`}
							validate={
								!this.state.objProducto.Servidores[index].UsaKraken
									? (value) =>
											this.validaPass(
												esTransaccional,
												esProductivo,
												value,
												this.state.objProducto.Servidores[index].UsaKraken,
											)
									: null
							}
						/>
					</Col>
				</FormGroup>
				<FormGroup className="text-left" row>
					<Col md={6}>
						<Label>Tipo de seguridad</Label>
						<Field
							as="input"
							placeholder="Tipo de seguridad"
							className="form-control form-control-sm"
							disabled
							value="SQL"
						/>
					</Col>
					<Col md={6}>
						<FormGroup check style={{ marginTop: "25px" }}>
							<Row>
								<Col>
									<Label check>
										<Input
											type="checkbox"
											checked={this.state.objProducto.Servidores[index].EsEncriptado === 1 ? true : false}
											onChange={(event) => {
												let objAux = this.state.objProducto;

												if (event.target.checked) {
													objAux.Servidores[index].EsEncriptado = 1;
												} else {
													objAux.Servidores[index].EsEncriptado = 0;
												}

												this.setState({
													objProducto: objAux,
												});
											}}
											disabled={this.state.objProducto.Servidores[index].UsaKraken}
										/>

										{"Conexión segura"}
										<span className="form-check-sign">
											<span className="check" />
										</span>
									</Label>
								</Col>
								<Col>
									{this.state.objProducto.Servidores[index].EsTransaccional === 0 ? (
										<Label check>
											<Input
												type="checkbox"
												checked={this.state.objProducto.Servidores[index].UsaKraken}
												onChange={(event) => {
													let objAux = this.state.objProducto;

													if (event.target.checked) {
														objAux.Servidores[index].UsaKraken = true;
														props.setFieldValue(`servidores[${index}].NomServidor`, "");
														props.setFieldValue(`servidores[${index}].NomBaseDatos`, "");
														props.setFieldValue(`servidores[${index}].Usuario`, "");
														props.setFieldValue(`servidores[${index}].Contrasena`, "");
													} else {
														objAux.Servidores[index].UsaKraken = false;
													}

													this.setState({
														objProducto: objAux,
													});
												}}
											/>

											{"Utiliza Kraken"}
											<span className="form-check-sign">
												<span className="check" />
											</span>
										</Label>
									) : (
										<span></span>
									)}
								</Col>
							</Row>
						</FormGroup>
					</Col>
				</FormGroup>
			</div>
		);
	}

	componenteError(props) {
		const { children } = props;

		return (
			<span style={{ color: "red" }}>
				{children}
				<br></br>
			</span>
		);
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<div className="spinner-border text-info" style={{ backgroundColor: "transparent" }} role="status">
					<span className="sr-only">{translate("Producto.cargando")}</span>
				</div>
			);
		}

		return (
			<>
				{this.state.showAlert}
				<div className="content">
					<div className="container">
						<Formik
							initialValues={{
								...this.state.producto,

								tranNomServidor: "",
								tranNomBaseDatos: "",
								tranNomUsuario: "",
								tranPassword: "",
								tranEsProductivo: false,

								conNomServidor: "",
								conNomBaseDatos: "",
								conNomUsuario: "",
								conPassword: "",
								conEsProductivo: false,
								// claProducto: this.state.objProducto.ClaProducto,
								// nomProducto: this.state.objProducto.NomProducto,
								// descripcion: this.state.objProducto.Descripcion,
								// apiKey: this.state.objProducto.ApiKey,
								// imagen: this.state.Imagen,
								// bajaLogica: this.state.objProducto.BajaLogica,
								// servidores: this.state.objProducto.Servidores,
							}}
							onSubmit={this.handleSubmitAceptar}>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit} key="frmProducto">
									<Card>
										<CardHeader>
											<div className="nav-tabs-navigation">
												<div className="nav-tabs-wrapper">
													<Nav tabs className="justify-content-center">
														<NavItem>
															<NavLink
																data-toggle="tab"
																className={this.state.horizontalTabs === "general" ? "active" : ""}
																onClick={(e) => this.changeActiveTab(e, "horizontalTabs", "general")}>
																{translate("Producto.navGeneral")}
															</NavLink>
														</NavItem>
														<NavItem>
															<NavLink
																data-toggle="tab"
																className={this.state.horizontalTabs === "consulta" ? "active" : ""}
																onClick={(e) => this.changeActiveTab(e, "horizontalTabs", "consulta")}>
																{translate("Producto.navConsulta")}
															</NavLink>
														</NavItem>
														<NavItem>
															<NavLink
																data-toggle="tab"
																className={
																	this.state.horizontalTabs === "transaccional" ? "active" : ""
																}
																onClick={(e) =>
																	this.changeActiveTab(e, "horizontalTabs", "transaccional")
																}>
																{translate("Producto.navTransaccional")}
															</NavLink>
														</NavItem>
													</Nav>
												</div>
											</div>
										</CardHeader>

										<CardBody
											style={{
												minHeight: "450px",
											}}>
											<TabContent className="tab-space" activeTab={this.state.horizontalTabs}>
												<TabPane tabId="general">
													<Row>
														<Col md={3} className="text-center">
															<ImageUpload
																image={this.state.producto.Imagen}
																onChange={this.onInputChage}
															/>
														</Col>
														<Col md={9}>
															<FormGroup className="text-left" row>
																<Col md={12}>
																	<Label>{translate("Producto.lblNomProducto")}</Label>
																	<Field
																		as="input"
																		name="NomProducto"
																		placeholder={translate("Producto.lblNomProducto")}
																		className="form-control"
																		disabled
																	/>
																</Col>
															</FormGroup>
															<FormGroup className="text-left" row>
																<Col md={12}>
																	<Label>{translate("Producto.lblApiKey")}</Label>
																	<Field
																		as="input"
																		name="ApiKey"
																		placeholder={translate("Producto.lblApiKey")}
																		className="form-control"
																		disabled
																	/>
																</Col>
															</FormGroup>

															{this.state.producto.EsExterno === 1 && (
																<FormGroup className="text-left" row>
																	<Col md={12}>
																		<Label>{translate("Producto.lblClientId")}</Label>
																		<Field
																			as="input"
																			name="ClientId"
																			placeholder={translate("Producto.lblClientId")}
																			className="form-control"
																			disabled
																		/>
																	</Col>
																</FormGroup>
															)}

															{this.state.producto.EsExterno === 1 && (
																<FormGroup className="text-left" row>
																	<Col md={12}>
																		<Label>{translate("Producto.lblClientSecret")}</Label>
																		<Field
																			as="input"
																			name="ClientSecret"
																			placeholder={translate("Producto.lblClientSecret")}
																			className="form-control"
																			disabled
																		/>
																	</Col>
																</FormGroup>
															)}
														</Col>
													</Row>
													<FormGroup className="text-left" row>
														<Col md={12}>
															<Label>{translate("Producto.lblDescripcion")}</Label>
															<Field
																as="textarea"
																name="Descripcion"
																placeholder={translate("Producto.phDescripcion")}
																className="form-control textarea"
															/>
														</Col>
													</FormGroup>
												</TabPane>
												<TabPane tabId="consulta">
													<h2>{translate("Producto.lblAgregaServidorConsulta")}</h2>
													<FormGroup row>
														<Label for="conNomServidor" sm={2}>
															{translate("Producto.lblServidor")}
														</Label>
														<Col md={3}>
															<Field
																as="input"
																name="conNomServidor"
																placeholder={translate("Producto.phNombreServidor")}
																className="form-control"></Field>
														</Col>

														<Label for="conNomBaseDatos" sm={2}>
															{translate("Producto.lblBaseDatos")}
														</Label>
														<Col md={3}>
															<Field
																as="input"
																name="conNomBaseDatos"
																placeholder={translate("Producto.phBaseDatos")}
																className="form-control"></Field>
														</Col>
														<Col md={2}>
															<Label check>
																<Field type="checkbox" name="conEsProductivo" />
																&nbsp;{translate("Producto.lblEsProductivo")}
															</Label>
														</Col>
													</FormGroup>

													<FormGroup row>
														<Label for="conNomUsuario" sm={2}>
															{translate("Producto.lblUsuario")}
														</Label>
														<Col md={3}>
															<Field
																as="input"
																name="conNomUsuario"
																placeholder={translate("Producto.phUsuario")}
																className="form-control"></Field>
														</Col>

														<Label for="tranPassword" sm={2}>
															{translate("Producto.lblPassword")}
														</Label>
														<Col md={3}>
															<Field
																as="input"
																name="conPassword"
																type="password"
																placeholder={translate("Producto.phPassword")}
																className="form-control"></Field>
														</Col>
														<Col md={2}>
															<Button
																id={`btnAgregarServidorConsulta`}
																onClick={() => {
																	let srvServidoresConsulta = this.state.producto.ServidoresConsulta;

																	let claServidor = Math.max.apply(
																		Math,
																		srvServidoresConsulta.map(function (oS) {
																			return oS.ClaServidor;
																		}),
																	);

																	srvServidoresConsulta.push({
																		ClaProducto: this.state.producto.ClaProducto,
																		ClaServidor: !isFinite(claServidor) ? 1 : claServidor + 1,
																		ClaTipoSeguridad: 1,
																		NomServidor: formikProps.values.conNomServidor,
																		Descripcion: formikProps.values.conNomServidor,
																		NomBaseDatos: formikProps.values.conNomBaseDatos,
																		Contrasena: formikProps.values.conPassword,
																		Usuario: formikProps.values.conNomUsuario,
																		EsEncriptado: 1,
																		EsProductivo: formikProps.values.conEsProductivo ? 1 : 0,
																		BajaLogica: 0,
																		EsTransaccional: 0,
																	});

																	let tmpProducto = this.state.producto;
																	tmpProducto.ServidoresConsulta = srvServidoresConsulta;
																	this.setState({
																		producto: tmpProducto,
																	});
																	this.handleSubmitAceptar(tmpProducto.ServidoresConsulta);
																}}
																color="success"
																size="sm"
																className="btn btn-simple btn-sm">
																<i className="fa fa-add" />
																{translate("Producto.btnAgregar")}
															</Button>
														</Col>
													</FormGroup>
													<h2>{translate("Producto.lblServidoresRegistrados")}</h2>
													<FieldArray
														name="ServidoresConsulta"
														render={(arrayHelpers) => {
															return (
																<table className="table table-head table-hover table-gv table-sm">
																	<thead className="table-head">
																		<tr>
																			<th className="w-20">
																				{translate("Producto.colNombreServidor")}
																			</th>
																			<th className="w-10">{translate("Producto.colBaseDatos")}</th>
																			<th className="w-10">{translate("Producto.colUsuario")}</th>
																			<th className="w-40">
																				{translate("Producto.colDescripcion")}
																			</th>
																			<th className="w-10" style={{ textAlign: "center" }}>
																				{translate("Producto.colProductivo")}
																			</th>
																			<th className="w-10"></th>
																		</tr>
																	</thead>
																	<tbody>
																		{this.renderServidoresConsulta(arrayHelpers, formikProps)}
																	</tbody>
																</table>
															);
														}}
													/>
												</TabPane>

												<TabPane tabId="transaccional">
													<h2>{translate("Producto.lblAgregaServidorTransaccional")}</h2>
													<FormGroup row>
														<Label for="tranNomServidor" sm={2}>
															{translate("Producto.lblServidor")}
														</Label>
														<Col md={3}>
															<Field
																as="input"
																name="tranNomServidor"
																placeholder={translate("Producto.phNombreServidor")}
																className="form-control"></Field>
														</Col>

														<Label for="tranNomBaseDatos" sm={2}>
															{translate("Producto.lblBaseDatos")}
														</Label>
														<Col md={3}>
															<Field
																as="input"
																name="tranNomBaseDatos"
																placeholder={translate("Producto.phBaseDatos")}
																className="form-control"></Field>
														</Col>
														<Col md={2}>
															<Label check>
																<Field type="checkbox" name="tranEsProductivo" />
																&nbsp;{translate("Producto.lblEsProductivo")}
															</Label>
														</Col>
													</FormGroup>

													<FormGroup row>
														<Label for="tranNomUsuario" sm={2}>
															{translate("Producto.lblUsuario")}
														</Label>
														<Col md={3}>
															<Field
																as="input"
																name="tranNomUsuario"
																placeholder={translate("Producto.phUsuario")}
																className="form-control"></Field>
														</Col>

														<Label for="tranPassword" sm={2}>
															{translate("Producto.lblPassword")}
														</Label>
														<Col md={3}>
															<Field
																as="input"
																name="tranPassword"
																type="password"
																placeholder={translate("Producto.phPassword")}
																className="form-control"></Field>
														</Col>
														<Col md={2}>
															<Button
																id={`btnAgregarServidorConsulta`}
																onClick={() => {
																	let srvTransaccionales =
																		this.state.producto.ServidoresTransaccionales;

																	let claServidor = Math.max.apply(
																		Math,
																		srvTransaccionales.map(function (oS) {
																			return oS.ClaServidor;
																		}),
																	);

																	srvTransaccionales.push({
																		ClaProducto: this.state.producto.ClaProducto,
																		ClaServidor: !isFinite(claServidor) ? 1 : claServidor + 1,
																		ClaTipoSeguridad: 1,
																		NomServidor: formikProps.values.tranNomServidor,
																		Descripcion: formikProps.values.tranNomServidor,
																		NomBaseDatos: formikProps.values.tranNomBaseDatos,
																		Contrasena: formikProps.values.tranPassword,
																		Usuario: formikProps.values.tranNomUsuario,
																		EsEncriptado: 1,
																		EsProductivo: formikProps.values.tranEsProductivo ? 1 : 0,
																		BajaLogica: 0,
																		EsTransaccional: 1,
																	});

																	let tmpProducto = this.state.producto;
																	tmpProducto.ServidoresTransaccionales = srvTransaccionales;
																	this.setState({
																		producto: tmpProducto,
																	});
																	this.handleSubmitAceptar(tmpProducto.ServidoresTransaccionales);
																}}
																color="success"
																size="sm"
																className="btn btn-simple btn-sm">
																<i className="fa fa-add" />
																{translate("Producto.btnAgregar")}
															</Button>
														</Col>
													</FormGroup>
													<h2>{translate("Producto.lblServidoresRegistrados")}</h2>
													<FieldArray
														name="ServidoresTransaccionales"
														render={(arrayHelpers) => {
															return (
																<table className="table table-head table-hover table-gv table-sm">
																	<thead className="table-head">
																		<tr>
																			<th className="w-20">
																				{translate("Producto.colNombreServidor")}
																			</th>
																			<th className="w-10">{translate("Producto.colBaseDatos")}</th>
																			<th className="w-10">{translate("Producto.colUsuario")}</th>
																			<th className="w-40">
																				{translate("Producto.colDescripcion")}
																			</th>
																			<th className="w-10" style={{ textAlign: "center" }}>
																				{translate("Producto.colProductivo")}
																			</th>
																			<th className="w-10"></th>
																		</tr>
																	</thead>
																	<tbody>
																		{this.renderServidoresTransaccionales(arrayHelpers, formikProps)}
																	</tbody>
																</table>
															);
														}}
													/>
												</TabPane>
											</TabContent>
										</CardBody>

										<CardFooter>
											{/* <Row>
												<Col>
													<ErrorMessage
														name={`servidores[0].NomServidor`}
														component={this.componenteError}
													/>
													<ErrorMessage
														name={`servidores[1].NomServidor`}
														component={this.componenteError}
													/>
													<ErrorMessage
														name={`servidores[2].NomServidor`}
														component={this.componenteError}
													/>
													<ErrorMessage
														name={`servidores[3].NomServidor`}
														component={this.componenteError}
													/>
													<ErrorMessage
														name={`servidores[0].NomBaseDatos`}
														component={this.componenteError}
													/>
													<ErrorMessage
														name={`servidores[1].NomBaseDatos`}
														component={this.componenteError}
													/>
													<ErrorMessage
														name={`servidores[2].NomBaseDatos`}
														component={this.componenteError}
													/>
													<ErrorMessage
														name={`servidores[3].NomBaseDatos`}
														component={this.componenteError}
													/>
													<ErrorMessage name={`servidores[0].Usuario`} component={this.componenteError} />
													<ErrorMessage name={`servidores[1].Usuario`} component={this.componenteError} />
													<ErrorMessage name={`servidores[2].Usuario`} component={this.componenteError} />
													<ErrorMessage name={`servidores[3].Usuario`} component={this.componenteError} />
													<ErrorMessage
														name={`servidores[0].Contrasena`}
														component={this.componenteError}
													/>
													<ErrorMessage
														name={`servidores[1].Contrasena`}
														component={this.componenteError}
													/>
													<ErrorMessage
														name={`servidores[2].Contrasena`}
														component={this.componenteError}
													/>
													<ErrorMessage
														name={`servidores[3].Contrasena`}
														component={this.componenteError}
													/>
												</Col> */}

											<Row>
												<Col md={12} className="text-right">
													{this.state.horizontalTabs === "general" ? (
														<Button
															id="btnGuardar"
															className={`animation-on-hover`}
															color="success"
															size="sm"
															type="submit">
															{translate("Producto.btnGuardar")}
														</Button>
													) : null}
												</Col>
											</Row>
										</CardFooter>
									</Card>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</>
		);
	}
}

ProductoF.propTypes = {
	hideModal: PropTypes.func,
};
