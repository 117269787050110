const Producto = {
	navGeneral: "General",
	navConsulta: "Inquiries",
	navTransaccional: "Transactional",
	lblNomProducto: "Product Name",
	lblApiKey: "API Key",
	lblClientId: "Client Id",
	lblClientSecret: "Client Secret",
	lblDescripcion: "Description",
	phDescripcion: "Detailed Product Description",
	lblAgregaServidorConsulta: "Add a query server",
	lblServidor: "Server:",
	phNombreServidor: "Server name",
	lblUsuario: "User:",
	phUsuario: "User",
	lblBaseDatos: "Database:",
	phBaseDatos: "Database name",
	lblPassword: "Password:",
	phPassword: "Password",
	lblEsProductivo: "Is productive",
	btnAgregar: "Add",
	lblServidoresRegistrados: "Registered servers",
	colNombreServidor: "Server Name",
	colBaseDatos: "Database",
	colUsuario: "User",
	colDescripcion: "Description",
	colProductivo: "Productive",
	lblAgregaServidorTransaccional: "Add a transactional server",
	alertCambioExitoso: "Attention",
	alertCambioExitosoDescripcion: "Changes made successfully",
	phServidor: "Server",
	pbBaseDatos: "Database",
	btnActualizar: "Update",
	btnGuardar: "Save",
	cargando: "Loading...",
};

export default Producto;
