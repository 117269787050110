import React from "react";
import {
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	Form,
	FormGroup,
	Row,
	Col,
	Button,
	Label,
	CardTitle,
} from "reactstrap";
import { Formik, Field } from "formik";
import PropTypes from "prop-types";
import { Config } from "utils/Config";
import { getProducto, callApi, showAlert, writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";
import Datetime from "react-datetime";
import "moment/locale/es";

let usuarios;

export default class UsuarioMtto extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			objUsuario: {
				NomUsuario: "",
				ClaUsuario: "",
			},
		};

		this.btnCancelar_Click = this.btnCancelar_Click.bind(this);
		this.handleSubmitAceptar = this.handleSubmitAceptar.bind(this);
	}

	componentDidMount() {
		let urlWebService = Config.urlApiService + "/Producto/Usuarios/" + getProducto();

		callApi(urlWebService, "GET", null, (status, response) => {
			usuarios = response;
			let objSeleccione = { ClaUsuario: 0, ClaEmpleado: 0, NomUsuario: "SELECCIONE" };
			usuarios.unshift(objSeleccione);

			this.setState({
				isLoaded: true,
			});
		});
	}

	/**
	 * Función del botón para cancelar la operación
	 * y ocultar el modal sin guardar ningún cambio
	 */
	btnCancelar_Click(isOk) {
		this.props.hideModal(isOk);
	}

	/**
	 * Muestra los prompts de alertas
	 */
	showAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Oculta los promts de alertas
	 */
	hideAlert() {
		this.setState({
			showAlert: null,
		});
	}

	/**
	 * Función del botón para guardar cambios en el objeto responsable
	 * @param {JSON} value los valores del formulario
	 */
	handleSubmitAceptar(value) {
		if (value.claEmpleado === undefined) {
			this.showAlert("Debe ingresar un usuario válido", undefined, "warning");
			return;
		}
		let method = "PUT"; //Editar
		let urlWebService =
			Config.urlApiService +
			"/SolicitudServicio/" +
			this.props.objPeticion.ClaProducto +
			"/" +
			this.props.objPeticion.IdPeticion;
		let data = {
			...this.props.objPeticion,
		};

		data.ClaUsuarioAsignacion = value.claEmpleado;
		data.FechaPromesa = this.state.fechaPromesa;
		data.BajaLogica = 0;
		data.ClaEstatus = 4;

		if (method === "PUT" && this.state.fechaPromesa === undefined) {
			this.showAlert("Debe ingresar una fecha promesa", undefined, "warning");
			return;
		}

		callApi(
			urlWebService,
			method,
			data,
			(status, response) => {
				let mensaje = undefined;
				if (response.length <= 0) {
					this.setState({
						isLoaded: true,
					});
				} else {
					this.setState({
						isLoaded: true,
						objUsuario: {
							ClaUsuario: response[0],
						},
					});
					mensaje = "Usuario asignado con éxito";
					this.btnCancelar_Click(true);
				}

				writeLog(
					Formularios.AsignarUsuario.ClaFuncionalidad,
					Formularios.AsignarUsuario.Funcionalidades.btnGuardar,
					urlWebService,
					data,
					response,
					mensaje,
					0,
				);
			},
			Formularios.AsignarUsuario.ClaFuncionalidad,
			Formularios.AsignarUsuario.Funcionalidades.btnGuardar,
		);
	}

	render() {
		if (!this.state.isLoaded) {
			return <div>Cargando...</div>;
		}
		return (
			<div className="content">
				{this.state.showAlert}
				<Formik
					initialValues={{
						nomUsuario: this.state.objUsuario.NomUsuario,
						claEmpleado: this.state.objUsuario.ClaEmpleado,
					}}
					onSubmit={this.handleSubmitAceptar}>
					{(props) => (
						<Form onSubmit={props.handleSubmit}>
							<Card>
								<CardHeader>
									<Row>
										<Col>
											<CardTitle>Asignar usuario</CardTitle>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Row>
										<Col md={12} sm={12} style={{ marginBottom: "10px" }}>
											<Label for="claEmpleado">Usuarios</Label>
											<Field
												name="claEmpleado"
												as="select"
												type="input"
												className="form-control"
												placeholder="Selecciona el Usuario">
												{usuarios &&
													usuarios.length > 0 &&
													usuarios.map((obj, index) => (
														<option className="ddlOptions" key={index} value={obj.ClaEmpleado}>
															{obj.NomUsuario}
														</option>
													))}
											</Field>
										</Col>
									</Row>

									<Row>
										<Col md={8}>
											<FormGroup>
												<Label for="fechaAsignacion">Fecha promesa</Label>
												<Datetime
													inputProps={{
														placeholder: "Fecha preevista para terminar el desarrollo del servicio",
													}}
													onChange={(value) => {
														if (value._d !== undefined) {
															this.setState({
																fechaPromesa: value._d.toSqlDateTime(),
															});
														} else {
															this.setState({
																fechaPromesa: value._d,
															});
														}
													}}
													required={true}
													timeFormat={false}
													locale="es"
													id="fechaPromesa"
												/>
											</FormGroup>
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<Row>
										<Col md={12} className="text-right">
											<Button
												id="btnGuardar"
												className="animation-on-hover"
												color="success"
												size="sm"
												type="submit"
												onClick={() => {
													this.setState({ buscar: false });
												}}>
												Guardar
											</Button>

											<Button
												id="btnCancelar"
												className="animation-on-hover"
												color="default"
												size="sm"
												onClick={() => {
													writeLog(
														Formularios.AsignarUsuario.ClaFuncionalidad,
														Formularios.AsignarUsuario.Funcionalidades.btnCancelar,
													);

													this.setState({
														buscar: true,
													});
													this.btnCancelar_Click();
												}}>
												Cancelar
											</Button>
										</Col>
									</Row>
								</CardFooter>
							</Card>
						</Form>
					)}
				</Formik>
			</div>
		);
	}
}

UsuarioMtto.propTypes = {
	hideModal: PropTypes.func,
	objPeticion: PropTypes.any,
};
