import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { callApi, getThemeColors, writeLog } from "utils/Utilerias";
import { Config } from "../../utils/Config";
import PropTypes from "prop-types";
import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";

export default class Peticiones extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			claIdioma: sessionStorage.getItem("ClaIdioma"),
			options: {
				chart: {
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: "pie",
					backgroundColor: "#27293d",
				},
				//colors: this.pieColors(),
				legend: {
					itemStyle: {
						fontSize: "10pt",
						font: "10pt Trebuchet MS, Verdana, sans-serif",
						color: "#A0A0A0",
					},
				},
				title: {
					text: "",
				},
				tooltip: {
					pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
					backgroundColor: "rgb(49, 51, 77, .80)",
					style: {
						color: "#FFFFFF",
					},
				},
				accessibility: {
					point: {
						valueSuffix: "%",
					},
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: "pointer",
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
						borderWidth: 0,
					},
					series: {
						cursor: "pointer",
						point: {
							events: {
								click: (e) => {
									if (this.props.claCliente === undefined) {
										writeLog(
											Formularios.DashboardProveedor.ClaFuncionalidad,
											Formularios.DashboardProveedor.Funcionalidades.PeticionesPorUsuarioClick,
										);
									} else {
										writeLog(
											Formularios.DashboardCliente.ClaFuncionalidad,
											Formularios.DashboardCliente.Funcionalidades.PeticionesPorProovedorClick,
										);
									}

									let nombreSeleccionado = e.point.options.selected ? "" : e.point.name;
									this.props.claProducto !== undefined
										? this.props.filtrarUsuario(nombreSeleccionado)
										: this.props.filtrarProducto(nombreSeleccionado);
								},
							},
						},
					},
				},
				credits: {
					enabled: false,
				},
			},
		};
	}

	componentDidMount() {
		this.fillData();
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.claProducto !== prevProps.claProducto ||
			this.props.claCliente !== prevProps.claCliente ||
			this.props.fechaInicio !== prevProps.fechaInicio ||
			this.props.fechaFin !== prevProps.fechaFin
		) {
			this.setState({
				options: {},
				isLoaded: false,
			});
			this.fillData();
		}
	}

	fillData() {
		const urlService = `${Config.urlApiService}/Dashboard2`;
		const data = {
			ClaProducto: this.props.claProducto,
			ClaCliente: this.props.claCliente,
			FechaInicial: this.props.fechaInicio ? this.props.fechaInicio.toSqlDateTime() : undefined,
			FechaFinal: this.props.fechaFin ? this.props.fechaFin.toSqlDateTime() : undefined,
			ClaConsulta: this.props.claProducto ? 9 : 14,
		};

		callApi(
			urlService,
			"POST",
			data,
			(status, response) => {
				if (response.Result0.length > 0) {
					this.setState({
						options: {
							series: [
								{
									name: "Peticiones",
									colorByPoint: true,
									data: response.Result0,
									innerSize: "50%",
								},
							],
							colors: getThemeColors(response.Result0.length),
						},
					});
				} else {
					this.setState({
						options: {
							series: [
								{
									name: translate("DashboardProveedor.lblNoHayDatos"),
									colorByPoint: true,
									data: [{ name: translate("DashboardProveedor.lblNoSeEncontroInformacion"), y: 100 }],
									innerSize: "50%",
								},
							],
							colors: ["#1d8cf8"],
						},
						claIdioma: sessionStorage.getItem("ClaIdioma"),
					});
				}

				if (this.props.claCliente === undefined) {
					writeLog(
						Formularios.DashboardProveedor.ClaFuncionalidad,
						Formularios.DashboardProveedor.Funcionalidades.PeticionesPorUsuario,
						urlService,
						data,
						response,
						undefined,
						0,
					);
				} else {
					writeLog(
						Formularios.DashboardCliente.ClaFuncionalidad,
						Formularios.DashboardCliente.Funcionalidades.PeticionesPorProovedor,
						urlService,
						data,
						response,
						undefined,
						0,
					);
				}
			},
			this.props.claCliente === undefined
				? Formularios.DashboardProveedor.ClaFuncionalidad
				: Formularios.DashboardCliente.ClaFuncionalidad,
			this.props.claCliente === undefined
				? Formularios.DashboardProveedor.Funcionalidades.PeticionesPorUsuario
				: Formularios.DashboardCliente.Funcionalidades.PeticionesPorProovedor,
		);
	}

	render() {
		return (
			<>
				{sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.fillData()}
				<HighchartsReact highcharts={Highcharts} options={this.state.options} />
			</>
		);
	}
}

Peticiones.propTypes = {
	claProducto: PropTypes.number,
	claCliente: PropTypes.number,
	fechaInicio: PropTypes.object,
	fechaFin: PropTypes.object,
	filtrarUsuario: PropTypes.func,
	filtrarProducto: PropTypes.func,
};
