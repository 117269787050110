import React from "react";
import { Card, CardBody, CardFooter, Form, FormGroup, Row, Col, Button, Label } from "reactstrap";
import { Formik, Field } from "formik";
import PropTypes from "prop-types";

import { Config } from "utils/Config";
import { callApi, showAlert, writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";
let productos = [];
export default class GlosarioMtto extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			guardar: false,
			ddBases: [],
			ddlTipoSeguridad: [],
			objTermino: {
				NomTermino: "",
				NoUsar: "",
				Sinonimos: "",
				Abreviatura: "",
				ClaProducto: 0,
				NomProducto: "",
			},
			alert: null,
		};
		this.btnCancelar_Click = this.btnCancelar_Click.bind(this);
		this.handleSubmitAceptar = this.handleSubmitAceptar.bind(this);

		if (this.props.claTermino === 0) {
			this.setState({
				isLoaded: true,
				objTermino: {
					NomTermino: "",
					ClaTermino: 0,
					ClaProducto: 0,
					NoUsar: "",
					Sinonimos: "",
					Abreviatura: "",
					BajaLogica: 0,
					NomProducto: "",
				},
			});
		}
	}

	componentDidMount() {
		// this.cargarTipoSeguridad();
		let urlApi = `${Config.urlApiService}/Catalogos/11`;

		callApi(urlApi, "GET", null, (status, response) => {
			productos = response;
		});

		let data = {};
		let urlWebService = Config.urlApiService + "/Glosario/" + this.props.claTermino;

		callApi(urlWebService, "GET", data, (status, response) => {
			this.setState({
				objTermino: response[0],
				isLoaded: true,
			});
		});
	}

	/**
	 * Funcion para ocultar la alerta/mensaje que fue activado
	 */
	hideAlert() {
		this.setState({
			showAlert: null,
			showConfirm: null,
		});
	}

	/**
	 * Función que se muestra un mensaje de éxito en la operación
	 * @param {String} title titulo que tendrá el mensaje mostrado
	 * @param {String} text contenido del mensaje
	 * @param {String} icon icono que mostrará el mensaje
	 */
	successAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Función que valida que los datos se hayan ingresado correctamente
	 * @param {JSON} value Valores ingresados en el formulario
	 */
	validar(value) {
		let huboErrores = false;
		let msg = "";

		if (value.claProducto <= 0) {
			huboErrores = true;
			msg += "Debe especificar un producto ";
		}

		if (value.descripcion === undefined || value.descripcion.isNullOrEmpty()) {
			huboErrores = true;
			msg += "Debe ingresar una descripción ";
		}

		if (value.nomTermino === undefined || value.nomTermino.isNullOrEmpty()) {
			huboErrores = true;
			msg += "Debe ingresar un nombre para el término ";
		}

		if (huboErrores) {
			throw Error(msg);
		}
	}

	/**
	 * Función que envia los datos a la base de datos
	 * @param {JSON} value Valores ingresados en el formulario
	 */
	handleSubmitAceptar(value) {
		let method = "PUT"; //Editar

		if (this.props.claTermino === 0) {
			method = "POST"; //Agregar
		}

		if (value.nomProducto === "SELECCIONE") {
			value.nomProducto = null;
		}

		this.validar(value);

		let data = {
			ClaTermino: this.props.claTermino,
			NomTermino: value.nomTermino,
			NoUsar: value.noUsar,
			Abreviatura: value.abreviatura,
			Sinonimos: value.sinonimos,
			Descripcion: value.descripcion,
			ClaProducto: value.claProducto,
		};

		let urlWebServiceServ = Config.urlApiService + "/Glosario/" + value.claTermino;

		callApi(
			urlWebServiceServ,
			method,
			data,
			(status, response) => {
				this.setState({
					isLoaded: true,
					objTermino: response,
				});

				writeLog(
					Formularios.GlosarioMtto.ClaFuncionalidad,
					Formularios.GlosarioMtto.Funcionalidades.btnGuardar,
					urlWebServiceServ,
					data,
					response,
					undefined,
					0,
				);

				this.btnCancelar_Click(true);
			},
			Formularios.GlosarioMtto.ClaFuncionalidad,
			Formularios.GlosarioMtto.Funcionalidades.btnGuardar,
		);
	}

	/**
	 * Función que oculta el modal
	 */
	btnCancelar_Click(isOk) {
		this.props.hideModal(isOk);
	}

	render() {
		if (!this.state.isLoaded) {
			return <div>Cargando...</div>;
		}
		return (
			<>
				{this.state.showAlert}
				<Formik
					initialValues={{
						nomTermino: this.state.objTermino.NomTermino,
						noUsar: this.state.objTermino.NoUsar,
						abreviatura: this.state.objTermino.Abreviatura,
						descripcion: this.state.objTermino.Descripcion,
						sinonimos: this.state.objTermino.Sinonimos,
						claProducto: this.state.objTermino.ClaProducto,
						nomProducto: this.state.objTermino.NomProducto,
					}}
					onSubmit={this.handleSubmitAceptar}>
					{(props) => (
						<Form onSubmit={props.handleSubmit} key="frmGlosarioTermino">
							<Card>
								<CardBody>
									<FormGroup>
										<Row>
											<Col md={2}>
												<Label for="claProducto">Producto</Label>
												<Field
													name="claProducto"
													as="select"
													type="input"
													className="form-control"
													placeholder="Producto">
													{productos &&
														productos.length > 0 &&
														productos.map((obj, index) => (
															<option className="ddlOptions" key={index} value={obj.ClaProducto}>
																{obj.NomProducto}
															</option>
														))}
												</Field>
											</Col>
											<Col md={4}>
												<Label>Nombre</Label>
												<Field
													name="nomTermino"
													type="input"
													className="form-control"
													placeholder="Nombre del término"></Field>
											</Col>
											<Col md={2}>
												<Label>No Utilizar</Label>
												<Field
													name="noUsar"
													type="input"
													className="form-control"
													placeholder="No utilizar"></Field>
											</Col>
											<Col md={2}>
												<Label>Abreviatura</Label>
												<Field
													name="abreviatura"
													type="input"
													className="form-control"
													placeholder="Abreviación"></Field>
											</Col>
											<Col md={2}>
												<Label>Sinónimos</Label>
												<Field
													name="sinonimos"
													type="input"
													className="form-control"
													placeholder="Sinónimos"></Field>
											</Col>
										</Row>
									</FormGroup>
									<FormGroup row>
										<Col md={12}>
											<Label for="Descripcion">Descripción</Label>
											<Field
												as="textarea"
												id="glosarioTextArea"
												name="descripcion"
												placeholder="Descripción detallada del término"
												className="form-control textarea"
											/>
										</Col>
									</FormGroup>
								</CardBody>
								<CardFooter>
									<Row>
										<Col md={12} className="text-right">
											<Button
												id="btnGuardar"
												className="animation-on-hover"
												color="success"
												size="sm"
												type="submit">
												Guardar
											</Button>

											<Button
												id="btnCancelar"
												className="animation-on-hover"
												color="default"
												size="sm"
												onClick={() => {
													writeLog(
														Formularios.GlosarioMtto.ClaFuncionalidad,
														Formularios.GlosarioMtto.Funcionalidades.btnCancelar,
													);

													this.btnCancelar_Click(false);
												}}>
												Cancelar
											</Button>
										</Col>
									</Row>
								</CardFooter>
							</Card>
						</Form>
					)}
				</Formik>
			</>
		);
	}
}

GlosarioMtto.propTypes = {
	claTermino: PropTypes.any,
	hideModal: PropTypes.func,
};
