import React from "react";
import NotificationAlert from "react-notification-alert";
import { Card, CardHeader, CardBody, Button, UncontrolledTooltip, Modal, Badge, Col, Row } from "reactstrap";
import { getProducto, callApi, showAlert, writeLog } from "utils/Utilerias";
import { Config } from "utils/Config";
import { Link } from "react-router-dom";
import EntidadMtto from "../Entidades/EntidadMtto";
import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";

let srcData = [];
let formularioDet = [];

export default class PendientesPorRealizar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			showModalEntidad: false,
			peticiones: [],
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		};

		this.load = this.load.bind(this);
		this.hideModalEntidad = this.hideModalEntidad.bind(this);
		this.peticionCallBack = this.peticionCallBack.bind(this);
	}

	componentDidMount() {
		this.load();
	}

	load() {
		//Help tooltips
		let urlService = `${Config.urlApiService}/CatFormularioDet/7`;
		callApi(urlService, "GET", null, (status, response) => {
			formularioDet = response;
		});

		//Obtener información
		urlService = `${Config.urlApiService}/Home/PeticionesPendientes/${getProducto()}`;

		callApi(urlService, "GET", null, (status, response) => {
			if (status === 200) {
				srcData = response.map((obj, key) => {
					return {
						IdPeticion: obj.IdPeticion,
						Titulo: obj.Titulo,
						Estado: (
							<>
								<UncontrolledTooltip placement="top" target={`btnComenzarPeticion_${key}`}>
									<span>
										{obj.ClaEstatus === 6
											? translate("HomeDev.ttFinalizar")
											: translate("HomeDev.ttSiguiente")}
									</span>
								</UncontrolledTooltip>
								<Button
									id={`btnComenzarPeticion_${key}`}
									className="btn btn-link "
									color="success"
									size="sm"
									onClick={() => this.editarEstatus(obj)}>
									<i className={obj.ClaEstatus === 6 ? "fas fa-flag-checkered" : "fas fa-step-forward"} />
									{/* {obj.ClaEstatus === 6 ? "Finalizar" : "Siguiente Estatus"} */}
								</Button>
							</>
						),
						NomEstatus: obj.NomEstatus,
						NomProducto: obj.NomProducto,
						FechaPromesa: obj.FechaPromesa !== null ? obj.FechaPromesa.toDateNoTime() : null,
						EsAtrasado: obj.EsAtrasado === 1 ? translate("HomeDev.badgeAtrasado") : "",
					};
				});
				this.setState({
					isLoaded: true,
					peticiones: srcData,
					claIdioma: sessionStorage.getItem("ClaIdioma"),
				});
			}
		});
	}

	/**
	 *
	 * @param {JSON} obj objeto al que se le modificara el estatus o asociara una entidad
	 * @param {Boolean} documentado solo llegará como true cuando venga del callback exitosamente,
	 * quiere decir que ya se documentó en la pantalla de entidades
	 */
	editarEstatus(obj, documentado = false) {
		let urlWebService = Config.urlApiService + "/SolicitudServicio/" + obj.ClaProducto + "/" + obj.IdPeticion;
		let data = {
			...obj,
		};

		if (obj.FechaPromesa !== null) {
			obj.FechaPromesa = new Date(obj.FechaPromesa);
			data.FechaPromesa = obj.FechaPromesa.toSqlDateTime();
			obj.FechaCaptura = new Date(obj.FechaCaptura);
			data.FechaCaptura = obj.FechaCaptura.toSqlDateTime();
		} else {
			data.FechaPromesa = null;
		}

		data.BajaLogica = 0;

		if (obj.ClaEstatus === 6 && !documentado) {
			// Si está en terminado, y va a cambiar a en verificacion,
			//en el callback de entidad mtto se cambiara el estatus a 7
			this.showAlert(translate("HomeDev.alertAtencion"), translate("HomeDev.descripcionAtencion"), "warning");
			this.setState({
				showModalEntidad: true,
				idEntidad: obj.IdEntidad && obj.IdEntidad > 0 ? obj.IdEntidad : 0,
				objPeticion: obj,
			});
		} else {
			if (documentado) {
				data.IdEntidad = obj.IdEntidad;
			}

			data.ClaEstatus = obj.ClaEstatus + 1;

			callApi(urlWebService, "PUT", data, (status, response) => {
				if (status === 200) {
					writeLog(
						Formularios.PendientesPorRealizar.ClaFuncionalidad,
						Formularios.PendientesPorRealizar.Funcionalidades.btnComenzarPeticion,
						urlWebService,
						data,
						response,
						undefined,
						0,
					);

					this.showAlert("", translate("HomeDev.alertCambioExitoso"), "success");
				} else {
					writeLog(
						Formularios.PendientesPorRealizar.ClaFuncionalidad,
						Formularios.PendientesPorRealizar.Funcionalidades.btnComenzarPeticion,
						urlWebService,
						data,
						response,
						undefined,
						1,
					);
					this.showAlert("", translate("HomeDev.alertAlgoSalioMal"), "warning");
				}
				this.load();
			});
		}
	}

	/**
	 *
	 * @param {JSON} obj objeto al que se le modificara el estatus
	 */
	rechazarPeticion(obj) {
		let urlWebService = Config.urlApiService + "/SolicitudServicio/" + obj.ClaProducto + "/" + obj.IdPeticion;
		let data = {
			...obj,
		};

		if (obj.FechaPromesa !== null) {
			obj.FechaPromesa = new Date(obj.FechaPromesa);
			data.FechaPromesa = obj.FechaPromesa.toSqlDateTime();
			obj.FechaCaptura = new Date(obj.FechaCaptura);
			data.FechaCaptura = obj.FechaCaptura.toSqlDateTime();
		} else {
			data.FechaPromesa = null;
		}

		data.BajaLogica = 0;

		if (obj.IdEntidad) {
			data.IdEntidad = obj.IdEntidad;
		}

		data.ClaEstatus = obj.ClaEstatus = 5;

		callApi(urlWebService, "PUT", data, (status) => {
			if (status === 200) {
				this.showAlert("", translate("HomeDev.alertCambioExitoso"), "success");
			} else {
				this.showAlert("", translate("HomeDev.alertAlgoSalioMal"), "warning");
			}
			this.load();
		});
	}

	/**
	 *
	 * @param {JSON} response  respuesta recibida al guardar la entidad en ENTIDADMTTO
	 * @param {JSON} objPeticion objeto JSON que se va a guardar la entidad asociada a la peticion
	 */
	peticionCallBack(response, objPeticion, rechazado = false) {
		if (response.idEntidad && response.idEntidad > 0) {
			objPeticion.IdEntidad = response.idEntidad;
		}
		if (!rechazado) {
			this.editarEstatus(objPeticion, true);
		} else {
			this.rechazarPeticion(objPeticion);
		}
	}

	renderDetTexto(nomControl) {
		let detalle = formularioDet.filter((detalle) => detalle.NomControl === nomControl);
		if (detalle[0]) return detalle[0].Texto;
		else return "No se encontró descripción";
	}

	/**
	 * Muestra los prompts de alertas
	 */
	showAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Oculta los promts de alertas
	 */
	hideAlert() {
		this.setState({
			showAlert: null,
			showConfirm: null,
		});
	}

	/**
	 * Función 	que oculta el modal
	 * correcta y refresca la pantalla
	 */
	hideModalEntidad(isOk) {
		this.setState({
			showModalEntidad: false,
		});

		if (isOk) {
			var options = {
				place: "br",
				message: translate("HomeDev.operacionExitosa"),
				type: "success",
				autoDismiss: 3,
			};

			this.notify.notificationAlert(options);
		}
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<div className="spinner-border text-info" role="status">
					<span className="sr-only">Cargando...</span>
				</div>
			);
		} else {
			return (
				<>
					{sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.load()}
					<NotificationAlert ref={(ref) => (this.notify = ref)} />
					{this.state.showAlert}

					<Card id="ctnPendientesPorRealizar">
						<CardHeader>
							<UncontrolledTooltip placement="right" target={`helpServiciosPendientes`}>
								<span>{translate("HomeDev.ttPendientesPorRealizar")}</span>
							</UncontrolledTooltip>
							<Row>
								<Col md={8}>
									<h2>
										{translate("HomeDev.lblPendientesPorRealizar")} &nbsp;
										<span id="helpServiciosPendientes">
											<i className="far fa-question-circle"></i>
										</span>
									</h2>
								</Col>
								<Col md={4}>
									<Link to="/admin/SolicitudServicio">
										<Button
											id="btnVerTodos"
											className="btn-link btn-home float-right"
											size="sm"
											onClick={() => {
												writeLog(
													Formularios.PendientesPorRealizar.ClaFuncionalidad,
													Formularios.PendientesPorRealizar.Funcionalidades.btnVerTodos,
												);
												this.setState({ showSolitudServicios: true });
											}}>
											{translate("HomeDev.lblVerTodos")}
										</Button>
									</Link>
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							<div className="pendientesPorRealizar">
								<table className="table table-sm table-noBorder ">
									<thead>
										<tr>
											<th className="w-30">{translate("HomeDev.colSolicitud")}</th>
											<th className="w-20">{translate("HomeDev.colEstatus")}</th>
											<th className="w-15">{translate("HomeDev.colCliente")}</th>
											<th className="w-15">{translate("HomeDev.colFechaPromesa")}</th>
											<th className="w-10"></th>
											<th className="w-10"></th>
										</tr>
									</thead>
									<tbody>
										{(this.state.peticiones.length > 0 &&
											this.state.peticiones.map((peticion, index) => {
												return (
													<tr key={`ind_${index}`}>
														<td>{peticion.Titulo}</td>
														<td>{peticion.NomEstatus}</td>
														<td>{peticion.NomProducto}</td>
														<td>{peticion.FechaPromesa} &nbsp; </td>
														<td>
															<Badge color="danger">{peticion.EsAtrasado}</Badge>
														</td>
														<td className="text-right"> {peticion.Estado}</td>
													</tr>
												);
											})) || (
											<tr>
												<td colSpan={5} style={{ textAlign: "center", height: "105px" }}>
													<p>
														{translate("HomeDev.lblNoPendientes")}
														<span className="btn-icon btn-link text-success">
															<i className="fas fa-check"></i>
														</span>
													</p>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</CardBody>
					</Card>

					<Modal centered isOpen={this.state.showModalEntidad} size="lg">
						<EntidadMtto
							hideModal={this.hideModalEntidad}
							idEntidad={this.state.idEntidad}
							claProducto={getProducto()}
							readOnly={false}
							className="mdlEntidad"
							objPeticion={this.state.objPeticion}
							peticionCallBack={this.peticionCallBack}
							aprobarCallBack={this.aprobarCallBack}
						/>
					</Modal>
				</>
			);
		}
	}
}
