import React, { Component } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { callApi } from "../../utils/Utilerias";
import { Config } from "../../utils/Config";
import PropTypes from "prop-types";
import Tour from "reactour";
import "../../assets/css/TourVirtual.css";

var tourConfig = [];
var currentStep = 0;

class TourVirtual extends Component {
	constructor() {
		super();
		this.state = {
			isTourOpen: false,
			isShowingMore: false,
			itemsCount: 0,
		};
		this.closeTour = this.closeTour.bind(this);
		this.NoVolverAVer = this.NoVolverAVer.bind(this);
		this.buiildLayoutConfig = this.buiildLayoutConfig.bind(this);
	}

	disableBody(target) {
		disableBodyScroll(target);
	}

	enableBody(target) {
		enableBodyScroll(target);
	}

	/**
	 * Cierra el Tour virtual
	 */
	closeTour(esOmitir = true) {
		this.setState({ isTourOpen: false });
		if (currentStep === this.state.itemsCount - 1) {
			if (esOmitir) {
				let urlService = Config.urlApiService + "/Tour";

				let params = {
					ClaMenu: this.props.PaginaID,
					ClaTour: this.props.ClaTour,
					EsVisitado: 1,
				};

				callApi(urlService, "POST", params, () => {});
			}
		}
	}

	/**
	 * Abre el Tour virtual
	 */
	openTour() {
		this.setState({ isTourOpen: true });
	}

	/**
	 * Obtiene los datos para llenar el tour virtual con base en la página que se solicite,
	 * la página viene dada como parámetro de las props y cada página tiene un ID interno por producto
	 */
	getTourPagina() {
		let urlService = Config.urlApiService + "/Tour/" + this.props.PaginaID + "/" + this.props.ClaTour;
		callApi(urlService, "GET", null, (status, response) => {
			let tourPagina = [];

			if (response) {
				response.forEach(function (element) {
					let nodo = {
						Titulo: element.Titulo,
						Descripcion: element.Redaccion,
						Actual: element.ControlHTML,
						NomNoVolverAVer: element.NomNoVolverAVer,
						contenidoHTML: element.ContenidoHTML,
					};
					tourPagina.push(nodo);
				});

				this.buildConfig(tourPagina);
				this.setState({ itemsCount: response.length });
				if (this.state.itemsCount > 0) {
					this.openTour();
				}
			}
		});
	}

	/**
	 * Construye el objeto de configuración que alimenta el componente del tour virtual
	 * @data Es el arreglo de datos que regresa el servicio
	 */
	buildConfig(data) {
		tourConfig = [];
		data.map((e) => {
			let elemento = {};
			elemento.selector = "[id='" + e.Actual + "']";
			elemento.content = this.buiildLayoutConfig(e, this.NoVolverAVer);
			elemento.style = e.contenidoHTML ? { maxWidth: "46%" } : {};
			tourConfig.push(elemento);
			return null;
		});
	}

	/**
	 *
	 * @param {String} htmlContent Contenido HTML que será inyectado dentro de un Div
	 */
	setInnerHtml(htmlContent) {
		return { __html: htmlContent };
	}

	/**
	 * Crea un template rellenando con datos las variables para mostrar dicha plantilla en cada item del tour
	 * @parametro Es el objeto de donde se obtendrán cada una de las propiedades
	 * @func Es una función por parámetro que se utilizará para mandar el click de omitir tour y con est acción persistirlo en la base de datos
	 */
	buiildLayoutConfig(parametro, func) {
		return (
			<div>
				<h3 className="lblTituloTour">{parametro.Titulo}</h3>
				{parametro.contenidoHTML ? (
					<div className="overflow" dangerouslySetInnerHTML={this.setInnerHtml(parametro.contenidoHTML)} />
				) : (
					<span>{parametro.Descripcion}</span>
				)}

				<br />
				<button className={"btnOmitir btn-link"} onClick={func}>
					{parametro.NomNoVolverAVer}
				</button>
			</div>
		);
	}

	/**
	 * Es una función que manda llamar el servicio que registra la acción de que un usuario no desea volver a ver el tour que está navegando
	 */
	NoVolverAVer() {
		if (this.state.isTourOpen) {
			let urlService = Config.urlApiService + "/Tour";

			let params = {
				ClaMenu: this.props.PaginaID,
				ClaTour: this.props.ClaTour,
				EsVisitado: 0,
			};

			callApi(urlService, "POST", params, () => {
				this.closeTour(false);
			});
		}
	}

	/**
	 * Es una función que manda llamar el servicio que registra la acción de que un usuario ha terminado de ver el tour que estaba navegando
	 */
	TerminarTour(currentItem) {
		if (currentItem === this.state.itemsCount - 1) {
			let urlService = Config.urlApiService + "/Tour";

			let params = {
				ClaMenu: this.props.PaginaID,
				ClaTour: this.props.ClaTour,
				EsVisitado: 1,
			};

			callApi(urlService, "POST", params, () => {});
		}
	}

	componentDidMount() {
		this.getTourPagina();
	}

	render() {
		const { isTourOpen } = this.state;
		const accentColor = "#5cb7b7";
		return (
			<div>
				<Tour
					onRequestClose={this.closeTour}
					steps={tourConfig}
					isOpen={isTourOpen}
					maskClassName="mask"
					className="helper"
					rounded={5}
					accentColor={accentColor}
					onBeforeClose={this.enableBody}
					lastStepNextButton={<button className="btnOmitir btn-link">{">"}</button>}
					//getCurrentStep={(curr) => this.TerminarTour(curr)}
					getCurrentStep={(curr) => (currentStep = curr)}
				/>
			</div>
		);
	}
}

export default TourVirtual;

TourVirtual.propTypes = {
	PaginaID: PropTypes.any,
	ClaTour: PropTypes.number,
};
