import React, { useEffect, useState, useRef } from "react";
/*
import { callApi, showSweetAlert } from "utils/Utilerias";

import { Formik, Field } from "formik";
import * as Yup from "yup";*/
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";

require("utils/Prototipos");

var ReplicaComentario = (props) => {
	const [NumeroReplicas, setNumeroReplicas] = useState(0);
	const [VerReplicas, setVerReplicas] = useState(false);
	const comments = useRef(null);
	const scrollToBottom = () => {
		comments.current.scrollIntoView({ behavior: "smooth" });
	};
	useEffect(() => {
		let commentNumber = 0;

		[...props.hijos].reverse().forEach((obj) => {
			if (obj.IdComentarioPadre === props.padre) {
				commentNumber++;
			}
		});
		setNumeroReplicas(commentNumber);
	}, [props]);

	let renderReplicas = () => {
		return props.hijos || props.hijos.length > 0
			? [...props.hijos]
					.reverse()
					.filter((obj) => obj.IdComentarioPadre === props.padre)
					.map((obj, index) => (
						<>
							<div className="boxedReplicaComentario sb4" key={index}>
								<Row>
									<Col md={10}>{obj.NombreUsuario + " " + obj.ApellidoPaterno}</Col>
								</Row>
								<Row>
									<Col md={12}>
										<br></br>
										<p>{obj.Comentario}</p>
									</Col>
								</Row>
							</div>
						</>
					))
			: null;
	};

	const onClick = () => {
		writeLog(
			Formularios.ReplicaComentario.ClaFuncionalidad,
			Formularios.ReplicaComentario.Funcionalidades.verReplicas,
		);
		scrollToBottom();
		setVerReplicas(!VerReplicas);
	};

	return (
		<>
			{NumeroReplicas > 0 && (
				<div className="comVerReplica" onClick={onClick}>
					{translate("Comentarios.lblVer") + ` ${NumeroReplicas} ` + translate("Comentarios.lblRespuestas")}
				</div>
			)}
			<div ref={comments}>{VerReplicas && renderReplicas()}</div>
		</>
	);
};

ReplicaComentario.propTypes = {
	hijos: PropTypes.any,
	padre: PropTypes.number,
};

export default ReplicaComentario;
