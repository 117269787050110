/** @format */

import React from "react";
import NotificationAlert from "react-notification-alert";
import SolicitudServicioMtto from "./SolicitudServicioMtto";
import AsignacionUsuario from "./AsignacionUsuario";
import EntidadMtto from "../Entidades/EntidadMtto";
import { getProducto, showAlert, showConfirm, writeLog } from "utils/Utilerias";
import Datetime from "react-datetime";
import "moment/locale/es";
import { Grid, Table, TableHeaderRow } from "@devexpress/dx-react-grid-bootstrap4";
import TourVirtual from "components/Tour/TourVirtual";
import { Tours } from "../../utils/Config";
import { Formularios } from "../../utils/funcionalidad";
import translate from "utils/translate";

import { IntegratedSorting, SortingState } from "@devexpress/dx-react-grid";
import {
	Button,
	Form,
	Card,
	CardHeader,
	UncontrolledTooltip,
	CardBody,
	CardTitle,
	Row,
	Col,
	Modal,
	FormGroup,
	Label,
	Input,
} from "reactstrap";
import { Formik, Field } from "formik";
import { Config } from "utils/Config";
import { callApi, getLoginInfo } from "utils/Utilerias";
import { tableComponent, headerComponent } from "utils/gridConfig";

let productos;
let srcData = [];
export default class SolicitudServicio extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			alert: null,
			esAdmin: getLoginInfo().ClaRol === 5,
			showModalAsignarUsuario: false,
			showModalEntidad: false,
			recibidasPM: [],
			realizadasPM: [],
			realizadasDev: [],
			asignadasDev: [],
			soloMisSolicitudes: true,
			soloPendientes: false,
			readOnly: false,
			columnsTablaSolicitudesRecibidasPM: [
				{ name: "Titulo", title: "Servicio" },
				{ name: "NomProductoSolicita", title: "Solicitante" },
				{ name: "NomUsuarioSolicita", title: "Usuario Solicita" },
				{ name: "NomEstatus", title: "Estatus " },
				{ name: "FechaCaptura", title: "Fecha Solicitud" },
				{ name: "FechaPromesa", title: "Fecha Promesa" },
				{ name: "Actions", title: " " },
			],

			columnsTablaSolicitudesRealizadasPM: [
				{ name: "Titulo", title: "Servicio" },
				{ name: "NomProducto", title: "Solicitado a" },
				{ name: "NomUsuarioSolicita", title: "Usuario Solicita" },
				{ name: "NomEstatus", title: "Estatus" },
				{ name: "FechaCaptura", title: "Fecha Solicitud" },
				{ name: "Actions", title: " " },
			],

			columnsTablaSolicitudesRealizadasDev: [
				{ name: "Titulo", title: "Servicio" },
				{ name: "NomProducto", title: "Solicitado a" },
				{ name: "NomUsuarioSolicita", title: "Usuario Solicita" },
				{ name: "NomEstatus", title: "Estatus" },
				{ name: "FechaCaptura", title: "Fecha Solicitud" },
				{ name: "FechaPromesa", title: "Fecha Promesa" },
				{ name: "Info", title: " " },
			],

			columnsTablaSolicitudesAsignadasDev: [
				{ name: "Titulo", title: "Servicio" },
				{ name: "NomProducto", title: "Solicitado a" },
				{ name: "NomUsuarioSolicita", title: "Usuario Solicita" },
				{ name: "NomEstatus", title: "Estatus" },
				{ name: "FechaCaptura", title: "Fecha Solicitud" },
				{ name: "FechaPromesa", title: "Fecha Promesa" },
				{ name: "Actions", title: " " },
				{ name: "Info", title: " " },
			],

			sortingTablaSolicitudesRecibidasPM: [{ columnName: "FechaCaptura", direction: "desc" }],
			sortingTablaSolicitudesRealizadasPM: [{ columnName: "Titulo", direction: "asc" }],
			sortingTablaSolicitudesRealizadasDev: [{ columnName: "Titulo", direction: "asc" }],
			sortingTablaSolicitudesAsignadasDev: [{ columnName: "Titulo", direction: "asc" }],

			formatoTablaSolicitudesRecibidasPM: [
				{ columnName: "Titulo", width: "20%", wordWrapEnabled: true },
				{
					columnName: "NomProductoSolicita",
					width: "15%",
					wordWrapEnabled: true,
				},
				{
					columnName: "NomUsuarioSolicita",
					width: "15%",
					wordWrapEnabled: true,
				},
				{ columnName: "NomEstatus", width: "10%", wordWrapEnabled: true },
				{ columnName: "FechaCaptura", align: "center", width: "auto" },
				{ columnName: "FechaPromesa", align: "center", width: "auto" },
				{ columnName: "Actions" },
			],

			formatoTablaSolicitudesRealizadasPM: [
				{ columnName: "Titulo", width: "25%", wordWrapEnabled: true },
				{ columnName: "NomProducto", width: "15%", wordWrapEnabled: true },
				{
					columnName: "NomUsuarioSolicita",
					width: "20%",
					wordWrapEnabled: true,
				},
				{ columnName: "NomEstatus", width: "10%", wordWrapEnabled: true },
				{ columnName: "FechaCaptura", align: "center", width: "auto" },
				{ columnName: "Actions" },
			],
			formatoTablaSolicitudesRealizadasDev: [
				{ columnName: "Titulo", width: "20%", wordWrapEnabled: true },
				{ columnName: "NomProducto", width: "15%", wordWrapEnabled: true },
				{
					columnName: "NomUsuarioSolicita",
					width: "25%",
					wordWrapEnabled: true,
				},
				{ columnName: "NomEstatus", width: "15%", wordWrapEnabled: true },
				{ columnName: "FechaCaptura", align: "center", width: "auto" },
				{ columnName: "FechaPromesa", align: "center", width: "auto" },
				{ columnName: "Info", width: "5%" },
			],
			formatoTablaSolicitudesAsignadasDev: [
				{ columnName: "Titulo", width: "20%", wordWrapEnabled: true },
				{ columnName: "NomProducto", width: "10%", wordWrapEnabled: true },
				{
					columnName: "NomUsuarioSolicita",
					width: "20%",
					wordWrapEnabled: true,
				},
				{ columnName: "NomEstatus", width: "10%", wordWrapEnabled: true },
				{ columnName: "FechaCaptura", align: "center", width: "auto" },
				{ columnName: "FechaPromesa", align: "center", width: "auto" },
				{ columnName: "Info", width: "5%" },
				{ columnName: "Actions" },
			],
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		};

		this.hideModal = this.hideModal.bind(this);
		this.hideModalEntidad = this.hideModalEntidad.bind(this);
		this.hideModalAsignacionUsuario = this.hideModalAsignacionUsuario.bind(this);
		this.handleSubmitButton = this.handleSubmitButton.bind(this);
		this.renderSolicitudesRecibidasPM = this.renderSolicitudesRecibidasPM.bind(this);
		this.renderSolicitudesRealizadasPM = this.renderSolicitudesRealizadasPM.bind(this);
		this.renderSolicitudesRealizadasDev = this.renderSolicitudesRealizadasDev.bind(this);
		this.renderSolicitudesAsignadasDev = this.renderSolicitudesAsignadasDev.bind(this);
		this.cargaPM = this.cargaPM.bind(this);
		this.cargaDev = this.cargaDev.bind(this);
		this.cargaRealizadas = this.cargaRealizadas.bind(this);
		this.cargaRecibidas = this.cargaRecibidas.bind(this);
		this.cargaAsignadas = this.cargaAsignadas.bind(this);
		this.showConfirm = this.showConfirm.bind(this);
		this.btnEliminarClick = this.btnEliminarClick.bind(this);
		this.peticionCallBack = this.peticionCallBack.bind(this);
		this.clear = this.clear.bind(this);
	}

	componentDidMount() {
		//Catalogo de productos
		let urlService = `${Config.urlApiService}/Catalogos/11`;
		callApi(urlService, "GET", null, (status, response) => {
			productos = response;
		});

		if (getLoginInfo().ClaRol === 5) {
			this.cargaPM();
		} else {
			this.cargaDev();
		}
	}

	clear() {
		if (getLoginInfo().ClaRol === 5) {
			this.cargaPM();
		} else {
			this.cargaDev();
		}
	}
	/**
	 * Muestra los prompts de alertas
	 */
	showAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	showConfirm(onConfirm, onCancel, title, text) {
		const warningAlert = showConfirm(onConfirm, onCancel, title, text);

		this.setState({
			showConfirm: warningAlert,
		});
	}

	/**
	 * Oculta los promts de alertas
	 */
	hideAlert() {
		this.setState({
			showAlert: null,
			showConfirm: null,
		});
	}

	btnEliminarClick(obj) {
		let urlWebService = Config.urlApiService + "/SolicitudServicio/" + obj.ClaProducto + "/" + obj.IdPeticion;
		let data = {
			...obj,
		};

		if (obj.FechaPromesa !== null) {
			obj.FechaPromesa = new Date(obj.FechaPromesa);
			data.FechaPromesa = obj.FechaPromesa.toSqlDateTime();
			obj.FechaCaptura = new Date(obj.FechaCaptura);
			data.FechaCaptura = obj.FechaCaptura.toSqlDateTime();
		} else {
			data.FechaPromesa = null;
		}
		data.BajaLogica = 0;

		data.ClaEstatus = 9;

		callApi(
			urlWebService,
			"PUT",
			data,
			(status, response) => {
				this.hideAlert();
				writeLog(
					Formularios.SolicitudesDeServicio.ClaFuncionalidad,
					Formularios.SolicitudesDeServicio.Funcionalidades.btnNoAutorizar,
					urlWebService,
					data,
					response,
					"Estatus cambiado con éxito",
					0,
				);
				this.showAlert(translate("SolicitudServicio.alertRegistroEliminado"), undefined, "success");

				if (this.state.esAdmin) {
					this.cargaPM();
				} else {
					this.cargaDev();
				}
			},
			Formularios.SolicitudesDeServicio.ClaFuncionalidad,
			Formularios.SolicitudesDeServicio.Funcionalidades.btnNoAutorizar,
		);

		if (this.state.esAdmin) {
			this.cargaPM();
		} else {
			this.cargaDev();
		}
	}

	/**
	 * Función que hará las llamadas a la base de datos para llenar la tabla
	 */
	cargaPM(params = {}) {
		this.cargaRecibidas(params);
		this.cargaRealizadas(params);
	}

	cargaDev(params = {}) {
		this.cargaRealizadas(params, true); //al inicio solo cargara mis solicitudes
		this.cargaAsignadas(params);
	}

	cargaRecibidas(params = {}) {
		//Solicitudes Recibidas
		srcData = [];
		let urlService = `${Config.urlApiService}/SolicitudServicio/Buscar`;
		let data = {
			ClaProducto: getProducto(),
		};

		if (params.fechaInicio !== undefined) {
			data.FechaInicio = params.fechaInicio;
		}

		if (params.fechaFin !== undefined) {
			data.FechaFin = params.fechaFin;
		}

		if (params.producto !== undefined) {
			data.ClaProductoSolicita = params.producto;
		}

		callApi(
			urlService,
			"POST",
			data,
			(status, response) => {
				if (status === 200) {
					console.log(response);
					if (response.length <= 0) {
						srcData = [];
						this.setState({
							recibidasPM: srcData,
						});
					} else {
						srcData = response.map((obj, key) => {
							return {
								Titulo: obj.Titulo,
								NomProductoSolicita: obj.NomProductoSolicita,
								NomUsuarioSolicita: obj.NomUsuarioSolicita,
								NomEstatus: obj.NomEstatus,
								FechaCaptura: obj.FechaCaptura !== null ? obj.FechaCaptura.toDateNoTime() : null,
								FechaPromesa: obj.FechaPromesa !== null ? obj.FechaPromesa.toDateNoTime() : null,
								Actions: (
									<div style={{ textAlign: "right" }}>
										{(obj.ClaEstatus === 2 || obj.ClaEstatus === 3) && (
											<>
												{obj.ClaEstatus === 2 ? (
													<>
														<UncontrolledTooltip placement="left" target={`btnAutorizar_${key}`}>
															{translate("SolicitudServicio.ttAutorizar")}
														</UncontrolledTooltip>
														<Button
															id={`btnAutorizar_${key}`}
															onClick={() => {
																writeLog(
																	Formularios.SolicitudesDeServicio.ClaFuncionalidad,
																	Formularios.SolicitudesDeServicio.Funcionalidades.btnAutorizar,
																	undefined,
																	obj,
																);
																this.editarEstatus(obj);
															}}
															color="success"
															size="sm"
															className="btn-icon btn-link">
															<i className="fa fa-check" />
														</Button>
													</>
												) : (
													<>
														<UncontrolledTooltip placement="left" target={`btnAsignar_${key}`}>
															{translate("SolicitudServicio.ttAsignar")}
														</UncontrolledTooltip>
														<Button
															id={`btnAsignar_${key}`}
															onClick={() => {
																writeLog(
																	Formularios.SolicitudesDeServicio.ClaFuncionalidad,
																	Formularios.SolicitudesDeServicio.Funcionalidades.btnAsignar,
																);
																if (obj.ClaEstatus === 2) {
																	this.editarEstatus(obj);
																} else {
																	this.setState({
																		showModalAsignarUsuario: true,
																		objPeticion: obj,
																	});
																}
															}}
															color="success"
															size="sm"
															className="btn-icon btn-link">
															<i className="fa fa-user" />
														</Button>
													</>
												)}
											</>
										)}
										{obj.ClaEstatus === 7 && (
											<>
												<UncontrolledTooltip placement="left" target={`btnVerificar_${key}`}>
													{translate("SolicitudServicio.ttVerificar")}
												</UncontrolledTooltip>
												<Button
													id={`btnVerificar_${key}`}
													onClick={() => {
														writeLog(
															Formularios.SolicitudesDeServicio.ClaFuncionalidad,
															Formularios.SolicitudesDeServicio.Funcionalidades.btnVerificar,
														);
														this.setState({
															showModalEntidad: true,
															idEntidad: obj.IdEntidad,
															objPeticion: obj,
														});
													}}
													color="success"
													size="sm"
													className="btn-icon btn-link">
													<i className="fa fa-check" />
												</Button>
											</>
										)}
										{obj.ClaEstatus < 8 && (
											<>
												<UncontrolledTooltip placement="left" target={`btnNoAutorizar_${key}`}>
													{translate("SolicitudServicio.ttRechazar")}
												</UncontrolledTooltip>
												<Button
													id={`btnNoAutorizar_${key}`}
													onClick={() => {
														this.showConfirm(
															() => this.btnEliminarClick(obj),
															() => this.hideAlert(),
															`¿Desea rechazar la solicitud?`,
															obj.Titulo,
														);
													}}
													color="warning"
													size="sm"
													className="btn-icon btn-link">
													<i className="fa fa-times" />
												</Button>
											</>
										)}

										<UncontrolledTooltip placement="left" target={`btnInformacion_${key}`}>
											{translate("SolicitudServicio.ttInformacion")}
										</UncontrolledTooltip>
										<Button
											id={`btnInformacion_${key}`}
											onClick={() => {
												writeLog(
													Formularios.SolicitudesDeServicio.ClaFuncionalidad,
													Formularios.SolicitudesDeServicio.Funcionalidades.btnInformacion,
													undefined,
													obj,
												);

												this.setState({
													showModal: true,
													idPeticion: obj.IdPeticion,
													readOnly: true,
												});
											}}
											color="info"
											size="sm"
											className="btn-icon btn-link">
											<i className="fas fa-info-circle" />
										</Button>
									</div>
								),
							};
						});

						writeLog(
							Formularios.SolicitudesDeServicio.ClaFuncionalidad,
							Formularios.SolicitudesDeServicio.Funcionalidades.cargaRecibidas,
							urlService,
							data,
							srcData,
							"Carga peticiones recibidas",
							0,
						);

						this.setState({
							isLoaded: true,
							recibidasPM: srcData,
							claIdioma: sessionStorage.getItem("ClaIdioma"),

							columnsTablaSolicitudesRecibidasPM: [
								{ name: "Titulo", title: translate("SolicitudServicio.colRecibidasPMServicio") },
								{
									name: "NomProductoSolicita",
									title: translate("SolicitudServicio.colRecibidasPMSolicitante"),
								},
								{
									name: "NomUsuarioSolicita",
									title: translate("SolicitudServicio.colRecibidasPMUsuarioSolicita"),
								},
								{ name: "NomEstatus", title: translate("SolicitudServicio.colRecibidasPMEstatus") },
								{ name: "FechaCaptura", title: translate("SolicitudServicio.colRecibidasPMFechaSolicitud") },
								{ name: "FechaPromesa", title: translate("SolicitudServicio.colRecibidasPMFechaPromesa") },
								{ name: "Actions", title: " " },
							],
						});
					}
				}
			},
			Formularios.SolicitudesDeServicio.ClaFuncionalidad,
			Formularios.SolicitudesDeServicio.Funcionalidades.cargaRecibidas,
		);
	}

	cargaRealizadas(params = {}, soloMisSolicitudes = false) {
		//Realizadas

		srcData = [];

		let urlService = `${Config.urlApiService}/SolicitudServicio/Buscar`;

		let data = {
			ClaProductoSolicita: getProducto(),
		};

		if (!this.state.esAdmin && soloMisSolicitudes) {
			data = {
				ClaProductoSolicita: getProducto(),
				ClaUsuarioSolicita: getLoginInfo().ClaEmpleado,
			};
		}

		if (params.fechaInicio !== undefined) {
			data.FechaInicio = params.fechaInicio;
		}

		if (params.fechaFin !== undefined) {
			data.FechaFin = params.fechaFin;
		}

		if (params.producto !== undefined) {
			data.ClaProducto = params.producto;
		}

		callApi(
			urlService,
			"POST",
			data,
			(status, response) => {
				if (status === 200) {
					console.log(response);
					if (response.length <= 0) {
						srcData = [];
						this.setState({
							realizadasPM: srcData,
						});
					} else {
						srcData = response.map((obj, key) => {
							return {
								Titulo: obj.Titulo,
								NomProducto: obj.NomProducto,
								NomUsuarioSolicita: obj.NomUsuarioSolicita,
								NomEstatus: obj.NomEstatus,
								FechaCaptura: obj.FechaCaptura !== null ? obj.FechaCaptura.toDateNoTime() : null,
								FechaPromesa: obj.FechaPromesa !== null ? obj.FechaPromesa.toDateNoTime() : null,
								Info: (
									<div style={{ textAlign: "center" }}>
										<UncontrolledTooltip placement="left" target={`btnInformacion_${key}`}>
											{translate("SolicitudServicio.ttInformacion")}
										</UncontrolledTooltip>
										<Button
											id={`btnInformacion_${key}`}
											onClick={() => {
												writeLog(
													Formularios.SolicitudesDeServicio.ClaFuncionalidad,
													Formularios.SolicitudesDeServicio.Funcionalidades.btnInformacion,
													undefined,
													obj,
												);

												this.setState({
													showModal: true,
													idPeticion: obj.IdPeticion,
												});
											}}
											color="info"
											size="sm"
											className="btn-icon btn-link">
											<i className="fas fa-info-circle" />
										</Button>
									</div>
								),
								Actions: (
									<div style={{ textAlign: "right" }}>
										{obj.ClaEstatus === 1 && (
											<>
												<UncontrolledTooltip placement="left" target={`btnAprobarSol_${key}`}>
													{translate("SolicitudServicio.ttAprobarSolicitud")}
												</UncontrolledTooltip>
												<Button
													id={`btnAprobarSol_${key}`}
													onClick={() => {
														writeLog(
															Formularios.SolicitudesDeServicio.ClaFuncionalidad,
															Formularios.SolicitudesDeServicio.Funcionalidades.btnAprobarSol,
															undefined,
															obj,
														);
														this.editarEstatus(obj);
													}}
													color="success"
													size="sm"
													className="btn-icon btn-link">
													<i className="fa fa-check" />
												</Button>
											</>
										)}

										<UncontrolledTooltip placement="left" target={`btnNoAutorizar_${key}`}>
											{translate("SolicitudServicio.ttRechazar")}
										</UncontrolledTooltip>
										<Button
											id={`btnNoAutorizar_${key}`}
											onClick={() => {
												this.showConfirm(
													() => this.btnEliminarClick(obj),
													() => this.hideAlert(),
													`¿Desea rechazar la solicitud?`,
													obj.Titulo,
												);
											}}
											color="warning"
											size="sm"
											className="btn-icon btn-link">
											<i className="fa fa-times" />
										</Button>
										<UncontrolledTooltip placement="left" target={`btnInformacion_${key}`}>
											{translate("SolicitudServicio.ttInformacion")}
										</UncontrolledTooltip>
										<Button
											id={`btnInformacion_${key}`}
											onClick={() => {
												writeLog(
													Formularios.SolicitudesDeServicio.ClaFuncionalidad,
													Formularios.SolicitudesDeServicio.Funcionalidades.btnInformacion,
													undefined,
													obj,
												);

												this.setState({
													showModal: true,
													idPeticion: obj.IdPeticion,
												});
											}}
											color="info"
											size="sm"
											className="btn-icon btn-link">
											<i className="fas fa-info-circle" />
										</Button>
									</div>
								),
							};
						});

						writeLog(
							Formularios.SolicitudesDeServicio.ClaFuncionalidad,
							Formularios.SolicitudesDeServicio.Funcionalidades.cargaRealizadas,
							urlService,
							data,
							srcData,
							"Carga peticiones realizadas",
							0,
						);

						this.setState({
							isLoaded: true,
							realizadasPM: srcData,
							claIdioma: sessionStorage.getItem("ClaIdioma"),

							columnsTablaSolicitudesRealizadasDev: [
								{ name: "Titulo", title: translate("SolicitudServicio.colRealizadasDevServicio") },
								{ name: "NomProducto", title: translate("SolicitudServicio.colRealizadasDevSolicitadoA") },
								{
									name: "NomUsuarioSolicita",
									title: translate("SolicitudServicio.colRealizadasDevUsuarioSolicita"),
								},
								{ name: "NomEstatus", title: translate("SolicitudServicio.colRealizadasDevEstatus") },
								{ name: "FechaCaptura", title: translate("SolicitudServicio.colRealizadasDevFechaSolicita") },
								{ name: "FechaPromesa", title: translate("SolicitudServicio.colRealizadasDevFechaPromesa") },
								{ name: "Info", title: " " },
							],

							columnsTablaSolicitudesRealizadasPM: [
								{ name: "Titulo", title: translate("SolicitudServicio.colSolSalPMServicio") },
								{ name: "NomProducto", title: translate("SolicitudServicio.colSolSalPMSolicitadoA") },
								{
									name: "NomUsuarioSolicita",
									title: translate("SolicitudServicio.colSolSalPMUsuarioSolicita"),
								},
								{ name: "NomEstatus", title: translate("SolicitudServicio.colSolSalPMEstatus") },
								{ name: "FechaCaptura", title: translate("SolicitudServicio.colSolSalPMFechaSolicitud") },
								{ name: "Actions", title: " " },
							],
						});
					}
				}
			},
			Formularios.SolicitudesDeServicio.ClaFuncionalidad,
			Formularios.SolicitudesDeServicio.Funcionalidades.cargaRealizadas,
		);
	}

	cargaAsignadas(params = {}, soloPendientes = false) {
		srcData = [];

		let urlService = `${Config.urlApiService}/SolicitudServicio/Buscar`;

		let data = {
			ClaProducto: getProducto(),
			ClaUsuarioAsignado: getLoginInfo().ClaEmpleado,
		};

		if (params.fechaInicio !== undefined) {
			data.FechaInicio = params.fechaInicio;
		}

		if (params.fechaFin !== undefined) {
			data.FechaFin = params.fechaFin;
		}

		if (params.producto !== undefined) {
			data.ClaProducto = params.producto;
		}

		callApi(urlService, "POST", data, (status, response) => {
			if (status === 200) {
				console.log(response);
				if (response.length <= 0) {
					srcData = [];
					this.setState({
						asignadasDev: srcData,
					});
				} else {
					srcData = response.map((obj, key) => {
						return {
							Titulo: obj.Titulo,
							NomProducto: obj.NomProducto,
							NomUsuarioSolicita: obj.NomUsuarioSolicita,
							NomEstatus: obj.NomEstatus,
							FechaCaptura: obj.FechaCaptura !== null ? obj.FechaCaptura.toDateNoTime() : null,
							FechaPromesa: obj.FechaPromesa !== null ? obj.FechaPromesa.toDateNoTime() : null,
							Info: (
								<div style={{ textAlign: "center" }}>
									<UncontrolledTooltip placement="left" target={`btnInformacion_${key}`}>
										{translate("SolicitudServicio.ttInformacion")}
									</UncontrolledTooltip>
									<Button
										id={`btnInformacion_${key}`}
										onClick={() => {
											writeLog(
												Formularios.SolicitudesDeServicio.ClaFuncionalidad,
												Formularios.SolicitudesDeServicio.Funcionalidades.btnInformacion,
												undefined,
												obj,
											);

											this.setState({
												showModal: true,
												idPeticion: obj.IdPeticion,
											});
										}}
										color="info"
										size="sm"
										className="btn-icon btn-link">
										<i className="fas fa-info-circle" />
									</Button>
								</div>
							),
							Actions: (
								<div style={{ textAlign: "center" }}>
									{(obj.ClaEstatus === 2 ||
										obj.ClaEstatus === 3 ||
										obj.ClaEstatus === 4 ||
										obj.ClaEstatus === 5 ||
										obj.ClaEstatus === 6) && (
										<>
											<div>
												<UncontrolledTooltip placement="left" target={`btnSiguiente_${key}`}>
													{translate("SolicitudServicio.ttSiguienteEstatus")}
												</UncontrolledTooltip>
												<Button
													id={`btnSiguiente_${key}`}
													onClick={() => {
														writeLog(
															Formularios.SolicitudesDeServicio.ClaFuncionalidad,
															Formularios.SolicitudesDeServicio.Funcionalidades.btnSiguiente,
															undefined,
															obj,
														);
														this.editarEstatus(obj);
													}}
													color="primary"
													size="sm"
													className="btn-link">
													{translate("SolicitudServicio.lblSiguienteEstatus")}
												</Button>
											</div>
										</>
									)}
								</div>
							),
						};
					});
					if (soloPendientes) {
						let filtered = srcData.filter(function (value) {
							return (
								value.NomEstatus.toUpperCase() === "ASIGNADO" || value.NomEstatus.toUpperCase() === "EN PROCESO"
							);
						});

						this.setState({
							isLoaded: true,
							asignadasDev: filtered,
						});

						writeLog(
							Formularios.SolicitudesDeServicio.ClaFuncionalidad,
							Formularios.SolicitudesDeServicio.Funcionalidades.cargaAsignadas,
							urlService,
							data,
							filtered,
							"Carga peticiones asignadas pendientes",
							0,
						);
					} else {
						writeLog(
							Formularios.SolicitudesDeServicio.ClaFuncionalidad,
							Formularios.SolicitudesDeServicio.Funcionalidades.cargaAsignadas,
							urlService,
							data,
							srcData,
							"Carga peticiones asignadas",
							0,
						);

						this.setState({
							isLoaded: true,
							asignadasDev: srcData,
							claIdioma: sessionStorage.getItem("ClaIdioma"),

							columnsTablaSolicitudesAsignadasDev: [
								{ name: "Titulo", title: translate("SolicitudServicio.colAsignadasDevServicio") },
								{ name: "NomProducto", title: translate("SolicitudServicio.colAsignadasDevSolicitadoA") },
								{
									name: "NomUsuarioSolicita",
									title: translate("SolicitudServicio.colAsignadasDevUsuarioSolicita"),
								},
								{ name: "NomEstatus", title: translate("SolicitudServicio.colAsignadasEstatus") },
								{ name: "FechaCaptura", title: translate("SolicitudServicio.colAsignadasFechaSolicita") },
								{ name: "FechaPromesa", title: translate("SolicitudServicio.colAsignadasFechaPromesa") },
								{ name: "Actions", title: " " },
								{ name: "Info", title: " " },
							],
						});
					}
				}
			}
		});
	}

	/**
	 * Función 	que oculta el modal
	 * correcta y refresca la pantalla
	 */
	hideModal(isOk) {
		this.setState({
			showModal: false,
			readOnly: false,
		});

		if (isOk) {
			var options = {
				place: "br",
				message: translate("SolicitudServicio.notificacion"),
				type: "success",
				autoDismiss: 3,
			};

			this.notify.notificationAlert(options);
		}
	}

	/**
	 * Función 	que oculta el modal
	 * correcta y refresca la pantalla
	 */
	hideModalEntidad(isOk) {
		this.setState({
			showModalEntidad: false,
		});

		if (isOk) {
			var options = {
				place: "br",
				message: translate("SolicitudServicio.notificacion"),
				type: "success",
				autoDismiss: 3,
			};

			this.notify.notificationAlert(options);
		}
	}

	/**
	 * Función 	que oculta el modal
	 * correcta y refresca la pantalla
	 */
	hideModalAsignacionUsuario(isOk) {
		this.setState({
			showModalAsignarUsuario: false,
		});

		if (isOk) {
			var options = {
				place: "br",
				message: translate("SolicitudServicio.notificacion"),
				type: "success",
				autoDismiss: 3,
			};

			this.notify.notificationAlert(options);

			this.cargaRecibidas();
		}
	}

	renderSolicitudesRealizadasPM() {
		return (
			<div>
				<Row>
					<Col md={6}>
						<h3>{translate("SolicitudServicio.lblSolicitudesSalientes")}</h3>
					</Col>
				</Row>
				<Grid rows={this.state.realizadasPM} columns={this.state.columnsTablaSolicitudesRealizadasPM}>
					<SortingState
						sorting={this.state.sortingTablaSolicitudesRealizadasPM}
						onSortingChange={(value) => {
							this.setState({ sortingTablaSolicitudesRealizadasPM: value });
						}}
					/>
					<IntegratedSorting />

					<Table
						tableComponent={tableComponent}
						headComponent={headerComponent}
						height={250}
						messages={{ noData: translate("SolicitudServicio.noInfo") }}
						columnExtensions={this.state.formatoTablaSolicitudesRealizadasPM}
					/>
					<TableHeaderRow showSortingControls={true} />
				</Grid>
			</div>
		);
	}

	renderSolicitudesRecibidasPM() {
		return (
			<div>
				<Row>
					<Col md={6}>
						<h3>{translate("SolicitudServicio.lblSolicitudesRecibidas")}</h3>
					</Col>
				</Row>
				<Grid rows={this.state.recibidasPM} columns={this.state.columnsTablaSolicitudesRecibidasPM}>
					<SortingState
						sorting={this.state.sortingTablaSolicitudesRecibidasPM}
						onSortingChange={(value) => {
							this.setState({ sortingTablaSolicitudesRecibidasPM: value });
						}}
					/>
					<IntegratedSorting />

					<Table
						tableComponent={tableComponent}
						headComponent={headerComponent}
						height={250}
						columnExtensions={this.state.formatoTablaSolicitudesRecibidasPM}
						messages={{ noData: translate("SolicitudServicio.lblSolicitudesAsignadas") }}
					/>
					<TableHeaderRow showSortingControls={true} />
				</Grid>
			</div>
		);
	}

	renderSolicitudesRealizadasDev() {
		return (
			<div>
				<Row>
					<Col md={10} sm={12}>
						<h3>{translate("SolicitudServicio.lblSolicitudesRealizadas")}</h3>
					</Col>
					<Col md={2} sm={12} className="text-right">
						<FormGroup check>
							<Label check>
								<Input
									type="checkbox"
									name="soloMisSolicitudes"
									checked={this.state.soloMisSolicitudes}
									onChange={(event) => {
										this.setState({ soloMisSolicitudes: event.target.checked });
										this.cargaRealizadas({}, event.target.checked);
									}}
								/>
								{translate("SolicitudServicio.lblVerMisSolicitudes")}
								<span className="form-check-sign">
									<span className="check" />
								</span>
							</Label>
						</FormGroup>
					</Col>
				</Row>
				<Grid rows={this.state.realizadasPM} columns={this.state.columnsTablaSolicitudesRealizadasDev}>
					<SortingState
						sorting={this.state.sortingTablaSolicitudesRealizadasDev}
						onSortingChange={(value) => {
							this.setState({ sortingTablaSolicitudesRealizadasDev: value });
						}}
					/>
					<IntegratedSorting />

					<Table
						className="table-gv"
						tableComponent={tableComponent}
						headComponent={headerComponent}
						height={250}
						messages={{ noData: translate("SolicitudServicio.noInfo") }}
						columnExtensions={this.state.formatoTablaSolicitudesRealizadasDev}
					/>
					<TableHeaderRow showSortingControls={true} />
				</Grid>
			</div>
		);
	}

	renderSolicitudesAsignadasDev() {
		return (
			<div>
				<Row>
					<Col md={10} sm={12}>
						<h3>{translate("SolicitudServicio.lblSolicitudesAsignadas")}</h3>
					</Col>
					<Col md={2} sm={12} className="text-right">
						<FormGroup check>
							<Label check>
								<Input
									type="checkbox"
									name="soloPendientes"
									checked={this.state.soloPendientes}
									onChange={(event) => {
										this.setState({ soloPendientes: event.target.checked });
										this.cargaAsignadas({}, event.target.checked);
									}}
								/>
								{translate("SolicitudServicio.lblVerSoloPendientes")}
								<span className="form-check-sign">
									<span className="check" />
								</span>
							</Label>
						</FormGroup>
					</Col>
				</Row>
				<Grid rows={this.state.asignadasDev} columns={this.state.columnsTablaSolicitudesAsignadasDev}>
					<SortingState
						sorting={this.state.sortingTablaSolicitudesAsignadasDev}
						onSortingChange={(value) => {
							this.setState({ sortingTablaSolicitudesAsignadasDev: value });
						}}
					/>
					<IntegratedSorting />

					<Table
						tableComponent={tableComponent}
						headComponent={headerComponent}
						height={250}
						messages={{ noData: translate("SolicitudServicio.noInfo") }}
						columnExtensions={this.state.formatoTablaSolicitudesAsignadasDev}
					/>
					<TableHeaderRow showSortingControls={true} />
				</Grid>
			</div>
		);
	}

	/**
	 *
	 * @param {JSON} obj objeto al que se le modificara el estatus o asociara una entidad
	 * @param {Boolean} documentado solo llegará como true cuando venga del callback exitosamente,
	 * quiere decir que ya se documentó en la pantalla de entidades
	 * @param {Boolean} conservarEstatus solo llegará como true cuando en entidad mtto se haga una modificacion, no una inserción nueva
	 */
	editarEstatus(obj, documentado = false, conservarEstatus = false) {
		let urlWebService = Config.urlApiService + "/SolicitudServicio/" + obj.ClaProducto + "/" + obj.IdPeticion;
		let data = {
			...obj,
		};

		if (obj.FechaPromesa !== null) {
			obj.FechaPromesa = new Date(obj.FechaPromesa);
			data.FechaPromesa = obj.FechaPromesa.toSqlDateTime();
			obj.FechaCaptura = new Date(obj.FechaCaptura);
			data.FechaCaptura = obj.FechaCaptura.toSqlDateTime();
		} else {
			data.FechaPromesa = null;
		}

		data.BajaLogica = 0;

		if (obj.ClaEstatus === 6 && !documentado) {
			// Si está en terminado, y va a cambiar a en verificacion,
			//en el callback de entidad mtto se cambiara el estatus a 7

			this.showAlert(
				translate("SolicitudServicio.alertAtencion"),
				translate("SolicitudServicio.alertAtencionDescripcion"),
				"warning",
			);

			this.setState({
				showModalEntidad: true,
				idEntidad: obj.IdEntidad && obj.IdEntidad > 0 ? obj.IdEntidad : 0,
				objPeticion: obj,
			});
		} else {
			if (documentado) {
				data.IdEntidad = obj.IdEntidad;
			}

			if (!conservarEstatus) {
				data.ClaEstatus = obj.ClaEstatus + 1;
			}

			callApi(
				urlWebService,
				"PUT",
				data,
				(status, response) => {
					if (status === 200) {
						if (!conservarEstatus) {
							writeLog(
								Formularios.SolicitudesDeServicio.ClaFuncionalidad,
								Formularios.SolicitudesDeServicio.Funcionalidades.modificarEstatus,
								urlWebService,
								data,
								response,
								"Estatus cambiado con éxito",
								0,
							);

							this.showAlert("", translate("SolicitudServicio.alertEstatusCambiado"), "success");
						}

						if (this.state.esAdmin) {
							this.cargaPM();
						} else {
							this.cargaDev();
						}
					} else {
						this.showAlert("", translate("SolicitudServicio.alertAlgoSalioMal"), "warning");
						if (this.state.esAdmin) {
							this.cargaPM();
						} else {
							this.cargaDev();
						}
					}
				},
				Formularios.SolicitudesDeServicio.ClaFuncionalidad,
				Formularios.SolicitudesDeServicio.Funcionalidades.modificarEstatus,
			);
		}
	}

	/**
	 *
	 * @param {JSON} obj objeto al que se le modificara el estatus
	 */
	rechazarPeticion(obj) {
		let urlWebService = Config.urlApiService + "/SolicitudServicio/" + obj.ClaProducto + "/" + obj.IdPeticion;
		let data = {
			...obj,
		};

		if (obj.FechaPromesa !== null) {
			obj.FechaPromesa = new Date(obj.FechaPromesa);
			data.FechaPromesa = obj.FechaPromesa.toSqlDateTime();
			obj.FechaCaptura = new Date(obj.FechaCaptura);
			data.FechaCaptura = obj.FechaCaptura.toSqlDateTime();
		} else {
			data.FechaPromesa = null;
		}

		data.BajaLogica = 0;

		if (obj.IdEntidad) {
			data.IdEntidad = obj.IdEntidad;
		}

		data.ClaEstatus = obj.ClaEstatus = 5;

		callApi(urlWebService, "PUT", data, (status) => {
			if (status === 200) {
				this.showAlert("", translate("SolicitudServicio.alertEstatusCambiado"), "success");
				this.cargaPM();
			} else {
				this.showAlert("", translate("SolicitudServicio.alertAlgoSalioMal"), "warning");
			}
		});

		if (this.state.esAdmin) {
			this.cargaPM();
		} else {
			this.cargaDev();
		}
	}

	/**
	 * Función que hara la busqueda por filtros
	 * @param {JSON} value Valores ingresados en el formulario
	 */
	handleSubmitButton(value) {
		let params = {};

		if (this.state.fechaInicio !== undefined) {
			params.fechaInicio = this.state.fechaInicio;
		}

		if (this.state.fechaFin !== undefined) {
			params.fechaFin = this.state.fechaFin;
		}

		if (value.ClaProducto > 0) {
			params.producto = value.ClaProducto;
		}

		if (this.state.esAdmin) {
			this.cargaPM(params);
		} else {
			this.cargaDev(params);
		}
	}

	/**
	 *
	 * @param {JSON} response  respuesta recibida al guardar la entidad en ENTIDADMTTO
	 * @param {JSON} objPeticion objeto JSON que se va a guardar la entidad asociada a la peticion
	 */
	peticionCallBack(response, objPeticion, rechazado = false, conservarEstatus = false) {
		if (response.idEntidad && response.idEntidad > 0) {
			objPeticion.IdEntidad = response.idEntidad;
		}

		if (conservarEstatus) {
			this.editarEstatus(objPeticion, true, conservarEstatus);
		} else {
			if (!rechazado) {
				this.editarEstatus(objPeticion, true);
			} else {
				this.rechazarPeticion(objPeticion);
			}
		}
	}

	render() {
		return (
			<>
				{this.state.esAdmin ? (
					<TourVirtual PaginaID="18" ClaTour={Tours.SolicitudServicioPM} />
				) : (
					<TourVirtual PaginaID="18" ClaTour={Tours.SolicitudServicioDev} />
				)}
				<NotificationAlert ref={(ref) => (this.notify = ref)} />

				{this.state.esAdmin && sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.cargaPM()}
				{!this.state.esAdmin && sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.cargaDev()}

				{this.state.showAlert}
				{this.state.showConfirm}
				<div className="content">
					<Formik initialValues={{ ClaProducto: 0 }} onReset={this.clear} onSubmit={this.handleSubmitButton}>
						{(formikProps) => (
							<Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset} key="frmSolicitudes">
								<Card>
									<CardHeader>
										<CardTitle>
											<FormGroup row>
												<Col md={6}>
													<h1>{translate("SolicitudServicio.titulo")}</h1>
												</Col>
												<Col md={6} className="text-right">
													<UncontrolledTooltip placement="left" target={`btnBuscar`}>
														{translate("SolicitudServicio.ttBuscar")}
													</UncontrolledTooltip>
													<Button
														id="btnBuscar"
														color="info"
														size="sm"
														className="btn-simple btn-icon"
														type="submit">
														<i className="fas fa-search" />
													</Button>

													<UncontrolledTooltip placement="left" target={`btnLimpiar`}>
														{translate("SolicitudServicio.ttLimpiar")}
													</UncontrolledTooltip>
													<Button
														onClick={() => {
															writeLog(
																Formularios.SolicitudesDeServicio.ClaFuncionalidad,
																Formularios.SolicitudesDeServicio.Funcionalidades.btnLimpiar,
															);
															formikProps.handleReset();
														}}
														id="btnLimpiar"
														color="info"
														size="sm"
														className="btn-simple btn-icon">
														<i className="fas fa-eraser" />
													</Button>

													<UncontrolledTooltip placement="left" target={`btnNuevo`}>
														{translate("SolicitudServicio.btnCrearSolicitud")}
													</UncontrolledTooltip>
													<Button
														id={`btnNuevo`}
														onClick={() => {
															writeLog(
																Formularios.SolicitudesDeServicio.ClaFuncionalidad,
																Formularios.SolicitudesDeServicio.Funcionalidades.btnNuevo,
															);

															this.setState({
																showModal: true,
																idPeticion: 0,
															});
														}}
														color="info"
														size="sm"
														className="btn-simple btnAgregar">
														<i className="fa fa-plus" />
														{translate("SolicitudServicio.btnCrearSolicitud")}
													</Button>
												</Col>
											</FormGroup>
										</CardTitle>
										<Row>
											<Col md={6} sm={12}>
												<FormGroup row>
													<Col md={4} sm={12}>
														<Label for="ClaProducto">{translate("SolicitudServicio.lblProducto")}</Label>
														<Field
															name="ClaProducto"
															as="select"
															type="input"
															className="form-control"
															placeholder="Producto al que pertenece">
															{productos &&
																productos.length > 0 &&
																productos.map((obj, index) => (
																	<option className="ddlOptions" key={index} value={obj.ClaProducto}>
																		{sessionStorage.getItem("ClaIdioma") === "2" &&
																		obj.NomProducto === "SELECCIONE"
																			? "SELECT"
																			: obj.NomProducto}
																	</option>
																))}
														</Field>
													</Col>
													<Col md={3}>
														<Label for="fechaInicio">
															{translate("SolicitudServicio.lblFechaDesde")}
														</Label>
														<Datetime
															name="fechaInicio"
															inputProps={{
																placeholder: translate("SolicitudServicio.phFechaDesde"),
															}}
															onChange={(value) => {
																if (value._d !== undefined) {
																	this.setState({
																		fechaInicio: value._d.toSqlDateTime(),
																	});
																} else {
																	this.setState({
																		fechaInicio: undefined,
																	});
																}
															}}
															initialValue={new Date()}
															timeFormat={false}
															locale="es"
															id="fechaInicio"
														/>
													</Col>

													<Col md={3}>
														<Label for="fechaFin">{translate("SolicitudServicio.lblHasta")}</Label>
														<Datetime
															inputProps={{ placeholder: translate("SolicitudServicio.phHasta") }}
															id="fechaFin"
															name="fechaFin"
															initialValue={new Date()}
															onChange={(value) => {
																if (value._d !== undefined) {
																	this.setState({
																		fechaFin: value._d.toSqlDateTime(),
																	});
																} else {
																	this.setState({
																		fechaFin: undefined,
																	});
																}
															}}
															timeFormat={false}
															locale="es"
														/>
													</Col>
												</FormGroup>

												<FormGroup row></FormGroup>
											</Col>
										</Row>
									</CardHeader>
									<CardBody>
										{this.state.esAdmin ? (
											<div>
												{this.renderSolicitudesRecibidasPM()}
												<hr></hr>
												{this.renderSolicitudesRealizadasPM()}
											</div>
										) : (
											<div>
												{this.renderSolicitudesRealizadasDev()}
												<hr></hr>
												{this.renderSolicitudesAsignadasDev()}
											</div>
										)}
									</CardBody>
								</Card>
							</Form>
						)}
					</Formik>
				</div>
				<Modal centered={true} isOpen={this.state.showModal} size="lg">
					<SolicitudServicioMtto
						hideModal={this.hideModal}
						pantallaEntidad={false}
						idPeticion={this.state.idPeticion}
						claProducto={getProducto()}
						readOnly={this.state.readOnly}
					/>
				</Modal>
				<Modal centered={true} isOpen={this.state.showModalAsignarUsuario} size="sm">
					<AsignacionUsuario hideModal={this.hideModalAsignacionUsuario} objPeticion={this.state.objPeticion} />
				</Modal>
				<Modal centered isOpen={this.state.showModalEntidad} size="lg">
					<EntidadMtto
						hideModal={this.hideModalEntidad}
						idEntidad={this.state.idEntidad}
						claProducto={getProducto()}
						readOnly={false}
						className="mdlEntidad"
						objPeticion={this.state.objPeticion}
						peticionCallBack={this.peticionCallBack}
						aprobarCallBack={this.aprobarCallBack}
					/>
				</Modal>
			</>
		);
	}
}
