import React from "react";
import { FormGroup, Col, Label, Badge } from "reactstrap";
import SyntaxHighlighter from "react-syntax-highlighter";
import { lioshi } from "react-syntax-highlighter/dist/esm/styles/hljs";
import PropTypes from "prop-types";
import { Config, Tours } from "utils/Config";
import TourVirtual from "../../components/Tour/TourVirtual";
import { getLoginInfo } from "utils/Utilerias";
import translate from "utils/translate";
export default class EntidadEjemplo extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			viewTour: false,
		};
	}

	renderSyntaxHighligter(text) {
		return (
			<SyntaxHighlighter className="round" language="json" style={lioshi} showLineNumbers={true}>
				{text}
			</SyntaxHighlighter>
		);
	}

	renderUrl() {
		let url = "";
		let urlNoProductivo = "";

		url = `${Config.urlApi}/${this.props.entidad.ClaProducto}/${this.props.entidad.IdEntidad}`;
		urlNoProductivo = `${Config.urlNoProductivo}/${this.props.entidad.ClaProducto}/${this.props.entidad.IdEntidad}`;

		let codeString = "";
		let ambiente = translate("EntidadMtto.lblProduccion");
		parseInt(this.props.entidad.EsVerificado) === 1
			? (codeString = `${ambiente}: ${url}
Sandbox: ${urlNoProductivo}`)
			: (codeString = urlNoProductivo);

		return codeString;
		//return this.renderSyntaxHighligter(url);
	}

	renderHeader() {
		let apiKey = getLoginInfo().ApiKey;
		let comentarioApiKey = translate("EntidadMtto.txtCommentApi");
		let comentarioToken = translate("EntidadMtto.txtCommentToken");
		const codeString =
			"headers = {\r\n" +
			"\t'Content-Type': 'application/json',\r\n" +
			"\t'x-api-key': " +
			'"' +
			apiKey +
			'"' +
			", " +
			comentarioApiKey +
			"\r\n" +
			"\t'x-access-token': '[ACCESS TOKEN]', " +
			comentarioToken +
			"\r\n" +
			"};";

		return codeString;
	}

	renderDetalles(claTipoDetalle, prefijo) {
		let tmpString = "";
		let array = [];

		if (this.props.entidad.Detalles && this.props.entidad.Detalles.length > 0) {
			array = this.props.entidad.Detalles.filter((oX) => oX.ClaTipoEntidadDet === claTipoDetalle);
		}

		if (array.length > 0) {
			array.forEach((item) => {
				if (item.ClaTipoEntidadDet === claTipoDetalle) {
					tmpString += `${prefijo}${item.NomEntidadDet.trimEnd()}: ${
						sessionStorage.getItem("ClaIdioma") === "1" ? item.Descripcion : item.DescripcionIngles
					}\r\n`;
				}
			});
		} else {
			let noInfo = translate("EntidadMtto.noInfo");
			tmpString = `${prefijo}${noInfo}\r\n`;
		}

		return tmpString;
	}

	renderVista(claTipoDetalle, prefijo) {
		let tmpString = "";
		let array = [];

		if (this.props.entidad.Detalles && this.props.entidad.Detalles.length > 0) {
			array = this.props.entidad.Detalles.filter((oX) => oX.ClaTipoEntidadDet === claTipoDetalle);
		}

		if (array.length > 0) {
			array.forEach((item, index) => {
				if (item.ClaTipoEntidadDet === claTipoDetalle) {
					tmpString += `${item.NomEntidadDet.trimEnd()}`;

					if (index < array.length - 1) {
						tmpString = tmpString + ",  ";
					}
				}
			});
		} else {
			let noInfo = translate("EntidadMtto.noInfo");
			tmpString = `${prefijo}${noInfo}\r\n`;
		}

		return tmpString;
	}

	getEjemplo() {
		let array = [];

		if (this.props.entidad.Detalles && this.props.entidad.Detalles.length > 0) {
			array = this.props.entidad.Detalles.filter((oX) => oX.ClaTipoEntidadDet === 1);
		}

		if (array.length > 0) {
			return array[0].NomEntidadDet;
		}
	}

	renderBody() {
		let codeString;
		let detalles;
		let comentarioEstructura = translate("EntidadMtto.commentEstructura");
		if (this.props.entidad.ClaTipoEntidad === 1 || this.props.entidad.ClaTipoEntidad === 2) {
			detalles = this.renderVista(1, `\t\t`);
			let ejemplo = this.getEjemplo();

			codeString =
				"{\r\n" +
				"\t" +
				comentarioEstructura +
				"\r\n" +
				'\t"tipoEstructura": 1,\r\n' +
				'\t"columnas": ' +
				'"' +
				detalles +
				'",' +
				"\t\r\n " +
				"\t" +
				'"condicion": "' +
				ejemplo +
				"='ejemplo'\" " +
				"  \r\n}";
		} else {
			detalles = this.renderDetalles(1, `\t\t`);
			codeString =
				"{\r\n" +
				"\t" +
				comentarioEstructura +
				"\r\n" +
				"\t'tipoEstructura': 1\r\n" +
				"\t'parameters': {\r\n" +
				detalles +
				"\t}\r\n " +
				"}";
		}

		return this.renderSyntaxHighligter(codeString);
	}

	renderResponse() {
		let detalles = this.renderDetalles(2, `\t`);

		const codeString = "{\r\n" + detalles + "}";

		return this.renderSyntaxHighligter(codeString);
	}

	render() {
		return (
			<>
				<div className="content">
					{this.props.openTour && <TourVirtual PaginaID="2" ClaTour={Tours.Ejemplo} />}
					<div className="scrollModal">
						<FormGroup row>
							<Col xs={12}>
								<Label>
									{translate("EntidadMtto.txtEjemploConsumirPost")} <Badge color="info">POST</Badge>{" "}
									{translate("EntidadMtto.txtEjemploConsumirPost2")}
								</Label>
								<div id="txtUrls">{this.renderSyntaxHighligter(this.renderUrl())}</div>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col xs={12}>
								<Label>
									{translate("EntidadMtto.txtEjemploConsumirHeader")} <Badge color="info">Header</Badge>{" "}
									{translate("EntidadMtto.txtEjemploConsumirHeader2")}
								</Label>
								<div id="txtHeader">{this.renderSyntaxHighligter(this.renderHeader())}</div>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col xs={12}>
								{this.props.entidad.ClaTipoEntidad === 1 ? (
									<Label>
										{translate("EntidadMtto.txtEjemploConsumirBody")} <Badge color="info">Body</Badge>{" "}
										{translate("EntidadMtto.txtEjemploConsumirBody2")} &nbsp;
										<em>
											<b>tipoEstructura</b>
										</em>{" "}
										{translate("EntidadMtto.txtEjemploConsumirBody5")} &nbsp;
										<em>
											<b>columnas</b>
										</em>{" "}
										{translate("EntidadMtto.txtEjemploConsumirBody6")}&nbsp;
										<em>
											<b>condicion</b>
										</em>{" "}
										{translate("EntidadMtto.txtEjemploConsumirBody7")}
									</Label>
								) : (
									<Label>
										{translate("EntidadMtto.txtEjemploConsumirBody")} <Badge color="info">Body</Badge>
										{translate("EntidadMtto.txtEjemploConsumirBody2")} &nbsp;
										<em>
											<b>tipoEstructura</b>
										</em>{" "}
										{translate("EntidadMtto.txtEjemploConsumirBody3")}
										&nbsp;
										<em>
											<b>parameters</b>
										</em>{" "}
										{translate("EntidadMtto.txtEjemploConsumirBody4")}
									</Label>
								)}
								<div id="txtBody">{this.renderBody()}</div>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col xs={12}>
								<Label>
									{translate("EntidadMtto.txtEjemploConsumirJSON")} <Badge color="success">JSON</Badge>{" "}
									{translate("EntidadMtto.txtEjemploConsumirJSON2")}
								</Label>
								<div id="txtResponse">{this.renderResponse()}</div>
							</Col>
						</FormGroup>
						{/* </Form> */}
					</div>
				</div>
			</>
		);
	}
}

EntidadEjemplo.propTypes = {
	entidad: PropTypes.object,
	openTour: PropTypes.bool,
};
