import React from "react";
import { Card, CardBody, Row, Col, UncontrolledTooltip } from "reactstrap";
import { callApi, writeLog } from "utils/Utilerias";
import { Config } from "../../utils/Config";
import PropTypes from "prop-types";
import translate from "utils/translate";
import { Formularios } from "utils/funcionalidad";
let srcData = {};

export default class DesempenoEquipo extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
		};
	}

	componentDidMount() {
		this.fillData();
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.claProducto !== prevProps.claProducto ||
			this.props.claCliente !== prevProps.claCliente ||
			this.props.nomUsuario !== prevProps.nomUsuario ||
			this.props.nomProducto !== prevProps.nomProducto ||
			this.props.fechaInicio !== prevProps.fechaInicio ||
			this.props.fechaFin !== prevProps.fechaFin
		) {
			this.setState({
				isLoaded: false,
			});
			this.fillData();
		}
	}

	fillData() {
		const urlService = `${Config.urlApiService}/Dashboard2`;
		const data = {
			ClaProducto: this.props.claProducto,
			ClaCliente: this.props.claCliente,
			NomUsuario: this.props.nomUsuario,
			NomProducto: this.props.nomProducto,
			FechaInicial: this.props.fechaInicio ? this.props.fechaInicio.toSqlDateTime() : undefined,
			FechaFinal: this.props.fechaFin ? this.props.fechaFin.toSqlDateTime() : undefined,
			ClaConsulta: this.props.nomUsuario !== undefined ? 10 : 15,
		};

		callApi(
			urlService,
			"POST",
			data,
			(status, response) => {
				if (status === 200 && response.length > 0) {
					srcData = response[0];
				} else {
					srcData = { TiempoProm: 0, Errores: 0, Peticiones: 0 };
				}
				if (this.props.claCliente !== undefined) {
					writeLog(
						Formularios.DashboardCliente.ClaFuncionalidad,
						Formularios.DashboardCliente.Funcionalidades.DesempenoEquipo,
						urlService,
						data,
						response,
						undefined,
						0,
					);
				} else {
					writeLog(
						Formularios.DashboardProveedor.ClaFuncionalidad,
						Formularios.DashboardProveedor.Funcionalidades.DesempenoEquipo,
						urlService,
						data,
						response,
						undefined,
						0,
					);
				}

				this.setState({
					isLoaded: true,
				});
			},
			this.props.claCliente !== undefined
				? Formularios.DashboardCliente.ClaFuncionalidad
				: Formularios.DashboardProveedor.ClaFuncionalidad,
			this.props.claCliente !== undefined
				? Formularios.DashboardCliente.Funcionalidades.DesempenoEquipo
				: Formularios.DashboardProveedor.Funcionalidades.DesempenoEquipo,
		);
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<div className="row justify-content-center p-5">
					<div className="spinner-border text-info" style={{ backgroundColor: "transparent" }} role="status">
						<span className="sr-only">Cargando...</span>
					</div>
				</div>
			);
		} else {
			return (
				<Card>
					<CardBody>
						<Row className="text-default text-center justify-content-center">
							<Col md={2}>
								<UncontrolledTooltip placement="top" target={`txtPeticiones`}>
									{srcData.Peticiones}
								</UncontrolledTooltip>
								<span id="txtPeticiones" className="display-3 text-primary">
									{srcData.Peticiones.toFormatNumber()}
								</span>{" "}
								<br />
								{translate("DashboardProveedor.lblPeticiones")}
							</Col>
							{this.props.nomUsuario !== undefined && (
								<Col md={2}>
									<span className="display-3 text-primary">{srcData.ServiciosPublicados}</span>
									<br /> {translate("DashboardProveedor.lblServiciosPublicados")}
								</Col>
							)}

							<Col md={2}>
								<span className="display-3 text-primary">{srcData.Errores}</span> <br />{" "}
								{translate("DashboardProveedor.lblErroresTitulo")}
							</Col>
							<Col md={3}>
								<span className="display-3 text-primary">{srcData.TiempoProm}</span> <br />{" "}
								{translate("DashboardProveedor.lblTiempoPromedio")}
							</Col>
						</Row>
					</CardBody>
				</Card>
			);
		}
	}
}

DesempenoEquipo.propTypes = {
	claProducto: PropTypes.number,
	claCliente: PropTypes.number,
	nomUsuario: PropTypes.string,
	nomProducto: PropTypes.string,
	fechaInicio: PropTypes.object,
	fechaFin: PropTypes.object,
};
