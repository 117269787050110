import React from "react";
import ReactStars from "react-rating-stars-component";
import { Config } from "utils/Config";
import { callApi, showSweetAlert, writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";
import PropTypes from "prop-types";
import { Formik, Field, ErrorMessage } from "formik";
import ReplicaComentario from "./ReplicaComentario.js";
import * as Yup from "yup";
import {
	Card,
	CardTitle,
	CardHeader,
	CardBody,
	CardFooter,
	Form,
	FormGroup,
	Row,
	Col,
	Button,
	Label,
} from "reactstrap";
import translate from "utils/translate";

require("utils/Prototipos");
//let comentarios = {};
export default class ComentariosMtto extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			idEntidad: this.props.idEntidad,
			claProducto: this.props.claProducto,
			datos: {},
			promedioEntidad: {},
			existenComentarios: false,
			exit: false,
			rating: 0,
			initialValueRating: 0,
			_isMounted: false,
			responderForm: false,
			responderComentarioIndice: new Map(),
		};
		this.btnCancelarClick = this.btnCancelarClick.bind(this);
		this.handleSubmitAceptar = this.handleSubmitAceptar.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.load = this.load.bind(this);
		this.ratingChanged = this.ratingChanged.bind(this);
	}

	btnCancelarClick() {
		this.props.hideModalComentarios();
	}

	/**
	 * Funcion para ocultar la alerta/mensaje que fue activado
	 */
	hideAlert() {
		this.setState({
			exit: true,
			_isMounted: false,
		});
	}

	ratingChanged(newRating) {
		this.setState({
			rating: newRating,
		});
	}

	load() {
		let urlWebService = `${Config.urlApiService}/Comentarios/${this.state.idEntidad}/${this.state.claProducto}`;
		let method = "GET";

		callApi(urlWebService, method, null, (status, response) => {
			if (response && response.length !== 0) {
				this.setState({
					existenComentarios: true,
					datos: response,
					_isMounted: true,
				});
			} else {
				this.setState({
					existenComentarios: false,
					_isMounted: true,
				});
			}
		});
	}

	componentDidMount() {
		this.load();
	}

	/**
	 * Función del botón para enviar correo a dueño del producto con el mensaje indicado
	 * @param {JSON} value los valores del formulario
	 */
	handleSubmitAceptar(value, idComentarioPadre = null) {
		let datos = {
			idEntidad: this.state.idEntidad,
			claProducto: this.state.claProducto,
			comentarioPadre: idComentarioPadre,
			calificacion: idComentarioPadre === null ? this.state.rating : null,
			comentario: value.comentario,
		};

		let urlWebService = `${Config.urlApiService}/Comentario`;
		let method = "PUT";
		callApi(
			urlWebService,
			method,
			datos,
			(status, response) => {
				writeLog(
					Formularios.ComentariosMtto.ClaFuncionalidad,
					Formularios.ComentariosMtto.Funcionalidades.btnAgregar,
					urlWebService,
					datos,
					response,
					"Comentario enviado",
					0,
				);

				showSweetAlert(translate("Comentarios.alertEnviado"), "", "success", this.hideAlert);
			},
			Formularios.ComentariosMtto.ClaFuncionalidad,
			Formularios.ComentariosMtto.Funcionalidades.btnAgregar,
		);
		this.btnCancelarClick();
	}

	FormResponder = (id) => {
		if (this.state.responderComentarioIndice.get(id) !== undefined) {
			let cpy = this.state.responderComentarioIndice;
			cpy.set(id, true);
			this.setState({
				responderComentarioIndice: cpy,
				responderForm: !this.state.responderForm,
			});
		} else {
			let mapIndices = new Map();
			mapIndices.set(id, true);
			this.setState({
				responderComentarioIndice: mapIndices,
				responderForm: !this.state.responderForm,
			});
		}
	};

	renderFormResponder(idComentario) {
		return (
			<div className="content">
				<Formik
					initialValues={{ comentario: "" }}
					onSubmit={(value, { resetForm }) => {
						this.handleSubmitAceptar(value, idComentario);
						resetForm({ value: "" });
					}}>
					{(formikProps) => (
						<Form onSubmit={formikProps.handleSubmit} key="frmComentarioMtto">
							<Row>
								<Col md={12}>
									<FormGroup>
										<Field
											as="textarea"
											name="comentario"
											rows={6}
											className="form-control"
											disabled={this.props.readOnly}
											placeholder={translate("Comentarios.phComentario")}
										/>
										{formikProps.errors.comentario ? <Label>{formikProps.errors.comentario}</Label> : null}
										<ErrorMessage name="comentario" />
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col md={12} className="text-right">
									<Button
										id="btnEnviar"
										className="animation-on-hover"
										color="success"
										size="sm"
										type="submit"
										hidden={this.props.readOnly}>
										{translate("Comentarios.btnResponder")}
									</Button>
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			</div>
		);
	}
	render() {
		if (!this.state._isMounted) {
			this.load();
		}
		return (
			<div className="divComentarios">
				<Formik
					initialValues={{ comentario: "" }}
					validationSchema={Yup.object({
						comentario: Yup.string().min(1).required("Se requiere comentario"),
					})}
					onSubmit={(value, { resetForm }) => {
						this.handleSubmitAceptar(value);
						resetForm({ value: "" });
					}}>
					{(formikProps) => (
						<Form onSubmit={formikProps.handleSubmit} key="frmComentarioMtto">
							<Card className="cardComentario">
								<CardHeader>
									<CardTitle>
										<h2>
											{translate("Comentarios.titulo")} {this.props.nomEntidad}
										</h2>
									</CardTitle>
								</CardHeader>
								<CardBody>
									<Row>
										<Col md={12}>
											<div className="scrollModalComentarios">
												{this.state.existenComentarios ? (
													(this.state.datos || this.state.datos.length > 0) &&
													this.state.datos.map((obj) => (
														<>
															{obj.IdComentarioPadre === null && (
																<div
																	className="boxedComentario sb1"
																	key={`idComentario_${obj.IdComentario}`}>
																	<Row>
																		<Col md={8}>{obj.NombreUsuario + " " + obj.ApellidoPaterno}</Col>
																		<Col md={4}>
																			<ReactStars
																				key={`star_${obj.IdComentario}`}
																				count={5}
																				size={24}
																				edit={false}
																				value={parseInt(obj.Calificacion)}
																				activeColor="#ffd700"
																			/>
																		</Col>
																	</Row>
																	<Row>
																		<Col md={12}>
																			<p>{obj.Comentario}</p>
																		</Col>
																	</Row>
																	<div
																		className="comResponder"
																		onClick={() => this.FormResponder(obj.IdComentario)}>
																		{" "}
																		{translate("Comentarios.btnResponder")}
																	</div>
																	{!this.props.readOnly &&
																		this.state.responderComentarioIndice.get(obj.IdComentario) !==
																			undefined &&
																		this.state.responderForm &&
																		this.renderFormResponder(obj.IdComentario)}
																</div>
															)}
															<ReplicaComentario padre={obj.IdComentario} hijos={this.state.datos} />
														</>
													))
												) : (
													<div className="comNoComentarios">{translate("Comentarios.noComentarios")}</div>
												)}
											</div>
										</Col>
									</Row>
									{!this.props.readOnly && (
										<>
											<hr />
											<Row>
												<Col md={6}>
													<Label>{translate("Comentarios.lblDejaComentario")}</Label>
												</Col>
												<Col md={6}>
													<div className="text-right float-right">
														<ReactStars
															count={5}
															onChange={this.ratingChanged}
															size={24}
															activeColor="#ffd700"
															value={0}
														/>
													</div>
												</Col>
												{/* <Row>
														<Col md={3}></Col>
														<Col className="text-center" md={6}>
															{formikProps.errors.comentario ? (
																<Label style={{ color: "red" }}>
																	{"    " + formikProps.errors.comentario}
																</Label>
															) : null}
														</Col>
														<Col style={{ marginLeft: "auto" }} md={6}>
															
														</Col>
													</Row> */}

												{/* </Col> */}
											</Row>
											<Row>
												<Col md={12}>
													<FormGroup>
														<Field
															as="textarea"
															name="comentario"
															rows={6}
															className="form-control"
															disabled={this.props.readOnly}
															placeholder={translate("Comentarios.phComentario")}
														/>
													</FormGroup>
												</Col>
											</Row>
										</>
									)}
								</CardBody>
								<CardFooter>
									<Row>
										<Col md={12} className="text-right">
											<Button
												id="btnEnviar"
												className="animation-on-hover"
												color="success"
												size="sm"
												type="submit"
												hidden={this.props.readOnly}>
												{translate("Comentarios.btnAgregar")}
											</Button>
											<Button
												id="btnCancelar"
												className="animation-on-hover"
												color="default"
												size="sm"
												onClick={() => {
													writeLog(
														Formularios.ComentariosMtto.ClaFuncionalidad,
														Formularios.ComentariosMtto.Funcionalidades.btnCancelar,
													);
													this.btnCancelarClick();
												}}>
												{this.props.readOnly
													? translate("Comentarios.btnCerrar")
													: translate("Comentarios.btnCancelar")}
											</Button>
										</Col>
									</Row>
								</CardFooter>
							</Card>
						</Form>
					)}
				</Formik>
			</div>
		);
	}
}

ComentariosMtto.propTypes = {
	idEntidad: PropTypes.number,
	claProducto: PropTypes.number,
	nomEntidad: PropTypes.string,
	readOnly: PropTypes.bool,
	hideModalComentarios: PropTypes.func,
};
