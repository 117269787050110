/* eslint-disable react/prefer-stateless-function */
import React from "react";
import PropTypes from "prop-types";
const Idiomas = {
	ESP: 1,
	ING: 2,
};

class Idioma extends React.Component {
	render() {
		const hStyle = { color: "orange", cursor: "pointer", fontSize: "14px" };
		const hStyle2 = { color: "white", cursor: "pointer", fontSize: "14px" };
		const StyleInline = { float: "left", display: "flex" };
		let element;
		if (this.props.claIdioma === Idiomas.ING) {
			element = (
				<a className="nav-link pr-0" role="button" href="/" aria-haspopup="true" aria-expanded="true">
					<div style={StyleInline}>
						<span
							className="WebLabelCtrl mb-0 "
							style={hStyle2}
							onClick={(e) => {
								if (this.props.onChangeIdiomaCallback) {
									this.props.onChangeIdiomaCallback(1);
									e.preventDefault();
								}
							}}>
							ESP
						</span>
						&nbsp;<span style={{ color: "white" }}>/</span>&nbsp;
						<span
							className="WebLabelCtrl mb-0"
							style={hStyle}
							onClick={(e) => {
								if (this.props.onChangeIdiomaCallback) {
									this.props.onChangeIdiomaCallback(2);
									e.preventDefault();
								}
							}}>
							ENG
						</span>
					</div>
				</a>
			);
		} else {
			element = (
				<a className="nav-link pr-0" role="button" href="/" aria-haspopup="true" aria-expanded="true">
					<div style={StyleInline}>
						<span
							className="WebLabelCtrl mb-0"
							style={hStyle}
							onClick={(e) => {
								if (this.props.onChangeIdiomaCallback) {
									this.props.onChangeIdiomaCallback(1);
									e.preventDefault();
								}
							}}>
							ESP
						</span>
						&nbsp;<span style={{ color: "white" }}>/</span>&nbsp;
						<span
							className="WebLabelCtrl mb-0"
							style={hStyle2}
							onClick={(e) => {
								if (this.props.onChangeIdiomaCallback) {
									this.props.onChangeIdiomaCallback(2);
									e.preventDefault();
								}
							}}>
							ENG
						</span>
					</div>
				</a>
			);
		}

		return <>{element}</>;
	}
}

export default Idioma;

Idioma.propTypes = {
	claIdioma: PropTypes.number,
	onChangeIdiomaCallback: PropTypes.func,
};
