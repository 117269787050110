const SuscripcionMtto = {
	titulo: "Solicitud a Servicio",
	lblMsj: "Se requiere permiso para consumir el servicio ",
	lblMotivo: "Motivo de la suscripción",
	phMotivo: "Ingrese el propósito",
	btnEnviar: "Enviar",
	btnCancelar: "Cancelar",
	alertNoSeConsulta: "No se pudo consultar los datos del servicio",
	alertRegistroExitoso: "Solicitud registrada con éxito",
	alertRegistroExitosoDescripcion:
		"Recibiras un correo electronico cuando el dueño del servicio responda la solicitud.",
};

export default SuscripcionMtto;
