import React from "react";
// react component for creating dynamic tables
import { Config } from "utils/Config";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Row,
	Col,
	Modal,
	Label,
	UncontrolledTooltip,
	Form,
	FormGroup,
} from "reactstrap";

import translate from "utils/translate";

import { showAlert, showConfirm, callApi, getProducto, writeLog, getLoginInfo } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";

import NotificationAlert from "react-notification-alert";
import EntidadMtto from "./EntidadMtto";
import { Formik, Field } from "formik";
import PropTypes from "prop-types";
import ReactStars from "react-rating-stars-component";
import { Flags } from "react-feature-flags";
import ComentariosMtto from "../Comentarios/ComentariosMtto";
import { Grid, PagingPanel, Table, TableHeaderRow, TableRowDetail } from "@devexpress/dx-react-grid-bootstrap4";
import {
	IntegratedPaging,
	IntegratedSorting,
	PagingState,
	SortingState,
	RowDetailState,
} from "@devexpress/dx-react-grid";

import { tableComponent, headerComponent, gvConfig } from "utils/gridConfig";
import TourVirtual from "components/Tour/TourVirtual";
import { Tours } from "../../utils/Config";

import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
require("utils/Prototipos");

let tipo;
let srcData = [];
let terminosGlosario = [];

export default class Entidades extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			idEntidad: -1,
			isLoaded: false,
			alert: null,
			claProducto: getProducto(),
			nuevosTerminos: [],
			clearAutocomplete: false,
			srcData: [],
			currentPage: 0,
			pageSize: 20,
			isClone: false,
			isSubscribed: false,
			sorting: [{ columnName: "IdEntidad", direction: "asc" }],
			showModalComentarios: false,
			gvEntidades: {
				columns: [
					{ name: "IdEntidad", title: "ID" },
					{ name: "NomServicio", title: translate("MisServiciosPublicados.colNombre") },
					{ name: "Actions", title: " " },
				],
				tableColumnExtensions: [
					{ columnName: "IdEntidad", width: "80px", align: "left" },
					{ columnName: "NomServicio", width: "auto", wordWrapEnabled: true },
					{ columnName: "Actions", width: "200px", align: "right" },
				],
			},
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		};

		this.hideModal = this.hideModal.bind(this);
		this.successAlert = this.showAlert.bind(this);
		this.showConfirm = this.showConfirm.bind(this);
		this.frmLoad = this.frmLoad.bind(this);
		this.btnBuscarClick = this.btnBuscarClick.bind(this);
		this.dgvFillTable = this.dgvFillTable.bind(this);
		this.clear = this.clear.bind(this);
		this.hideModalComentarios = this.hideModalComentarios.bind(this);
	}

	RowDetail = ({ row }) => (
		<div
			style={{
				padding: "1%",
				marginLeft: "20px",
			}}>
			<Row>
				<Col md="5">{row.Descripcion}</Col>
				<Col md="3">
					{/* <div className="float-right text-right"> */}
					<ReactStars
						count={5}
						size={20}
						edit={false}
						activeColor="#ffd700"
						isHalf={true}
						value={row.Calificacion}
					/>
					{/* </div> */}
				</Col>
				<Col md="2" style={{ textAlign: "right", borderLeft: "2px solid #999" }}>
					{translate("MisServiciosPublicados.ttOtrasFn")}
				</Col>
				<Col md="2" style={{ textAlign: "right" }}>
					<Flags authorizedFlags={[getLoginInfo().NomGrupo]}>
						<span id="beta">
							<UncontrolledTooltip
								placement="left"
								target={`btnComentarios_${row.IdEntidad.toString() + "-" + row.ClaProducto.toString()}`}>
								{translate("MisServiciosPublicados.ttComentarios")}
							</UncontrolledTooltip>
							<Button
								id={`btnComentarios_${row.IdEntidad.toString() + "-" + row.ClaProducto.toString()}`}
								onClick={() => {
									writeLog(
										Formularios.ServiciosPublicados.ClaFuncionalidad,
										Formularios.ServiciosPublicados.Funcionalidades.btnComentarios,
										undefined,
										row,
									);

									this.setState({
										showModalComentarios: true,
										idEntidad: row.IdEntidad,
										claProducto: row.ClaProducto,
										nomEntidad: row.NomEntidad,
										calificacion: row.Calificacion,
										isClone: false,
										isSubscribed: false,
									});
								}}
								color="info"
								size="sm"
								className="btn-icon btn-link ml-0">
								<i className="fas fa-comments" />
							</Button>
						</span>
					</Flags>

					<UncontrolledTooltip
						placement="left"
						target={`btnSuscribed_${row.IdEntidad.toString() + "-" + row.ClaProducto.toString()}`}>
						{translate("MisServiciosPublicados.ttSuscritos")}
					</UncontrolledTooltip>
					<Button
						id={`btnSuscribed_${row.IdEntidad.toString() + "-" + row.ClaProducto.toString()}`}
						onClick={() => {
							writeLog(
								Formularios.ServiciosPublicados.ClaFuncionalidad,
								Formularios.ServiciosPublicados.Funcionalidades.btnSuscritos,
								undefined,
								row,
							);
							this.setState({
								showModal: true,
								idEntidad: row.IdEntidad,
								claProducto: row.ClaProducto,
								isClone: true,
								isSubscribed: true,
							});
						}}
						color="info"
						size="sm"
						className="btn-icon btn-link">
						<i className="fas fa-clipboard-check" />
					</Button>
					<UncontrolledTooltip
						placement="left"
						target={`btnClone_${row.IdEntidad.toString() + "-" + row.ClaProducto.toString()}`}>
						{translate("MisServiciosPublicados.ttClonar")}
					</UncontrolledTooltip>
					<Button
						id={`btnClone_${row.IdEntidad.toString() + "-" + row.ClaProducto.toString()}`}
						onClick={() => {
							writeLog(
								Formularios.ServiciosPublicados.ClaFuncionalidad,
								Formularios.ServiciosPublicados.Funcionalidades.btnClonar,
								undefined,
								row,
							);
							this.setState({
								showModal: true,
								idEntidad: row.IdEntidad,
								claProducto: row.ClaProducto,
								isClone: true,
								isSubscribed: false,
							});
						}}
						color="info"
						size="sm"
						className="btn-icon btn-link">
						<i className="fas fa-stamp" />
					</Button>
				</Col>
			</Row>
		</div>
	);

	componentDidMount() {
		this.frmLoad();
	}

	/**
	 * Función que oculta el modal de los comentarios.
	 */
	hideModalComentarios() {
		this.setState({
			showModalComentarios: false,
		});
	}

	clear() {
		this.frmLoad();
		this.setState({
			nuevosTerminos: [],
			clearAutocomplete: !this.state.clearAutocomplete,
		});
	}

	btnEliminarClick(obj) {
		const urlApi = `${Config.urlApiService}/Entidades/${obj.ClaProducto}/${obj.IdEntidad}`;

		const data = {
			...obj,
			EsClon: 0,
		};

		if (data.Titulo === undefined || data.Titulo === null) {
			data.Titulo = ""; //debido a que muchas entidades aun no cuentan con titulo
		}

		if (data.Detalles === undefined || data.Detalles === null) {
			data.Detalles = []; //debido a que muchas entidades no cuentan con Detalles
		}

		if (data.ReglasNegocio === undefined || data.ReglasNegocio === null) {
			data.ReglasNegocio = []; //debido a que muchas entidades no cuentan con RDN
		}

		data.BajaLogica = 1;

		callApi(
			urlApi,
			"PUT",
			data,
			(status, response) => {
				const oR = response;

				if (oR.idEntidad <= 0) {
					throw oR.Mensaje;
				}

				writeLog(
					Formularios.ServiciosPublicados.ClaFuncionalidad,
					Formularios.ServiciosPublicados.Funcionalidades.btnEliminar,
					urlApi,
					data,
					response,
					undefined,
					0,
				);

				this.hideAlert();
				this.showAlert(translate("MisServiciosPublicados.alertEliminar"), undefined, "success");
				this.frmLoad();
			},
			Formularios.ServiciosPublicados.ClaFuncionalidad,
			Formularios.ServiciosPublicados.Funcionalidades.btnEliminar,
		);
	}

	frmLoad() {
		let urlApi = ``;

		//Traer terminos existentes del glosario
		urlApi = `${Config.urlApiService}/Glosario`;
		callApi(urlApi, "GET", null, (status, response) => {
			terminosGlosario = response;
		});

		urlApi = `${Config.urlApiService}/Catalogos/2`;
		callApi(urlApi, "GET", null, (status, response) => {
			tipo = response;
		});

		urlApi = `${Config.urlApiService}/Entidades/${getProducto()}`;

		callApi(urlApi, "GET", null, (status, response) => {
			srcData = this.dgvFillTable(response);

			this.setState({
				isLoaded: true,
				srcData: srcData,
				totalCount: srcData.length,
				claIdioma: sessionStorage.getItem("ClaIdioma"),
				gvEntidades: {
					columns: [
						{ name: "IdEntidad", title: "ID" },
						{ name: "NomServicio", title: translate("MisServiciosPublicados.colNombre") },
						{ name: "Actions", title: " " },
					],
					// tableColumnExtensions: [
					// 	{ columnName: "IdEntidad", width: "30px", align: "left" },
					// 	{ columnName: "NomServicio", width: "auto", wordWrapEnabled: true },
					// 	{ columnName: "Actions", width: "10%", align: "right" },
					// ],
					tableColumnExtensions: [
						{ columnName: "IdEntidad", width: "80px", align: "center" },
						{ columnName: "NomServicio", width: "auto", wordWrapEnabled: true },
						{ columnName: "Actions", width: "200px", align: "right" },
					],
				},
			});
		});
	}

	dgvFillTable(source) {
		let dgvSource;

		dgvSource = source.map((obj, key) => {
			return {
				...obj,
				key: key,
				id: obj.IdEntidad,
				NomServicio: obj.Titulo,
				Descripcion: obj.Descripcion,
				Actions: (
					<div className="actions-right">
						<UncontrolledTooltip placement="left" target={`iconEsVerificado${key}`}>
							{obj.EsVerificado === 0 ? (
								<div>{translate("MisServiciosPublicados.ttNoVerificado")}</div>
							) : (
								<div>{translate("MisServiciosPublicados.ttVerificado")}</div>
							)}
						</UncontrolledTooltip>
						{obj.EsVerificado === 0 ? (
							<span id={`iconEsVerificado${key}`} className="btn-icon btn-link" style={{ color: "yellow" }}>
								<i className="fa fa-exclamation-triangle" />
							</span>
						) : (
							<span id={`iconEsVerificado${key}`} className="btn-icon btn-link text-success">
								<i className="fas fa-check"></i>
							</span>
						)}

						<UncontrolledTooltip placement="left" target={`btnEditar_${key}`}>
							{translate("MisServiciosPublicados.ttEditar")}
						</UncontrolledTooltip>
						<Button
							id={`btnEditar_${key}`}
							onClick={() => {
								writeLog(
									Formularios.ServiciosPublicados.ClaFuncionalidad,
									Formularios.ServiciosPublicados.Funcionalidades.btnEditar,
									undefined,
									obj,
								);
								this.setState({
									showModal: true,
									idEntidad: obj.IdEntidad,
									claProducto: obj.ClaProducto,
									isClone: false,
									isSubscribed: false,
								});
							}}
							color="info"
							size="sm"
							className="btn-icon btn-link">
							<i className="fa fa-edit" />
						</Button>

						<UncontrolledTooltip placement="left" target={`btnEliminar_${key}`}>
							{translate("MisServiciosPublicados.ttEliminar")}
						</UncontrolledTooltip>
						<Button
							id={`btnEliminar_${key}`}
							onClick={() => {
								this.showConfirm(
									() => this.btnEliminarClick(obj),
									() => this.hideAlert(),
									translate("MisServiciosPublicados.alertDesactivar"),
									obj.Titulo,
								);
							}}
							color="danger"
							size="sm"
							className="btn-icon btn-link">
							<i className="fas fa-trash-alt" />
						</Button>
					</div>
				),
			};
		});

		console.log(dgvSource);
		return dgvSource;
	}

	btnBuscarClick(value) {
		const urlService = `${Config.urlApiService}/Entidades/Buscar`;

		let body = {
			claProducto: getProducto(),
			claTipo: value.ClaTipoEntidad,
			claCliente: 0,
			nomEntidad: value.Nombre,
			terminosGlosario: this.state.nuevosTerminos,
		};

		callApi(
			urlService,
			"POST",
			body,
			(status, response) => {
				srcData = this.dgvFillTable(response);

				writeLog(
					Formularios.ServiciosPublicados.ClaFuncionalidad,
					Formularios.ServiciosPublicados.Funcionalidades.btnBuscar,
					urlService,
					body,
					response,
					undefined,
					0,
				);

				this.setState({
					srcData: srcData,
				});
			},
			Formularios.ServiciosPublicados.ClaFuncionalidad,
			Formularios.ServiciosPublicados.Funcionalidades.btnBuscar,
		);
	}

	hideAlert() {
		this.setState({
			showAlert: null,
			showConfirm: null,
		});
	}

	showAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	showConfirm(onConfirm, onCancel, title, text) {
		const warningAlert = showConfirm(onConfirm, onCancel, title, text);

		this.setState({
			showConfirm: warningAlert,
		});
	}

	/**
	 * Función 	que oculta el modal
	 * @param {Boolean} isOk establece si la operación fue realizada de manera
	 * correcta y refresca la pantalla
	 */
	hideModal(isOk) {
		if (isOk) {
			const options = {
				place: "br",
				message: translate("MisServiciosPublicados.operacionExitosa"),
				type: "success",
				autoDismiss: 3,
				// icon: icon
			};

			this.notify.notificationAlert(options);
			// this.cargaInicial();
			this.frmLoad();
		}

		this.setState({
			showModal: false,
		});
	}

	/**
	 * Función del botón	que redirige a la página de incidentes
	 * @param {String} idEntidad id de la entidad seleccionada para consultar
	 * sus incidentes
	 * @param {String} nomEntidad nombre de la entidad seleccionada para consultar
	 * sus incidentes
	 */
	btnIncidenteClick(idEntidad, nomEntidad) {
		this.props.history.push({
			pathname: "/admin/Incidentes",
			idEntidad,
			nombreEntidad: nomEntidad,
		});
	}

	render() {
		return (
			<>
				{getLoginInfo().NomGrupo === "Pruebas" ? (
					<TourVirtual PaginaID="2" ClaTour={Tours.NuevosFeaturePublicados} />
				) : (
					<TourVirtual PaginaID="2" ClaTour={Tours.ServiciosPublicados} />
				)}

				{sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.frmLoad()}

				<NotificationAlert ref={(ref) => (this.notify = ref)} />
				{this.state.showAlert}
				{this.state.showConfirm}

				<div className="content">
					<Formik
						id="ctnSrvPublicados"
						initialValues={{
							ClaTipoEntidad: 0,
							ClaSistema: 0,
							// ClaCategoria: 0,
							ClaProducto: 0,
							Nombre: "",
							TerminosGlosario: "",
						}}
						onSubmit={this.btnBuscarClick}
						onReset={this.clear}>
						{(formikProps) => (
							<Form
								onSubmit={formikProps.handleSubmit}
								onReset={formikProps.handleReset}
								key="frmEntidadesBuscar">
								<Card>
									<CardHeader>
										<CardTitle>
											<FormGroup row>
												<Col md={6}>
													<h1>{translate("MisServiciosPublicados.titulo")}</h1>
												</Col>
												<Col md={6} className="text-right">
													<UncontrolledTooltip target={`btnBuscar`}>
														{translate("MisServiciosPublicados.ttBuscar")}
													</UncontrolledTooltip>
													<Button
														id="btnBuscar"
														color="info"
														size="sm"
														className="btn-simple btn-icon"
														type="submit">
														<i className="fas fa-search" />
													</Button>

													<UncontrolledTooltip target={`btnLimpiar`}>
														{translate("MisServiciosPublicados.ttLimpiar")}
													</UncontrolledTooltip>
													<Button
														onClick={() => {
															writeLog(
																Formularios.ServiciosPublicados.ClaFuncionalidad,
																Formularios.ServiciosPublicados.Funcionalidades.btnLimpiar,
															);
															formikProps.handleReset();
														}}
														id="btnLimpiar"
														color="info"
														size="sm"
														className="btn-simple btn-icon">
														<i className="fas fa-eraser" />
													</Button>

													<UncontrolledTooltip target={`btnNuevo`}>
														{` ` + translate("MisServiciosPublicados.btnAgregar")}
													</UncontrolledTooltip>
													<Button
														id={`btnNuevo`}
														onClick={() => {
															writeLog(
																Formularios.ServiciosPublicados.ClaFuncionalidad,
																Formularios.ServiciosPublicados.Funcionalidades.btnNuevo,
															);
															this.setState({
																showModal: true,
																idEntidad: 0,
															});
														}}
														color="info"
														size="sm"
														className="btn-simple btnAgregar">
														<i className="fa fa-plus" />
														{translate("MisServiciosPublicados.btnAgregar")}
													</Button>
												</Col>
											</FormGroup>
										</CardTitle>
									</CardHeader>
									<CardBody>
										<FormGroup row>
											<Col md={2}>
												<Label>{translate("MisServiciosPublicados.lblTipo")}</Label>
												<Field
													name="ClaTipoEntidad"
													as="select"
													type="input"
													id="btnClaTipoEntidad"
													className="form-control"
													onClick={() => {
														//	alert("Seleccion");
													}}>
													{tipo &&
														tipo.length > 0 &&
														tipo.map((obj, index) => (
															<option
																className="ddlOptions"
																key={index}
																value={obj.ClaTipoEntidad}
																onClick={() => alert(obj.ClaTipoEntidad)}>
																{sessionStorage.getItem("ClaIdioma") === "2" &&
																obj.NomTipoEntidad === "SELECCIONE"
																	? "SELECT"
																	: obj.NomTipoEntidad}
															</option>
														))}
												</Field>
											</Col>
											<Col md={3}>
												<Label>{translate("MisServiciosPublicados.lblServicio")}</Label>
												<Field
													name="Nombre"
													type="input"
													className="form-control"
													placeholder={translate("MisServiciosPublicados.phNombre")}></Field>
											</Col>
											<Col md={3} className="pt-0">
												<Label for="TerminosGlosario">
													{translate("MisServiciosPublicados.lblGlosario")}
												</Label>
												<Autocomplete
													multiple
													disableCloseOnSelect
													key={this.state.clearAutocomplete}
													id="TerminosGlosario"
													size="small"
													onChange={(_, value) =>
														this.setState({
															nuevosTerminos: value,
														})
													}
													options={terminosGlosario}
													getOptionLabel={(option) => option.NomTermino}
													getOptionSelected={(option, value) => option.ClaTermino === value.ClaTermino}
													//defaultValue={this.state.nuevosTerminos ? this.state.nuevosTerminos : ''}
													renderInput={(params) => (
														<TextField
															{...params}
															multiline
															name="TerminosGlosario"
															placeholder={translate("MisServiciosPublicados.phTerminosRelacionados")}
															variant="outlined"
															rowsMax="6"
														/>
													)}
												/>
											</Col>
										</FormGroup>
										<Row>
											<Col md={12} className="text-right">
												<label>
													{translate("MisServiciosPublicados.lblTotalDeRegistros")}:{" "}
													{this.state.totalCount}
												</label>
											</Col>
										</Row>
										<Grid rows={this.state.srcData} columns={this.state.gvEntidades.columns}>
											<SortingState
												sorting={this.state.sorting}
												onSortingChange={(value) => {
													this.setState({ sorting: value });
												}}
											/>

											<RowDetailState />

											<PagingState
												currentPage={this.state.currentPage}
												onCurrentPageChange={(value) => {
													this.setState({ currentPage: value });
												}}
												pageSize={this.state.pageSize}
												onPageSizeChange={(value) => {
													this.setState({ pageSize: value });
												}}
											/>

											<IntegratedSorting />
											<IntegratedPaging />

											<Table
												tableComponent={tableComponent}
												columnExtensions={this.state.gvEntidades.tableColumnExtensions}
												headComponent={headerComponent}
												messages={{ noData: gvConfig.noData }}
											/>

											<TableHeaderRow showSortingControls={true} />
											<TableRowDetail contentComponent={this.RowDetail} />

											<PagingPanel pageSizes={gvConfig.pageSizes} />
										</Grid>
									</CardBody>
								</Card>
							</Form>
						)}
					</Formik>
				</div>

				<Modal centered isOpen={this.state.showModal} size="lg">
					<EntidadMtto
						hideModal={this.hideModal}
						idEntidad={this.state.idEntidad}
						updateData={this.frmLoad}
						claProducto={this.state.claProducto}
						readOnly={false}
						className="mdlEntidad"
						isClone={this.state.isClone}
						isSubscribed={this.state.isSubscribed}
					/>
				</Modal>
				<Flags authorizedFlags={[getLoginInfo().NomGrupo]}>
					<Modal centered={true} isOpen={this.state.showModalComentarios} size="md">
						<ComentariosMtto
							hideModalComentarios={this.hideModalComentarios}
							idEntidad={this.state.idEntidad}
							claProducto={this.state.claProducto}
							calificacion={this.state.calificacion}
							nomEntidad={this.state.nomEntidad}
							className="mdlEntidad"
							readOnly={true}
						/>
					</Modal>
				</Flags>
			</>
		);
	}
}

Entidades.propTypes = {
	history: PropTypes.any,
};

// RowDetail.propTypes = {
// 	row: {
// 		Descripcion: PropTypes.string,
// 		NomTipoEntidad: PropTypes.string,
// 	},
// };
