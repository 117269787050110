const DashboardCliente = {
	titulo: "Indicadores Clave",
	lblFechaDesde: "Fecha Desde:",
	lblFechaHasta: "Fecha Hasta:",
	ttLimpiar: "Limpiar",
	lblErroresTitulo: "Errores",
	lblPeticionesPorProveedor: "Peticiones por proveedor",
	lblTop5MayorTiempo: "Top 5 servicios con mayor tiempo de ejecución (ms)",
	lblTop5MasUtilizados: "Top 5 servicios más utilizados",

	lblPeticiones: "Peticiones",
	lblSolicitudesPorHora: "Solicitudes p/hora",
	lblErroresPorHora: "Errores p/hora",
	lblPorcentajeError: " % Error",
	lblClientesAfectados: "Clientes afectados",
	lblCerrar: "Cerrar",
};

export default DashboardCliente;
