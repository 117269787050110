const Comentarios = {
	titulo: "Comments for ",
	alertEnviado: "Comment sent",
	noComentarios: "No comments yet",
	lblDejaComentario: "Leave your comment",
	btnAgregar: "Add",
	btnResponder: "Reply",
	phComentario: "Enter the comment",
	lblVer: "See",
	lblRespuestas: "response (s)",
	btnCancelar: "Cancel",
	btnCerrar: "Close",
};

export default Comentarios;
