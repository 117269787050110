import React from "react";
import NotificationAlert from "react-notification-alert";
import { Card, CardHeader, CardBody, Button, UncontrolledTooltip, Modal, Row, Col } from "reactstrap";
import { getProducto, callApi, writeLog } from "utils/Utilerias";
import { Link } from "react-router-dom";
import { Config } from "utils/Config";
import SolicitudServicioMtto from "../SolicitudServicios//SolicitudServicioMtto";
import EntidadMtto from "../Entidades/EntidadMtto";
import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";

let srcData = [];
let formularioDet = [];

export default class SolicitudesRecibidas extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			showsSolicitudModal: false,
			solicitudes: [],
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		};

		this.load = this.load.bind(this);
		this.hideSolicitudModal = this.hideSolicitudModal.bind(this);
	}

	componentDidMount() {
		this.load();
	}

	load() {
		//Help tooltips
		let urlService = `${Config.urlApiService}/CatFormularioDet/6`;
		callApi(urlService, "GET", null, (status, response) => {
			formularioDet = response;
		});

		//Obtener información
		urlService = `${Config.urlApiService}/Home/SolicitudesRecibidas/${getProducto()}`;

		callApi(urlService, "GET", null, (status, response) => {
			console.log("estatus solicitudes", response);
			if (status === 200) {
				srcData = response.map((obj, key) => {
					return {
						NomProductoSolicita: obj.NomProductoSolicita,
						NomPM: obj.NomPM,
						Categoria: obj.Categoria,
						Nombre: obj.Nombre,
						IdTipo: obj.IdTipo,
						ClaProducto: obj.ClaProducto,
						IdEntidad: obj.IdEntidad,
						ClaProductoSolicita: obj.ClaProductoSolicita,
						Fecha: obj.Fecha !== null ? obj.Fecha.toDateNoTime() : null,
						VerDetalle: (
							<>
								<UncontrolledTooltip placement="right" target={`btnVerDetalle_${key}`}>
									<span>{translate("HomeDev.ttInformacion")}</span>
								</UncontrolledTooltip>
								<Button
									id={`btnVerDetalle_${key}`}
									className="btn btn-link btn-img"
									color="info"
									size="sm"
									onClick={() => {
										writeLog(
											Formularios.SolicitudesRecibidas.ClaFuncionalidad,
											Formularios.SolicitudesRecibidas.Funcionalidades.btnVerDetalle,
											undefined,
											obj,
										);
										if (obj.IdEntidad) {
											this.setState({
												showEntidadModal: true,
												idEntidad: obj.IdEntidad,
												claProducto: getProducto(),
											});
										} else {
											this.setState({
												showSolicitudModal: true,
												idPeticion: obj.IdTipo,
											});
										}
									}}>
									<i className="fas fa-info-circle" />
								</Button>
							</>
						),
					};
				});
				this.setState({
					isLoaded: true,
					solicitudes: srcData,
					claIdioma: sessionStorage.getItem("ClaIdioma"),
				});
			}
		});
	}
	/**
	 * Función 	que oculta el modal
	 * correcta y refresca la pantalla
	 */
	hideSolicitudModal(isOk) {
		this.setState({
			showSolicitudModal: false,
			showEntidadModal: false,
		});

		if (isOk) {
			var options = {
				place: "br",
				message: "Operación realizada correctamente.",
				type: "success",
				autoDismiss: 3,
			};

			this.notify.notificationAlert(options);
		}
	}

	renderDetTexto(nomControl) {
		let detalle = formularioDet.filter((detalle) => detalle.NomControl === nomControl);
		if (detalle[0]) return detalle[0].Texto;
		else return "No se encontró descripción";
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<div className="spinner-border text-info" role="status">
					<span className="sr-only">{translate("HomeDev.lblCargando")}</span>
				</div>
			);
		} else {
			return (
				<>
					{sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.load()}
					<NotificationAlert ref={(ref) => (this.notify = ref)} />
					<Card id="ctnSolicitudesRecibidas" style={{ maxHeight: "inherit", minHeight: "inherit" }}>
						<CardHeader>
							<UncontrolledTooltip placement="right" target={`helpSolicitudesRecibidas`}>
								<span>{translate("HomeDev.ttSolicitudesRecibidas")}</span>
							</UncontrolledTooltip>
							<Row>
								<Col md={8}>
									<h2>
										{translate("HomeDev.lblSolicitudesRecibidas")}
										<span id="helpSolicitudesRecibidas">
											<i className="far fa-question-circle"></i>
										</span>
									</h2>
								</Col>
								<Col md={4} className="text-right">
									<Link to="/admin/SolicitudServicio">
										<Button
											id="btnVerTodos"
											className="btn-link btn-home"
											size="sm"
											onClick={() => {
												writeLog(
													Formularios.SolicitudesRecibidas.ClaFuncionalidad,
													Formularios.SolicitudesRecibidas.Funcionalidades.btnVerTodos,
												);
												this.setState({ showSolitudServicios: true });
											}}>
											{translate("HomeDev.lblVerTodos")}
										</Button>
									</Link>
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							<div className="solicitudesRecibidas">
								<table className="table table-sm table-noBorder ">
									<thead>
										<tr>
											<th className="w-20">{translate("HomeDev.colProducto")}</th>
											{/* <th>Product Manager</th> */}
											<th className="w-35">{translate("HomeDev.colCategoria")}</th>
											<th className="w-40">{translate("HomeDev.colSolicitud")}</th>
											<th className="w-5"></th>
										</tr>
									</thead>
									<tbody>
										{(this.state.solicitudes.length > 0 &&
											this.state.solicitudes.map((obj, index) => {
												return (
													<tr key={`sol_${index}`}>
														<td>{obj.NomProductoSolicita}</td>
														{/* <td>{obj.NomPM}</td> */}
														<td>{obj.Categoria}</td>
														<td>{obj.Nombre}</td>
														<td>{obj.VerDetalle}</td>
													</tr>
												);
											})) || (
											<tr>
												<td colSpan={4} style={{ textAlign: "center", height: "105px" }}>
													<p>
														{translate("HomeDev.lblNoPendientes")}
														<span className="btn-icon btn-link text-success">
															<i className="fas fa-check"></i>
														</span>
													</p>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</CardBody>
					</Card>
					<Modal centered={true} isOpen={this.state.showSolicitudModal} size="lg">
						<SolicitudServicioMtto
							hideModal={this.hideSolicitudModal}
							pantallaEntidad={false}
							idPeticion={this.state.idPeticion}
							claProducto={getProducto()}
							readOnly={true}
						/>
					</Modal>

					<Modal centered isOpen={this.state.showEntidadModal} size="lg">
						<EntidadMtto
							hideModal={this.hideSolicitudModal}
							idEntidad={this.state.idEntidad}
							claProducto={getProducto()}
							readOnly={false}
							className="mdlEntidad"
						/>
					</Modal>
				</>
			);
		}
	}
}
