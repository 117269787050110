import React from "react";
import { Row, Col, Button, Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import { Grid, VirtualTable, TableHeaderRow, TableSelection } from "@devexpress/dx-react-grid-bootstrap4";
import { SortingState, SelectionState, IntegratedSorting, IntegratedSelection } from "@devexpress/dx-react-grid";
import PropTypes from "prop-types";
import { callApi, showSweetAlert } from "utils/Utilerias";
import { Config } from "../../utils/Config";
import { tableComponent, headerComponent, gvConfig } from "utils/gridConfig";
import { writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";
export default class FiltroMtto extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			showModal: false,
			clientes: [],
			gvClientes: {
				columns: [
					this.props.claProducto
						? { name: "NomCliente", title: translate("FiltroMtto.colNombre") }
						: { name: "NomProducto", title: translate("FiltroMtto.colNombre") },
					{ name: "ServiciosConsumidos", title: translate("FiltroMtto.colServiciosConsumidos") },
					{ name: "TotalErrores", title: translate("FiltroMtto.colTotalErrores") },
					{ name: "TiempoMasLento", title: translate("FiltroMtto.colTiempoMasLento") },
					{ name: "Actividad", title: translate("FiltroMtto.colActividad") },
				],
				tableColumnExtensions: [
					{ columnName: "NomCliente", width: "150px" },
					{ columnName: "ServiciosConsumidos", width: "110px", align: "center", wordWrapEnabled: true },
					{ columnName: "TotalErrores", width: "110px", align: "center", wordWrapEnabled: true },
					{ columnName: "TiempoMasLento", width: "110px", align: "center", wordWrapEnabled: true },
					{ columnName: "Actividad", align: "center" },
				],
			},
			sortingTablaClientes: [{ columnName: "Actividad", direction: "desc" }],
			selection: [],
		};

		this.hideModal = this.hideModal.bind(this);
		this.btnGuardarClick = this.btnGuardarClick.bind(this);
	}

	componentDidMount() {
		this.fillData();
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.claCliente !== prevProps.claCliente ||
			this.props.claProducto !== prevProps.claProducto ||
			this.props.fechaInicio !== prevProps.fechaInicio ||
			this.props.fechaFin !== prevProps.fechaFin
		) {
			this.setState({
				isLoaded: false,
			});
			this.fillData();
		}
	}

	fillData() {
		//Obtener información del filtro
		let urlService = `${Config.urlApiService}/Dashboard2`;
		let data = {
			ClaProducto: this.props.claProducto,
			//ClaCliente: this.props.claCliente,
			ClaConsulta: this.props.claProducto !== undefined ? 2 : 12,
			FechaInicial: this.props.fechaInicio ? this.props.fechaInicio.toSqlDateTime() : undefined,
			FechaFinal: this.props.fechaFin ? this.props.fechaFin.toSqlDateTime() : undefined,
		};

		callApi(
			urlService,
			"POST",
			data,
			(status, response) => {
				writeLog(
					Formularios.DashboardProveedor.ClaFuncionalidad,
					Formularios.DashboardProveedor.Funcionalidades.btnFiltrar,
					urlService,
					data,
					response,
					undefined,
					0,
				);

				if (status === 200 && response.length > 0) {
					this.setState({
						clientes: response,
						isLoaded: true,
					});
				} else {
					this.setState({
						clientes: [],
						isLoaded: true,
					});
				}
			},
			Formularios.DashboardProveedor.ClaFuncionalidad,
			Formularios.DashboardProveedor.Funcionalidades.btnFiltrar,
		);
	}

	btnGuardarClick() {
		let tipoDashboard = this.props.claProducto ? translate("FiltroMtto.cliente") : translate("FiltroMtto.proveedor");
		if (this.state.selection.length > 1) {
			showSweetAlert(translate("FiltroMtto.alertSeleccionarSoloUno") + tipoDashboard, "", "warning");
		} else if (this.state.selection.length === 0) {
			showSweetAlert(translate("FiltroMtto.alertSeleccionarUn") + tipoDashboard, "", "warning");
		} else {
			let selectedRow = this.state.selection[0];
			let claveFiltro = this.props.claProducto
				? this.state.clientes[selectedRow].ClaCliente
				: this.state.clientes[selectedRow].ClaProducto;
			this.hideModal(claveFiltro);
		}
	}

	hideModal(claveFiltro = 0) {
		this.props.filtrar(true, claveFiltro);
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<div className="row justify-content-center p-5">
					<div className="spinner-border text-info" style={{ backgroundColor: "transparent" }} role="status">
						<span className="sr-only">{translate("FiltroMtto.cargando")}</span>
					</div>
				</div>
			);
		} else {
			return (
				<Card>
					<CardHeader>
						<h2 className="pb-0">{translate("FiltroMtto.titulo")}</h2>
					</CardHeader>
					<CardBody>
						<Grid rows={this.state.clientes} columns={this.state.gvClientes.columns}>
							<SortingState
								sorting={this.state.sortingTablaClientes}
								onSortingChange={(value) => {
									this.setState({ sortingTablaClientes: value });
								}}
							/>
							<SelectionState
								selection={this.state.selection}
								onSelectionChange={(value) => this.setState({ selection: value })}
							/>
							<IntegratedSorting />
							<IntegratedSelection />

							<VirtualTable
								height={250}
								tableComponent={tableComponent}
								columnExtensions={this.state.gvClientes.tableColumnExtensions}
								messages={{
									noData: gvConfig.noData,
								}}
								headComponent={headerComponent}
							/>
							<TableHeaderRow showSortingControls={true} />
							<TableSelection />
						</Grid>
					</CardBody>
					<CardFooter>
						<Row>
							<Col md={12} className="text-right">
								<Button
									id="btnGuardar"
									className={`animation-on-hover`}
									color="success"
									size="sm"
									onClick={() => this.btnGuardarClick()}>
									{translate("FiltroMtto.btnGuardar")}
								</Button>
								<Button
									id="btnCancelar"
									className="animation-on-hover"
									color="default"
									size="sm"
									onClick={() => this.hideModal()}>
									{translate("FiltroMtto.btnCancelar")}
								</Button>
							</Col>
						</Row>
					</CardFooter>
				</Card>
			);
		}
	}
}

FiltroMtto.propTypes = {
	claProducto: PropTypes.number,
	claCliente: PropTypes.number,
	filtrar: PropTypes.func,
	fechaInicio: PropTypes.object,
	fechaFin: PropTypes.object,
};
