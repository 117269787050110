import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Card, CardHeader, CardTitle, CardBody, UncontrolledTooltip } from "reactstrap";
import { callApi, getProducto } from "utils/Utilerias";
import { Config } from "../../utils/Config";
import PropTypes from "prop-types";
import translate from "utils/translate";

let formularioDet = [];

export default class EntidadesMasYMenosUsadas extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		};
	}

	getChartOpstions() {
		return {
			chart: {
				type: "column",
				backgroundColor: "#27293d",
			},
			colors: ["#1d8cf8", "#2dce89", "#f3a4b5", "#f5365c", "#8965e0"],
			title: {
				text: "",
			},
			legend: {
				itemStyle: {
					fontSize: "9pt",
					font: "9pt Trebuchet MS, Verdana, sans-serif",
					color: "#A0A0A0",
				},
			},
			plotOptions: {
				column: {
					borderWidth: 0,
					dataLabels: {
						enabled: false,
					},
				},
			},
			tooltip: {
				shared: true,
				backgroundColor: "rgb(49, 51, 77, .80)",
				style: {
					color: "#FFFFFF",
				},
			},
			yAxis: {
				title: {
					text: translate("HomeDev.lblTotalPeticiones"),
				},
				gridLineColor: "rgba(255, 255, 255, 0.1)",
				tickLength: 10,
			},
			xAxis: {},
		};
	}

	componentDidMount() {
		this.fillData();
	}

	fillData() {
		//Help tooltips
		let urlService = `${Config.urlApiService}/CatFormularioDet/10`;
		callApi(urlService, "GET", null, (status, response) => {
			formularioDet = response;
		});

		//Obtener información
		urlService = `${Config.urlApiService}/Home/TopBottomEntidades/${getProducto()}/${this.props.esTop}`;

		callApi(urlService, "GET", {}, (status, response) => {
			console.log(response);
			let dataArray = response.data[0].Result0;
			let tempOptions = {};
			tempOptions = this.getChartOpstions();

			if (dataArray && dataArray[0][0] !== "") {
				let labels = response.data[1].Result1[0][0].split(",");

				let dataChart = [];

				dataArray.map((item, index) => {
					let arrayName = dataArray[index].splice(0, 1)[0];
					let arrayItem = dataArray[index];

					dataChart.push({
						name: arrayName,
						data: arrayItem,
					});

					return item;
				});

				tempOptions.series = dataChart;
				tempOptions.xAxis.categories = labels;
			} else {
				tempOptions.series = [{ name: translate("HomeDev.lblNoInformacion"), data: 0 }];
				tempOptions.xAxis.categories = [translate("HomeDev.lblNoInformacion")];
			}

			this.setState({
				isLoaded: true,
				options: tempOptions,
				claIdioma: sessionStorage.getItem("ClaIdioma"),
			});
		}).catch((err) => {
			console.log("ServiciosPorTamano", err);
			//throw err;
		});
	}

	renderDetTexto(nomControl) {
		let detalle = formularioDet.filter((detalle) => detalle.NomControl === nomControl);
		if (detalle[0]) return detalle[0].Texto;
		else return "No se encontró descripción";
	}

	render() {
		if (this.state.isLoaded) {
			return (
				<>
					{sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.fillData()}
					<Card
						id={`ctnEntidades${this.props.esTop === 1 ? "Top" : "Bottom"}_${this.props.esAdmin ? "PM" : "Dev"}`}>
						<CardHeader>
							<CardTitle>
								<UncontrolledTooltip
									placement="right"
									target={`Entidades${this.props.esTop === 1 ? "Mas" : "Menos"}`}>
									<span>
										{this.props.esTop === 1
											? translate("HomeDev.ttMisServiciosMasUtilizados")
											: translate("HomeDev.ttMisServiciosMenosUtilizados")}
									</span>
								</UncontrolledTooltip>
								<h2>
									{this.props.esTop === 1
										? translate("HomeDev.lblMisServiciosMasUtilizados")
										: translate("HomeDev.lblMisServiciosMenosUtilizados")}
									&nbsp;
									<span id={`Entidades${this.props.esTop === 1 ? "Mas" : "Menos"}`}>
										<i className="far fa-question-circle"></i>
									</span>
								</h2>
							</CardTitle>
						</CardHeader>
						<CardBody>
							<HighchartsReact
								containerProps={{ style: { height: "100%" } }}
								highcharts={Highcharts}
								options={this.state.options}
							/>
						</CardBody>
					</Card>
				</>
			);
		} else {
			return (
				<div className="text-center">
					<h3>{translate("HomeDev.lblCargando")}</h3>
				</div>
			);
		}
	}
}

EntidadesMasYMenosUsadas.propTypes = {
	esTop: PropTypes.any,
	esAdmin: PropTypes.any,
};
