const Usuarios = {
	titulo: "Users Management",
	lblClaveEmpleado: "Employee Key",
	phClaveEmpleado: "Employee Key",
	lblNombre: "Name",
	phNombre: "User name",
	btnAgregar: " Add",
	ttAgregar: "Add",
	ttBuscar: "Search",
	ttLimpiar: "Clear",
	colClaveEmpleado: "Employee Key",
	colNombre: "Name",
	colPerfil: "Profile",
	ttEditar: "Edit",
	ttEliminar: "Delete",
};

export default Usuarios;
