const MisServiciosSuscritos = {
	titulo: "Available Services",
	lblProducto: "Product",
	lblServicio: "Services",
	phServicio: " Name, description or id of the service",
	lblGlosario: "Glossary",
	phGlosario: "Related terms",
	ttBuscar: "Search",
	ttLimpiar: "Clear",
	btnCrearSolicitud: " Create request",
	lblTotalDeRegistros: "Total records: ",
	colProducto: "Product",
	colServicio: "Service",
	colTipo: "Type",
	ttVerificado: "Verified service",
	ttNoVerificado: "The service is not verified",
	ttSuscribirse: "Suscribe",
	ttComentarios: "Comments",
	ttEliminar: "Unsuscribe",
	ttInformacion: "Information",
	ttPublico: "Public service",
	ttPrivado: "Private service",
};

export default MisServiciosSuscritos;
