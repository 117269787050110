/*
*Componente que muestra y elige el cliente sobrem el que está ubicado,
posicionado en la parte superior derecha
 */
import React from "react";
import { Config } from "utils/Config";
import { callApi, getLoginInfo } from "utils/Utilerias";
import { Switch, Redirect, Link } from "react-router-dom";
import { Collapse, NavbarBrand } from "reactstrap";
import { getNomProducto } from "utils/Utilerias";
import { setProducto } from "utils/Utilerias";
import logo from "assets/img/kraken_icon.png";
import logoBeta from "assets/img/kraken_icon_beta.png";
import PropTypes from "prop-types";
import { Formularios } from "utils/funcionalidad";
import { getNomUsuario, writeLog } from "utils/Utilerias";

export default class UsuarioNavbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			Productos: undefined,
			isSelected: false,
			collapseOpen: false,
			color: "navbar-transparent",
			sesion: true,
		};
		let nomUsuario = getNomUsuario().toLowerCase();
		nomUsuario = nomUsuario.split(" ");

		this.primerNombre = nomUsuario[0] + " " + nomUsuario[1];
	}

	componentDidMount() {
		const urlService = `${Config.urlApiService}/Producto/Usuarios`;

		callApi(urlService, "GET", null, (status, response) => {
			if (response.length === 1) {
				let obj = response[0];

				setProducto(obj.ClaProducto, obj.NomProducto, obj.ApiKey, obj.VerComo);

				this.setState({
					isSelected: false,
				});
			} else {
				this.setState({
					Productos: response,
				});
			}
		}).catch((err) => {
			alert(err);
		});
	}

	seleccionProducto() {
		writeLog(
			Formularios.UsuarioNavBar.ClaFuncionalidad,
			Formularios.UsuarioNavBar.Funcionalidades.ctnSeleccionProducto,
		);
		sessionStorage.setItem("isSelected", false);
	}

	render() {
		if (this.state.isSelected) {
			this.setState({
				isSelected: false,
			});
			return (
				<Switch>
					<Redirect from="/" to="/Login" />
				</Switch>
			);
		} else {
			return (
				<>
					<Collapse navbar isOpen={this.state.collapseOpen}>
						<NavbarBrand id="ctnSeleccionProducto">
							{/* <Switch>
								<Route to="/SeleccionProducto" />
							</Switch> */}
							<Link to="/SeleccionProducto">
								<img
									src={getLoginInfo().NomGrupo === "Pruebas" ? logoBeta : logo}
									alt="react-logo"
									style={{ marginRight: "10px" }}
									onClick={() => {
										this.seleccionProducto();
									}}
									height="30px"
								/>
							</Link>
							{this.props.pantallaProducto ? (
								<span style={{ color: "white", textTransform: "capitalize" }}>
									{sessionStorage.getItem("ClaIdioma") === "2" ? "Welcome " : "Bienvenido "}
									{this.primerNombre}
								</span>
							) : getLoginInfo().NomGrupo === "Pruebas" ? (
								<span style={{ color: "white", textTransform: "none" }}>Early Access {getNomProducto()}</span>
							) : (
								<span style={{ color: "white", textTransform: "none" }}>
									{sessionStorage.getItem("ClaIdioma") === "2" ? "Welcome to " : "Bienvenido a "}
									{getNomProducto()}
								</span>
							)}
						</NavbarBrand>
					</Collapse>
				</>
			);
		}
	}
}

UsuarioNavbar.propTypes = {
	pantallaProducto: PropTypes.bool,
};
