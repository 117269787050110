import React from "react";

import {
	Form,
	FormGroup,
	Col,
	Button,
	Label,
	UncontrolledTooltip,
	Card,
	CardHeader,
	Row,
	CardTitle,
	CardBody,
	Modal,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { Formik, Field } from "formik";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { callApi, getProducto, showConfirm, showSweetAlert, getLoginInfo, writeLog } from "utils/Utilerias";

import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";
import { Config } from "utils/Config";
import EntidadMtto from "./EntidadMtto";
import SuscripcionMtto from "../Suscripcion/SuscripcionMtto";
import SolicitudServicioMtto from "../SolicitudServicios/SolicitudServicioMtto";
import ComentariosMtto from "../Comentarios/ComentariosMtto";
import { Grid, PagingPanel, Table, TableHeaderRow, TableRowDetail } from "@devexpress/dx-react-grid-bootstrap4";
import {
	IntegratedPaging,
	IntegratedSorting,
	PagingState,
	SortingState,
	RowDetailState,
} from "@devexpress/dx-react-grid";
import { tableComponent, headerComponent, gvConfig } from "utils/gridConfig";
import TourVirtual from "components/Tour/TourVirtual";
import { Tours } from "../../utils/Config";
import ReactStars from "react-rating-stars-component";
import { Flags } from "react-feature-flags";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
require("utils/Prototipos");

let producto;
let srcData = [];
let terminosGlosario = [];

export default class ServiciosDisponibles extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			srcData: srcData,
			isLoaded: false,
			isSuscrito: false,
			showModal: false,
			showModalComentarios: false,
			showSuscriptionModal: false,
			idEntidad: -1,
			claProducto: -1,
			nuevosTerminos: [],
			datosSuscripcion: null,
			clearAutocomplete: false,
			NomEntidad: "",
			Calificacion: -1,

			// srcData: [],
			currentPage: 0,
			pageSize: 20,
			sorting: [{ columnName: "NomProducto", direction: "asc" }],

			gvEntidades: {
				columns: [
					{ name: "IdEntidad", title: "ID" },
					{ name: "NomProducto", title: "Producto" },
					{ name: "Nombre", title: "Servicio" },
					{ name: "NomTipoEntidad", title: "Tipo" },
					{ name: "Actions", title: " " },
				],
				tableColumnExtensions: [
					{ columnName: "IdEntidad", width: "80px", align: "center" },
					{ columnName: "NomProducto", width: "200px", align: "left" },
					{ columnName: "Nombre", width: "auto", wordWrapEnabled: true },
					{ columnName: "NomTipoEntidad", width: "100px" },
					{ columnName: "Actions", width: "200px", align: "right" },
				],
			},

			claIdioma: sessionStorage.getItem("ClaIdioma"),
		};

		this.btnBuscarClick = this.btnBuscarClick.bind(this);
		this.btnSuscribirseClick = this.btnSuscribirseClick.bind(this);
		this.hideModal = this.hideModal.bind(this);
		this.hideModalComentarios = this.hideModalComentarios.bind(this);
		this.clear = this.clear.bind(this);
		this.hideSuscriptionModal = this.hideSuscriptionModal.bind(this);
		this.hideSolicitudServicio = this.hideSolicitudServicio.bind(this);
		this.dgvFillTable = this.dgvFillTable.bind(this);
	}

	/**
	 * Función 	que oculta el modal
	 * @param {Boolean} isOk establece si la operación fue realizada de manera
	 * correcta y refresca la pantalla
	 */
	hideModal() {
		this.setState({
			showModal: false,
		});
	}
	/**
	 * Función que oculta el modal de los comentarios.
	 */
	hideModalComentarios() {
		this.setState({
			showModalComentarios: false,
		});
	}

	/**
	 * Función 	que oculta el modal de suscripción cuando una entidad es privada
	 */
	hideSuscriptionModal() {
		this.setState({
			showSuscriptionModal: false,
		});
	}

	/**
	 * Función 	que oculta el modal de una solicitud de servicio
	 */
	hideSolicitudServicio(isOk) {
		this.setState({
			showSolicitudModal: false,
		});

		if (isOk) {
			var options = {
				place: "br",
				message:
					sessionStorage.getItem("ClaIdioma") === "1"
						? "Operación realizada correctamente."
						: "Successful operation.",
				type: "success",
				autoDismiss: 3,
			};

			this.notify.notificationAlert(options);
		}
	}

	/**
	 * Función que se muestra un mensaje de advertencia en la operación
	 * @param {String} title titulo que tendrá el mensaje mostrado
	 * @param {String} text contenido del mensaje
	 * @param {String} icon icono que mostrará el mensaje
	 */
	warningAlert(title, text, icon) {
		const msgAlert = showSweetAlert(title, text, icon, () => this.btnSuscribirseClick);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Funcion para ocultar la alerta/mensaje que fue activado
	 */
	hideAlertConfirm() {
		//this.btnBuscarClick(this.state.buscarData);
		this.setState({
			showConfirm: null,
		});
	}

	/**
	 * Oculta los promts de alertas con pregunta de confirmacion
	 */
	showConfirm(onConfirm, onCancel, title, text) {
		const warningAlert = showConfirm(onConfirm, onCancel, title, text);

		this.setState({
			showConfirm: warningAlert,
		});
	}

	RowDetail = ({ row }) => (
		<div
			style={{
				padding: "1%",
				marginLeft: "20px",
			}}>
			<Row>
				<Col md="5">{row.Descripcion}</Col>
				<Col md="3">
					{/* <div className="float-right text-right"> */}
					<ReactStars
						count={5}
						size={20}
						edit={false}
						activeColor="#ffd700"
						isHalf={true}
						value={row.Calificacion}
					/>
					{/* </div> */}
				</Col>
				<Col md="2" style={{ textAlign: "right", borderLeft: "2px solid #999" }}>
					{translate("MisServiciosPublicados.ttOtrasFn")}
				</Col>
				<Col md="2" style={{ textAlign: "right" }}>
					<UncontrolledTooltip
						placement="left"
						target={`iconEsPrivado${row.IdEntidad.toString() + "-" + row.ClaProducto.toString()}`}>
						{row.EsPrivado === 0 ? (
							<div>{translate("ServiciosDisponibles.ttPublico")}</div>
						) : (
							<div>{translate("ServiciosDisponibles.ttPrivado")}</div>
						)}
					</UncontrolledTooltip>

					<span
						id={`iconEsPrivado${row.IdEntidad.toString() + "-" + row.ClaProducto.toString()}`}
						className="btn-icon btn-link p-1"
						style={{ color: "#D4AC0D" }}>
						{row.EsPrivado === 0 ? <i className="fas fa-lock-open" /> : <i className="fas fa-lock"></i>}
					</span>
					<Flags authorizedFlags={[getLoginInfo().NomGrupo]}>
						<span id="beta">
							<UncontrolledTooltip
								placement="left"
								target={`btnComentarios_${row.IdEntidad.toString() + "-" + row.ClaProducto.toString()}`}>
								{translate("MisServiciosPublicados.ttComentarios")}
							</UncontrolledTooltip>
							<Button
								id={`btnComentarios_${row.IdEntidad.toString() + "-" + row.ClaProducto.toString()}`}
								onClick={() => {
									writeLog(
										Formularios.ServiciosPublicados.ClaFuncionalidad,
										Formularios.ServiciosPublicados.Funcionalidades.btnComentarios,
										undefined,
										row,
									);

									this.setState({
										showModalComentarios: true,
										idEntidad: row.IdEntidad,
										claProducto: row.ClaProducto,
										nomEntidad: row.NomEntidad,
										calificacion: row.Calificacion,
										isClone: false,
										isSubscribed: false,
									});
								}}
								color="info"
								size="sm"
								className="btn-icon btn-link ml-0">
								<i className="fas fa-comments" />
							</Button>
						</span>
					</Flags>
				</Col>
			</Row>
		</div>
	);

	componentDidMount() {
		this.load();
	}

	clear() {
		this.load();
		this.setState({
			nuevosTerminos: [],
			clearAutocomplete: !this.state.clearAutocomplete,
		});
	}

	load() {
		let urlApi = ``;
		//Traer terminos existentes del glosario
		urlApi = `${Config.urlApiService}/Glosario`;
		callApi(urlApi, "GET", null, (status, response) => {
			terminosGlosario = response;
		});

		urlApi = `${Config.urlApiService}/Catalogos/11`;
		callApi(urlApi, "GET", null, (status, response) => {
			producto = response;
		});

		urlApi = `${Config.urlApiService}/Entidades/BuscarDisponibles`;

		var body = {
			nombreEntidad: "",
			claTipo: 0,
			claProducto: 0,
			claCliente: getProducto(),
			terminosGlosario: [],
		};

		callApi(urlApi, "POST", body, (status, response) => {
			srcData = this.dgvFillTable(response);

			this.setState({
				srcData: srcData,
				totalCount: srcData.length,
			});
		});

		this.setState({
			isLoaded: true,

			gvEntidades: {
				columns: [
					{ name: "IdEntidad", title: "ID" },
					{ name: "NomProducto", title: translate("ServiciosDisponibles.colProducto") },
					{ name: "Nombre", title: translate("ServiciosDisponibles.colServicio") },
					{ name: "NomTipoEntidad", title: translate("ServiciosDisponibles.colTipo") },
					{ name: "Actions", title: " " },
				],
				// tableColumnExtensions: [
				// 	{ columnName: "IdEntidad", width: "30px", align: "center" },
				// 	{ columnName: "NomProducto", width: "100px", align: "left" },
				// 	{ columnName: "Nombre", width: "auto", wordWrapEnabled: true },
				// 	{ columnName: "NomTipoEntidad", width: "50px" },
				// 	{ columnName: "Actions", width: "75px", align: "right" },
				// ],
				tableColumnExtensions: [
					{ columnName: "IdEntidad", width: "80px", align: "center" },
					{ columnName: "NomProducto", width: "200px", align: "left" },
					{ columnName: "Nombre", width: "auto", wordWrapEnabled: true },
					{ columnName: "NomTipoEntidad", width: "100px" },
					{ columnName: "Actions", width: "200px", align: "right" },
				],
			},
			claIdioma: sessionStorage.getItem("ClaIdioma"),
		});
	}

	dgvFillTable(source) {
		let dgvSource;

		dgvSource = source.map((item, key) => {
			return {
				...item,
				key: key,
				Nombre: item.Titulo,
				Descripcion: item.Descripcion,

				Actions: (
					<div className="actions-right" id={`grdColActionsSrvDisponibles`}>
						<UncontrolledTooltip placement="left" target={`iconEsVerificado${key}`}>
							{item.EsVerificado === 0 ? (
								<div>{translate("ServiciosDisponibles.ttNoVerificado")}</div>
							) : (
								<div>{translate("ServiciosDisponibles.ttVerificado")}</div>
							)}
						</UncontrolledTooltip>
						{item.EsVerificado === 0 ? (
							<span id={`iconEsVerificado${key}`} className="btn-icon btn-link" style={{ color: "yellow" }}>
								<i className="fa fa-exclamation-triangle" />
							</span>
						) : (
							<span id={`iconEsVerificado${key}`} className="btn-icon btn-link text-success">
								<i className="fas fa-check"></i>
							</span>
						)}

						<UncontrolledTooltip placement="left" target={`btnSuscribirse_${key}`}>
							{translate("ServiciosDisponibles.ttSuscribirse")}
						</UncontrolledTooltip>
						<Button
							id={`btnSuscribirse_${key}`}
							onClick={() => {
								if (item.EsVerificado === 0) {
									this.showConfirm(
										() => this.btnSuscribirseClick(item),
										() => this.hideAlertConfirm(),
										sessionStorage.getItem("ClaIdioma") === "1"
											? `El servicio al que se quiere suscribir aún no está verificado`
											: "The service you want to subscribe to is not yet verified",
										sessionStorage.getItem("ClaIdioma") === "1"
											? "¿Desea suscribirse?"
											: "Do you want to subscribe?",
									);
								} else {
									this.btnSuscribirseClick(item);
								}
							}}
							color="info"
							size="sm"
							className="btn-icon btn-link">
							<i className="far fa-hand-point-up" />
						</Button>

						<UncontrolledTooltip placement="left" target={`btnInformacion_${key}`}>
							{translate("ServiciosDisponibles.ttInformacion")}
						</UncontrolledTooltip>
						<Button
							id={`btnInformacion_${key}`}
							onClick={() => {
								writeLog(
									Formularios.ServiciosDisponibles.ClaFuncionalidad,
									Formularios.ServiciosDisponibles.Funcionalidades.btnInformacion,
									undefined,
									item,
								);

								this.setState({
									showModal: true,
									idEntidad: item.IdEntidad,
									claProducto: item.ClaProducto,
								});
							}}
							color="info"
							size="sm"
							className="btn-icon btn-link ml-0">
							<i className="fas fa-info-circle" />
						</Button>
					</div>
				),
			};
		});

		return dgvSource;
	}

	btnSuscribirseClick(item) {
		let data = {};

		if (item.EsPrivado === 1) {
			data = {
				claProductoSolicita: getProducto(),
				claProductoDuenio: item.ClaProducto,
				claEntidad: item.IdEntidad,
				claUsuario: sessionStorage.getItem("ClaUsuario"),
			};

			this.setState({
				showSuscriptionModal: true,
				datosSuscripcion: data,
			});
		} else {
			data = {
				claCliente: getProducto(),
				idEntidad: item.IdEntidad,
				claProducto: item.ClaProducto,
				bajaLogica: 0,
			};

			const urlService = `${Config.urlApiService}/Entidades/Suscripcion`;

			callApi(
				urlService,
				"PUT",
				data,
				(status, response) => {
					writeLog(
						Formularios.ServiciosDisponibles.ClaFuncionalidad,
						Formularios.ServiciosDisponibles.Funcionalidades.btnSuscribirse,
						urlService,
						data,
						response,
						undefined,
						0,
					);
					showSweetAlert(
						sessionStorage.getItem("ClaIdioma") === "1"
							? "La suscripción se realizó con exito"
							: "The subscription was successful",
						"",
						"success",
					);
				},
				Formularios.ServiciosDisponibles.ClaFuncionalidad,
				Formularios.ServiciosDisponibles.Funcionalidades.btnSuscribirse,
			);
		}
		this.hideAlertConfirm();
	}

	btnBuscarClick(value) {
		const urlService = `${Config.urlApiService}/Entidades/BuscarDisponibles`;

		var body = {
			nombreEntidad: value.Nombre,
			claTipo: value.ClaTipoEntidad,
			claProducto: value.ClaProducto,
			claCliente: getProducto(),
			terminosGlosario: this.state.nuevosTerminos,
		};

		callApi(
			urlService,
			"POST",
			body,
			(status, response) => {
				srcData = this.dgvFillTable(response);

				writeLog(
					Formularios.ServiciosDisponibles.ClaFuncionalidad,
					Formularios.ServiciosDisponibles.Funcionalidades.btnBuscar,
					urlService,
					body,
					response,
					undefined,
					0,
				);

				this.setState({
					srcData: srcData,
					buscarData: value,
				});
			},
			Formularios.ServiciosDisponibles.ClaFuncionalidad,
			Formularios.ServiciosDisponibles.Funcionalidades.btnBuscar,
		);
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<>
					<div className="content">
						<Card>
							<CardHeader>
								<Row>
									<Col>
										<CardTitle style={{ fontSize: "16px" }}>
											{translate("ServiciosDisponibles.titulo")}
										</CardTitle>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<div
									className="spinner-border text-info"
									style={{ backgroundColor: "transparent" }}
									role="status">
									{sessionStorage.getItem("ClaIdioma") === "1" ? (
										<span className="sr-only">Cargando...</span>
									) : (
										<span className="sr-only">Loading...</span>
									)}
								</div>
							</CardBody>
						</Card>
					</div>
				</>
			);
		} else {
			return (
				<>
					{getLoginInfo().NomGrupo === "Pruebas" ? (
						<TourVirtual PaginaID="14" ClaTour={Tours.NuevosFeatureServiciosDisponibles} />
					) : (
						<TourVirtual PaginaID="14" ClaTour={Tours.ServiciosDisponibles} />
					)}
					{this.state.showAlert}
					{this.state.showConfirm}
					{sessionStorage.getItem("ClaIdioma") !== this.state.claIdioma && this.load()}
					<NotificationAlert ref={(ref) => (this.notify = ref)} />
					<div className="content">
						<Formik
							id="ctnSrvDisponibles"
							initialValues={{
								ClaTipoEntidad: 0,
								ClaProducto: 0,
								//ClaCategoria: 0,
								ClaSistema: 0,
								Nombre: "",
								TerminosGlosario: "",
							}}
							onSubmit={this.btnBuscarClick}
							onReset={this.clear}>
							{(formikProps) => (
								<Form onSubmit={formikProps.handleSubmit} key="frmEntidadBuscar">
									<Card>
										<CardHeader>
											<FormGroup row>
												<Col md={6}>
													<h1>{translate("ServiciosDisponibles.titulo")}</h1>
												</Col>
												<Col md={6} className="text-right">
													<UncontrolledTooltip target={`btnBuscar`}>
														{translate("ServiciosDisponibles.ttBuscar")}
													</UncontrolledTooltip>
													<Button
														id="btnBuscar"
														color="info"
														size="sm"
														className="btn-simple btn-icon"
														type="submit">
														<i className="fas fa-search" />
													</Button>

													<UncontrolledTooltip target={`btnLimpiar`}>
														{translate("ServiciosDisponibles.ttLimpiar")}
													</UncontrolledTooltip>
													<Button
														onClick={() => {
															writeLog(
																Formularios.ServiciosDisponibles.ClaFuncionalidad,
																Formularios.ServiciosDisponibles.Funcionalidades.btnLimpiar,
															);
															formikProps.handleReset();
														}}
														id="btnLimpiar"
														size="sm"
														color="info"
														className="btn-simple btn-icon"
														type="submit">
														<i className="fas fa-eraser" />
													</Button>
													<UncontrolledTooltip target={`btnNuevo`}>
														{translate("ServiciosDisponibles.btnCrearSolicitud")}
													</UncontrolledTooltip>
													<Button
														id={`btnNuevo`}
														onClick={() => {
															writeLog(
																Formularios.ServiciosDisponibles.ClaFuncionalidad,
																Formularios.ServiciosDisponibles.Funcionalidades.btnNuevo,
																undefined,
															);

															this.setState({
																showSolicitudModal: true,
																idPeticion: 0,
															});
														}}
														color="info"
														size="sm"
														className="btn-simple btnAgregar">
														<i className="fa fa-plus" />
														{translate("ServiciosDisponibles.btnCrearSolicitud")}
													</Button>
												</Col>
											</FormGroup>
										</CardHeader>
										<CardBody>
											<FormGroup row>
												<Col md={2}>
													<Label>{translate("ServiciosDisponibles.lblProducto")}</Label>
													<Field name="ClaProducto" as="select" type="input" className="form-control">
														{producto &&
															producto.length > 0 &&
															producto.map((obj, index) => (
																<option className="ddlOptions" key={index} value={obj.ClaProducto}>
																	{sessionStorage.getItem("ClaIdioma") === "2" &&
																	obj.NomProducto === "SELECCIONE"
																		? "SELECT"
																		: obj.NomProducto}
																</option>
															))}
													</Field>
												</Col>
												<Col md={3}>
													<Label for="Nombre">{translate("ServiciosDisponibles.lblServicio")}</Label>
													<Field
														name="Nombre"
														type="input"
														className="form-control"
														placeholder={translate("ServiciosDisponibles.phServicio")}></Field>
												</Col>
												<Col md={3}>
													<Label for="TerminosGlosario">
														{translate("ServiciosDisponibles.lblGlosario")}
													</Label>
													<Autocomplete
														multiple
														disableCloseOnSelect
														key={this.state.clearAutocomplete}
														id="TerminosGlosario"
														size="small"
														onChange={(_, value) => this.setState({ nuevosTerminos: value })}
														options={terminosGlosario}
														getOptionLabel={(option) => option.NomTermino}
														getOptionSelected={(option, value) => option.ClaTermino === value.ClaTermino}
														renderInput={(params) => (
															<TextField
																{...params}
																multiline
																name="TerminosGlosario"
																placeholder={translate("ServiciosDisponibles.phGlosario")}
																variant="outlined"
																rowsMax="6"
															/>
														)}
													/>
												</Col>
											</FormGroup>
											<Row>
												<Col md={12} className="text-right">
													<label>
														{translate("ServiciosDisponibles.lblTotalDeRegistros")}{" "}
														{this.state.totalCount}
													</label>
												</Col>
											</Row>
											<Grid rows={this.state.srcData} columns={this.state.gvEntidades.columns}>
												<SortingState
													sorting={this.state.sorting}
													onSortingChange={(value) => {
														this.setState({ sorting: value });
													}}
												/>

												<RowDetailState />

												<PagingState
													currentPage={this.state.currentPage}
													onCurrentPageChange={(value) => {
														this.setState({ currentPage: value });
													}}
													pageSize={this.state.pageSize}
													onPageSizeChange={(value) => {
														this.setState({ pageSize: value });
													}}
												/>

												<IntegratedSorting />
												<IntegratedPaging />

												<Table
													tableComponent={tableComponent}
													columnExtensions={this.state.gvEntidades.tableColumnExtensions}
													headComponent={headerComponent}
													messages={{ noData: gvConfig.noData }}
												/>

												<TableHeaderRow showSortingControls={true} />
												<TableRowDetail contentComponent={this.RowDetail} />

												<PagingPanel pageSizes={gvConfig.pageSizes} />
											</Grid>
										</CardBody>
									</Card>
								</Form>
							)}
						</Formik>
					</div>
					<Modal centered isOpen={this.state.showModal} size="lg">
						<EntidadMtto
							hideModal={this.hideModal}
							idEntidad={this.state.idEntidad}
							claProducto={this.state.claProducto}
							readOnly={true}
							className="mdlEntidad"
						/>
					</Modal>
					<Modal centered isOpen={this.state.showSuscriptionModal} size="md">
						<SuscripcionMtto
							hideSuscriptionModal={this.hideSuscriptionModal}
							data={this.state.datosSuscripcion}
							className="mdlSuscripcion"
						/>
					</Modal>

					<Modal centered={true} isOpen={this.state.showSolicitudModal} size="lg">
						<SolicitudServicioMtto
							hideModal={this.hideSolicitudServicio}
							pantallaEntidad={true}
							idPeticion={this.state.idPeticion}
							claProducto={getProducto()}
						/>
					</Modal>
					<Flags authorizedFlags={[getLoginInfo().NomGrupo]}>
						<Modal centered={true} isOpen={this.state.showModalComentarios} size="md">
							<ComentariosMtto
								hideModalComentarios={this.hideModalComentarios}
								idEntidad={this.state.idEntidad}
								claProducto={this.state.claProducto}
								calificacion={this.state.calificacion}
								nomEntidad={this.state.nomEntidad}
								readOnly={true}
								className="mdlEntidad"
							/>
						</Modal>
					</Flags>
				</>
			);
		}
	}
}
