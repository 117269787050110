const MisServiciosSuscritos = {
	titulo: "Mis Servicios suscritos",
	lblTipo: "Tipo",
	lblServicio: "Servicio",
	lblGlosario: "Glosario",
	btnAgregar: " Agregar",
	lblTotalDeRegistros: "Total de registros",
	lblProducto: "Producto",
	colNombre: "Nombre",
	colServicio: "Servicio",
	colProducto: "Producto",
	phNombre: "Nombre, descripción o número del servicio",
	phTerminosRelacionados: "Términos relacionados",
	ttLimpiar: "Limpiar",
	ttBuscar: "Buscar",
	ttVerificado: "Servicio verificado",
	ttNoVerificado: "El servicio no está verificado",
	ttEditar: "Editar",
	ttComentarios: "Comentarios",
	ttEliminar: "Eliminar suscripción",
	ttInformacion: "Información",
	alertEliminarSuscripcion: "You unsubscribed successfully",
};

export default MisServiciosSuscritos;
