/** @format */

import React from "react";

import { callApi, showAlert, showConfirm } from "utils/Utilerias";
import { Config } from "utils/Config";
import NotificationAlert from "react-notification-alert";
import ReactTable from "react-table";
import { Button, Card, CardBody, Row, UncontrolledTooltip } from "reactstrap";
import PropTypes from "prop-types";

let srcData = [];
let tableWidth;

export default class BasesDatos extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			alert: null,
		};
		this.llenarTabla = this.llenarTabla.bind(this);
	}

	componentDidMount() {
		this.llenarTabla();
	}

	/**
	 * Función 	que oculta el modal
	 * correcta y refresca la pantalla
	 */
	hideModal(isOk) {
		this.llenarTabla();
		this.setState({
			showModal: false,
		});

		if (isOk) {
			var options = {
				place: "br",
				message: "Operación realizada correctamente.",
				type: "success",
				autoDismiss: 3,
			};

			this.notify.notificationAlert(options);
		}
	}

	/**
	 * Funcion para ocultar la alerta/mensaje que fue activado
	 */
	hideAlert() {
		this.setState({ showAlert: null, showConfirm: null });
	}

	/**
	 * Función que se muestra un mensaje de éxito en la operación
	 * @param {String} title titulo que tendrá el mensaje mostrado
	 * @param {String} text contenido del mensaje
	 * @param {String} icon icono que mostrará el mensaje
	 */
	successAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Función que desactiva a un termino
	 * @param {Object} objBaseDatos Termino que se va a desactivar
	 */
	desactivarBaseDatos(objBaseDatos) {
		try {
			let urlWebService = `${Config.urlApiService}/BasesDatos/${objBaseDatos.ClaServidor}/${objBaseDatos.ClaBaseDatos}`;

			let data = {
				claServidor: objBaseDatos.ClaServidor,
				claBaseDatos: objBaseDatos.ClaBaseDatos,
				nomBaseDatos: objBaseDatos.NomBaseDatos,
				descripcion: objBaseDatos.Descripcion,
				bajaLogica: 1,
			};

			callApi(urlWebService, "PUT", data, (status, response) => {
				this.hideAlert();

				if (response.claBaseDatos === undefined) {
					throw Error(response.message);
				} else {
					this.llenarTabla();
				}

				this.setState({
					isLoaded: true,
					objBaseDatos: response,
				});
			}).catch((oErr) => {
				this.hideAlert();
				this.successAlert("Error", oErr, "error");
			});

			this.llenarTabla();
		} catch (err) {
			alert(err);
		}
	}

	/**
	 * Función 	para llenar la tabla incial
	 */
	llenarTabla() {
		let urlWebService = Config.urlApiService + "/BasesDatos/" + this.props.claServidor;
		let data = {};

		callApi(urlWebService, "GET", data, (status, reponse) => {
			if (reponse.length <= 0) {
				srcData = [];
			} else {
				srcData = reponse.map((obj, key) => {
					return {
						...obj,
						CustomCell: (
							<div className="w-100 p-3">
								<Row>
									<h4>{obj.NomBaseDatos}&nbsp;&nbsp; </h4>
								</Row>
								<Row>
									<div style={{ wordWrap: "break-word" }}>{obj.Descripcion}</div>
								</Row>
							</div>
						),
						Actions: (
							<div className="actions-center">
								<UncontrolledTooltip target={`btnEliminar_${key}`}> Eliminar</UncontrolledTooltip>
								<Button
									onClick={() => {
										this.showConfirm(
											() => this.desactivarBaseDatos(obj),
											() => this.hideAlert(),
											`¿Desea desactivar la base de datos?`,
											obj.NomTermino,
										);

										this.setState({
											claBaseDatos: obj.ClaBaseDatos,
										});
									}}
									color="danger"
									size="sm"
									className="btn-icon btn-link"
									id={`btnEliminar_${key}`}>
									<i className="fa fa-eraser" />
								</Button>
							</div>
						),
					};
				});
			}

			this.setState({
				isLoaded: true,
				data: srcData,
			});
		});
	}

	/**
	 * Función 	que muestra un mensaje en el centro de la pantalla con poder de
	 * elegir una opción
	 * @param {Function} onConfirm Función que se ejecuta al presionar el boton de confirmar
	 * @param {Function} OnCancel Función que se ejecuta al presionar el botón de cancelar
	 * @param {String} title titulo del mensaje
	 * @param {String} text cuerpo del mensaje
	 */
	showConfirm(onConfirm, onCancel, title, text) {
		const warningAlert = showConfirm(onConfirm, onCancel, title, text);
		this.setState({
			showConfirm: warningAlert,
		});
	}

	render() {
		return (
			<>
				<NotificationAlert ref={(ref) => (this.notify = ref)} style={{ "z-index": "1" }} />
				{this.state.showAlert}
				{this.state.showConfirm}
				<div className="content">
					<Card>
						<CardBody>
							<div
								ref={(el) => {
									if (!el) return;
									tableWidth = el.getBoundingClientRect().width;
								}}>
								<ReactTable
									data={srcData}
									filterable={false}
									previousText="Anterior"
									nextText="Siguiente"
									loadingText="Cargando..."
									noDataText="No se encontró información"
									pageText="Página"
									ofText="de"
									rowsText="filas"
									columns={[
										{
											Header: "Bases de datos",
											accessor: "CustomCell",
											style: { whiteSpace: "unset" },
											width: Math.round(tableWidth * 0.95),
										},
										{
											accessor: "Actions",
											sortable: false,
											filterable: false,
											width: Math.round(tableWidth * 0.05),
										},
									]}
									pageSize={srcData.length}
									showPaginationTop={false}
									showPaginationBottom={false}
									className="-highlight secondary-pagination"
								/>
							</div>
						</CardBody>
					</Card>
				</div>
			</>
		);
	}
}

BasesDatos.propTypes = {
	claServidor: PropTypes.any,
};
