const SolicitudMtto = {
	titulo: "Service Request",
	lblTitulo: "Title",
	lblProducto: "Product",
	lblDescripcion: "Description",
	lblEntradasEsperadas: "Expected input",
	lblResultadoEsperado: "Expected output",

	phTitulo: "Request Title",
	phDescripcion: "Detailed description of what is expected in the service",
	phEntradasEsperadas: "Description / suggestion of what is expected to be sent to the service",
	phResultadoEsperado: "Description / suggestion of what you expect to receive the service",

	ttTitulo: "Request Title",
	ttProducto: "Name of the product to which the request will be made",
	ttDescripcion: "Description of what the service is",
	ttEntradasEsperadas: "Detailed description of the input",
	ttResultadoEsperado: "Detailed description of the expected result",

	colNombre: "Name",
	colDescripcion: "Description",
	colTipo: "Type",

	ttEntradaNombre: "Name of the input",
	ttEntradaDescripcion: "Description of what the input is",
	ttEntradaTipo: "Input data type",

	ttSalidaNombre: "Output name",
	ttSalidaDescripcion: "Description of what the output is",
	ttSalidaTipo: "Output data type",

	btnGuardar: "Save",
	btnCancelar: "Cancel",
	btnCerrar: "Close",

	alertEntradasSalidas: "You must select a data type on all inputs and outputs",
	alertProductoSolicitud: "You must select the product to which the request will be made",
	notificacionOperacionExitosa: "Successful operation",
	alertRegistroGuardado: "Record saved successfully",
	alertCambioGuardado: "Changes made successfully",
	alertEstatusCambiado: "Status changed successfully",
	noInfo: "No information found",

	navGeneral: "General",
	navEntradas: "Inputs",
	navSalidas: "Outputs",

	cargando: "Loading...",

	ttEnviarSolicitud: "Send request",
	alertEnviarSolicitud: "Do you want to send the request? ",
	btnEnviar: "Send",

	ttRechazarSolicitud: "Reject request",
	alertRechazarSolicitud: "Do you want to reject the request?",
	btnRechazar: "Reject",
	phProducto: "Product to which it belongs",
	lblAgregarParametro: "Add a parameter",
};

export default SolicitudMtto;
