import React from "react";
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Label, UncontrolledTooltip, Button, Modal } from "reactstrap";
import Datetime from "react-datetime";
import IndicadoresGenerales from "./IndicadoresGenerales";
import IndicadoresEntidad from "./IndicadoresEntidad";
import Errores from "./Errores";
import ServiciosMenosUtilizados from "./ServiciosMenosUtilizados";
import ServiciosMasUtilizados from "./ServiciosMasUtilizados";
import Peticiones from "./Peticiones";
import DesempenoEquipoProducto from "./DesempenoEquipoProducto";
import { getProducto, writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";
import ServiciosMayorTiempo from "./ServiciosMayorTiempo";
import FiltroMtto from "./FiltroMtto";
import TourVirtual from "components/Tour/TourVirtual";
import { Tours } from "../../utils/Config";
import translate from "utils/translate";

const dHoy = new Date(),
	d6DiasAntes = new Date(dHoy.getFullYear(), dHoy.getMonth(), dHoy.getDate() - 6);
export default class DashboardProveedor extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			claProducto: parseInt(getProducto()),
			claCliente: 0,
			nomUsuario: "",
			nomEntidad: "",
			fechaDet: undefined,
			fechaInicio: d6DiasAntes,
			fechaFin: dHoy,
			showModal: false,
			showModalFiltro: false,
		};

		this.chartRef = React.createRef();
		this.clear = this.clear.bind(this);
		this.clearEquipo = this.clearEquipo.bind(this);
		this.filtrarCliente = this.filtrarCliente.bind(this);
		this.filtrarUsuario = this.filtrarUsuario.bind(this);
		this.hideModalEntidad = this.hideModalEntidad.bind(this);
		this.hideModalFiltro = this.hideModalFiltro.bind(this);
		this.handleFechaFin = this.handleFechaFin.bind(this);
		this.handleFechaInicio = this.handleFechaInicio.bind(this);
	}

	clear() {
		this.setState({
			fechaInicio: d6DiasAntes,
			fechaFin: dHoy,
			claCliente: 0,
			nomUsuario: "",
		});
	}

	clearEquipo() {
		this.setState({
			nomUsuario: "",
		});
	}

	filtrarUsuario(nomUsuario = "") {
		this.setState({ nomUsuario: nomUsuario });
	}

	filtrarCliente(claCliente = 0) {
		this.setState({ claCliente: claCliente });
	}

	hideModalEntidad(hide = true, nomEntidad = "", fecha = undefined) {
		this.setState({
			nomEntidad: nomEntidad,
			fechaDet: fecha,
			showModal: !hide,
		});
	}

	hideModalFiltro(hide = true, claCliente = 0) {
		hide
			? this.setState({ showModalFiltro: !hide, claCliente: claCliente })
			: this.setState({ showModalFiltro: !hide });
	}

	handleFechaInicio(date) {
		let fechaFinal = this.state.fechaFin !== undefined ? this.state.fechaFin : dHoy;
		//let fechaInicial = this.state.fechaInicio !== undefined ? this.state.fechaInicio : d6DiasAntes;
		let d15DiasAntes = new Date(fechaFinal.getFullYear(), fechaFinal.getMonth(), fechaFinal.getDate() - 15);
		let valid = true;
		if (date._d < d15DiasAntes) {
			date = d15DiasAntes;
			valid = false;
		}
		if (date._d > fechaFinal) {
			date = fechaFinal;
			valid = false;
		}
		this.setState({ fechaInicio: valid ? date._d : date });
	}

	handleFechaFin(date) {
		let fechaFinal = this.state.fechaFin !== undefined ? this.state.fechaFin : dHoy;
		let fechaInicial = this.state.fechaInicio !== undefined ? this.state.fechaInicio : d6DiasAntes;
		let d15DiasDespues = new Date(fechaFinal.getFullYear(), fechaFinal.getMonth(), fechaFinal.getDate() + 15);
		let valid = true;
		if (date._d > dHoy) {
			date = dHoy;
			valid = false;
		} else if (date._d > d15DiasDespues) {
			date = d15DiasDespues;
			valid = false;
		}
		if (date._d < fechaInicial) {
			date = fechaInicial;
			valid = false;
		}
		this.setState({ fechaFin: valid ? date._d : date });
	}

	render() {
		return (
			<div className="content">
				<TourVirtual PaginaID="20" ClaTour={Tours.DashboardProveedor} />
				<Row>
					<Col md={6}>
						<h1>{translate("DashboardProveedor.titulo")}</h1>
					</Col>
				</Row>
				<Row>
					<div className="float-left w-100" style={{ paddingRight: "10px", paddingBottom: "10px" }}>
						<Card>
							<CardBody>
								<IndicadoresGenerales
									claProducto={this.state.claProducto}
									filtrarCliente={this.filtrarCliente}
								/>
							</CardBody>
						</Card>
					</div>
				</Row>
				<Row>
					<div className="float-left w-100" style={{ paddingRight: "10px", paddingBottom: "10px" }}>
						<Card>
							<CardHeader>
								<FormGroup row>
									<Col md={3}>
										<Label for="fechaInicio">{translate("DashboardProveedor.lblFechaDesde")}</Label>
										<Datetime
											name="fechaInicio"
											inputProps={{
												placeholder: "Fecha inicial",
											}}
											value={this.state.fechaInicio}
											onChange={this.handleFechaInicio}
											timeFormat={false}
											locale="es"
											id="fechaInicio"
										/>
									</Col>
									<Col md={3}>
										<Label for="fechaFin">{translate("DashboardProveedor.lblFechaHasta")}</Label>
										<Datetime
											inputProps={{ placeholder: "Fecha final" }}
											id="fechaFin"
											name="fechaFin"
											value={this.state.fechaFin}
											onChange={this.handleFechaFin}
											timeFormat={false}
											locale="es"
										/>
									</Col>
									<Col md={6} className="text-right">
										<Button
											id="btnFiltrar"
											name="btnFiltrar"
											color="info"
											size="sm"
											className="btn-simple"
											onClick={() => this.hideModalFiltro(false)}>
											{translate("DashboardProveedor.bntCliente")} <i className="fas fa-filter"></i>
										</Button>
										<UncontrolledTooltip placement="left" target={`btnLimpiar`}>
											{translate("DashboardProveedor.ttLimpiar")}
										</UncontrolledTooltip>
										<Button
											onClick={() => {
												writeLog(
													Formularios.DashboardProveedor.ClaFuncionalidad,
													Formularios.DashboardProveedor.Funcionalidades.btnLimpiar,
												);
												this.clear();
											}}
											id="btnLimpiar"
											color="info"
											size="sm"
											className="btn-simple btn-icon">
											<i className="fas fa-eraser" />
										</Button>
									</Col>
								</FormGroup>
							</CardHeader>
							<CardBody>
								<h2>{translate("DashboardProveedor.lblErroresPresentados")}</h2>
								<Row className="pb-4">
									<Col>
										<Errores
											claProducto={this.state.claProducto}
											claCliente={this.state.claCliente}
											hideModalEntidad={this.hideModalEntidad}
											fechaInicio={this.state.fechaInicio}
											fechaFin={this.state.fechaFin}
										/>
									</Col>
								</Row>

								<h2>{translate("DashboardProveedor.lblTop5MayorTiempo")}</h2>
								<Row className="pb-4">
									<Col>
										<ServiciosMayorTiempo
											claProducto={this.state.claProducto}
											claCliente={this.state.claCliente}
											hideModalEntidad={this.hideModalEntidad}
											fechaInicio={this.state.fechaInicio}
											fechaFin={this.state.fechaFin}
										/>
									</Col>
								</Row>

								<h2>{translate("DashboardProveedor.lblTop5MenosUtilizados")}</h2>
								<Row className="pb-4">
									<Col>
										<ServiciosMenosUtilizados
											claCliente={this.state.claCliente}
											hideModalEntidad={this.hideModalEntidad}
											fechaInicio={this.state.fechaInicio}
											fechaFin={this.state.fechaFin}
										/>
									</Col>
								</Row>

								<h2>{translate("DashboardProveedor.lblTop5MasUtilizados")}</h2>
								<Row className="pb-4">
									<Col>
										<ServiciosMasUtilizados
											claProducto={this.state.claProducto}
											claCliente={this.state.claCliente}
											hideModalEntidad={this.hideModalEntidad}
											fechaInicio={this.state.fechaInicio}
											fechaFin={this.state.fechaFin}
										/>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</div>
				</Row>

				<Row>
					<div className="float-left w-100" style={{ paddingRight: "10px", paddingBottom: "10px" }}>
						<Card>
							<CardBody>
								<h2>{translate("DashboardProveedor.lblDesempenoEquipo")}</h2>
								<Row>
									<Col md={12}>
										<DesempenoEquipoProducto
											claProducto={this.state.claProducto}
											nomUsuario={this.state.nomUsuario}
											fechaInicio={this.state.fechaInicio}
											fechaFin={this.state.fechaFin}
										/>
									</Col>
								</Row>
								<Row>
									<Col md={4}>
										<h3>{translate("DashboardProveedor.lblPeticionesPorUsuario")}</h3>
										<Peticiones
											claProducto={this.state.claProducto}
											fechaInicio={this.state.fechaInicio}
											fechaFin={this.state.fechaFin}
											filtrarUsuario={this.filtrarUsuario}
										/>
									</Col>
									<Col md={8}>
										<h3>{translate("DashboardProveedor.lblErroresTitulo")}</h3>
										<Errores
											claProducto={this.state.claProducto}
											nomUsuario={this.state.nomUsuario}
											fechaInicio={this.state.fechaInicio}
											fechaFin={this.state.fechaFin}
											hideModalEntidad={this.hideModalEntidad}
										/>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</div>
				</Row>

				<Modal centered isOpen={this.state.showModal} size="lg">
					<IndicadoresEntidad
						claProducto={this.state.claProducto}
						claCliente={this.state.claCliente}
						nomEntidad={this.state.nomEntidad}
						fechaDet={this.state.fechaDet}
						hideModalEntidad={this.hideModalEntidad}
					/>
				</Modal>

				<Modal centered isOpen={this.state.showModalFiltro} size="lg">
					<FiltroMtto
						claProducto={this.state.claProducto}
						//claCliente={this.state.claCliente}
						filtrar={this.hideModalFiltro}
						fechaInicio={this.state.fechaInicio}
						fechaFin={this.state.fechaFin}
					/>
				</Modal>
			</div>
		);
	}
}
