import React from "react";
import { Card, CardBody, CardFooter, Form, FormGroup, Row, Col, Button, Label } from "reactstrap";
import { Formik, Field } from "formik";
import { Config } from "utils/Config";
import { callApi, showSweetAlert, writeLog } from "utils/Utilerias";
import { Formularios } from "utils/funcionalidad";
import PropTypes from "prop-types";
import translate from "utils/translate";

export default class SuscriptionMtto extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			datos: {},
		};

		this.btnCancelar_Click = this.btnCancelar_Click.bind(this);
		this.handleSubmitAceptar = this.handleSubmitAceptar.bind(this);
	}

	componentDidMount() {
		if (this.props.data !== null) {
			let method = "POST";
			let data = {
				claProductoSolicita: this.props.data.claProductoSolicita,
				claProductoDuenio: this.props.data.claProductoDuenio,
				claEntidad: this.props.data.claEntidad,
				claUsuario: this.props.data.claUsuario,
			};

			let urlWebService = Config.urlApiService + "/Entidades/ConsultarDatosSuscripcion";
			callApi(urlWebService, method, data, (status, res) => {
				if (status === 200 && res.length > 0) {
					this.setState({
						isLoaded: true,
						datos: res[0],
					});
				} else {
					showSweetAlert(translate("SuscripcionMtto.alertNoSeConsulta"), "", "warning", this.btnCancelar_Click);

					this.setState({
						isLoaded: true,
						datos: {},
					});
				}
			});
		}
	}

	/**
	 * Función del botón para cancelar la operación
	 * y ocultar el modal sin guardar ningún cambio
	 */
	btnCancelar_Click() {
		this.props.hideSuscriptionModal();
	}

	/**
	 * Función del botón para enviar correo a dueño del producto con el mensaje indicado
	 * @param {JSON} value los valores del formulario
	 */
	handleSubmitAceptar(value) {
		//Registrar en la tabla LogSuscripción
		let method = "POST";
		let datos = {
			claProducto: this.state.datos.ClaProductoDuenio,
			claEntidad: this.state.datos.ClaEntidad,
			claCliente: this.state.datos.ClaProductoSolicita,
			mensajeSolicitud: value.mensaje,
		};

		let urlWebService = Config.urlApiService + "/Entidades/LogSuscripcion";
		callApi(urlWebService, method, datos, (status, response) => {
			writeLog(
				Formularios.SuscripcionMtto.ClaFuncionalidad,
				Formularios.SuscripcionMtto.Funcionalidades.btnGuardar,
				urlWebService,
				datos,
				response,
				"Solicitud registrada con exito",
				0,
			);

			showSweetAlert(
				translate("SuscripcionMtto.alertRegistroExitoso"),
				translate("SuscripcionMtto.alertRegistroExitosoDescripcion"),
				"success",
				this.btnCancelar_Click,
			);
		});
	}

	render() {
		if (!this.state.isLoaded) {
			return <div>Cargando...</div>;
		}
		return (
			<>
				<div className="content">
					<Formik
						initialValues={{
							mensaje: "",
						}}
						onSubmit={this.handleSubmitAceptar}>
						{(props) => (
							<Form onSubmit={props.handleSubmit}>
								<Card>
									<CardBody>
										<h2>{translate("SuscripcionMtto.titulo")}</h2>
										<h5>
											{translate("SuscripcionMtto.lblMsj")}
											<b> {this.state.datos.NomEntidad}</b>
										</h5>
										<Row>
											<Col md={12}>
												<FormGroup>
													<Label for="mensaje">
														<h5 className="mb-0">{translate("SuscripcionMtto.lblMotivo")}</h5>
													</Label>
													<Field
														as="textarea"
														name="mensaje"
														rows={6}
														className="form-control"
														placeholder={translate("SuscripcionMtto.phMotivo")}
														required
													/>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
									<CardFooter>
										<Row>
											<Col md={12} className="text-right">
												<Button
													id="btnEnviar"
													className="animation-on-hover"
													color="success"
													size="sm"
													type="submit">
													{translate("SuscripcionMtto.btnEnviar")}
												</Button>
												<Button
													id="btnCancelar"
													className="animation-on-hover"
													color="default"
													size="sm"
													onClick={() => {
														writeLog(
															Formularios.SuscripcionMtto.ClaFuncionalidad,
															Formularios.SuscripcionMtto.Funcionalidades.btnCancelar,
														);
														this.btnCancelar_Click();
													}}>
													{translate("SuscripcionMtto.btnCancelar")}
												</Button>
											</Col>
										</Row>
									</CardFooter>
								</Card>
							</Form>
						)}
					</Formik>
				</div>
			</>
		);
	}
}

SuscriptionMtto.propTypes = {
	data: PropTypes.any,
	hideSuscriptionModal: PropTypes.func,
};
