import React from "react";
import { Card, CardHeader, CardBody, Button, UncontrolledTooltip, Row, Col, Modal } from "reactstrap";
import { getProducto, callApi, showAlert, writeLog } from "utils/Utilerias";
import { Config } from "utils/Config";
import { Link } from "react-router-dom";
import EntidadMtto from "../Entidades/EntidadMtto";
import PropTypes from "prop-types";
import { Formularios } from "utils/funcionalidad";
import translate from "utils/translate";

let srcData = [];
let formularioDet = [];

export default class PendientesPorRealizar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			peticiones: [],
		};

		this.load = this.load.bind(this);
		this.hideModal = this.hideModal.bind(this);
		this.peticionCallBack = this.peticionCallBack.bind(this);
		this.editarEstatus = this.editarEstatus.bind(this);
		this.rechazarPeticion = this.rechazarPeticion.bind(this);
		this.consultaPeticion = this.consultaPeticion.bind(this);
	}

	selectColorBadge(nomEstatus) {
		switch (nomEstatus.toUpperCase()) {
			case "EN TIEMPO":
				return "info";

			case "ATRASADO":
				return "danger";

			default:
				return "info";
		}
	}

	componentDidMount() {
		this.load();
	}

	load() {
		//Help tooltips
		let urlService = `${Config.urlApiService}/CatFormularioDet/5`;
		callApi(urlService, "GET", null, (status, response) => {
			formularioDet = response;
		});

		//Obtener información
		urlService = `${Config.urlApiService}/Home/PendientesVerificar/${getProducto()}`;

		callApi(urlService, "GET", null, (status, response) => {
			if (status === 200) {
				srcData = response.map((obj) => {
					return {
						Titulo: obj.Titulo,
						Verificar: (
							<>
								<UncontrolledTooltip placement="right" target={`btnComenzarPeticion`}>
									<span>{"Información del servicio"}</span>
								</UncontrolledTooltip>
								<Button
									id="btnComenzarPeticion"
									className="animation-on-hover float-right btn btn-link btn-img"
									color="info"
									size="sm"
									onClick={() => this.consultaPeticion(obj)}
									hidden={!this.props.esAdmin}>
									<i className="fas fa-info-circle" />
									{/* <i className="fas fa-clipboard-list" /> */}
								</Button>
							</>
						),
						NomProducto: obj.NomProducto,
						FechaPromesa: obj.FechaPromesa !== null ? obj.FechaPromesa.toDateNoTime() : null,
						Estado: obj.Estado,
						ClaProducto: obj.ClaProducto,
						//ClaUsuarioAsignacion: obj.ClaUsuarioAsignacion,
						//NomUsuarioAsignacion: obj.NomUsuarioAsignacion,
						NomEntidad: obj.NomEntidad,
						IdEntidad: obj.IdEntidad,
						IdPeticion: obj.IdPeticion,
						NomCliente: obj.NomCliente,
					};
				});
				this.setState({
					isLoaded: true,
					peticiones: srcData,
				});
			}
		});
	}

	consultaPeticion(obj) {
		let urlWebService = Config.urlApiService + "/SolicitudServicio/" + obj.ClaProducto + "/" + obj.IdPeticion;
		let data = {
			...obj,
		};

		callApi(
			urlWebService,
			"GET",
			data,
			(status, response) => {
				writeLog(
					Formularios.PendientesVerificacion.ClaFuncionalidad,
					Formularios.PendientesVerificacion.Funcionalidades.btnComenzarPeticion,
					urlWebService,
					data,
					response,
					undefined,
					0,
				);

				this.setState({
					showModal: true,
					idEntidad: obj.IdEntidad,
					claProducto: obj.ClaProducto,
					objPeticion: response.recordset[0],
				});
			},
			Formularios.PendientesVerificacion.ClaFuncionalidad,
			Formularios.PendientesVerificacion.Funcionalidades.btnComenzarPeticion,
		);
	}

	renderDetTexto(nomControl) {
		let detalle = formularioDet.filter((detalle) => detalle.NomControl === nomControl);
		if (detalle[0]) return detalle[0].Texto;
		else return "No se encontró descripción";
	}

	/**
	 * Muestra los prompts de alertas
	 */
	showAlert(title, text, icon) {
		const msgAlert = showAlert(() => this.hideAlert(), title, text, icon);

		this.setState({
			showAlert: msgAlert,
		});
	}

	/**
	 * Oculta los promts de alertas
	 */
	hideAlert() {
		this.setState({
			showAlert: null,
			showConfirm: null,
		});
	}

	/**
	 * Función 	que oculta el modal
	 * @param {Boolean} isOk establece si la operación fue realizada de manera
	 * correcta y refresca la pantalla
	 */
	hideModal(isOk) {
		if (isOk) {
			this.load();
		}

		this.setState({
			showModal: false,
		});
	}

	/**
	 *
	 * @param {JSON} response  respuesta recibida al guardar la entidad en ENTIDADMTTO
	 * @param {JSON} objPeticion objeto JSON que se va a guardar la entidad asociada a la peticion
	 */
	peticionCallBack(response, objPeticion, rechazado = false, conservarEstatus = false) {
		if (response.idEntidad && response.idEntidad > 0) {
			objPeticion.IdEntidad = response.idEntidad;
		}

		if (conservarEstatus) {
			this.editarEstatus(objPeticion, true, conservarEstatus);
		} else {
			if (!rechazado) {
				this.editarEstatus(objPeticion);
			} else {
				this.rechazarPeticion(objPeticion);
			}
		}
	}

	/**
	 *
	 * @param {JSON} obj objeto al que se le modificara el estatus o asociara una entidad
	 * @param {Boolean} documentado solo llegará como true cuando venga del callback exitosamente,
	 * quiere decir que ya se documentó en la pantalla de entidades
	 * @param {Boolean} conservarEstatus solo llegará como true cuando en entidad mtto se haga una modificacion, no una inserción nueva
	 */
	editarEstatus(obj, conservarEstatus = false) {
		let urlWebService = Config.urlApiService + "/SolicitudServicio/" + obj.ClaProducto + "/" + obj.IdPeticion;
		let data = {
			...obj,
		};

		if (obj.FechaPromesa !== null) {
			obj.FechaPromesa = new Date(obj.FechaPromesa);
			data.FechaPromesa = obj.FechaPromesa.toSqlDateTime();
			obj.FechaCaptura = new Date(obj.FechaCaptura);
			data.FechaCaptura = obj.FechaCaptura.toSqlDateTime();
		} else {
			data.FechaPromesa = null;
		}

		data.BajaLogica = 0;
		data.IdEntidad = obj.IdEntidad;
		if (!conservarEstatus) {
			data.ClaEstatus = 8;
		}

		callApi(urlWebService, "PUT", data, (status) => {
			if (status === 200) {
				if (!conservarEstatus) {
					this.showAlert("", "Estatus cambiado con éxito", "success");
				}
				this.load();
			} else {
				this.showAlert("", "Algo salió mal", "warning");
				this.load();
			}
		});
	}

	/**
	 *
	 * @param {JSON} obj objeto al que se le modificara el estatus
	 */
	rechazarPeticion(obj) {
		let urlWebService = Config.urlApiService + "/SolicitudServicio/" + obj.ClaProducto + "/" + obj.IdPeticion;
		let data = {
			...obj,
		};

		if (obj.FechaPromesa !== null) {
			obj.FechaPromesa = new Date(obj.FechaPromesa);
			data.FechaPromesa = obj.FechaPromesa.toSqlDateTime();
			obj.FechaCaptura = new Date(obj.FechaCaptura);
			data.FechaCaptura = obj.FechaCaptura.toSqlDateTime();
		} else {
			data.FechaPromesa = null;
		}

		data.BajaLogica = 0;

		if (obj.IdEntidad) {
			data.IdEntidad = obj.IdEntidad;
		}

		data.ClaEstatus = obj.ClaEstatus = 5;

		callApi(urlWebService, "PUT", data, (status) => {
			if (status === 200) {
				this.showAlert("", "Estatus cambiado con éxito", "success");
				this.cargaPM();
			} else {
				this.showAlert("", "Algo salió mal", "warning");
			}
		});

		this.load();
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<div className="spinner-border text-info" role="status">
					<span className="sr-only">Cargando...</span>
				</div>
			);
		} else {
			return (
				<>
					{this.state.showAlert}
					<Card className="card-home" id="ctnPendientesVerificacion">
						<CardHeader>
							<UncontrolledTooltip placement="right" target={`helpPendientesVerificacion`}>
								<span>{translate("HomeDev.ttMisServiciosPendientesVerif")}</span>
							</UncontrolledTooltip>
							<Row>
								<Col md={9}>
									<h2>
										{translate("HomeDev.lblMisServiciosPendientesVerif")}
										<span id="helpPendientesVerificacion">
											<i className="far fa-question-circle"></i>
										</span>
									</h2>
								</Col>
								<Col md={3}>
									<Link to="/admin/SolicitudServicio">
										<Button
											id="btnVerTodos"
											className="btn-link btn-home float-right"
											size="sm"
											onClick={() => {
												writeLog(
													Formularios.PendientesVerificacion.ClaFuncionalidad,
													Formularios.PendientesVerificacion.Funcionalidades.btnVerTodos,
												);
												this.setState({ showSolitudServicios: true });
											}}>
											{translate("HomeDev.lblVerTodos")}
										</Button>
									</Link>
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							<div className="pendientesVerificacion">
								<table className="table table-sm table-noBorder">
									<thead>
										<tr>
											<th className="w-40">{translate("HomeDev.colSolicitud")}</th>
											<th className="w-20">{translate("HomeDev.colEstatus")}</th>
											<th className="w-20">{translate("HomeDev.colCliente")}</th>
											<th
												className="w-15"
												style={{
													textAlign: "center",
												}}>
												{translate("HomeDev.colFechaPromesa")}
											</th>
											<th className="w-10"></th>
										</tr>
									</thead>
									<tbody>
										{(this.state.peticiones.length > 0 &&
											this.state.peticiones.map((peticion, index) => {
												return (
													<tr key={`pet_${index}`}>
														<td>{peticion.Titulo}</td>
														<td>
															{/* <Badge color={this.selectColorBadge(peticion.Estado)}> */}
															{peticion.Estado}
															{/* </Badge> */}
														</td>
														<td>
															{/* {this.props.esAdmin ? peticion.NomUsuarioAsignacion : peticion.NomCliente} */}
															{peticion.NomProducto}
														</td>
														<td
															style={{
																textAlign: "center",
															}}>
															{peticion.FechaPromesa}
														</td>
														<td className="w-10 text-right"> {peticion.Verificar}</td>
													</tr>
												);
											})) || (
											<tr>
												<td colSpan={5} style={{ textAlign: "center", height: "105px" }}>
													<p>
														{translate("HomeDev.lblSolicitudesPendientes")}
														<span className="btn-icon btn-link text-success">
															<i className="fas fa-check"></i>
														</span>
													</p>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</CardBody>
					</Card>
					<Modal centered isOpen={this.state.showModal} size="lg">
						<EntidadMtto
							hideModal={this.hideModal}
							idEntidad={this.state.idEntidad}
							claProducto={this.state.claProducto}
							readOnly={false}
							objPeticion={this.state.objPeticion}
							peticionCallBack={this.peticionCallBack}
							className="mdlEntidad"
						/>
					</Modal>
				</>
			);
		}
	}
}

PendientesPorRealizar.propTypes = {
	esAdmin: PropTypes.any,
};
